// @flow

import React, { type Node } from 'react';
import cx from 'classnames';

import styles from './RadioButtonGroup.module.css';

type Props = {
  label?: string,
  inline?: Boolean,
  value?: string | number | boolean,
  onChange: Function,
  children: Node,
  name?: string,
  invalid?: boolean,
  multicolumn?: boolean,
};

function RadioButtonGroup({
  label,
  inline,
  value,
  onChange,
  children,
  name,
  invalid,
  multicolumn,
}: Props) {
  const classes = cx(
    'form-group',
    {
      'form-inline': inline,
      invalid,
    },
    multicolumn && 'row flex-items-xs-left',
  );

  const handleChange = event => {
    onChange(event.currentTarget.value);
  };

  return (
    <div className={ classes }>
      {/* eslint-disable-next-line jsx-a11y/label-has-for  */ }
      { label && <label>{ label }</label> }
      { React.Children.map(children, child => {
        const checked =
          value != null &&
          child.props.value != null &&
          child.props.value.toString() === value.toString();
        return React.cloneElement(child, {
          className: multicolumn && cx(styles.narrow, 'col-xs-12 col-md-4'),
          checked,
          name,
          onChange: handleChange,
        });
      }) }
    </div>
  );
}

export default RadioButtonGroup;
