// @flow

import React, { type Node } from 'react';
import cx from 'classnames';
import Icon from '../Icon';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

import styles from './Button.module.css';

type Props = {
  color: string,
  disabled?: boolean,
  responsive?: boolean,
  full?: boolean,
  icon?: string,
  className?: string,
  children: Array<Node>,
  isLoading?: boolean,
};

const Button = ({
  color,
  children,
  disabled,
  responsive,
  full,
  icon,
  className,
  isLoading,
  ...props
}: Props) => {
  const classes = cx('button', className, {
    'button--primary': color === 'primary',
    'button--secondary': color === 'secondary',
    'button--confirm': color === 'confirm',
    'button--light': color === 'light',
    'button--responsive': responsive,
    'button--full': full,
    button__icon: !!icon,
    disabled: disabled || isLoading,
  });

  return (
    <button {...props} className={classes} aria-disabled={disabled}>
      {!isLoading && icon && <Icon name={icon} />}
      {isLoading && (
        <div className="flex flex-center">
          <LoadingSpinner className={styles.loading} size="small" />
        </div>
      )}
      {!isLoading && children}
    </button>
  );
};

Button.defaultProps = {
  color: 'primary',
};

export default Button;
