//@flow

import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { Trans, useTranslation } from 'react-i18next';
import ApprovalStatus from '../../../components/ApprovalStatus';
import { AuthContext } from '../../../components/AuthProvider/AuthProvider';
import FormattedDate from '../../../components/FormattedDate';
import PaddedId from '../../../components/PaddedId';
import { type ContractMutation } from '../../../types/contract-mutation-types';

import { differenceInDays } from 'date-fns';
import DeliveryDate from '../../../components/DeliveryDate';
import useAssignees from '../../../hooks/useAssignees';
import {
  formatCategoryName, getCurrentApprovalPoint, getCurrentVehicleCategory
} from '../../../util/MutationProperties';
import styles from './MutationListItem.module.css';

type Props = {
  mutation: ContractMutation,
};

function MutationListItem({ mutation }: Props) {
  const { profile } = useContext(AuthContext);
  const history = useHistory();
  const { t } = useTranslation();
  const { assignees, exchangeAssignees, returnAssignees, assigneesLoading } = useAssignees(mutation, "PROVIDER");

  const currentApprovalPoint = getCurrentApprovalPoint(mutation);

  const activeAssignees = () => {
    const assigneeUsers = [assignees[0]?.assigneeUser, returnAssignees[0]?.assigneeUser, exchangeAssignees[0]?.assigneeUser].filter(assignne => !!assignne);

    if (assigneeUsers.length === 0) {
      if (currentApprovalPoint.type === 'PROVIDER' && profile.swisscomEmployeeNr === currentApprovalPoint.approver?.swisscomEmployeeNr) {
        return <span>{`${profile.firstname} ${profile.lastname} ${profile.unit}`}<br /></span>;
      }
      return '-';
    }

    return assigneeUsers.map((user, index) => (<span key={user.swisscomEmployeeNr + index}>{`${user.firstname} ${user.lastname} ${user.unit}`}<br /></span>));
  };

  const requiresAction =
    currentApprovalPoint &&
    mutation.status === 'PENDING' &&
    profile &&
    // Current user is approver of the current approval point and mutation is not closed
    ((currentApprovalPoint.eligibleApprovers.find(
      user => user.swisscomEmployeeNr.toLowerCase() === profile.swisscomEmployeeNr.toLowerCase(),
    ) != null &&
      mutation.closedAt == null) ||
      // Mutation is in the PROVIDER stage and current user is old provider of a change vehicle mutation
      (currentApprovalPoint.type === 'PROVIDER' &&
        mutation.type === 'VEHICLECHANGE' &&
        mutation.contract &&
        mutation.changeVehicleMutation &&
        !mutation.changeVehicleMutation.vehicleReturnConfirmed &&
        mutation.contract.vehicle.provider.responsibles.find(
          user =>
            user.swisscomEmployeeNr.toLowerCase() === profile.swisscomEmployeeNr.toLowerCase(),
        ) != null));

  let route;

  if (mutation.status === 'DRAFT' && mutation.type === 'REQUESTVEHICLE') {
    if (mutation.requestVehicleMutation) {
      route = `/request-vehicle?categoryId=${mutation.requestVehicleMutation.vehicleCategory.id}&draftId=${mutation.id}`;
    }
  } else if (mutation.status === 'DRAFT' && mutation.type === 'RETURNVEHICLE' && mutation.contract) {
    route = `/contracts/${mutation.contract.id}/return-vehicle?draftId=${mutation.id}`;
  } else if (mutation.status === 'DRAFT' && mutation.type === 'DRIVERCHANGE' && mutation.contract) {
    route = `/contracts/${mutation.contract.id}/driver-change?draftId=${mutation.id}`;
  } else if (mutation.status === 'DRAFT' && mutation.type === 'VEHICLECHANGE' && mutation.contract) {
    route = `/contracts/${mutation.contract.id}/vehicle-change?draftId=${mutation.id}`;
  } else {
    route = `/mutations/${mutation.id}`;
  }


  return (
    <tr className={styles.row} onClick={() => history.push(route)}>
      <td>{requiresAction && <span className="status" aria-label="Unread"></span>}</td>
      <td>
        <PaddedId id={mutation.id} />
      </td>
      <td>
        <Trans i18nKey={`mutationtypes.${mutation.type.toLowerCase()}`} />
      </td>
      {mutation.contract && <td>
        {mutation.contract.contractor.lastname} {mutation.contract.contractor.firstname}
        <br />
        {mutation.contract.contractor.unit}
      </td>}
      {!mutation.contract && <td>-</td>}
      {mutation.contract && <td>{formatCategoryName(getCurrentVehicleCategory(mutation.requestVehicleMutation ? mutation.requestVehicleMutation : mutation.contract.vehicle))}</td>}
      {!mutation.contract && <td>-</td>}
      <td>
        <DeliveryDate
          mutationType={mutation.type}
          date={mutation.dateOfExecution ? mutation.dateOfExecution : null}
          warningEnabled={mutation.status === 'PENDING'}
        />
      </td>
      {mutation.type === 'REQUESTVEHICLE' ? (
        <td>{mutation.requestVehicleMutation?.sNumber || '-'}</td>
      ) : (
        <td>{mutation.contract && mutation.contract.vehicle ? mutation.contract.vehicle?.sNumber :  '-'}</td>
      )}

      <td>
        <div>
          {currentApprovalPoint && <ApprovalStatus
            status={currentApprovalPoint.type}
            mutationStatus={mutation.status}
            lastActionTaken={new Date(currentApprovalPoint.createdAt)}
            warningEnabled={
              mutation.status === 'PENDING' &&
              currentApprovalPoint?.type !== 'PROVIDER' &&
              differenceInDays(new Date(), new Date(currentApprovalPoint.createdAt)) > 9
            }
          />}
          {!currentApprovalPoint && t('common.draft')}
        </div>
        <div>
          {mutation.status === 'PENDING' ? (
            <FormattedDate date={currentApprovalPoint.createdAt} />
          ) : (
            <FormattedDate date={mutation.closedAt} />
          )}
        </div>
      </td>
      {mutation.type === 'REQUESTVEHICLE' || mutation.type === 'VEHICLECHANGE' ? (
        <td>{mutation.requestVehicleMutation?.selectedProvider?.name || '-'}</td>
      ) : (
        <td>{mutation.contract && mutation.contract.vehicle ? mutation.contract.vehicle?.provider.name : '-'}</td>
      )}
      <td>{assigneesLoading ? <div className={styles.dot_flashing}></div> : activeAssignees()}</td>
    </tr>
  );
}

export default MutationListItem;
