// @flow

import React from 'react';
import Select from '../Select';

type Props = {
  value?: string,
  onChange?: Function,
  onBlur?: Function,
  steps: number,
  [key: any]: any,
};

function MileageInput({ value, steps, min, max, onChange, onBlur, ...props }: Props) {
  const options = [{ value: min, label: min }];
  let lastNum = min;
  while (lastNum < max) {
    lastNum = lastNum + steps;
    options.push({ value: lastNum, label: lastNum });
  }

  return (
    <Select
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      isSearchable={false}
      options={options}
    />
  );
}

MileageInput.defaultProps = {
  min: 5000,
  max: 60000,
  steps: 5000,
};

export default MileageInput;
