import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import cx from 'classnames';

import styles from './ApprovalStatus.module.css';
import ReactTooltip from 'react-tooltip';
import { differenceInDays } from 'date-fns/esm';
import Icon from '../Icon';
import useIdentifier from '../../hooks/useIdentifier';

export const statusMap = {
  FIRST_APPROVAL_PT: 'firstapprovalpoint',
  SECOND_APPROVAL_PT: 'secondapprovalpoint',
  FLEET_MGMT: 'fleetmgmt',
  PROVIDER: 'provider',
};

type Props = {
  status: string,
  mutationStatus: string,
  warningEnabled?: boolean,
  lastActionTaken?: Date,
};

function ApprovalStatus({ status, mutationStatus, warningEnabled, lastActionTaken }: Props) {
  const { t } = useTranslation();

  const classes = cx({
    [styles.warning]: warningEnabled,
  });

  const idleDays = differenceInDays(new Date(), lastActionTaken);
  const tooltipId = useIdentifier('tooltip_');

  const warningEnabledProps = warningEnabled && {
    'data-tip': t('approvalpointstatus.idle', { idleDays }),
    'data-for': tooltipId,
  };

  if (mutationStatus !== 'PENDING') {
    return <Trans i18nKey={'approvalpointstatus.' + mutationStatus.toLowerCase()} />;
  }

  return (
    <span className={classes}>
      <span className={styles.content} {...warningEnabledProps}>
        {warningEnabled && <Icon className={styles.icon} name="046-information-circle" />}
        <Trans i18nKey={`approvalpointstatus.${statusMap[status]}`} />
      </span>
      {warningEnabled && (
        <>
          <ReactTooltip
            id={tooltipId}
            effect="solid"
            type="light"
            border
            className={styles.tooltip}
            place="top"
          />
        </>
      )}
    </span>
  );
}

export default ApprovalStatus;
