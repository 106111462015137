const protocolLinksPw = {
  de:
    'https://swisscom.sharepoint.com/sites/reisen-mobilitaet-intranet/Freigegebene%20Dokumente/Forms/AllItems.aspx?id=%2Fsites%2Freisen%2Dmobilitaet%2Dintranet%2FFreigegebene%20Dokumente%2FGeneral%2FFleet%5FManagement%2Fde%2FZustandsprotokoll%5FDE%2Epdf&parent=%2Fsites%2Freisen%2Dmobilitaet%2Dintranet%2FFreigegebene%20Dokumente%2FGeneral%2FFleet%5FManagement%2Fde',
  fr:
    "https://swisscom.sharepoint.com/sites/reisen-mobilitaet-intranet/Freigegebene%20Dokumente/Forms/AllItems.aspx?id=%2Fsites%2Freisen%2Dmobilitaet%2Dintranet%2FFreigegebene%20Dokumente%2FGeneral%2FFleet%5FManagement%2Ffr%2FProtocol%5Fd%27%C3%A9tat%5Fvoitures%5Fde%5Ftourisme%2Epdf&parent=%2Fsites%2Freisen%2Dmobilitaet%2Dintranet%2FFreigegebene%20Dokumente%2FGeneral%2FFleet%5FManagement%2Ffr",
  it:
    "https://swisscom.sharepoint.com/sites/reisen-mobilitaet-intranet/Freigegebene%20Dokumente/Forms/AllItems.aspx?id=%2Fsites%2Freisen%2Dmobilitaet%2Dintranet%2FFreigegebene%20Dokumente%2FGeneral%2FFleet%5FManagement%2Fit%2FProtocollo%5Fdi%5Fstato%5Fautovetture%2Epdf&parent=%2Fsites%2Freisen%2Dmobilitaet%2Dintranet%2FFreigegebene%20Dokumente%2FGeneral%2FFleet%5FManagement%2Fit"
};

const protocolLinksUv = {
  de: "https://swisscom.sharepoint.com/sites/reisen-mobilitaet-intranet/Freigegebene%20Dokumente/Forms/AllItems.aspx?id=%2Fsites%2Freisen%2Dmobilitaet%2Dintranet%2FFreigegebene%20Dokumente%2FGeneral%2FFleet%5FManagement%2Fde%2FZustandsprotokoll%5FNFz%5Fde%2Epdf&parent=%2Fsites%2Freisen%2Dmobilitaet%2Dintranet%2FFreigegebene%20Dokumente%2FGeneral%2FFleet%5FManagement%2Fde",
  fr: "https://swisscom.sharepoint.com/sites/reisen-mobilitaet-intranet/Freigegebene%20Dokumente/Forms/AllItems.aspx?id=%2Fsites%2Freisen%2Dmobilitaet%2Dintranet%2FFreigegebene%20Dokumente%2FGeneral%2FFleet%5FManagement%2Ffr%2FProtocol%5Fd%27%C3%A9tat%5Fv%C3%A9hicules%5Futilitaires%2Epdf&parent=%2Fsites%2Freisen%2Dmobilitaet%2Dintranet%2FFreigegebene%20Dokumente%2FGeneral%2FFleet%5FManagement%2Ffr",
  it: "https://swisscom.sharepoint.com/sites/reisen-mobilitaet-intranet/Freigegebene%20Dokumente/Forms/AllItems.aspx?id=%2Fsites%2Freisen%2Dmobilitaet%2Dintranet%2FFreigegebene%20Dokumente%2FGeneral%2FFleet%5FManagement%2Fit%2FProtocollo%5Fdi%5Fstato%5Fautovetture%2Epdf&parent=%2Fsites%2Freisen%2Dmobilitaet%2Dintranet%2FFreigegebene%20Dokumente%2FGeneral%2FFleet%5FManagement%2Fit",
};

export function getConditionProtocolLink(language, vehicleType) {
  const protocolLink = vehicleType === 'utilityvehicle' ? protocolLinksUv[language.toLowerCase()] : protocolLinksPw[language.toLowerCase()];

  if (!protocolLink) {
    return protocolLinksPw.de;
  }

  return protocolLink;
}
