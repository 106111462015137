import React from 'react';
import cx from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import BillingCycleListItem from '../BillingCycleListItem/index';
import useContractQueryFilters from '../useContractQueryFilters';
import tableStyles from '../ContractTable.module.css';
import LoadingSpinner from '../../../components/LoadingSpinner';
import EmptyState from '../../../components/EmptyState';
import ErrorState from '../../../components/ErrorState';
import Pagination from '../../../components/Pagination';
import useBillingCycles from '../../../hooks/useBillingCycles';

function BillingCycleTable() {
  const { t } = useTranslation();

  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const page = urlParams.get('p') ? +urlParams.get('p') - 1 : undefined;

  const { filter: billingCycleFilter, setSearchQuery } = useContractQueryFilters();
  const { billingCycles, isLoading, hasError, pagination, loadMore, setPage } = useBillingCycles(
    billingCycleFilter,
    page,
  );

  return (
    <>
      <div className="row flex-items-md-right">
        <div className="col-md-3">
          <Input
            id="search-billing-cycle"
            placeholder={t('common.search')}
            onChange={evt => {
              setPage(0);
              setSearchQuery(evt.currentTarget.value);
            }}
            value={billingCycleFilter?.query || ''}
            inputClassName="margin-0"
          />
        </div>
        <div className="col-md-3 align-right">
          <Link to="/billing/run-billing">
            <Button className="padding-v-0 padding-h-1">
              <Trans i18nKey="contractoverview.startBilling" />
            </Button>
          </Link>
        </div>
      </div>
      <div className="row">
        <div className={`table table--responsive ${tableStyles.orderlist}`}>
          <div className="table__wrapper">
            <table className={cx({ [tableStyles.empty]: billingCycles?.length === 0 })}>
              <thead>
                <tr>
                  <td style={{ width: '15%' }}>{t('billingcycletable.billingcyclename')}</td>
                  <td style={{ width: '5%' }}>{t('billingcycletable.id')}</td>
                  <td style={{ width: '15%' }}>{t('billingcycletable.status')}</td>
                  <td style={{ width: '20%' }}>{t('billingcycletable.creator')}</td>
                  <td style={{ width: '15%' }}>{t('billingcycletable.timestamp')}</td>
                  <td style={{ width: '15%' }}>{t('billingcycletable.sum')}</td>
                </tr>
              </thead>

              <tbody>
                {isLoading && (
                  <tr>
                    <td colSpan="12">
                      <div className={tableStyles.empty__outer}>
                        <LoadingSpinner />
                      </div>
                    </td>
                  </tr>
                )}
                {!isLoading && !hasError && billingCycles?.length === 0 && (
                  <tr>
                    <td colSpan="12">
                      <div className={tableStyles.empty__outer}>
                        <EmptyState />
                      </div>
                    </td>
                  </tr>
                )}
                {!isLoading && hasError && (
                  <tr>
                    <td colSpan="12">
                      <div className={tableStyles.empty__outer}>
                        <ErrorState />
                      </div>
                    </td>
                  </tr>
                )}

                {!isLoading &&
                  !hasError &&
                  billingCycles.length > 0 &&
                  billingCycles.map(b => <BillingCycleListItem key={b.id} billingCycle={b} />)}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 flex flex-items-center flex-space-between">
          {pagination.totalCount > pagination.take && pagination.totalCount > 0 && (
            <Pagination pagination={pagination} loadMore={loadMore} />
          )}
        </div>
      </div>
    </>
  );
}

export default BillingCycleTable;
