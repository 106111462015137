// @flow

import { type OrderDirection } from '../types/order-types';
import { type QueryComparator } from '../types/query-comparator-types';

type FilterFilters = {
  [key: string]: { value: any, comparator: QueryComparator },
};

export type SortFilters = {
  [key: string]: OrderDirection,
};

export type QueryFilters = {
  sort?: SortFilters,
  softFilter?: FilterFilters,
  hardFilter?: FilterFilters,
  query?: string,
  take?: number,
  skip?: number,
};

export const parseQueryFilters = (filters?: QueryFilters) => {
  const sort = parseSort(filters?.sort);
  const query = filters?.query;
  const take = filters?.take || 100;
  const skip = filters?.skip;
  const softFilter = parseFilter(filters?.softFilter);
  const hardFilter = parseFilter(filters?.hardFilter);

  return {
    sort,
    q: query,
    softFilter,
    hardFilter,
    take,
    skip,
  };
};

export const parseSort = (sortFilters?: SortFilters) => {
  const entries = sortFilters ? Object.entries(sortFilters) : [];
  if (entries.length === 0) {
    return undefined;
  }

  return entries.map(([key, value]) => `${key}|${value}`).join(',');
};

export const parseFilter = (filters?: FilterFilters) => {
  const entries = filters ? Object.entries(filters) : [];
  if (entries.length === 0) {
    return undefined;
  }

  return entries.map(([key, value]) => `${key}|${value.comparator}|${value.value}`).join(',');
};
