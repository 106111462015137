// @flow

import { useState } from 'react';
import omit from 'lodash/fp/omit';

import { type OrderDirection } from '../types/order-types';
import { type QueryComparator } from '../types/query-comparator-types';
import { type QueryFilters } from '../util/QueryFilterHelper';

/**
 * Provides functions to create backend queries to sort and filter entities
 * @param {*} initialMutationFilter Initial Value
 */
function useQueryFilters(initialFilter?: QueryFilters = {}) {
  const [filter, setFilter] = useState<QueryFilters>(initialFilter);

  /**
   * Sets the search query
   * @param {*} query The new search query
   */
  const setSearchQuery = (query: string) => {
    setFilter((filter: QueryFilters): QueryFilters => ({
      ...filter,
      query,
    }));
  };

  /**
   * Sets sort filter
   * @param sortOrders
   */
  const setSort = (sortOrders: Array<[string, OrderDirection]>) => {
    const sort = sortOrders.reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
    setFilter((filter: QueryFilters): QueryFilters => ({
      ...filter,
      sort: {
        ...filter?.sort,
        ...sort,
      },
    }));
  };

  /**
   * Sets sort filter exclusively
   * (Removes other filters)
   * @param sortOrders
   */
  const setExclusiveSort = (sortOrders: Array<[string, OrderDirection]>) => {
    const sort = sortOrders.reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
    setFilter((filter: QueryFilters): QueryFilters => ({
      ...filter,
      sort,
    }));
  };

  /**
   * Removes a where filter
   * @param {*} key Filter key to remove
   */
  const removeWhereFilter = (key: string) => {
    setFilter((filter: QueryFilters): QueryFilters => ({
      ...filter,
      hardFilter: omit([key], filter?.hardFilter),
      softFilter: omit([key], filter?.softFilter),
    }));
  };

  /**
   * Sets a where filter
   * @param {*} key Where filter key
   * @param {*} value value of the filter
   * @param {*} comparator
   */
  const setWhereFilter = (
    key: string,
    value?: string,
    comparator: QueryComparator,
    soft: boolean = false,
    replaceOldEntry: boolean = false,
  ) => {
    if (!value || replaceOldEntry) {
      removeWhereFilter(key);
    }
    if (value) {
      setFilter((filter: QueryFilters): QueryFilters => ({
        ...filter,
        [soft ? 'softFilter' : 'hardFilter']: {
          ...(soft ? filter.softFilter : filter.hardFilter),
          [key]: {
            value,
            comparator,
          },
        },
      }));
    }
  };

  return {
    filter,
    setFilter,
    setSearchQuery,
    setExclusiveSort,
    setSort,
    setWhereFilter,
    removeWhereFilter,
  };
}

export default useQueryFilters;
