//@flow

import React from 'react';
import { useTranslation } from 'react-i18next';
import FormField from '../FormField';
import Textarea from '../Textarea';

type Props = {
  value?: string,
  name?: string,
  onBlur?: Function,
  onChange: Function,
};

function CommentField({ name, value, onBlur, onChange }: Props) {
  const { t } = useTranslation();

  return (
    <FormField label={t('common.labels.comment')}>
      <Textarea
        hasMarginTop={false}
        name={name}
        value={value}
        onChange={e => onChange(e.currentTarget.value)}
        onBlur={e => onBlur && onBlur(e)}
        maxLength={400}
      />
    </FormField>
  );
}

CommentField.defaultProps = {
  name: 'comment',
};

export default CommentField;
