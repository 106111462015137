import React, { createRef, ReactElement, useEffect } from 'react';
import { Trans } from 'react-i18next';
import i18n from '../../../../i18n';
import { FlyOutListItemMouseEvent } from '../../../../types/mouse-event-types';
import styles from '../LanguageSelect.module.css';

type Props = {
  lang: string;
  handleToggle: (event: FlyOutListItemMouseEvent) => void;
};

const LanguageSelectButton = ({ lang, handleToggle }: Props): ReactElement => {
  // eslint-disable-next-line
  const langRef = createRef();

  useEffect(() => {
    if (langRef.current) {
      langRef.current.onclick = (e: FlyOutListItemMouseEvent) => {
        i18n.changeLanguage(lang);
        handleToggle(e);
      };
    }

    return () => {
      if (langRef.current?.onclick) {
        // eslint-disable-next-line
        langRef.current.onclick = null;
      }
    };
  }, [handleToggle, lang, langRef]);
  return (
    <button type="button" className={styles.langItem} ref={langRef} tabIndex={-1}>
      <Trans i18nKey={'common.languages.' + lang}></Trans>
    </button>
  );
};

export default LanguageSelectButton;
