// @flow

import React from 'react';
import cx from 'classnames';

import styles from './ValidationMessage.module.css';
import Icon from '../Icon';
import { useTranslation } from 'react-i18next';

type Props = {
  i18nKey?: string,
  invalid?: boolean,
  className?: string,
};

function ValidationErrorMessage({ i18nKey, invalid, className }: Props) {
  const { t } = useTranslation();
  if (!invalid) {
    return null;
  }

  return (
    <div className={cx(styles.container, className)}>
      <Icon name="026-exclamation-mark-circle"/>
      <span>{t(i18nKey)}</span>
    </div>
  );
}

export default ValidationErrorMessage;
