// @flow

import { useState, useEffect } from 'react';
import { getDebtorCostcenterById } from '../services/debtorCostcenterService';
import { type DebtorCostcenter } from '../types/debtor-costcenter-types';

function useDebtorCostcenter(id: number | null) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [debtorCostcenter, setDebtorCostcenter] = useState<?DebtorCostcenter>(null);

  useEffect(() => {
    let isCancelled = false;

    async function fetchDebtorCostcenter() {
      try {
        setIsLoading(true);
        const data = id ? (await getDebtorCostcenterById(id)).data : null;
        if (!isCancelled && data) {
          let formattedData = {
            ...data,
            costcenter: {
              label: data?.costcenter
                ? data?.costcenter
                : 'Costcenter wählen...', //TODO Translation
              value: data?.costcenter ? data?.costcenter : null,
            },
            debtor: {
              label: data?.debtor?.id
                ? `${data.debtor.number} - ${data.debtor.name}`
                : 'Debitor wählen...', //TODO Translation
              value: data?.debtor?.id ? data?.debtor?.id : null,
            },
          };
          setDebtorCostcenter(formattedData);
          setIsLoading(false);
        }
      } catch (err) {
        if (!isCancelled) {
          setHasError(true);
          setIsLoading(false);
        }
        throw err;
      }
    }

    fetchDebtorCostcenter();

    return () => {
      isCancelled = true;
    };
  }, [id]);

  return { isLoading, debtorCostcenter, hasError };
}

export default useDebtorCostcenter;
