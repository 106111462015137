// @flow

import React, { useContext, useState } from 'react';
import cx from 'classnames';
import format from 'date-fns/fp/format';
import {
  type ContractMutationProtocolEntry,
  type ContractMutation,
} from '../../types/contract-mutation-types';
import { Trans, useTranslation } from 'react-i18next';
import ProfileLink from '../ProfileLink';
import { AuthContext } from '../AuthProvider/AuthProvider';

import styles from './Protocol.module.css';
import ApprovalPointModal from '../ApprovalPointModal/ApprovalPointModal';
import { MutationDetailContext } from '../../container/MutationDetail/MutationDetail';
import FormattedDate from '../FormattedDate';
import { useNotifications } from '../Notifications/NotificationsProvider';
import useAssignees from '../../hooks/useAssignees';
import AssigneeHistoryItem from './AssigneeHistoryItem';

type Props = {
  item: ContractMutationProtocolEntry,
  mutation: ContractMutation,
  labellingDate: ?Date,
};

function ProtocolItem({ item, mutation, labellingDate }: Props) {
  const { profile } = useContext(AuthContext);
  const { sendManualReminder } = useContext(MutationDetailContext);
  const { addAlert, addSuccess } = useNotifications();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const { assignees, exchangeAssignees, returnAssignees, addNewAssignee } = useAssignees(
    mutation,
    item.status,
  );
  const { t } = useTranslation();

  // [0] is always the current assignee and length -1 is always the oldest
  const isCurrentAssignee =
    assignees[0]?.assigneeUser?.swisscomEmployeeNr === profile?.swisscomEmployeeNr;
  const isCurrentExchangeAssignee =
    exchangeAssignees[0]?.assigneeUser?.swisscomEmployeeNr === profile?.swisscomEmployeeNr;
  const isCurrentReturnAssignee =
    returnAssignees[0]?.assigneeUser?.swisscomEmployeeNr === profile?.swisscomEmployeeNr;

  const isApprover = !!item.responsibleUsers.find(
    user => user.swisscomEmployeeNr === profile?.swisscomEmployeeNr,
  );

  let isResponsibleForProvider = false;

  if (mutation.contract && mutation.contract.vehicle) {
    isResponsibleForProvider = mutation.contract.vehicle.provider.responsibles.find(
      resp => resp.swisscomEmployeeNr === profile?.swisscomEmployeeNr,
    );
  }

  const mapAssignees = (assigneesToMap, finished) => {
    return assigneesToMap
      .slice(0, 3)
      .reverse()
      .map((assignee, index) => assignee && <AssigneeHistoryItem key={assignee.id} assignee={assignee} lineThrough={assigneesToMap.length !== index + 1 || !!finished} />);
  };

  const setAssignee = async (referenceType, contractMutationId) => {
    try {
      await addNewAssignee(referenceType, contractMutationId);
      addSuccess(t('protocol.submit.success'));
      window.scrollTo(0, 0);
    } catch (err) {
      addAlert(t('protocol.submit.failure'));
      window.scrollTo(0, 0);
    }
  };

  const completed =
    mutation.type === 'VEHICLECHANGE' && item.status === 'PROVIDER'
      ? Boolean(mutation.closedAt && item.completed)
      : item.completed;

  let providerChanged = false;

  if (mutation.contract && mutation.contract.vehicle) {
    providerChanged = mutation.type === 'VEHICLECHANGE' &&
    mutation.requestVehicleMutation?.selectedProvider?.id !== mutation.contract.vehicle.provider.id;
  }


  const classes = cx(styles.item, {
    [styles.completed]: completed && item.approved !== false,
    [styles.rejected]: completed && item.approved === false,
  });

  const formatDate = format('dd.MM.yyyy');

  const showFleetActions =
    item.active && profile?.isFleetMember && !['FLEET_MGMT', 'PROVIDER'].includes(item.status);

  const newProviderName = mutation.requestVehicleMutation?.selectedProvider?.name;
  const oldProviderName = mutation.contract?.vehicle?.provider?.name;

  return (
    <>
      <div className={classes}>
        <div className={styles.step}></div>
        <div className={styles.description}>
          <div className={styles.title}>
            <div>
              <Trans i18nKey={`protocol.status.${item.status}`} />
            </div>
            {labellingDate && (
              <div className={styles.date}>{formatDate(new Date(labellingDate))}</div>
            )}
          </div>
          {mutation.type !== 'VEHICLECHANGE' && mapAssignees(assignees, item.closedBy)}
          {mutation.type !== 'VEHICLECHANGE' &&
            item.status === 'PROVIDER' &&
            !item.closedBy &&
            item.active &&
            !isCurrentAssignee &&
            isApprover && (
            <button
              onClick={() => setAssignee('APPROVAL')}
              className={cx('link', styles.noUnderline)}
            >
              {t('protocol.inprocess')}
            </button>
          )}
          {item.closedBy && (item.status !== 'PROVIDER' || mutation.type !== 'VEHICLECHANGE') ? (
            <ProfileLink key={item.closedBy.username} user={item.closedBy} />
          ) : (
            <>
              {item.overriddenResponsibleUsers.map(user => (
                <ProfileLink
                  key={user.username}
                  user={user}
                  style={{ textDecoration: 'line-through' }}
                />
              ))}
              {!['FLEET_MGMT', 'PROVIDER'].includes(item.status) &&
                item.responsibleUsers.map(user => (
                  <ProfileLink key={user.username} user={user} />
                ))}
            </>
          )}
          {item.status === 'PROVIDER' && mutation.type === 'VEHICLECHANGE' && mutation.status !== 'REJECTED' && (
            <ul>
              <li className={styles.subconfirmation}>
                <span>
                  <Trans i18nKey="vehiclechangedetail.labels.exchangedateconfirmed" />
                  {providerChanged && newProviderName && ` (${newProviderName})`}
                </span>
                <div>
                  {mutation.changeVehicleMutation?.exchangeDateConfirmed ? (
                    <FormattedDate date={mutation.changeVehicleMutation?.exchangeDateConfirmedAt} />
                  ) : (
                    <Trans i18nKey="vehiclechangedetail.labels.pending" />
                  )}
                </div>
              </li>
              {mapAssignees(exchangeAssignees, mutation.changeVehicleMutation?.exchangeDateConfirmed)}
              {!isCurrentExchangeAssignee && !mutation.changeVehicleMutation?.exchangeDateConfirmed && isApprover && (
                <button
                  onClick={() => setAssignee('EXCHANGE', mutation.changeVehicleMutation?.id)}
                  className={cx('link', styles.noUnderline)}
                >
                  {t('protocol.inprocess')}
                </button>
              )}
              {mutation.changeVehicleMutation?.exchangeDateConfirmed && (
                <li className={styles.bottomMargin}>
                  <ProfileLink user={mutation.changeVehicleMutation?.exchangeDateConfirmedBy} />
                </li>
              )}
              <li className={styles.subconfirmation}>
                <span>
                  <Trans i18nKey="vehiclechangedetail.labels.exchangeconfirmed" />
                  {providerChanged && newProviderName && ` (${newProviderName})`}
                </span>
                <div>
                  {item.approved ? (
                    <FormattedDate date={item.closedAt} />
                  ) : (
                    <Trans i18nKey="vehiclechangedetail.labels.pending" />
                  )}
                </div>
              </li>
              {mapAssignees(assignees, item.closedBy)}
              {!isCurrentAssignee && !item.closedBy && isApprover && (
                <button
                  onClick={() => setAssignee('APPROVAL')}
                  className={cx('link', styles.noUnderline)}
                >
                  {t('protocol.inprocess')}
                </button>
              )}
              {item.closedBy && (
                <li className={styles.bottomMargin}>
                  <ProfileLink user={item.closedBy} />
                </li>
              )}
              {providerChanged && (
                <>
                  <li className={styles.subconfirmation}>
                    <span>
                      <Trans i18nKey="vehiclechangedetail.labels.vehiclereturnconfirmed" />
                      {oldProviderName && ` (${oldProviderName})`}

                      <source className="type" />
                    </span>
                    <div>
                      {mutation.changeVehicleMutation?.vehicleReturnConfirmed ? (
                        <FormattedDate
                          date={mutation.changeVehicleMutation?.vehicleReturnConfirmedAt}
                        />
                      ) : (
                        <Trans i18nKey="vehiclechangedetail.labels.pending" />
                      )}
                    </div>
                  </li>
                  {mapAssignees(returnAssignees, mutation.changeVehicleMutation?.vehicleReturnConfirmedBy)}
                  {!isCurrentReturnAssignee && isResponsibleForProvider && !mutation.changeVehicleMutation?.vehicleReturnConfirmedBy && (
                    <button
                      onClick={() => setAssignee('RETURN', mutation.changeVehicleMutation?.id)}
                      className={cx('link', styles.noUnderline)}
                    >
                      {t('protocol.inprocess')}
                    </button>
                  )}
                  {mutation.changeVehicleMutation?.vehicleReturnConfirmedBy && (
                    <li>
                      <ProfileLink
                        user={mutation.changeVehicleMutation?.vehicleReturnConfirmedBy}
                      />
                    </li>
                  )}
                </>
              )}
            </ul>
          )}
          {showFleetActions && (
            <div className={styles.fleetActions}>
              <div>
                <button onClick={() => setModalOpen(true)} className="link">
                  <Trans i18nKey="approvalpointmodal.title" />
                </button>
              </div>
              {!item.manualReminderSentAt && (
                <div>
                  <button className="link" onClick={sendManualReminder}>
                    <Trans i18nKey="mutationdetail.manualreminder.label" />
                  </button>
                </div>
              )}
            </div>
          )}

          {(item.status === 'FIRST_APPROVAL_PT' || item.status === 'SECOND_APPROVAL_PT') && <div className={styles.reminders}>
            {item.firstReminderSentAt && <p>
              <Trans
                i18nKey='protocol.reminders.first'
                values={{ date: formatDate(new Date(item.firstReminderSentAt)) }}
              />
            </p>
            }
            {item.secondReminderSentAt && <p>
              <Trans
                i18nKey='protocol.reminders.second'
                values={{ date: formatDate(new Date(item.secondReminderSentAt)) }}
              />
            </p>}
          </div>}
        </div>
      </div>
      {modalOpen && (
        <ApprovalPointModal onClose={() => setModalOpen(false)} contractMutationId={mutation.id} />
      )}
    </>
  );
}

export default ProtocolItem;
