// @flow

import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { CardItem } from '../../../components/Card';
import EmployeeInformation from '../../../components/EmployeeInformation';
import ErrorState from '../../../components/ErrorState';
import FormattedDate from '../../../components/FormattedDate';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import useUser from '../../../hooks/useUser';
import {
  type ContractMutation,
  type RequestVehicleMutation,
} from '../../../types/contract-mutation-types';
import { InformationCard, InformationCardItem } from '../common';
import Comments from '../common/Comments/Comments';
import VehicleCategoryHistoryPopup from './RequestVehicleInformation/VehicleCategoryHistoryPopup';
import { getCurrentVehicleCategory, formatCategoryName } from '../../../util/MutationProperties';

type Props = {
  mutation: ContractMutation,
  requestVehicleMutation: RequestVehicleMutation,
};

function RequestVehicleSummary({ mutation, requestVehicleMutation }: Props) {
  const { t } = useTranslation();
  const { isLoading, hasError, user } = useUser(mutation.contract ? mutation.contract.contractor.swisscomEmployeeNr : '');
  const currentCategory = getCurrentVehicleCategory(requestVehicleMutation);

  return (
    <InformationCard color="purple">
      {(isLoading || hasError) && (
        <div className="flex flex-center" style={{ height: 300 }}>
          {isLoading && <LoadingSpinner />}
          {hasError && !isLoading && <ErrorState />}
        </div>
      )}
      {!isLoading && !hasError && user && (
        <>
          <CardItem>
            <EmployeeInformation user={user} />
          </CardItem>
          <CardItem>
            <InformationCardItem label={t('common.labels.vehiclecategory')}>
              {formatCategoryName(currentCategory)}
              <div>
                <VehicleCategoryHistoryPopup mutationId={mutation.id}></VehicleCategoryHistoryPopup>
              </div>
            </InformationCardItem>
            <InformationCardItem label={t('common.labels.ordernumber')}>
              {requestVehicleMutation.orderNumber}
            </InformationCardItem>
            <InformationCardItem label={t('requestvehicledetail.labels.requestedrentalstart')}>
              <FormattedDate date={mutation.dateOfExecution} />
            </InformationCardItem>
            <InformationCardItem label={t('requestvehicledetail.labels.registrationcanton')}>
              <Trans i18nKey={`cantons.${requestVehicleMutation.registrationCanton}`} />
            </InformationCardItem>
            <InformationCardItem label={t('requestvehicledetail.labels.deliverycanton')}>
              <Trans i18nKey={`cantons.${requestVehicleMutation.deliveryCanton}`} />
            </InformationCardItem>
            <InformationCardItem label={t('common.labels.mileageperyear')}>
              {requestVehicleMutation.mileagePerYear}
            </InformationCardItem>
            {requestVehicleMutation.labelingLanguage && (
              <InformationCardItem label={t('common.labels.labelingLanguage')}>
                <Trans i18nKey={`common.languages.${requestVehicleMutation.labelingLanguage}`} />
              </InformationCardItem>
            )}

            {requestVehicleMutation.sNumber && (
              <InformationCardItem label={t('common.labels.snumber')}>
                {requestVehicleMutation.sNumber}
              </InformationCardItem>
            )}
            {mutation.requestVehicleMutation?.selectedProvider?.id && (
              <InformationCardItem label={t('common.labels.provider')}>
                {mutation.requestVehicleMutation?.selectedProvider?.name}
              </InformationCardItem>
            )}
          </CardItem>
          <Comments contractMutationId={mutation.id} />
        </>
      )}
    </InformationCard>
  );
}

export default RequestVehicleSummary;
