// @flow

import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import cx from 'classnames';
import tableStyles from '../ContractOverview/ContractTable.module.css';
import debtorCostcenterStyles from './DebtorCostcenterOverview.module.css';

import Container from '../../components/Container';
import LoadingSpinner from '../../components/LoadingSpinner';
import EmptyState from '../../components/EmptyState';
import ErrorState from '../../components/ErrorState';
import Pagination from '../../components/Pagination';

import DebtorCostcenterListItem from './DebtorCostcenterListItem';
import Button from '../../components/Button/Button';
import DebtorCostCenterModal from '../../components/DebtorCostcenterModal/DebtorCostCenterModal';
import useDebtorCostcenters from '../../hooks/useDebtorCostcenters';

function DebtorCostcenterOverview() {
  const location = useLocation();
  const { t } = useTranslation();
  const urlParams = new URLSearchParams(location.search);
  const page = urlParams.get('p') ? +urlParams.get('p') - 1 : undefined;

  const [showMappingModal, setShowMappingModal] = useState<boolean>(false);
  const [openMappingId, setOpenMappingId] = useState<number | null>(null);

  const {
    debtorCostcenters,
    isLoading,
    hasError,
    pagination,
    loadMore,
    createMapping,
    updateMapping,
    deleteMapping,
  } = useDebtorCostcenters(page);

  const openMappingModal = id => {
    setOpenMappingId(id);
    setShowMappingModal(!showMappingModal);
  };

  return (
    <Container>
      {showMappingModal && (
        <DebtorCostCenterModal
          debtorCostcenterId={openMappingId}
          title={'Zuordnung verwalten'}
          createMapping={createMapping}
          updateMapping={updateMapping}
          deleteMapping={deleteMapping}
          onClose={() => {
            setShowMappingModal(false);
          }}
        />
      )}
      <div className={debtorCostcenterStyles.header}>
        <h1>
          <Trans i18nKey="debtorcostcenter.title" />
        </h1>
        <Button onClick={() => openMappingModal(null)}>
          {t('debtorcostcenter.createmapping')}
        </Button>
      </div>

      <div className="row">
        <div className={`table table--responsive ${tableStyles.orderlist}`}>
          <div className="table__wrapper">
            <table className={cx({ [tableStyles.empty]: debtorCostcenters?.length === 0 })}>
              <thead>
                <tr>
                  <td style={{ width: '25%' }}>
                    <Trans i18nKey="debtorcostcenter.fields.costcenter" />
                  </td>
                  <td style={{ width: '70%' }}>
                    <Trans i18nKey="debtorcostcenter.fields.debtor" />
                  </td>
                  <td style={{ width: '5%' }}></td>
                </tr>
              </thead>

              <tbody>
                {isLoading && (
                  <tr>
                    <td colSpan="12">
                      <div className={tableStyles.empty__outer}>
                        <LoadingSpinner />
                      </div>
                    </td>
                  </tr>
                )}
                {!isLoading && !hasError && debtorCostcenters?.length === 0 && (
                  <tr>
                    <td colSpan="12">
                      <div className={tableStyles.empty__outer}>
                        <EmptyState />
                      </div>
                    </td>
                  </tr>
                )}
                {!isLoading && hasError && (
                  <tr>
                    <td colSpan="12">
                      <div className={tableStyles.empty__outer}>
                        <ErrorState />
                      </div>
                    </td>
                  </tr>
                )}

                {!isLoading &&
                  !hasError &&
                  debtorCostcenters?.length > 0 &&
                  debtorCostcenters.map(dc => (
                    <DebtorCostcenterListItem
                      key={dc.id}
                      debtorCostcenter={dc}
                      openMapping={id => openMappingModal(id)}
                    />
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 flex flex-items-center flex-space-between">
          {pagination.totalCount > pagination.take && pagination.totalCount > 0 && (
            <Pagination pagination={pagination} loadMore={loadMore} />
          )}
        </div>
      </div>
    </Container>
  );
}

export default DebtorCostcenterOverview;
