import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../components/AuthProvider/AuthProvider';
import Container from '../../components/Container';
import MessageCard from '../../components/MessageCard';
import i18n, { DEFAULT_LANGUAGE, LANGUAGES } from '../../i18n';
import MutationListCard from './MutationListCard';
import RequestVehicleCard from './RequestVehicleCard';

function Dashboard() {
  const { t } = useTranslation();
  const { profile } = useContext(AuthContext);

  const browserLanguage = i18n.language.substr(0, 2).toLowerCase();
  const activeLanguage = LANGUAGES.includes(browserLanguage) ? browserLanguage : DEFAULT_LANGUAGE;

  // Edit these links with the new links we will get by Beni
  const informationLinks = {
    de: 'https://intranet.swisscom.com/home/articles/tmc21841/neue_ansprechpartner_fuer_firmenfahrzeug',
    fr: 'https://intranet.swisscom.com/home/articles/tmc21841/neue_ansprechpartner_fuer_firmenfahrzeug',
    it: 'https://intranet.swisscom.com/home/articles/tmc21841/neue_ansprechpartner_fuer_firmenfahrzeug',
  };

  // These links are already correct
  const intranetLinks = {
    de: 'https://intranet.swisscom.com/de/ueber_swisscom/human_resources/arbeitsalltag/mobilitaet/mobility_on_demand/Seiten/fleet_management.aspx',
    fr: 'https://intranet.swisscom.com/fr/voila_swisscom/human_resources/travail_quotidien/ma_mobilite/mobility_on_demand/Pages/fleet_management.aspx',
    it: 'https://intranet.swisscom.com/it/su_swisscom/human_resources/lavoro_quotidiano/mia_mobilita/mobility_on_demand/Pagine/fleet_management.aspx',
  };

  return (
    <Container>
      <MessageCard type="important" title={t('common.shutdown.title')} className="margin-bottom-2">
        {t('common.shutdown.textBeforeFirstLink')}
        <a
          href={informationLinks[activeLanguage]}
          target="_blank"
          rel="noreferrer"
          className="margin-0"
        >
          {t('common.shutdown.firstLink')}
        </a>
        {t('common.shutdown.textAfterFirstLink')}
        <a
          href={intranetLinks[activeLanguage]}
          target="_blank"
          rel="noreferrer"
          className="margin-0"
        >
          {t('common.shutdown.secondLink')}
        </a>
        {t('common.shutdown.textAfterSecondLink')}
      </MessageCard>

      <h1>{t('dashboard.title')}</h1>
      <div className="row">
        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 flex">
          <RequestVehicleCard />
        </div>
        {profile.createdMutationsCount > 0 && (
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-8">
            <MutationListCard />
          </div>
        )}
      </div>
    </Container>
  );
}

export default Dashboard;
