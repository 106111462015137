// @flow

import { useState, useEffect } from 'react';
import { findContract } from '../services/contractService';
import { type Contract } from '../types/contract-types';

function useContract(id: number) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [contract, setContract] = useState<?Contract>(null);

  useEffect(() => {
    let isCancelled = false;

    async function fetchContract() {
      try {
        setIsLoading(true);
        const { data } = await findContract(id);
        if (!isCancelled) {
          setContract(data);
          setIsLoading(false);
        }
      } catch (err) {
        if (!isCancelled) {
          setHasError(true);
          setIsLoading(false);
        }
        throw err;
      }
    }

    fetchContract();

    return () => {
      isCancelled = true;
    };
  }, [id]);

  return { isLoading, contract, hasError };
}

export default useContract;
