// @flow

import React from 'react';
import EmployeeNumber from '../../../components/EmployeeNumber';
import { type User } from '../../../types/user-types';
import { Trans } from 'react-i18next';

type Props = {
  driver?: User | null,
};

function DriverInformation({ driver }: Props) {
  if (!driver) return null;

  return (
    <div>
      <div>
        {driver.lastname} {driver.firstname}, {driver.unit}
      </div>
      <div>
        <EmployeeNumber swisscomEmployeeNr={driver.swisscomEmployeeNr} />
      </div>
      <div>
        <Trans i18nKey="common.labels.costCenter" values={{ costCenter: driver.costCenterIdentifier }} />
      </div>
    </div>
  );
}
export default DriverInformation;
