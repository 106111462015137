export const tableHeaderConfig = [
  { i18nKey: 'common.labels.contractnumber', width: '5%' },
  { i18nKey: 'common.labels.snumber', width: '5%' },
  { i18nKey: 'contractoverview.listheader.company', width: '15%' },
  { i18nKey: 'contractoverview.listheader.debtornumber', width: '10%' },
  { i18nKey: 'contractoverview.listheader.contractstart', width: '10%' },
  { i18nKey: 'contractoverview.listheader.contractend', width: '10%' },
  { i18nKey: 'contractoverview.listheader.driver', minWidth: '300px' },
  { i18nKey: 'contractoverview.listheader.costcenter', minWidth: '10%' },
  { i18nKey: 'common.labels.employeenumber', width: '10%' },
  { i18nKey: 'common.labels.price', width: '5%' },
  { i18nKey: 'contractoverview.listheader.state', width: '5%' },
  { i18nKey: 'contractoverview.listheader.nextbilldate', width: '10%' },
  { i18nKey: 'contractoverview.listheader.lastbilldate', width: '10%' },
];
