// @flow

import React from 'react';
import cx from 'classnames';

type Props = {
  value: string,
  label?: string,
  name: string,
  onChange: Function,
  onBlur?: Function,
  type?: string,
  readOnly?: boolean,
  disabled?: boolean,
  placeholder?: string,
  hasMarginTop: boolean,
  invalid?: boolean,
  id?: string,
  inputClassName?: string,
  accept?: string
};

const Input = ({
  value,
  onChange,
  onBlur,
  label,
  name,
  type,
  readOnly,
  disabled,
  placeholder,
  hasMarginTop,
  invalid,
  id,
  inputClassName,
  accept
}: Props) => {
  const inputClasses = cx(
    {
      'margin-top-0': !hasMarginTop,
      invalid,
    },
    inputClassName,
  );

  return (
    <div className="input-field">
      <input
        type={type}
        name={name}
        readOnly={readOnly}
        disabled={disabled}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        className={inputClasses}
        id={id}
        accept={accept}
      />
      {label && <label htmlFor={name}>{label}</label>}
    </div>
  );
};

Input.defaultProps = {
  type: 'text',
  hasMarginTop: true,
};

export default React.memo<Props>(Input);
