// @flow
import { useState } from 'react';
import { Link } from 'react-router-dom';
import LogoType from '../../assets/images/swisscom-logo-type.svg';

import EasterEgg from '../../container/EasterEgg';
import styles from './Logo.module.css';

type Props = {
  className?: string,
  onlyLifeform?: Boolean,
};

const Logo = ({ className, onlyLifeform }: Props) => {
  const [clickCounter, setClickCounter] = useState(0);
  return (
    <>
      <Link to="/" tabIndex={-1} className="logo" onClick={() => setClickCounter(clickCounter + 1)}>
        <span className={styles.lifeform} />
        {!onlyLifeform && <span className={styles.type}>
          <img src={LogoType} alt="Swisscom Logo Type" />
        </span>}
      </Link>
      {clickCounter % 10 === 0 && clickCounter !== 0 && <EasterEgg />}
    </>
  );
};

export default Logo;
