// @flow

import React, { useEffect } from 'react';

import userManager from '../../auth/userManager';
import LoadingPage from '../LoadingPage';

const LoginPage = () => {
  useEffect(() => {
    userManager.signinRedirect();
  }, []);
  return <LoadingPage />;
};

export default LoginPage;
