// @flow

import addWeeks from 'date-fns/addWeeks';
import startOfDay from 'date-fns/fp/startOfDay';
import { Formik } from 'formik';
import isEqual from 'lodash/fp/isEqual';
import React, { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import AcceptDriverConditions from '../../components/AcceptDriverConditions';
import { AuthContext } from '../../components/AuthProvider/AuthProvider';
import AutoId from '../../components/AutoId';
import BackMenu from '../../components/BackMenu';
import Button from '../../components/Button';
import ButtonGroup from '../../components/ButtonGroup';
import CantonPicker from '../../components/CantonPicker';
import CategoryPicker from '../../components/CategoryPicker';
import CommentField from '../../components/CommentField';
import Container from '../../components/Container';
import ContractInformation from '../../components/ContractInformation';
import { OmitKeys } from '../../components/ContractInformation/ContractInformation';
import DatePicker from '../../components/DatePicker/DatePicker';
import DocumentLink from '../../components/DocumentLink';
import FormField from '../../components/FormField';
import MessageCard from '../../components/MessageCard';
import MileageInput from '../../components/MileageInput';
import { useNotifications } from '../../components/Notifications/NotificationsProvider';
import RoutingPrompt from '../../components/RoutingPrompt';
import ValidationErrorMessage from '../../components/ValidationErrorMessage';
import useContract from '../../hooks/useContract';
import { getOpenMutation } from '../../services/contractMutationService';
import { changeVehicle } from '../../services/contractService';
import documents from '../RequestVehicleForm/documents.json';
import { initialValues } from './initial-values';
import { validationSchema } from './validation-schema';
import styles from './VehicleChangeForm.module.css';
import VehicleChangeFormFleet from './VehicleChangeFormFleet';

function VehicleChangeForm() {
  const { t } = useTranslation();
  const history = useHistory();
  const [hasSuccess, setHasSuccess] = useState(false);
  const { id: contractId } = useParams();
  const { contract, isLoading, hasError } = useContract(contractId);
  const { profile, fetchProfile } = useContext(AuthContext);
  const { addSuccess, addAlert } = useNotifications();
  const [hasOpenMutation, setHasOpenMutation] = useState<ContractMutation | null>(null);

  useEffect(() => {
    const fetchOpenMutation = async () => {
      const {data }= await getOpenMutation(+contractId, { contractMutationType: ['DRIVERCHANGE', 'RETURNVEHICLE', 'VEHICLECHANGE'], status: ['PENDING']});
      setHasOpenMutation(data);
    };


    fetchOpenMutation();
  }, [contractId]);


  if (profile?.isFleetMember) return <VehicleChangeFormFleet />;

  async function handleSubmit(values, actions) {
    try {
      const payload = {
        registrationCanton: values.registrationCanton.value,
        deliveryCanton: values.deliveryCanton.value,
        mileagePerYear: values.mileagePerYear.value,
        dateOfChange: values.dateOfChange,
        comment: values.comment,
        vehicleCategoryId: values.selectedVehicleCategory?.value,
        acceptDriverConditions: values.acceptDriverConditions,
      };

      await changeVehicle(contractId, payload);
      fetchProfile();
      addSuccess(t('vehiclechangeform.submit.success'));
      setHasSuccess(true);
      history.push('/');
    } catch (err) {
      const errorCode = err?.response?.data?.error;

      actions.setSubmitting(false);
      addAlert(
        t(
          errorCode === 'CONFLICT_MUTATION_COLLISION'
            ? 'vehiclechangeform.submit.collision'
            : 'errorstate.defaultmessage',

          errorCode === 'CONFLICT_MUTATION_COLLISION' ? {id: hasOpenMutation?.id} : null
        ),
      );
      window.scrollTo(0, 0);
      throw err;
    }
  }

  return (
    <>
      <BackMenu to="/" text={t('vehiclechangeform.back')} />
      <Container>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            isSubmitting,
            setFieldValue,
            setFieldTouched,
            isValid,
            submitCount,
            handleSubmit,
          }) => {
            return (
              <form onSubmit={handleSubmit}>
                <RoutingPrompt
                  when={!hasSuccess && !isEqual(values, initialValues)}
                  message={t('common.formprompt')}
                />
                <h1>
                  <Trans i18nKey="vehiclechangeform.title" />
                </h1>
                <ContractInformation
                  contract={contract}
                  isLoading={isLoading}
                  hasError={hasError}
                  className={styles.section}
                  titleKey="common.labels.vehicleinformation"
                  omittedProperties={[
                    OmitKeys.mileageperyear,
                    OmitKeys.pricePerMonth,
                    OmitKeys.contractId,
                  ]}
                />
                <div className={styles.section}>

                  {hasOpenMutation && <MessageCard
                    type="important"
                    title={t('common.blockingmutations.title')}
                    className="margin-bottom-4">
                    <Trans i18nKey="common.blockingmutations.text" values={{ id: hasOpenMutation.id} }/>
                  </MessageCard>}

                  <h3>
                    <Trans i18nKey="vehiclechangeform.subtitle" />
                  </h3>

                  <FormField label={t('vehiclechangeform.fields.vehiclecategory.label')} required>
                    <CategoryPicker
                      className="font--light"
                      name="vehicleCategory"
                      value={values.selectedVehicleCategory}
                      onBlur={() => setFieldTouched('selectedVehicleCategory', true)}
                      invalid={touched.selectedVehicleCategory && errors.selectedVehicleCategory}
                      onChange={v => setFieldValue('selectedVehicleCategory', v)}
                    />
                    <ValidationErrorMessage
                      invalid={touched.selectedVehicleCategory && errors.selectedVehicleCategory}
                      i18nKey={errors.selectedVehicleCategory}
                    />
                  </FormField>

                  <FormField
                    label={t('vehiclechangeform.fields.registrationcanton.label')}
                    required
                  >
                    <CantonPicker
                      name="registrationCanton"
                      hasMarginTop={true}
                      values={values.registrationCanton}
                      onChange={v => setFieldValue('registrationCanton', v)}
                      onBlur={() => setFieldTouched('registrationCanton', true)}
                      invalid={touched.registrationCanton && errors.registrationCanton}
                    />
                    <ValidationErrorMessage
                      invalid={touched.registrationCanton && errors.registrationCanton}
                      i18nKey={errors.registrationCanton}
                    />
                  </FormField>

                  <FormField label={t('vehiclechangeform.fields.deliverycanton.label')} required>
                    <CantonPicker
                      name="deliveryCanton"
                      hasMarginTop={true}
                      values={values.deliveryCanton}
                      onChange={v => setFieldValue('deliveryCanton', v)}
                      onBlur={() => setFieldTouched('deliveryCanton', true)}
                      invalid={touched.deliveryCanton && errors.deliveryCanton}
                    />
                    <ValidationErrorMessage
                      invalid={touched.deliveryCanton && errors.deliveryCanton}
                      i18nKey={errors.deliveryCanton}
                    />
                  </FormField>

                  <FormField label={t('vehiclechangeform.fields.mileageperyear.label')} required>
                    <MileageInput
                      name="mileagePerYear"
                      hasMarginTop={false}
                      value={values.mileagePerYear}
                      onChange={value => setFieldValue('mileagePerYear', value)}
                      onBlur={() => setFieldTouched('mileagePerYear', true)}
                      invalid={touched.mileagePerYear && errors.mileagePerYear}
                    />
                    <ValidationErrorMessage
                      invalid={touched.mileagePerYear && errors.mileagePerYear}
                      i18nKey={errors.mileagePerYear}
                    />
                  </FormField>
                  <FormField label={t('vehiclechangeform.fields.dateofchange.label')} required>
                    <DatePicker
                      name="dateOfChange"
                      invalid={touched.dateOfChange && errors.dateOfChange}
                      value={values.dateOfChange}
                      options={{ minDate: startOfDay(addWeeks(new Date(), 3)) }}
                      onChange={date => setFieldValue('dateOfChange', date)}
                      onBlur={() => setFieldTouched('dateOfChange', true)}
                    />
                    <ValidationErrorMessage
                      invalid={touched.dateOfChange && errors.dateOfChange}
                      i18nKey={errors.dateOfChange}
                    />
                  </FormField>
                </div>
                <div className={styles.section}>
                  <h3>
                    <Trans i18nKey="requestvehicleform.documents" />
                  </h3>
                  <div className="margin-bottom-4 flex flex-col">
                    {documents.map(doc => (
                      <DocumentLink
                        key={doc.i18nKey}
                        type={doc.type}
                        src={doc.url}
                        target="_blank"
                        className="margin-bottom-2"
                      >
                        <Trans i18nKey={doc.i18nKey} />
                      </DocumentLink>
                    ))}
                  </div>
                  <AutoId>
                    {id => (
                      <div className="margin-bottom-4">
                        <AcceptDriverConditions
                          required
                          name="acceptDriverConditions"
                          value={values.acceptDriverConditions}
                          id={id}
                          onChange={event =>
                            setFieldValue('acceptDriverConditions', event.currentTarget.checked)
                          }
                          onBlur={() => setFieldTouched('acceptDriverConditions', true)}
                        />
                        <ValidationErrorMessage
                          className="margin-top-0 margin-bottom-4"
                          invalid={touched.acceptDriverConditions && errors.acceptDriverConditions}
                          i18nKey={errors.acceptDriverConditions}
                        ></ValidationErrorMessage>
                      </div>
                    )}
                  </AutoId>
                  <CommentField
                    value={values.comment}
                    onChange={v => setFieldValue('comment', v)}
                    onBlur={handleBlur}
                  />
                </div>
                {values.vehicleProvider && !values.vehicleProvider?.hasReserve && (
                  <MessageCard
                    type="important"
                    title={t('vehiclechangeform.noticeoftermination.title')}
                    className="margin-bottom-4"
                  >
                    <Trans i18nKey="vehiclechangeform.noticeoftermination.message" />
                  </MessageCard>
                )}

                <ButtonGroup responsive>
                  <Button
                    type="submit"
                    disabled={(submitCount > 0 && !isValid) || isSubmitting}
                    isLoading={isSubmitting}
                  >
                    {t('common.send')}
                  </Button>
                  <Button color="secondary" onClick={history.goBack} type="button">
                    {t('common.cancel')}
                  </Button>
                </ButtonGroup>
                <p>{t('formvalidation.required.descriptions')}</p>
              </form>
            );
          }}
        </Formik>
      </Container>
    </>
  );
}

export default VehicleChangeForm;
