import React, { useContext } from 'react';
import * as Yup from 'yup';

import Modal from '../Modal';
import { Formik } from 'formik';
import FormField from '../FormField';
import UserPicker from '../UserPicker/UserPicker';
import ButtonGroup from '../ButtonGroup';
import Button from '../Button';
import { Trans, useTranslation } from 'react-i18next';
import { MutationDetailContext } from '../../container/MutationDetail/MutationDetail';
import ValidationErrorMessage from '../ValidationErrorMessage';

const validationSchema = Yup.object().shape({
  users: Yup.array(
    Yup.object().shape({
      label: Yup.string().required('formvalidation.required.message'),
      value: Yup.string().required('formvalidation.required.message'),
    }),
  )
    .nullable()
    .required('formvalidation.required.message'),
});

function ApprovalPointModal({ onClose, contractMutationId }) {
  const { t } = useTranslation();
  const { refetch, changeEligibleApprovers, currentApprovalPoint } = useContext(
    MutationDetailContext,
  );

  const handleSubmit = async values => {
    const payload = {
      employeeNrs: values.users.map(v => v.value),
    };
    await changeEligibleApprovers(payload);
    refetch();
  };

  return (
    <Modal title={t('approvalpointmodal.title')} size="medium" onClose={onClose}>
      <Formik
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        initialValues={{ users: null }}
      >
        {({
          values,
          touched,
          errors,
          setFieldValue,
          setFieldTouched,
          handleSubmit,
          isSubmitting,
          isValid,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <FormField label={t('approvalpointmodal.label')}>
                <UserPicker
                  name="users"
                  value={values.users}
                  onChange={values => setFieldValue('users', values)}
                  onBlur={values => setFieldTouched('users', true)}
                  invalid={touched.driver && errors.driver}
                  isMulti
                  hiddenUsers={currentApprovalPoint.eligibleApprovers}
                  hiddenUserLabelI18nKey="approvalpointmodal.userisapprovernotice"
                />
                <ValidationErrorMessage
                  i18nKey={errors.users}
                  invalid={touched.users && errors.users}
                />
              </FormField>
              <ButtonGroup responsive>
                <Button type="submit" disabled={!isValid || isSubmitting}>
                  <Trans i18nKey="common.save" />
                </Button>
                <Button onClick={onClose} color="secondary">
                  <Trans i18nKey="common.cancel" />
                </Button>
              </ButtonGroup>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
}

export default ApprovalPointModal;
