// @flow

import { useState, useEffect } from 'react';
import uniq from 'lodash/fp/uniq';

import { type User } from '../types/user-types';

import { getUserByEmployeeNr } from '../services/userService';

function useUsers(employeeNrs: Array<string>) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [users, setUsers] = useState<Array<User>>([]);

  useEffect(() => {
    async function fetchUsers() {
      try {
        setIsLoading(true);
        const uniqueEmployeeNrs = uniq(employeeNrs);

        if (uniqueEmployeeNrs.length < employeeNrs.length) {
          throw new Error('Duplicate employeeNr supplied');
        }

        const pendingUsers = uniqueEmployeeNrs.map(async employeeNr => {
          return getUserByEmployeeNr(employeeNr, true);
        });

        const data = await Promise.all(pendingUsers);
        setUsers(data);
        setIsLoading(false);
      } catch (err) {
        setHasError(true);
        setIsLoading(false);
        throw err;
      }
    }

    if (!isLoading && users.length === 0) fetchUsers();
  }, [employeeNrs, users, isLoading]);

  return { isLoading, hasError, users };
}

export default useUsers;
