//@flow

import React, { useContext } from 'react';
import { Trans } from 'react-i18next';

import { type ContractMutation } from '../../../types/contract-mutation-types';

import Button from '../../../components/Button';

import Container from '../../../components/Container';
import FormattedDate from '../../../components/FormattedDate';
import PaddedId from '../../../components/PaddedId';
import Protocol from '../../../components/Protocol';
import { CardItem } from '../../../components/Card';
import { ProtocolCard } from './';
import { MutationDetailContext } from '../MutationDetail';

type Props = {
  mutation: ContractMutation,
  children: any,
  buttonLabel: string,
  buttonClick: Function,
};

function ProviderForm({ buttonLabel, buttonClick, mutation, children }: Props) {
  const { userIsApprover, protocol, protocolIsLoading } = useContext(MutationDetailContext);

  return (
    <Container>
      <div className="row flex-items-md-bottom">
        <div className="col-sm-12 col-md-8 margin-bottom-3">
          <h1>
            <Trans i18nKey={`mutationtypes.${mutation.type.toLowerCase()}`} />{' '}
            <PaddedId id={mutation.id} />
          </h1>
        </div>
        <div className="col-sm-12 col-md-4 margin-bottom-3 text-align-right">
          {mutation.closedAt ? (
            <FormattedDate date={mutation.closedAt} />
          ) : userIsApprover ? (
            <Button onClick={() => buttonClick()}>{buttonLabel}</Button>
          ) : (
            <FormattedDate date={mutation.createdAt} />
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-7 flex  margin-bottom-3">{children}</div>
        <div className="col-sm-12 col-md-5 flex margin-bottom-3">
          <ProtocolCard>
            <CardItem>
              <Protocol mutation={mutation} items={protocol} isLoading={protocolIsLoading} />
            </CardItem>
          </ProtocolCard>
        </div>
      </div>
    </Container>
  );
}

export default ProviderForm;
