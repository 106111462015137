// @flow

import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import styles from './ProviderOverview.module.css';

import Container from '../../components/Container';
import useVehicleProviders from '../../hooks/useVehicleProviders';
import { type VehicleProvider } from '../../types/vehicle-provider-types';
import LoadingSpinner from '../../components/LoadingSpinner';
import EmptyState from '../../components/EmptyState';
import ErrorState from '../../components/ErrorState';
import EmailLink from '../../components/EmailLink';
import Button from '../../components/Button';
import Icon from '../../components/Icon';
import ProfileLink from '../../components/ProfileLink';
import EditProviderModal from '../../components/EditProviderModal';

function ProviderOverview() {
  const { t } = useTranslation();

  const {
    isLoading,
    providers,
    hasError,
    addNewProvider,
    updateProvider,
    deleteProvider,
  } = useVehicleProviders();
  const [editProvider, setEditProvider] = useState<?VehicleProvider | null>(null);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalType, setModalType] = useState<?string>();

  return (
    <Container>

      {showModal && (
        <EditProviderModal
          type={modalType}
          provider={editProvider ? editProvider : null}
          updateProvider={updateProvider}
          deleteProvider={deleteProvider}
          addNewProvider={addNewProvider}
          onClose={() => { setShowModal(false); setModalType(null); setEditProvider(null); }}
        />
      )}
      <div className="table table--responsive table--no-border">
        <div className={styles.headingContainer}>
          <h1 className="table__title">
            <Trans i18nKey="navigation.provider" />
          </h1>
          <Button color="primary" onClick={() => { setModalType('create'); setShowModal(true); }}>
            <Trans i18nKey="provideradministration.createprovider" />
          </Button>
        </div>

        <div className="table__wrapper">
          <table data-init="auto" className={styles['table__no-border']}>
            <thead>
              <tr>
                <th>
                  <Trans i18nKey="provideradministration.name" />
                </th>
                <th>
                  <Trans i18nKey="provideradministration.avriosidentifier" />
                </th>
                <th>
                  <Trans i18nKey="provideradministration.hasreserve" />
                </th>
                <th>
                  <Trans i18nKey="provideradministration.email" />
                </th>
                <th>
                  <Trans i18nKey="provideradministration.ordernumbers" />
                </th>
                <th>
                  <Trans i18nKey="provideradministration.responsibles" />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {isLoading && (
                <tr>
                  <td>
                    <div className={styles.empty__outer}>
                      <LoadingSpinner />
                    </div>
                  </td>
                </tr>
              )}
              {!isLoading && !hasError && providers.length === 0 && (
                <tr>
                  <td>
                    <div className={styles.empty__outer}>
                      <EmptyState />
                    </div>
                  </td>
                </tr>
              )}
              {!isLoading && hasError && (
                <tr>
                  <td>
                    <div className={styles.empty__outer}>
                      <ErrorState />
                    </div>
                  </td>
                </tr>
              )}
              {!isLoading &&
                !hasError &&
                providers.length > 0 &&
                providers.map((provider, index) => {
                  return (
                    <tr key={index}>
                      <td>{provider.name}</td>
                      <td>{provider.avriosIdentifier}</td>
                      <td>{provider.hasReserve ? t('common.yes') : t('common.no')}</td>
                      <td><EmailLink user={{ email: provider.email }} /></td>
                      <td>
                        {provider.orderNumbers &&
                          provider.orderNumbers.map((orderNumber, orderNumberIndex) => {
                            return (
                              <span key={orderNumberIndex}>
                                {orderNumber}
                                <br />
                              </span>
                            );
                          })}
                      </td>
                      <td>
                        {provider.responsibles &&
                          provider.responsibles.map(responsibleUser => {
                            return (
                              <span key={responsibleUser.swisscomEmployeeNr}>
                                <ProfileLink user={responsibleUser} />
                                <br />
                              </span>
                            );
                          })}
                      </td>
                      <td>
                        <span
                          role="button"
                          tabIndex="-1"
                          onClick={() => { setEditProvider(provider); setShowModal(true); setModalType('edit'); }}>
                          <Icon name="edit" className={'link icon--s2'} />
                        </span>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </Container>
  );
}

export default ProviderOverview;
