// @flow

import React, { useContext, useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import store2 from 'store2';
import userManager from '../../auth/userManager';
import LoadingPage from '../../container/LoadingPage';
import { AuthContext } from '../AuthProvider/AuthProvider';

const isAuthenticating = (location: { pathname: string }): boolean =>
  (location && location.pathname === '/auth/callback') || location.pathname === '/auth/login';

type AccessRoles = 'provider' | 'non-admin';

type Props = {
  component: Function,
  exact: boolean,
  path: string,
  preventAccess: AccessRoles[],
};

const LoginRoute = ({ component, exact, path, preventAccess }: Props) => {
  const [signedIn, setSignedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { fetchProfile, profile } = useContext(AuthContext);

  useEffect(() => {
    let isCancelled = false;
    const getUser = async () => {
      setIsLoading(true);
      const user = await userManager.getUser();
      if (!isCancelled) {
        const isUserSignedIn = user !== null && !user.expired;
        if (isUserSignedIn) {
          setSignedIn(true);
          await fetchProfile();
        }
        setIsLoading(false);
      }
    };
    getUser();
    return () => {
      isCancelled = true;
    };
  }, [fetchProfile]);

  return (
    <Route
      path={path}
      exact={exact}
      render={({ location, ...props }) => {
        const authenticating = isAuthenticating(location);
        if (authenticating || isLoading) {
          return <LoadingPage />;
        }

        if (!signedIn) {
          store2.set('location', location.pathname);
          return <Redirect to="/auth/login" push={false} />;
        }

        if (preventAccess.includes('provider') && profile?.provider) {
          return <Redirect to="/mutation-overview" push={false} />;
        }

        if (preventAccess.includes('non-admin') && !profile?.isAdmin) {
          return <Redirect to="/" push={false} />;
        }

        return React.createElement(component, props);
      }}
    />
  );
};

LoginRoute.defaultProps = {
  preventAccess: [],
};

export default LoginRoute;
