// @flow

import React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import Option from '../Typeahead/Option';
import Select from '../Select/';

type Props = {
  value?: string | number | boolean,
  onChange: Function,
  id?: string,
  invalid?: boolean,
  onBlur: Function,
  name?: string,
};

function StatusPicker({ value, onChange, id, invalid, onBlur, name }: Props) {
  const { t } = useTranslation();
  const states = t('debtorform.fields.debtorstatus.states', { returnObjects: true });
  const options = Object.keys(states).map(key => ({
    value: key,
    label: states[key],
  }));

  const containerClasses = cx('Select__Container', {
    'Select__Container--invalid': invalid,
  });

  return (
    <Select
      name={name}
      value={options.find(p => p.value === value)}
      onChange={e => {
        onChange(e.value);
      }}
      onBlur={onBlur}
      inputId={id}
      className={containerClasses}
      options={options}
      placeholder={t('common.select')}
      loadingMessage={() => t('common.loading')}
      noOptionsMessage={() => t('typeahead.nooptions')}
      components={{ Option: Option }}
    ></Select>
  );
}

export default StatusPicker;
