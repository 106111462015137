// @flow

import React from 'react';
import { useTranslation } from 'react-i18next';

import useUsers from '../../../hooks/useUsers';

import {
  type ContractMutation,
  type DriverChangeMutation,
} from '../../../types/contract-mutation-types';

import { CardItem } from '../../../components/Card';
import EmployeeInformation from '../../../components/EmployeeInformation';
import ErrorState from '../../../components/ErrorState';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';

import { InformationCardItem, InformationCard } from '../common';
import Comments from '../common/Comments';
import VehicleName from '../../../components/VehicleName';
import FormattedDate from '../../../components/FormattedDate';

type Props = {
  mutation: ContractMutation,
  driverChangeMutation: DriverChangeMutation,
};

function DriverChangeSummary({ mutation, driverChangeMutation }: Props) {
  const { t } = useTranslation();

  const { isLoading, hasError, users } = useUsers([
    driverChangeMutation.originalDriver.swisscomEmployeeNr,
    driverChangeMutation.driver.swisscomEmployeeNr,
  ]);

  return (
    <InformationCard color="purple">
      {(isLoading || hasError) && (
        <div className="flex flex-center" style={{ height: 300 }}>
          {isLoading && <LoadingSpinner />}
          {hasError && !isLoading && <ErrorState />}
        </div>
      )}
      {!isLoading && !hasError && users?.length === 2 && (
        <>
          <CardItem>
            <div className="row">
              <EmployeeInformation
                user={users[0]}
                className="col-4"
                title={t('driverchangedetail.labels.currentdriver')}
              />
              <EmployeeInformation
                user={users[1]}
                className="col-4"
                title={t('driverchangedetail.labels.newdriver')}
              />
            </div>
          </CardItem>
          <CardItem>
            { mutation.contract && <InformationCardItem label={t('common.labels.vehicle')}>
              {mutation.contract.vehicle.manufacturerName}
              <br />
              <VehicleName vehicle={mutation.contract.vehicle} />
            </InformationCardItem>}
            { mutation.contract && <InformationCardItem label={t('common.labels.snumber')}>
              {mutation.contract.vehicle.sNumber}
            </InformationCardItem>}
            { mutation.contract && <InformationCardItem label={t('common.vehicleproperties.platenumber')}>
              {mutation.contract.vehicle.activeLicensePlate}
            </InformationCardItem>}
            { mutation.contract && <InformationCardItem label={t('common.labels.leasingcontractnumber')}>
              {mutation.contract.contractNumber}
            </InformationCardItem>}
            <InformationCardItem label={t('driverchangedetail.labels.requestedchangedate')}>
              <FormattedDate date={mutation.requestedDateOfExecution} />
            </InformationCardItem>
          </CardItem>
          <Comments contractMutationId={mutation.id} />
        </>
      )}
    </InformationCard>
  );
}

export default DriverChangeSummary;
