// @flow

import useQueryFilters from '../../hooks/useQueryFilters';
import { QueryComparators } from '../../types/query-comparator-types';
import { type QueryFilters } from '../../util/QueryFilterHelper';

function useContractQueryFilters() {
  const initialFilter: QueryFilters = {
    hardFilter: {
      deletedAt: {
        value: null,
        comparator: QueryComparators.EQUAL
      }
    }
  };

  const {
    filter,
    setFilter,
    setSearchQuery,
    setExclusiveSort,
    setSort,
    setWhereFilter,
    removeWhereFilter,
  } = useQueryFilters(initialFilter);

  return {
    filter,
    setFilter,
    setSearchQuery,
    setExclusiveSort,
    setSort,
    setWhereFilter,
    removeWhereFilter,
  };
}

export default useContractQueryFilters;
