// @flow

import { fetch } from './fetch';
import { type AxiosResponse } from 'axios';
import { type VehicleProvider } from '../types/vehicle-provider-types';
import { type User } from '../types/user-types';

export async function getVehicleProviders(): Promise<AxiosResponse<VehicleProvider[]>> {
  return fetch<VehicleProvider[]>('/providers', 'GET');
}

export async function getVehicleProviderById(id: number): Promise<AxiosResponse<VehicleProvider>> {
  return fetch<VehicleProvider[]>(`/providers/${id}`, 'GET');
}

export async function getVehicleProviderResponsibles(id: number): Promise<AxiosResponse<User[]>> {
  return fetch<User[]>(`/providers/${id}/responsibles`, 'GET');
}

export async function createVehicleProvider(payload: VehicleProvider): Promise<AxiosResponse<VehicleProvider>> {
  return fetch<VehicleProvider>(`/providers`, 'POST', payload);
}

export async function updateVehicleProvider(id: number, payload: VehicleProvider): Promise<AxiosResponse<VehicleProvider>> {
  return fetch<VehicleProvider>(`/providers/${id}`, 'PUT', payload);
}

export async function deleteVehicleProvider(id: number): Promise<AxiosResponse<void>> {
  return fetch<void>(`/providers/${id}`, 'DELETE');
}
