// @flow

import React, { Fragment } from 'react';
import cx from 'classnames';
import styles from './RadioTabs.module.css';

type Props = {
  inline?: Boolean,
  value?: string | number | boolean,
  onChange: Function,
  children: Object[],
  name?: string,
  invalid?: boolean,
  selected: string,
};

function RadioTabs({ inline, value, onChange, children, name, selected }: Props) {
  const handleChange = event => {
    onChange(event.currentTarget.value);
  };

  return (
    <div className={`${styles.radio_tab_container}`}>
      {children.map(child => (
        <Fragment key={child.value}>
          <input
            type="radio"
            className={styles.radio_tabs}
            name={child.label}
            id={child.value}
            value={child.value}
            onChange={handleChange}
            checked={selected === child.value}
          />
          <label
            className={cx(
              styles.radio_tabs_label,
              child.value === selected && styles.radio_tabs_label_selected,
            )}
            htmlFor={child.value}
          >
            {child.label}
          </label>
        </Fragment>
      ))}
    </div>
  );
}

export default RadioTabs;
