import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  name: Yup.string().required('formvalidation.required.message'),
  avriosIdentifier: Yup.string().required('formvalidation.required.message'),
  hasReserve: Yup.bool().required('formvalidation.required.message'),
  email: Yup.string().nullable(),
  orderNumbers: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.string().matches(/^[0-9]+$/, 'formvalidation.ordernumber.numbersonly')
      })
    ).nullable(),
  responsibles: Yup.array().of(Yup.object()).nullable(),
});
