// @flow

import React from 'react';
import { components } from 'react-select';

import Icon from '../Icon';

const DropdownIndicator = (props: any) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <Icon name="077-search" />
      </components.DropdownIndicator>
    )
  );
};

export default DropdownIndicator;
