// @flow

import { useState, useEffect, useContext, useMemo } from 'react';
import { type QueryFilters } from '../util/QueryFilterHelper';

import { type ContractMutation } from '../types/contract-mutation-types';
import { AuthContext } from '../components/AuthProvider/AuthProvider';
import { getMutations } from '../services/contractMutationService';
import { QueryComparators } from '../types/query-comparator-types';
import { OrderDirections } from '../types/order-types';

function useMyMutations() {
  const [isLoading, setIsLoading] = useState < boolean > (false);
  const [hasError, setHasError] = useState < boolean > (false);
  const [mutations, setMutations] = useState < ContractMutation[] > ([]);
  const [totalCount, setTotalCount] = useState < number > (0);
  const { profile } = useContext(AuthContext);

  const defaultFilters = useMemo<QueryFilters>(
    () => ({
      hardFilter: {
        'orderer.swisscomEmployeeNr': {
          comparator: QueryComparators.EQUAL,
          value: profile?.swisscomEmployeeNr,
        },
        deletedAt: {
          value: null,
          comparator: QueryComparators.EQUAL
        },
        status: {
          comparator: QueryComparators.EQUAL,
          value: 'PENDING',
        },
      },
      sort: {
        createdAt: OrderDirections.DESC,
      },
      take: 5,
    }),
    [profile],
  );

  const draftFilters = useMemo < QueryFilters > (
    () => ({
      ...defaultFilters,
      hardFilter: {
        'orderer.swisscomEmployeeNr': {
          comparator: QueryComparators.EQUAL,
          value: profile?.swisscomEmployeeNr,
        },
        deletedAt: {
          value: null,
          comparator: QueryComparators.EQUAL
        },
        status: {
          comparator: QueryComparators.EQUAL,
          value: 'DRAFT',
        },
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [profile],
  );
  useEffect(() => {
    let isCancelled = false;

    async function fetchMutations() {
      try {
        setIsLoading(true);
        const { data } = await getMutations(defaultFilters);
        const { data: draftData } = await getMutations(draftFilters);
        setTotalCount(data.totalCount + draftData ? draftData.totalCount : 0);
        /**
         * Following snippets fetches users and appends them to the mutation
         * If fetching a user fails we just show the username
         */

        if (!isCancelled) {
          draftData ? setMutations([...data.data, ...draftData.data]) : setMutations(data.data);
          setIsLoading(false);
        }
      } catch (err) {
        if (!isCancelled) {
          setHasError(true);
          setIsLoading(false);
        }
        throw err;
      }
    }

    fetchMutations();

    return () => {
      isCancelled = true;
    };
  }, [defaultFilters, draftFilters]);

  return {
    isLoading,
    hasError,
    mutations,
    totalCount,
  };
}

export default useMyMutations;

