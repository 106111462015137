import oidc from 'oidc-client';
import config from '../config';

// oidc.Log.logger = console;
const { protocol, hostname, port } = window.location;
const origin = `${protocol}//${hostname}${port ? `:${port}` : ''}`;

const userManagerConfig = {
  client_id: config.clientId,
  authority: config.authority,
  response_type: 'token',
  scope: 'openid roles user_attributes',
  redirect_uri: `${origin}/auth/callback`,
  silent_redirect_uri: `${origin}/auth/silentrenew`,
  automaticSilentRenew: true,
  post_logout_redirect_uri: origin,
  loadUserInfo: false,
  metadata: {
    authorization_endpoint: config.metadata.authorization_endpoint,
    userinfo_endpoint: config.metadata.user_info_endpoint,
    jwks_uri: config.metadata.jwks_uri,
    end_session_endpoint: config.metadata.end_session_endpoint,
  },
};

export default new oidc.UserManager(userManagerConfig);
