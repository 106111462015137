// @flow

import React from 'react';
import Button from '../Button';
import styles from './DeleteButton.module.css';
import cx from 'classnames';

type Props = {
  onClick: Function,
  text: string,
}

function DeleteButton({ onClick, text }: Props) {
  return (
    <Button icon="bin" type="reset" onClick={onClick} className={cx(styles.deleteButton, "padding-left-2")}>{text}</Button>
  );
}

export default DeleteButton;
