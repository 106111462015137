export function isInt(n) {
  return n % 1 === 0;
}

export function formatPrice(price, { includeCurrency, includeCentimes }) {
  const currency = includeCurrency ? 'CHF ' : '';
  const formattedPrice = price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
  const centimes = includeCentimes && isInt(price) ? '.–' : '';
  return currency + formattedPrice + centimes;
}
