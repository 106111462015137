import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import differenceInDays from 'date-fns/differenceInDays';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import cx from 'classnames';
import ReactTooltip from 'react-tooltip';

import FormattedDate from '../FormattedDate';

import styles from './DeliveryDate.module.css';
import Icon from '../Icon';
import useIdentifier from '../../hooks/useIdentifier';
import { AuthContext } from '../AuthProvider/AuthProvider';
import { getLocale } from '../../util/DateHelper';

type Props = {
  date: Date,
  mutationType: 'REQUESTVEHICLE' | 'RETURNVEHICLE' | 'DRIVERCHANGE',
  warningEnabled: boolean,
};

function DeliveryDate({ date, mutationType, warningEnabled }: Props) {
  const { profile } = useContext(AuthContext);

  const expiresIn = differenceInDays(new Date(date), new Date());

  const isExpiring = expiresIn <= 10;
  const classes = cx({
    [styles.expiring]: expiresIn <= 10 && warningEnabled,
  });
  const tooltipId = useIdentifier('tooltip_');
  const { t } = useTranslation();

  const distance = formatDistanceToNow(new Date(date), {
    locale: getLocale(profile?.preferredLanguage),
    addSuffix: true,
  });

  return (
    <>
      {date ? <span className={classes}>
        <span className={styles.content}>
          <Trans i18nKey={`mutationdates.${mutationType.toLowerCase()}`} />
          <br />
          <FormattedDate date={date} />
        </span>
        {isExpiring && warningEnabled && (
          <>
            <span data-tip={t('deliverydate.expiring', { distance })} data-for={tooltipId}>
              <Icon className={styles.icon} name="046-information-circle" />
            </span>
            <ReactTooltip
              id={tooltipId}
              effect="solid"
              type="light"
              border
              className={styles.tooltip}
              place="top"
            />
          </>
        )}
      </span> : <span>-</span>}
    </>
  );
}

export default DeliveryDate;
