// @flow

import React, { useState, useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import {
  type ContractMutation,
  type RequestVehicleMutation,
} from '../../../types/contract-mutation-types';

import styles from './RequestVehicleDetail.module.css';
import { CardItem } from '../../../components/Card';
import CategoryPicker from '../../../components/CategoryPicker';

import { InformationCardItem, InformationCard } from '../common';
import FormattedDate from '../../../components/FormattedDate';
import { getCurrentVehicleCategory, formatCategoryName } from '../../../util/MutationProperties';
import VehicleCategoryHistoryPopup from './RequestVehicleInformation/VehicleCategoryHistoryPopup';
import { MutationDetailContext } from '../MutationDetail';
import { AuthContext } from '../../../components/AuthProvider/AuthProvider';
import Comments from '../common/Comments';
import { formatPrice } from '../../../util/PriceFormatHelper';
import EmployeeInformation from '../../../components/EmployeeInformation';
import DatePicker from '../../../components/DatePicker/DatePicker';
import {addDays, startOfDay} from 'date-fns';

type Props = {
  mutation: ContractMutation,
  requestVehicleMutation: RequestVehicleMutation,
  selectedCategoryChanged: Function,
  selectedRequestDateChanged?: Function,
  newExecutionDate?: Date,
};

function RequestVehicleInformation({
  mutation,
  selectedCategoryChanged,
  requestVehicleMutation,
  selectedRequestDateChanged,
  newExecutionDate,
}: Props) {
  const { isProvider } = useContext(AuthContext);
  const { userIsApprover } = useContext(MutationDetailContext);
  const [selectedDate, setSelectedDate] = useState<Date>(mutation.dateOfExecution ? mutation.dateOfExecution : new Date());

  const { t } = useTranslation();
  const currentCategory = getCurrentVehicleCategory(requestVehicleMutation);

  const [selectedCategory, setSelectedCategory] = useState({
    value: currentCategory.id,
    label: formatCategoryName(currentCategory),
  });

  return (
    <InformationCard color="purple">
      <CardItem>
        {mutation.contract && <EmployeeInformation
          user={mutation.contract.contractor}
          className="col-4"
          title={t('driverchangedetail.labels.currentdriver')}
        />}
      </CardItem>
      <CardItem>
        <InformationCardItem label={t('requestvehicledetail.labels.vehiclecategory')}>
          {mutation.status === 'PENDING' && userIsApprover ? (
            <CategoryPicker
              name="vehicleCategory"
              value={selectedCategory}
              compact={true}
              onChange={vehicleCategory => {
                selectedCategoryChanged(vehicleCategory);
                setSelectedCategory(vehicleCategory);
              }}
            />
          ) : (
            <span>{formatCategoryName(currentCategory)}</span>
          )}
          <div>
            <VehicleCategoryHistoryPopup mutationId={mutation.id}></VehicleCategoryHistoryPopup>
          </div>
        </InformationCardItem>
        {!isProvider && (
          <InformationCardItem label={t('common.labels.price')}>
            <Trans

              values={{ price: formatPrice(requestVehicleMutation.vehicleCategory.pricePerMonth, { includeCurrency: true, includeCentimes: true }) }}
              i18nKey="common.pricepermonth"
            />
          </InformationCardItem>
        )}
        <InformationCardItem label={t('requestvehicledetail.labels.requestedrentalstart')}>
          {!userIsApprover ? newExecutionDate ? (
            <>
              <div className={styles.all}>
                <div className={styles.oldDate}>
                  <FormattedDate date={mutation.dateOfExecution}></FormattedDate>*
                </div>

                <div className={styles.newDate}>
                  <FormattedDate date={newExecutionDate}></FormattedDate>
                </div>
              </div>
            </>
          ) : (
            <div className={styles.all}>
              <FormattedDate date={mutation.dateOfExecution}></FormattedDate>
            </div>
          ) :
            <DatePicker
              name={t('vehiclechangedetail.labels.requestedreturndate')}
              value={selectedDate.toString()}
              onChange={newRequestDate => {
                selectedRequestDateChanged && selectedRequestDateChanged(newRequestDate);
                setSelectedDate(selectedDate);}}
              disableWeekends={true}
              options={{ minDate: startOfDay(addDays(new Date(), 1)) }}
            />
          }
        </InformationCardItem>
        <InformationCardItem label={t('common.labels.registrationcanton')}>
          <Trans i18nKey={`cantons.${requestVehicleMutation.registrationCanton}`} />
        </InformationCardItem>
        <InformationCardItem label={t('requestvehicledetail.labels.deliverycanton')}>
          <Trans i18nKey={`cantons.${requestVehicleMutation.deliveryCanton}`} />
        </InformationCardItem>
        <InformationCardItem label={t('common.labels.mileageperyear')}>
          {requestVehicleMutation.mileagePerYear}
        </InformationCardItem>
        {requestVehicleMutation.vehicleCategory.type === 'utilityvehicle' && requestVehicleMutation.labelingLanguage && <InformationCardItem label={t('common.labels.labelingLanguage')}>
          {t(`common.languages.${requestVehicleMutation.labelingLanguage}`)}
        </InformationCardItem>}
      </CardItem>
      <Comments contractMutationId={mutation.id} />
    </InformationCard>
  );
}
export default RequestVehicleInformation;
