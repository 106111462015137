import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { getVehicleBySNumber } from '../../../../services/vehicleService';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import debounce from 'debounce-promise';

import style from './VehicleExistsValidator.module.css';
import Icon from '../../../../components/Icon';
import { Trans } from 'react-i18next';

const getVehicleBySNumberDebounced = debounce(getVehicleBySNumber, 500);

function VehicleExistsValidator({ sNumber }) {
  const [dirty, setDirty] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [vehicle, setVehicle] = useState(null);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    let isCancelled = false;
    if (sNumber && sNumber.length > 0) {
      setDirty(true);
      setIsLoading(true);
      setHasError(false);
      getVehicleBySNumberDebounced(sNumber)
        .then(({ data }) => {
          if (!isCancelled) {
            setVehicle(data);
            setIsLoading(false);
          }
        })
        .catch(err => {
          if (!isCancelled) {
            setVehicle(null);
            setHasError(true);
            setIsLoading(false);
          }
        });
    }

    return () => {
      isCancelled = true;
    };
  }, [sNumber]);

  if (!dirty) {
    return null;
  }

  if (isLoading) {
    return (
      <div className={cx('flex flex-items-center', style.loading)}>
        <LoadingSpinner className={style.loadingSpinner} size="small" />
        <span>
          <Trans i18nKey="mutationdetail.vehicleexistsvalidator.loading" />
        </span>
      </div>
    );
  }

  if (hasError) {
    return (
      <div className={cx('flex flex-items-center', style.error)}>
        <Icon className={style.icon} name="094-warning" />
        <span>
          <Trans i18nKey="mutationdetail.vehicleexistsvalidator.error" />
        </span>
      </div>
    );
  }

  return (
    <div className={cx('flex flex-items-center', style.content)}>
      <Icon className={style.icon} name="012-check-mark-2-circle" />
      <span>
        <Trans
          i18nKey="mutationdetail.vehicleexistsvalidator.success"
          values={{ vehicle: vehicle.baseModelDisplayName }}
        />
      </span>
    </div>
  );
}

export default VehicleExistsValidator;
