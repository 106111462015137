// @flow

import React from 'react';
import debounce from 'debounce-promise';
import Typeahead from '../Typeahead';
import { getDebtors } from '../../services/debtorService';

type Props = {
  value?: any,
  onChange: Function,
  [any: string]: any,
};

const loadDebtors = debounce(getDebtors, 250, { leading: true });

function DebtorTypeahead({ value, onChange, ...props }: Props) {
  const fetchOptions = async query => {
    const { data: debtors } = await loadDebtors({ query, take: 20 });
    return debtors.map(debtor => ({
      value: debtor.id,
      label: `${debtor.number} - ${debtor.name}`,
    }));
  };

  return (
    <Typeahead {...props} value={value} onChange={onChange} loadOptions={fetchOptions}></Typeahead>
  );
}

export default DebtorTypeahead;
