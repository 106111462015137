// @flow

import React, { useState, useContext } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import {
  type ContractMutation,
  type RequestVehicleMutation,
} from '../../../types/contract-mutation-types';

import cx from 'classnames';
import styles from './RequestVehicleDetail.module.css';
import Container from '../../../components/Container';
import { CardItem } from '../../../components/Card';
import Protocol from '../../../components/Protocol';

import RequestVehicleInformation from './RequestVehicleInformation';
import RequestVehicleSummary from './RequestVehicleSummary';
import { ApprovalForm, ApprovalFormFleet, ProviderModal } from './ApprovalForms';
import { MutationTitle, ProtocolCard, ProviderForm } from '../common';
import { getCurrentVehicleCategory, formatCategoryName } from '../../../util/MutationProperties';

import { MutationDetailContext } from '../MutationDetail';

type Props = {
  mutation: ContractMutation,
  requestVehicleMutation: RequestVehicleMutation,
};

function RequestVehicleDetail({ mutation, requestVehicleMutation }: Props) {
  const currentCategory = getCurrentVehicleCategory(requestVehicleMutation);
  const { userIsApprover, currentApprovalPoint, protocol, protocolIsLoading } = useContext(
    MutationDetailContext,
  );

  const [selectedCategory, setSelectedCategory] = useState({
    value: currentCategory.id,
    label: formatCategoryName(currentCategory),
  });

  const [selectedRequestDate, setSelectedRequestDate] = useState(mutation.dateOfExecution);

  const showExecutionDateWarning =
    currentApprovalPoint.type === 'FLEET_MGMT' &&
    mutation.dateOfExecution !== mutation.requestedDateOfExecution;

  const [showProviderModal, setShowProviderModal] = useState<boolean>(false);
  const { t } = useTranslation();

  if (currentApprovalPoint.type === 'PROVIDER') {
    return (
      <ProviderForm
        mutation={mutation}
        buttonLabel={t('requestvehicledetail.providermodal.label')}
        buttonClick={() => setShowProviderModal(true)}
      >
        {showProviderModal && (
          <ProviderModal
            mutationId={mutation.id}
            onClose={() => setShowProviderModal(false)}
            requireSNumber={!mutation.requestVehicleMutation?.selectedProvider?.hasReserve}
          />
        )}
        <RequestVehicleSummary
          mutation={mutation}
          requestVehicleMutation={requestVehicleMutation}
        />
      </ProviderForm>
    );
  }

  return (
    <Container>
      <MutationTitle mutation={mutation} />
      <div className="row">
        <div className="col-sm-12 col-md-7 flex  margin-bottom-3">
          <RequestVehicleInformation
            mutation={mutation}
            requestVehicleMutation={requestVehicleMutation}
            selectedCategoryChanged={cat => setSelectedCategory(cat)}
            selectedRequestDateChanged={e => setSelectedRequestDate(e)}
            showExecutionDateWarning={showExecutionDateWarning}
            category={currentCategory}
          />
        </div>
        <div className="col-sm-12 col-md-5 flex margin-bottom-3">
          <ProtocolCard>
            <CardItem>
              <Protocol mutation={mutation} items={protocol} isLoading={protocolIsLoading} />
            </CardItem>
          </ProtocolCard>
        </div>
      </div>
      {showExecutionDateWarning && (
        <div className={cx('margin-bottom-3', styles.warning)}>
          <p>
            <Trans i18nKey="mutationdetail.labels.deliverydateadjustment" />
          </p>
        </div>
      )}

      {mutation.status === 'PENDING' &&
        (currentApprovalPoint.type !== 'FLEET_MGMT' && userIsApprover === true ? (
          <ApprovalForm
            mutation={mutation}
            selectedCategory={selectedCategory}
            selectedRequestDate={selectedRequestDate}
          />
        ) : (
          userIsApprover === true && (
            <div className="margin-top-4">
              <ApprovalFormFleet
                mutation={mutation}
                selectedCategory={selectedCategory}
                selectedRequestDate={selectedRequestDate}
              />
            </div>
          )
        ))}
    </Container>
  );
}

export default RequestVehicleDetail;
