export const initialValues = {
  vehicleProvider: null,
  vehicleCategory: null,
  registrationCanton: null,
  deliveryCanton: null,
  mileagePerYear: null,
  comment: '',
  reserveVehicle: null,
  dateOfChange: null,
  endLeasingContract: null,
  acceptDriverConditions: false,
  sendToProvider: true,
  orderNumber: ''
};
