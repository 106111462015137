import React from 'react';

import styles from './VehicleCategoryFilter.module.css';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

type Props = {
  filterProps: Object,
  handleClick: Function
}

function VehicleCategoryFilter({ filterProps, handleClick }: Props) {
  const { t } = useTranslation();

  return (
    <>
      <div className={ cx(styles.container) }>
        <div className={ cx(styles.filterContainer) }>
          <h3>{ t('common.labels.passengervehicle') }</h3>
          { filterProps && filterProps.filter(elm => elm.type === 'passengervehicle').map(elm => (
            <button key={ elm.id } className={ cx({ [styles.active]: elm.active }) } onClick={ () => handleClick(elm.id) }>{ elm.title }</button>
          )) }
        </div>
        <div className={ cx(styles.filterContainer) }>
          <h3>{ t('common.labels.utilityvehicle') }</h3>
          { filterProps && filterProps.filter(elm => elm.type === 'utilityvehicle').map(elm => (
            <button key={ elm.id } className={ cx({ [styles.active]: elm.active }) } onClick={ () => handleClick(elm.id) }>{ elm.title }</button>
          )) }
        </div>
      </div>
    </>
  );
}

export default VehicleCategoryFilter;
