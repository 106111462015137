// @flow

import React from 'react';
import styles from './EasterEgg.module.css';

function EasterEgg() {
  return (
    <div className={styles.egg_container}>
      <img src="/delorian_animated.gif" alt="Easter Egg"></img>
    </div>
  );
}

export default EasterEgg;
