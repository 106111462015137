import React from 'react';
import Icon from '../Icon';

import styles from './ErrorState.module.css';
import { useTranslation } from 'react-i18next';

function ErrorState({ className, text }) {
  const { t } = useTranslation();
  return (
    <div className={styles.wrapper}>
      <Icon name="241-robot-broken" className={styles.icon} />
      <p>{text || t('errorstate.defaultmessage')}</p>
    </div>
  );
}

export default ErrorState;
