// @flow

import React from 'react';
import cx from 'classnames';
import CreatableSelect from 'react-select/creatable';
import Option from '../Typeahead/Option';
import { useTranslation } from 'react-i18next';

type Props = {
  name?: string,
  value?: any[],
  options?: any,
  onChange?: Function,
  onBlur?: Function,
  id?: string,
  invalid?: boolean,
  isMulti?: boolean,
};

function OrderNumberPicker({
  name,
  value,
  options,
  onChange,
  onBlur,
  id,
  invalid,
  isMulti,
}: Props) {
  const { t } = useTranslation();

  const containerClasses = cx('Select__Container', {
    'Select__Container--invalid': invalid,
  });

  return (
    <CreatableSelect
      name={name}
      options={options}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      inputId={id}
      className={containerClasses}
      classNamePrefix="Select"
      placeholder={''}
      isMulti={isMulti}
      cacheOptions
      defaultOptions={[]}
      onInputChange={() => { }}
      loadingMessage={() => t('common.loading')}
      noOptionsMessage={() => t('emptystate.defaultmessage')}
      components={{ Option: Option, DropdownIndicator: null }}
    />
  );
}

export default OrderNumberPicker;
