// @flow

import React, { useState, useContext, useEffect } from 'react';
import isEqual from 'lodash/fp/isEqual';
import { Formik } from 'formik';
import { useTranslation, Trans } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import startOfDay from 'date-fns/fp/startOfDay';

import BackMenu from '../../components/BackMenu';
import Container from '../../components/Container';
import styles from './ReturnVehicleForm.module.css';
import FormField from '../../components/FormField';
import DatePicker from '../../components/DatePicker';
import ValidationErrorMessage from '../../components/ValidationErrorMessage';
import CantonPicker from '../../components/CantonPicker';
import ButtonGroup from '../../components/ButtonGroup';
import Button from '../../components/Button';
import { validationSchema } from './validation-schema';
import { initialValues } from './initial-values';
import RoutingPrompt from '../../components/RoutingPrompt';
import { useNotifications } from '../../components/Notifications/NotificationsProvider';
import ContractInformation from '../../components/ContractInformation';
import {  deleteReturnVehicleDraft, returnVehicle, saveReturnVehicleDraft } from '../../services/contractService';
import { OmitKeys } from '../../components/ContractInformation/ContractInformation';
import { AuthContext } from '../../components/AuthProvider/AuthProvider';
import CommentField from '../../components/CommentField';
import { addYears, addWeeks } from 'date-fns';
import format from 'date-fns/fp/format';
import useContract from '../../hooks/useContract';
import MessageCard from '../../components/MessageCard';
import useQueryParams from '../../hooks/useQueryParams';
import { getMutation, updateContractMutation, getOpenMutation } from '../../services/contractMutationService';
import AutoId from '../../components/AutoId';
import Checkbox from '../../components/Checkbox';
import {type ContractMutation} from '../../types/contract-mutation-types';
import i18n, { DEFAULT_LANGUAGE, LANGUAGES } from '../../i18n';

function ReturnVehicleForm() {
  const { t } = useTranslation();
  const [hasSuccess, setHasSuccess] = useState(false);
  const history = useHistory();
  const { fetchProfile, profile } = useContext(AuthContext);
  const { id: contractId } = useParams();
  const draftId = +useQueryParams('draftId');
  const { addSuccess, addAlert } = useNotifications();
  const { contract, isLoading, hasError } = useContract(contractId);
  const formatDate = format('dd.MM.yyyy');
  const [formInitialValues, setFormInitialValues] = useState();
  const [showPrompt, setShowPrompt] = useState <boolean>(true);
  const [hasOpenMutation, setHasOpenMutation] = useState<ContractMutation | null>(null);


  const browserLanguage = i18n.language.substr(0, 2).toLowerCase();
  const activeLanguage = LANGUAGES.includes(browserLanguage) ? browserLanguage : DEFAULT_LANGUAGE;

  const returnGuideLinks = {
    de: "https://swisscom.sharepoint.com/sites/reisen-mobilitaet-intranet/Freigegebene%20Dokumente/Forms/AllItems.aspx?id=%2Fsites%2Freisen%2Dmobilitaet%2Dintranet%2FFreigegebene%20Dokumente%2FGeneral%2FFleet%5FManagement%2Fde%2FSC%2DMOBRLF%2D062021%5FV1%2E0%5Fdt%2Epdf&parent=%2Fsites%2Freisen%2Dmobilitaet%2Dintranet%2FFreigegebene%20Dokumente%2FGeneral%2FFleet%5FManagement%2Fde",
    fr: "https://swisscom.sharepoint.com/sites/reisen-mobilitaet-intranet/Freigegebene%20Dokumente/Forms/AllItems.aspx?id=%2Fsites%2Freisen%2Dmobilitaet%2Dintranet%2FFreigegebene%20Dokumente%2FGeneral%2FFleet%5FManagement%2Ffr%2FSC%2DMOBRLF%2D062021%5FV1%2E0%5Ffr%2Epdf&parent=%2Fsites%2Freisen%2Dmobilitaet%2Dintranet%2FFreigegebene%20Dokumente%2FGeneral%2FFleet%5FManagement%2Ffr",
    it: "https://swisscom.sharepoint.com/sites/reisen-mobilitaet-intranet/Freigegebene%20Dokumente/Forms/AllItems.aspx?id=%2Fsites%2Freisen%2Dmobilitaet%2Dintranet%2FFreigegebene%20Dokumente%2FGeneral%2FFleet%5FManagement%2Fit%2FSC%2DMOBRLF%2D060221%5FV1%2E0%5Fit%2Epdf&parent=%2Fsites%2Freisen%2Dmobilitaet%2Dintranet%2FFreigegebene%20Dokumente%2FGeneral%2FFleet%5FManagement%2Fit",
  };

  const supplementaryProtocolLinksPw = {
    de: "https://swisscom.sharepoint.com/sites/reisen-mobilitaet-intranet/Freigegebene%20Dokumente/Forms/AllItems.aspx?id=%2Fsites%2Freisen%2Dmobilitaet%2Dintranet%2FFreigegebene%20Dokumente%2FGeneral%2FFleet%5FManagement%2Fde%2FZustandsprotokoll%5FDE%2Epdf&parent=%2Fsites%2Freisen%2Dmobilitaet%2Dintranet%2FFreigegebene%20Dokumente%2FGeneral%2FFleet%5FManagement%2Fde",
    fr: "https://swisscom.sharepoint.com/sites/reisen-mobilitaet-intranet/Freigegebene%20Dokumente/Forms/AllItems.aspx?id=%2Fsites%2Freisen%2Dmobilitaet%2Dintranet%2FFreigegebene%20Dokumente%2FGeneral%2FFleet%5FManagement%2Ffr%2FProtocol%5Fd%27%C3%A9tat%5Fvoitures%5Fde%5Ftourisme%2Epdf&parent=%2Fsites%2Freisen%2Dmobilitaet%2Dintranet%2FFreigegebene%20Dokumente%2FGeneral%2FFleet%5FManagement%2Ffr",
    it: "https://swisscom.sharepoint.com/sites/reisen-mobilitaet-intranet/Freigegebene%20Dokumente/Forms/AllItems.aspx?id=%2Fsites%2Freisen%2Dmobilitaet%2Dintranet%2FFreigegebene%20Dokumente%2FGeneral%2FFleet%5FManagement%2Fit%2FProtocollo%5Fdi%5Fstato%5Fautovetture%2Epdf&parent=%2Fsites%2Freisen%2Dmobilitaet%2Dintranet%2FFreigegebene%20Dokumente%2FGeneral%2FFleet%5FManagement%2Fit",
  };

  const supplementaryProtocolLinksUv = {
    de: "https://swisscom.sharepoint.com/sites/reisen-mobilitaet-intranet/Freigegebene%20Dokumente/Forms/AllItems.aspx?id=%2Fsites%2Freisen%2Dmobilitaet%2Dintranet%2FFreigegebene%20Dokumente%2FGeneral%2FFleet%5FManagement%2Fde%2FZustandsprotokoll%5FNFz%5Fde%2Epdf&parent=%2Fsites%2Freisen%2Dmobilitaet%2Dintranet%2FFreigegebene%20Dokumente%2FGeneral%2FFleet%5FManagement%2Fde",
    fr: "https://swisscom.sharepoint.com/sites/reisen-mobilitaet-intranet/Freigegebene%20Dokumente/Forms/AllItems.aspx?id=%2Fsites%2Freisen%2Dmobilitaet%2Dintranet%2FFreigegebene%20Dokumente%2FGeneral%2FFleet%5FManagement%2Ffr%2FProtocol%5Fd%27%C3%A9tat%5Fv%C3%A9hicules%5Futilitaires%2Epdf&parent=%2Fsites%2Freisen%2Dmobilitaet%2Dintranet%2FFreigegebene%20Dokumente%2FGeneral%2FFleet%5FManagement%2Ffr",
    it: "https://swisscom.sharepoint.com/sites/reisen-mobilitaet-intranet/Freigegebene%20Dokumente/Forms/AllItems.aspx?id=%2Fsites%2Freisen%2Dmobilitaet%2Dintranet%2FFreigegebene%20Dokumente%2FGeneral%2FFleet%5FManagement%2Fit%2FProtocollo%5Fdi%2Dstato%5Fveicoli%5Fcommerciali%2Epdf&parent=%2Fsites%2Freisen%2Dmobilitaet%2Dintranet%2FFreigegebene%20Dokumente%2FGeneral%2FFleet%5FManagement%2Fit",
  };

  useEffect(() => {
    const fetchOpenMutation = async () => {
      const {data }= await getOpenMutation(+contractId, { contractMutationType: ['DRIVERCHANGE', 'RETURNVEHICLE', 'VEHICLECHANGE'], status: ['PENDING']});
      setHasOpenMutation(data);
    };

    fetchOpenMutation();
  }, [contractId]);

  useEffect(() => {
    if (!draftId) {
      setFormInitialValues({initialValues, sendToProvider: profile?.isFleetMember});
      return;
    }
    const getDraftMutation = async () => {
      const cantons = t('cantons', { returnObjects: true });
      const { data: initialValues } = await getMutation(draftId);
      const mappedInitialValues = {
        ...initialValues,
        cantonOfReturn: initialValues.returnVehicleMutation.cantonOfReturn ? { value: initialValues.returnVehicleMutation.cantonOfReturn, label: cantons[initialValues.returnVehicleMutation.cantonOfReturn] } : null,
        comment: initialValues.comment ? initialValues.comment : '',
        dateOfReturn: initialValues.requestedDateOfExecution,
        sendToProvider: true
      };
      setFormInitialValues(mappedInitialValues);
    };
    getDraftMutation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draftId, t]);

  async function handleSubmit(values, actions) {
    try {
      const payload = {
        dateOfReturn: values.dateOfReturn,
        cantonOfReturn: values.cantonOfReturn.value,
        comment: values.comment || '',
        sendToProvider: values.sendToProvider,
      };
      await returnVehicle(contractId, payload);
      fetchProfile();
      addSuccess(t('returnvehicleform.submit.success'));
      setHasSuccess(true);
      history.push('/');
    } catch (err) {
      const errorCode = err?.response?.data?.error;

      actions.setSubmitting(false);
      addAlert(
        t(
          errorCode === 'CONFLICT_MUTATION_COLLISION'
            ? 'returnvehicleform.submit.collision'
            : 'returnvehicleform.submit.failure',
          errorCode === 'CONFLICT_MUTATION_COLLISION' ? {id: hasOpenMutation?.id} : null
        ),
      );
      window.scrollTo(0, 0);
      throw err;
    }
  }

  const regularReturnDate = startOfDay(addWeeks(new Date(), 3));

  const earliestContractEndDate = contract
    ? startOfDay(addYears(new Date(contract.vehicle.immatriculation), 1))
    : undefined;

  const minReturnDate = contract && regularReturnDate && earliestContractEndDate
    ? [regularReturnDate, earliestContractEndDate].reduce((a, b) => (a > b ? a : b))
    : regularReturnDate;


  const handleSaveDraft = async values => {
    try {
      const payload = {
        ...values,
        comment: values.comment,
        requestedDateOfExecution: values.dateOfReturn,
        cantonOfReturn: values.cantonOfReturn ? values.cantonOfReturn.value : null,
      };

      if (hasValue(payload)) {
        if (draftId) {
          await updateContractMutation(draftId, payload);
          addSuccess(t('drafts.updatedraft'));
        } else {
          await saveReturnVehicleDraft(contractId, payload);
          addSuccess(t('drafts.savedraft'));
        }
        setShowPrompt(false);
        history.push('/');
      } else {
        addAlert(t('drafts.emptydraft'));
      }
    } catch (err) {
      addAlert(t('drafts.failure'));
      throw err;
    }
    window.scrollTo(0, 0);
  };

  const removeDraft = async values => {
    try {
      await deleteReturnVehicleDraft(draftId);
      addSuccess(t('drafts.deletedraft'));
      setShowPrompt(false);
      history.push('/');
    } catch (err) {
      addAlert(t('drafts.faildeletedraft'));
      throw err;
    }
    window.scrollTo(0, 0);
  };

  const hasValue = payload => {
    return payload.dateOfReturn || payload.cantonOfReturn || payload.comment !== '';
  };

  return (
    <>
      <BackMenu to="/" text={t('returnvehicleform.back')}></BackMenu>
      <Container>
        {formInitialValues &&  <Formik
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          initialValues={formInitialValues}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            setFieldTouched,
            isValid,
            submitCount,
          }) => {
            return (
              <form onSubmit={handleSubmit}>
                <RoutingPrompt
                  when={!hasSuccess && !isEqual(values, initialValues) && showPrompt}
                  message={t('common.formprompt')}
                />
                <h1>{t('returnvehicleform.title')}</h1>
                <ContractInformation
                  className={styles.infoCard}
                  contract={contract}
                  isLoading={isLoading}
                  hasError={hasError}
                  omittedProperties={[
                    OmitKeys.mileageperyear,
                    OmitKeys.pricePerMonth,
                    OmitKeys.contractId,
                  ]}
                />
                {contract && earliestContractEndDate && earliestContractEndDate > regularReturnDate && (
                  <MessageCard
                    type="important"
                    title={t('returnvehicleform.warning.title')}
                    className="margin-bottom-4"
                  >
                    <Trans
                      i18nKey="returnvehicleform.warning.text"
                      values={{
                        minReturnDate: formatDate(minReturnDate),
                      }}
                    />
                  </MessageCard>
                )}

                {hasOpenMutation && <MessageCard
                  type="important"
                  title={t('common.blockingmutations.title')}
                  className="margin-bottom-4">
                  <Trans i18nKey="common.blockingmutations.text" values={{ id: hasOpenMutation.id}} />
                </MessageCard>}

                <h3>{t('returnvehicleform.subtitle')}</h3>
                <FormField label={t('returnvehicleform.fields.dateofreturn.label')} required>
                  <DatePicker
                    name="dateOfReturn"
                    invalid={touched.dateOfReturn && errors.dateOfReturn}
                    value={values.dateOfReturn}
                    options={{
                      minDate: minReturnDate,
                    }}
                    onChange={date => setFieldValue('dateOfReturn', date)}
                    onBlur={() => setFieldTouched('dateOfReturn', true)}
                  ></DatePicker>
                  <ValidationErrorMessage
                    invalid={touched.dateOfReturn && errors.dateOfReturn}
                    i18nKey={errors.dateOfReturn}
                  />

                </FormField>
                <FormField label={t('returnvehicleform.fields.canton.label')} required>
                  <CantonPicker
                    name="cantonOfReturn"
                    value={values.cantonOfReturn}
                    onChange={cantonOfReturn => setFieldValue('cantonOfReturn', cantonOfReturn)}
                    onBlur={() => setFieldTouched('cantonOfReturn', true)}
                    invalid={touched.cantonOfReturn && errors.cantonOfReturn}
                  ></CantonPicker>
                  <ValidationErrorMessage
                    invalid={touched.cantonOfReturn && errors.cantonOfReturn}
                    i18nKey={errors.cantonOfReturn}
                  />
                </FormField>

                {profile && profile?.isFleetMember && <AutoId>{id => (<Checkbox className={styles.marginBottom} id={id} value={values.sendToProvider} onChange={v => setFieldValue('sendToProvider', v.target.checked)} >{t('driverchangeform.fields.sendtoprovider')}</Checkbox>)}</AutoId>}

                <CommentField
                  value={values.comment}
                  onChange={v => setFieldValue('comment', v)}
                  onBlur={handleBlur}
                />

                <MessageCard
                  type="important"
                  title={t('returnvehicleform.informationcard.title')}
                  className="margin-bottom-2">
                  <Trans i18nKey="returnvehicleform.informationcard.text" />
                  <ul className="list single-line">
                    <li>
                      <a href={returnGuideLinks[activeLanguage]} target="_blank" rel="noopener noreferrer">{t('returnvehicleform.informationcard.returnguide')}</a>
                    </li>
                    <li>
                      <a href={contract?.vehicle.vehicleCategory.type === 'utilityvehicle' ? supplementaryProtocolLinksUv[activeLanguage] : supplementaryProtocolLinksPw[activeLanguage]} target="_blank"  rel="noopener noreferrer">{contract?.vehicle?.vehicleCategory?.type === 'utilityvehicle' ?  t('returnvehicleform.informationcard.supplementaryprotocolutilityvehicle') : t('returnvehicleform.informationcard.supplementaryprotocolpassengervehicle')}</a>
                    </li>
                  </ul>
                </MessageCard>


                <ButtonGroup responsive>
                  <Button
                    type="submit"
                    disabled={(submitCount > 0 && !isValid) || isSubmitting}
                    isLoading={isSubmitting}
                  >
                    {t('common.send')}
                  </Button>
                  {profile?.isFleetMember &&
                    <>
                      <Button
                        type="button"
                        onClick={() => handleSaveDraft(values)}
                        isLoading={isSubmitting}
                        color="confirm"
                      >{t('drafts.buttons.save')}</Button>
                      {!isNaN(draftId) && <Button
                        className="deleteDraftButton"
                        type="button"
                        onClick={() => removeDraft(values)}
                        isLoading={isSubmitting}
                      >{t('drafts.buttons.delete')}</Button>}
                    </>
                  }
                  <Button color="secondary" onClick={history.goBack} type="button">
                    {t('common.cancel')}
                  </Button>
                </ButtonGroup>
                <p>{t('formvalidation.required.descriptions')}</p>
              </form>
            );
          }}
        </Formik>}
      </Container>
    </>
  );
}

export default ReturnVehicleForm;
