//@flow

import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { approveMutation } from '../../services/contractMutationService';

import ButtonGroup from '../ButtonGroup';
import Button from '../Button';
import FormField from '../FormField';
import Input from '../Input';
import Modal from '../Modal';
import ValidationErrorMessage from '../ValidationErrorMessage';
import { useNotifications } from '../Notifications/NotificationsProvider';
import { AuthContext } from '../AuthProvider/AuthProvider';

type Props = {
  mutationId: number,
  approvalPoint: string,
  title: string,
  onClose: Function,
  children?: any,
};

function RejectMutationModal({ mutationId, approvalPoint, title, onClose, children }: Props) {
  const { t } = useTranslation();
  const history = useHistory();
  const { fetchProfile } = useContext(AuthContext);
  const { addSuccess, addAlert } = useNotifications();

  async function handleSubmit(values, actions) {
    try {
      await approveMutation(mutationId, values);
      fetchProfile();
      addSuccess(t('mutationdetail.alerts.success'));
      window.scrollTo(0, 0);
      history.push('/mutation-overview');
    } catch {
      addAlert(t('errorstate.defaultmessage'));
      window.scrollTo(0, 0);
      actions.setSubmitting(false);
    }
  }

  const validationSchema = Yup.object().shape({
    approved: Yup.boolean(),
    rejectionComment: Yup.string().required(),
  });

  return (
    <Modal title={title} onClose={onClose}>
      {children}
      <Formik
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        initialValues={{ approvalPoint: approvalPoint, approved: false, rejectionComment: null }}
      >
        {({
          values,
          touched,
          errors,
          setFieldValue,
          setFieldTouched,
          handleChange,
          handleBlur,
          submitCount,
          handleSubmit,
          isSubmitting,
          isValid,
          submitForm,
        }) => {
          return (
            <form
              onSubmit={e => {
                e.stopPropagation();
                return handleSubmit(e);
              }}
            >
              <FormField>
                <Input
                  name="rejectionComment"
                  invalid={touched.rejectionComment && errors.rejectionComment}
                  onChange={e => setFieldValue('rejectionComment', e.currentTarget.value)}
                  onBlur={() => setFieldTouched('rejectionComment')}
                  label={t('mutationdetail.labels.rejectionreason')}
                />
                <ValidationErrorMessage
                  invalid={touched.rejectionComment && errors.rejectionComment}
                  i18nKey={'formvalidation.required.message'}
                />
              </FormField>
              <ButtonGroup responsive>
                <Button
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                >
                  <Trans i18nKey="mutationdetail.buttons.confirmrejection" />
                </Button>
                <Button color="secondary" disabled={isSubmitting} onClick={() => onClose()}>
                  <Trans i18nKey="mutationdetail.buttons.cancel" />
                </Button>
              </ButtonGroup>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
}

export default RejectMutationModal;
