// @flow

import { Formik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useVehicleInteriors from '../../hooks/useVehicleInteriors';
import { uploadFile } from '../../services/mediaService';
import { createVehicleCategory, updateVehicleCategory } from '../../services/vehicleCategoryService';
import AutoId from '../AutoId';
import Button from '../Button';
import ButtonGroup from '../ButtonGroup';
import CategoryFileUpload from '../CategoryFileUpload';
import Checkbox from '../Checkbox';
import FormField from '../FormField';
import Icon from '../Icon';
import Input from '../Input';
import Modal from '../Modal';
import { useNotifications } from '../Notifications/NotificationsProvider';
import OpenFile from '../OpenFile';
import RadioButton from '../RadioButton';
import RadioButtonGroup from '../RadioButtonGroup';
import RadioTabs from '../RadioTabs';
import Select from '../Select';
import Textarea from '../Textarea';
import ValidationErrorMessage from '../ValidationErrorMessage';
import styles from './CreateVehicleCategoryModal.module.css';
import { defaultValues } from './default-values';
import { validationSchemaDe, validationSchemaFr, validationSchemaIt } from './validation-schema';



type Props = {
  toggleCategoryModal: Function,
  initialValues: Object,
  updateCategories: Function
}

function CreateVehicleCategoryModal({ toggleCategoryModal, initialValues, updateCategories }: Props) {
  const { interiors } = useVehicleInteriors(false);
  const { t } = useTranslation();
  const [newInitialValues, setNewInitialValues] = useState < Object > (null);
  const [validationSchema, setValidationSchema] = useState(validationSchemaDe);

  const { addAlert, addSuccess } = useNotifications();

  const cruiseControlTypes = t('common.vehiclecategory.labels.cruisecontroltype', { returnObjects: true });
  const cruiseControlOptions = Object.keys(cruiseControlTypes).map(type => ({ label: cruiseControlTypes[type], value: type.toUpperCase() }));

  const typeOfDriveOptions = ['4x4', '4x2'].map(type => ({ label: type, value: type }));

  if (!newInitialValues) {
    setNewInitialValues(initialValues ? {
      ...initialValues,
      cruiseControlType: {
        label: initialValues.cruiseControlType ? cruiseControlTypes[initialValues.cruiseControlType.toLowerCase()] : '',
        value: initialValues.cruiseControlType ? initialValues.cruiseControlType : null
      },
      name_german: initialValues.translations.find(elm => elm.languageCode === 'de')?.name || '',
      name_french: initialValues.translations.find(elm => elm.languageCode === 'fr')?.name || '',
      name_italian: initialValues.translations.find(elm => elm.languageCode === 'it')?.name || '',
      description_german: initialValues.translations.find(elm => elm.languageCode === 'de')?.description || '',
      description_french: initialValues.translations.find(elm => elm.languageCode === 'fr')?.description || '',
      description_italian: initialValues.translations.find(elm => elm.languageCode === 'it')?.description || '',
      purpose_german: initialValues.translations.find(elm => elm.languageCode === 'de')?.purpose || '',
      purpose_french: initialValues.translations.find(elm => elm.languageCode === 'fr')?.purpose || '',
      purpose_italian: initialValues.translations.find(elm => elm.languageCode === 'it')?.purpose || '',
      additionalFeatures_german: initialValues.translations.find(elm => elm.languageCode === 'de')?.additionalFeatures || '',
      additionalFeatures_french: initialValues.translations.find(elm => elm.languageCode === 'fr')?.additionalFeatures || '',
      additionalFeatures_italian: initialValues.translations.find(elm => elm.languageCode === 'it')?.additionalFeatures || '',
      fieldOfApplication_german: initialValues.translations.find(elm => elm.languageCode === 'de')?.fieldOfApplication || '',
      fieldOfApplication_french: initialValues.translations.find(elm => elm.languageCode === 'fr')?.fieldOfApplication || '',
      fieldOfApplication_italian: initialValues.translations.find(elm => elm.languageCode === 'it')?.fieldOfApplication || '',
      roles_german: initialValues.translations.find(elm => elm.languageCode === 'de')?.roles || '',
      roles_french: initialValues.translations.find(elm => elm.languageCode === 'fr')?.roles || '',
      roles_italian: initialValues.translations.find(elm => elm.languageCode === 'it')?.roles || '',
      abbreviation: initialValues.abbreviation || '',
      towableMass: initialValues.towableMass || 0,
    } : defaultValues);
  }

  const options = [{ label: t('common.labels.passengervehicle'), value: 'passengervehicle', type: 'passengervehicle' }, { label: t('common.labels.utilityvehicle'), value: 'utilityvehicle', type: 'utilityvehicle' }];
  const interiorOptions = interiors.map(interior => ({
    ...interior,
    value: interior.disIdentifier,
    label: interior.name,
  }));

  const efficencyclasses = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'N/A'].map(elm => ({ label: elm, value: elm }));

  const handleCategorySubmit = async (values, actions) => {
    if (values.descriptionFile && !values.descriptionFile.id) {
      let formData = new FormData();
      formData.append('file', values.descriptionFile);
      const { data: file } = await uploadFile(formData, 'vehicle-category-files');
      values.descriptionFile = file.id;
      values.descriptionFileLocation = file.s3Bucket + '/' + file.s3Identifier;
    }

    const translations = [
      { languageCode: 'de', description: values.description_german, name: values.name_german, purpose: values.purpose_german, additionalFeatures: values.additionalFeatures_german, roles: values.roles_german, fieldOfApplication: values.fieldOfApplication_german },
      { languageCode: 'fr', description: values.description_french, name: values.name_french, purpose: values.purpose_french, additionalFeatures: values.additionalFeatures_french, roles: values.roles_french, fieldOfApplication: values.fieldOfApplication_french },
      { languageCode: 'it', description: values.description_italian, name: values.name_italian, purpose: values.purpose_italian, additionalFeatures: values.additionalFeatures_italian, roles: values.roles_italian, fieldOfApplication: values.fieldOfApplication_italian }
    ];

    if (values.id) {
      translations.map(trans => {
        return {
          ...trans,
          ...values.translations.find(elm => elm.languageCode === trans.languageCode)
        };
      });
    }
    const translated = {
      ...values,
      cruiseControlType: values.hasCruiseControl ? values.cruiseControlType.value : null,
      translations: translations
    };
    if (values.id) {
      try {
        const { data } = await updateVehicleCategory(translated, values.id);
        updateCategories(data, true);
        addSuccess(t('vehiclecategoryadmin.modal.submit.update'));
      } catch {
        addAlert(t('vehiclecategoryadmin.modal.submit.failure'));
      }
    } else {
      try {
        const { data } = await createVehicleCategory(translated);
        updateCategories(data, false);
        addSuccess(t('vehiclecategoryadmin.modal.submit.create'));
      } catch {
        addAlert(t('vehiclecategoryadmin.modal.submit.failure'));
      }
    }
    toggleCategoryModal();
  };

  const changeValidation = (lang, values) => {
    const frenchValues = {
      name: values.name_french,
      description: values.description_french,
      purpose: values.purpose_french,
      additionalFeatures: values.additionalFeatures_french,
      fieldOfApplication: values.fieldOfApplication_french,
    };

    const italianValues = {
      name: values.name_italian,
      description: values.description_italian,
      purpose: values.purpose_italian,
      additionalFeatures: values.additionalFeatures_italian,
      fieldOfApplication: values.fieldOfApplication_italian,
    };

    const hasFrench = Object.values(frenchValues).find(elm => elm !== '');
    const hasItalian = Object.values(italianValues).find(elm => elm !== '');

    const germanAndFrench = validationSchemaDe.concat(validationSchemaFr);
    const germanAndItalian = validationSchemaDe.concat(validationSchemaIt);
    const allLanguages = germanAndFrench.concat(validationSchemaIt);

    if (hasFrench && hasItalian) {
      setValidationSchema(allLanguages);
    } else if (hasFrench) {
      setValidationSchema(germanAndFrench);
    } else if (hasItalian) {
      setValidationSchema(germanAndItalian);
    } else {
      setValidationSchema(validationSchemaDe);
    }
  };

  const changeCruiseControl = (v: FormikValues, setFieldValue: () => void) => {
    if (v.hasCruiseControl) {
      setFieldValue('cruiseControlType', null);
    }

    setFieldValue('hasCruiseControl', v.target.checked);
  };

  return (
    <>
      <Modal title={initialValues ? t('vehiclecategoryadmin.modal.modaltitle.edit') : t('vehiclecategoryadmin.modal.modaltitle.create')} size="wide" onClose={toggleCategoryModal} closeDisabled={false}>
        {newInitialValues && <Formik
          enableReinitialize={true}
          initialValues={newInitialValues}
          onSubmit={handleCategorySubmit}
          validationSchema={validationSchema}
        >{
            ({
              values,
              touched,
              errors,
              handleSubmit,
              setFieldValue,
              setFieldTouched,
              handleChange,
              handleBlur,
              submitCount,
              isSubmitting,
              isValid,
            }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <h2 className={styles.subTitle}>{t('vehiclecategoryadmin.modal.commontitle')}</h2>

                  <FormField label={t('vehiclecategoryadmin.modal.vehiclecategoryname') + ' ' + t('vehiclecategoryadmin.avriosrelevant')} required>
                    <sdx-tabs>
                      <sdx-tabs-item label={t('common.languages.de')}>
                        <Input onChange={v => { changeValidation('de', values); setFieldValue('name_german', v.target.value); }} onBlur={() => setFieldTouched('name_german', true)} value={values.name_german} inputClassName={styles.input} placeholder={t('common.languages.de')}></Input>
                        <ValidationErrorMessage
                          i18nKey={errors.name_german}
                          invalid={touched.name_german && errors.name_german}
                        />
                      </sdx-tabs-item>
                      <sdx-tabs-item label={t('common.languages.fr')}>
                        <Input onChange={v => { changeValidation('fr', values); setFieldValue('name_french', v.target.value); }} onBlur={() => setFieldTouched('name_french', true)} value={values.name_french} inputClassName={styles.input} placeholder={t('common.languages.fr')}></Input>
                        <ValidationErrorMessage
                          i18nKey={errors.name_french}
                          invalid={touched.name_french && errors.name_french}
                        />
                      </sdx-tabs-item>
                      <sdx-tabs-item label={t('common.languages.it')}>
                        <Input onChange={v => { changeValidation('it', values); setFieldValue('name_italian', v.target.value); }} onBlur={() => setFieldTouched('name_italian', true)} value={values.name_italian} inputClassName={styles.input} placeholder={t('common.languages.it')}></Input>
                        <ValidationErrorMessage
                          i18nKey={errors.name_italian}
                          invalid={touched.name_italian && errors.name_italian}
                        />
                      </sdx-tabs-item>
                    </sdx-tabs>
                  </FormField>


                  <FormField label={t('vehiclecategoryadmin.modal.vehicletype')} required>
                    <RadioTabs children={options} onChange={v => setFieldValue('type', v)} onBlur={() => setFieldTouched('type', true)} selected={values.type}></RadioTabs>
                    <ValidationErrorMessage
                      i18nKey={errors.type}
                      invalid={touched.type && errors.type}
                    />
                  </FormField>


                  <FormField label={t('vehiclecategoryadmin.modal.representationvehicle')} required>
                    <RadioButtonGroup onChange={v => setFieldValue('isRepresentationVehicle', v === 'true')} onBlur={() => setFieldTouched('isRepresentationVehicle', true)} value={values.isRepresentationVehicle}>
                      <RadioButton name="representationVehicleSelect" value={true}>{t('common.no')}</RadioButton>
                      <RadioButton name="representationVehicleSelect" value={false}>{t('common.yes')}</RadioButton>
                    </RadioButtonGroup>
                    <ValidationErrorMessage
                      i18nKey={errors.isRepresentationVehicle}
                      invalid={touched.isRepresentationVehicle && errors.isRepresentationVehicle}
                    />
                  </FormField>

                  {values.type === 'utilityvehicle' &&
                    <FormField label={t('vehiclecategoryadmin.modal.interior')}>
                      <Select
                        options={interiorOptions}
                        placeholder={t('common.select')}
                        loadingMessage={() => t('common.loading')}
                        onChange={v => { setFieldValue('interiorConstructionId', v.id); setFieldValue('interiorConstruction', v); }}
                        onBlur={() => { setFieldTouched('interiorConstructionId', true); setFieldTouched('interiorConstruction', true); }}
                        value={values.interiorConstruction ? { label: values.interiorConstruction.name, value: values.interiorConstruction.disIdentifier } : null}
                        isClearable={false}
                      ></Select>
                      <ValidationErrorMessage
                        i18nKey={errors.interiorConstruction}
                        invalid={touched.interiorConstruction && errors.interiorConstruction}
                      />
                    </FormField>}

                  <FormField label={t('vehiclecategoryadmin.modal.description')} required>
                    <sdx-tabs>
                      <sdx-tabs-item label={t('common.languages.de')}>
                        <Textarea onChange={v => { changeValidation('de', values); setFieldValue('description_german', v.target.value); }} onBlur={() => setFieldTouched('description_german', true)} value={values.description_german} placeholder={t('common.languages.de')}></Textarea>
                        <ValidationErrorMessage
                          i18nKey={errors.description_german}
                          invalid={touched.description_german && errors.description_german}
                        />
                      </sdx-tabs-item>
                      <sdx-tabs-item label={t('common.languages.fr')}>
                        <Textarea onChange={v => { changeValidation('fr', values); setFieldValue('description_french', v.target.value); }} onBlur={() => setFieldTouched('description_french', true)} value={values.description_french} placeholder={t('common.languages.fr')}></Textarea>
                        <ValidationErrorMessage
                          i18nKey={errors.description_french}
                          invalid={touched.description_french && errors.description_french}
                        />
                      </sdx-tabs-item>
                      <sdx-tabs-item label={t('common.languages.it')}>
                        <Textarea onChange={v => { changeValidation('it', values); setFieldValue('description_italian', v.target.value); }} onBlur={() => setFieldTouched('description_italian', true)} value={values.description_italian} placeholder={t('common.languages.it')}></Textarea>
                        <ValidationErrorMessage
                          i18nKey={errors.description_italian}
                          invalid={touched.description_italian && errors.description_italian}
                        />
                      </sdx-tabs-item>
                    </sdx-tabs>

                  </FormField>

                  <FormField label={t('vehiclecategoryadmin.modal.purpose')} required>
                    <sdx-tabs>
                      <sdx-tabs-item label={t('common.languages.de')}>
                        <Textarea onChange={v => { changeValidation('de', values); setFieldValue('purpose_german', v.target.value); }} onBlur={() => setFieldTouched('purpose_german', true)} value={values.purpose_german} placeholder={t('common.languages.de')}></Textarea>
                        <ValidationErrorMessage
                          i18nKey={errors.purpose_german}
                          invalid={touched.purpose_german && errors.purpose_german}
                        />
                      </sdx-tabs-item>
                      <sdx-tabs-item label={t('common.languages.fr')}>
                        <Textarea onChange={v => { changeValidation('ft', values); setFieldValue('purpose_french', v.target.value); }} onBlur={() => setFieldTouched('purpose_french', true)} value={values.purpose_french} placeholder={t('common.languages.fr')}></Textarea>
                        <ValidationErrorMessage
                          i18nKey={errors.purpose_french}
                          invalid={touched.purpose_french && errors.purpose_french}
                        />
                      </sdx-tabs-item>
                      <sdx-tabs-item label={t('common.languages.it')}>
                        <Textarea onChange={v => { changeValidation('it', values); setFieldValue('purpose_italian', v.target.value); }} onBlur={() => setFieldTouched('purpose_italian', true)} value={values.purpose_italian} placeholder={t('common.languages.it')}></Textarea>
                        <ValidationErrorMessage
                          i18nKey={errors.purpose_italian}
                          invalid={touched.purpose_italian && errors.purpose_italian}
                        />
                      </sdx-tabs-item>
                    </sdx-tabs>

                  </FormField>


                  <FormField label={t('vehiclecategoryadmin.modal.fieldofapplication')} required>
                    <sdx-tabs>
                      <sdx-tabs-item label={t('common.languages.de')}>
                        <Textarea onChange={v => { changeValidation('de', values); setFieldValue('fieldOfApplication_german', v.target.value); }} onBlur={() => setFieldTouched('fieldOfApplication_german', true)} value={values.fieldOfApplication_german} placeholder={t('common.languages.de')}></Textarea>
                        <ValidationErrorMessage
                          i18nKey={errors.fieldOfApplication_german}
                          invalid={touched.fieldOfApplication_german && errors.fieldOfApplication_german}
                        />
                      </sdx-tabs-item>
                      <sdx-tabs-item label={t('common.languages.fr')}>
                        <Textarea onChange={v => { changeValidation('fr', values); setFieldValue('fieldOfApplication_french', v.target.value); }} onBlur={() => setFieldTouched('fieldOfApplication_french', true)} value={values.fieldOfApplication_french} placeholder={t('common.languages.fr')}></Textarea>
                        <ValidationErrorMessage
                          i18nKey={errors.fieldOfApplication_french}
                          invalid={touched.fieldOfApplication_french && errors.fieldOfApplication_french}
                        />
                      </sdx-tabs-item>
                      <sdx-tabs-item label={t('common.languages.it')}>
                        <Textarea onChange={v => { changeValidation('it', values); setFieldValue('fieldOfApplication_italian', v.target.value); }} onBlur={() => setFieldTouched('fieldOfApplication_italian', true)} value={values.fieldOfApplication_italian} placeholder={t('common.languages.it')}></Textarea>
                        <ValidationErrorMessage
                          i18nKey={errors.fieldOfApplication_italian}
                          invalid={touched.fieldOfApplication_italian && errors.fieldOfApplication_italian}
                        />
                      </sdx-tabs-item>
                    </sdx-tabs>

                  </FormField>

                  <FormField label={t('vehiclecategoryadmin.modal.roles')} required>
                    <sdx-tabs>
                      <sdx-tabs-item label={t('common.languages.de')}>
                        <Textarea onChange={v => { changeValidation('de', values); setFieldValue('roles_german', v.target.value); }} onBlur={() => setFieldTouched('roles_german', true)} value={values.roles_german} placeholder={t('common.languages.de')}></Textarea>
                        <ValidationErrorMessage
                          i18nKey={errors.roles_german}
                          invalid={touched.roles_german && errors.roles_german}
                        />
                      </sdx-tabs-item>
                      <sdx-tabs-item label={t('common.languages.fr')}>
                        <Textarea onChange={v => { changeValidation('fr', values); setFieldValue('roles_french', v.target.value); }} onBlur={() => setFieldTouched('roles_french', true)} value={values.roles_french} placeholder={t('common.languages.fr')}></Textarea>
                        <ValidationErrorMessage
                          i18nKey={errors.roles_french}
                          invalid={touched.roles_french && errors.roles_french}
                        />
                      </sdx-tabs-item>
                      <sdx-tabs-item label={t('common.languages.it')}>
                        <Textarea onChange={v => { changeValidation('it', values); setFieldValue('roles_italian', v.target.value); }} onBlur={() => setFieldTouched('roles_italian', true)} value={values.roles_italian} placeholder={t('common.languages.it')}></Textarea>
                        <ValidationErrorMessage
                          i18nKey={errors.roles_italian}
                          invalid={touched.roles_italian && errors.roles_italian}
                        />
                      </sdx-tabs-item>
                    </sdx-tabs>
                  </FormField>

                  <FormField label={t('vehiclecategoryadmin.modal.abbreviation')} required>
                    <Input onChange={v => setFieldValue('abbreviation', v.target.value)} onBlur={() => setFieldTouched('abbreviation', true)} value={values.abbreviation} inputClassName={styles.input} type="text"></Input>
                    <ValidationErrorMessage
                      i18nKey={errors.abbreviation}
                      invalid={touched.abbreviation && errors.abbreviation}
                    />
                  </FormField>

                  <FormField label={t('vehiclecategoryadmin.modal.identifier') + ' ' + t('vehiclecategoryadmin.avriosrelevant')} required>
                    <Input onChange={v => setFieldValue('disIdentifier', v.target.value)} onBlur={() => setFieldTouched('disIdentifier', true)} value={values.disIdentifier} inputClassName={styles.input} type="text"></Input>
                    <ValidationErrorMessage
                      i18nKey={errors.disIdentifier}
                      invalid={touched.disIdentifier && errors.disIdentifier}
                    />
                  </FormField>

                  <FormField label={t('vehiclecategoryadmin.modal.additionalfeatures')}>
                    <sdx-tabs>
                      <sdx-tabs-item label={t('common.languages.de')}>
                        <Textarea onChange={v => { changeValidation('de', values); setFieldValue('additionalFeatures_german', v.target.value); }} onBlur={() => setFieldTouched('additionalFeatures_german', true)} value={values.additionalFeatures_german} placeholder={t('common.languages.de')}></Textarea>
                        <ValidationErrorMessage
                          i18nKey={errors.additionalFeatures_german}
                          invalid={touched.additionalFeatures_german && errors.additionalFeatures_german}
                        />
                      </sdx-tabs-item>
                      <sdx-tabs-item label={t('common.languages.fr')}>
                        <Textarea onChange={v => { changeValidation('fr', values); setFieldValue('additionalFeatures_french', v.target.value); }} onBlur={() => setFieldTouched('additionalFeatures_french', true)} value={values.additionalFeatures_french} placeholder={t('common.languages.fr')}></Textarea>
                        <ValidationErrorMessage
                          i18nKey={errors.additionalFeatures_french}
                          invalid={touched.additionalFeatures_french && errors.additionalFeatures_french}
                        />
                      </sdx-tabs-item>
                      <sdx-tabs-item label={t('common.languages.it')}>
                        <Textarea onChange={v => { changeValidation('it', values); setFieldValue('additionalFeatures_italian', v.target.value); }} onBlur={() => setFieldTouched('additionalFeatures_italian', true)} value={values.additionalFeatures_italian} placeholder={t('common.languages.it')}></Textarea>
                        <ValidationErrorMessage
                          i18nKey={errors.additionalFeatures_italian}
                          invalid={touched.additionalFeatures_italian && errors.additionalFeatures_italian}
                        />
                      </sdx-tabs-item>
                    </sdx-tabs>
                  </FormField>

                  <FormField label={t('vehiclecategoryadmin.modal.pricepermonth')} required>
                    <Input onChange={v => setFieldValue('pricePerMonth', Number(v.target.value))} onBlur={() => setFieldTouched('pricePerMonth', true)} value={values.pricePerMonth} inputClassName={styles.input} type="number"></Input>
                    <ValidationErrorMessage
                      i18nKey={errors.pricePerMonth}
                      invalid={touched.pricePerMonth && errors.pricePerMonth}
                    />
                  </FormField>

                  <h2 className={styles.subTitle}>{t('vehiclecategoryadmin.modal.technicaltitle')}</h2>
                  <FormField label={t('vehiclecategoryadmin.modal.energyefficiencyclass')} required>
                    <Select
                      options={efficencyclasses}
                      placeholder={t('common.select')}
                      loadingMessage={() => t('common.loading')}
                      onChange={v => setFieldValue('energyEfficiencyClass', v?.value)}
                      onBlur={() => setFieldTouched('energyEfficiencyClass', true)}
                      value={{ label: values.energyEfficiencyClass, value: values.energyEfficiencyClass }}
                      isClearable={false}
                      isSearchable={false}
                    ></Select>
                    <ValidationErrorMessage
                      i18nKey={errors.energyEfficiencyClass}
                      invalid={touched.energyEfficiencyClass && errors.energyEfficiencyClass}
                    />
                  </FormField>

                  <FormField label={t('vehiclecategoryadmin.modal.maxemission')} required>
                    <Input onChange={v => setFieldValue('maxEmission', Number(v.target.value))} onBlur={() => setFieldTouched('maxEmission', true)} value={values.maxEmission} type="number" inputClassName={styles.input}></Input>
                    <ValidationErrorMessage
                      i18nKey={errors.maxEmission}
                      invalid={touched.maxEmission && errors.maxEmission}
                    />
                  </FormField>

                  <FormField label={t('vehiclecategoryadmin.modal.typeofdrive')} required>
                    <Select
                      options={typeOfDriveOptions}
                      placeholder={t('common.select')}
                      loadingMessage={() => t('common.loading')}
                      onChange={v => setFieldValue('typeOfDrive', v?.value)}
                      onBlur={() => setFieldTouched('typeOfDrive', true)}
                      value={{ label: values.typeOfDrive, value: values.typeOfDrive }}
                      isClearable={false}
                      isSearchable={false}
                    ></Select>
                    <ValidationErrorMessage
                      i18nKey={errors.typeOfDrive}
                      invalid={touched.typeOfDrive && errors.typeOfDrive}
                    />
                  </FormField>

                  <FormField label={t('vehiclecategoryadmin.modal.loadcapacity')} required>
                    <Input onChange={v => setFieldValue('loadCapacity', Number(v.target.value))} type="number" onBlur={() => setFieldTouched('loadCapacity', true)} value={values.loadCapacity} inputClassName={styles.input}></Input>
                    <ValidationErrorMessage
                      i18nKey={errors.loadCapacity}
                      invalid={touched.loadCapacity && errors.loadCapacity}
                    />
                  </FormField>

                  <FormField label={t('vehiclecategoryadmin.modal.maxgrossweight')} required>
                    <Input onChange={v => setFieldValue('maxGrossWeight', Number(v.target.value))} type="number" onBlur={() => setFieldTouched('maxGrossWeight', true)} value={values.maxGrossWeight} inputClassName={styles.input}></Input>
                    <ValidationErrorMessage
                      i18nKey={errors.maxGrossWeight}
                      invalid={touched.maxGrossWeight && errors.maxGrossWeight}
                    />
                  </FormField>

                  <FormField label={t('vehiclecategoryadmin.modal.minloadvolume')}>
                    <Input onChange={v => setFieldValue('minLoadVolume', Number(v.target.value))} onBlur={() => setFieldTouched('minLoadVolume', true)} value={values.minLoadVolume} inputClassName={styles.input} type="number"></Input>
                    <ValidationErrorMessage
                      i18nKey={errors.minLoadVolume}
                      invalid={touched.minLoadVolume && errors.minLoadVolume}
                    />
                  </FormField>

                  <h2 className={styles.subTitle}>{t('vehiclecategoryadmin.modal.equipmenttitle')}</h2>
                  <div>
                    <AutoId>{id => (<Checkbox id={id} value={values.hasTowHitch} onChange={v => setFieldValue('hasTowHitch', v.target.checked)} >{t('common.vehiclecategory.labels.towhitch')}</Checkbox>)}</AutoId>
                    {values.hasTowHitch && <FormField label={t('vehiclecategoryadmin.modal.towablemass')} className={styles.towableMass}>
                      <Input onChange={v => setFieldValue('towableMass', Number(v.target.value))} onBlur={() => setFieldTouched('towableMass', true)} value={values.towableMass} inputClassName={styles.input} type="number"></Input>
                      <ValidationErrorMessage
                        i18nKey={errors.towableMass}
                        invalid={touched.towableMass && errors.towableMass}
                      />
                    </FormField>}
                    <AutoId>{id => (<Checkbox id={id} value={values.hasNavigationSystem} onChange={v => setFieldValue('hasNavigationSystem', v.target.checked)}>{t('common.vehiclecategory.labels.navigationsystem')}</Checkbox>)}</AutoId>
                    <AutoId>{id => (<Checkbox id={id} value={values.hasParkingSensors} onChange={v => setFieldValue('hasParkingSensors', v.target.checked)}>{t('common.vehiclecategory.labels.parkingsensors')}</Checkbox>)}</AutoId>
                    <AutoId>{id => (<Checkbox id={id} value={values.hasAirConditioning} onChange={v => setFieldValue('hasAirConditioning', v.target.checked)}>{t('common.vehiclecategory.labels.airconditioning')}</Checkbox>)}</AutoId>
                    <AutoId>{id => (<Checkbox id={id} value={values.hasCitySafetySystem} onChange={v => setFieldValue('hasCitySafetySystem', v.target.checked)}>{t('common.vehiclecategory.labels.citysafetysystem')}</Checkbox>)}</AutoId>
                    <AutoId>{id => (<Checkbox id={id} value={values.hasLDWS} onChange={v => setFieldValue('hasLDWS', v.target.checked)}>{t('common.vehiclecategory.labels.ldws')}</Checkbox>)}</AutoId>
                    <AutoId>{id => (<Checkbox id={id} value={values.hasBlindSpotMonitor} onChange={v => setFieldValue('hasBlindSpotMonitor', v.target.checked)}>{t('common.vehiclecategory.labels.blindspotmonitor')}</Checkbox>)}</AutoId>
                    <AutoId>{id => (<Checkbox id={id} value={values.hasCruiseControl} onChange={v => changeCruiseControl(v, setFieldValue)}>{t('common.vehiclecategory.labels.cruisecontrol')}</Checkbox>)}</AutoId>
                    {values.hasCruiseControl &&
                      <FormField>
                        <Select
                          className={styles.cruiseControlField}
                          options={cruiseControlOptions}
                          placeholder={t('common.select')}
                          loadingMessage={() => t('common.loading')}
                          onChange={v => setFieldValue('cruiseControlType', v)}
                          value={values.cruiseControlType}
                          isClearable={false}
                          isSearchable={false}
                        ></Select>
                      </FormField>
                    }
                  </div>

                  {!values.descriptionFileLocation && <CategoryFileUpload descriptionFile={values.descriptionFile} onChange={v => setFieldValue('descriptionFile', v.target.files[0])} onBlur={() => setFieldTouched('descriptionFile', true)} onClick={v => { setFieldValue('descriptionFile', null); setFieldValue('descriptionFileLocation', null); }} />}

                  {values.descriptionFileLocation && (
                    <div className={styles.file}>
                      <OpenFile fileLocation={initialValues.descriptionFileLocation} />
                      <div className={styles.deleteFile} onClick={v => { setFieldValue('descriptionFile', null); setFieldValue('descriptionFileLocation', null); }} role="button" tabIndex="-1">
                        <Icon name="bin" className="icon--s2"></Icon>
                      </div>
                    </div>
                  )
                  }

                  <ButtonGroup responsive={true}>
                    <Button color="primary" type="submit" disabled={!isValid || isSubmitting}> {initialValues ? t('common.update') : t('common.create')}</Button>
                    <Button color="secondary" type="reset" onClick={toggleCategoryModal}>{t('common.cancel')}</Button>
                  </ButtonGroup>
                </form>
              );
            }
          }
        </Formik>}
      </Modal>
    </>
  );
}

export default CreateVehicleCategoryModal;
