//@flow

import React from 'react';
import format from 'date-fns/fp/format';
import { Trans } from 'react-i18next';

import PaddedId from '../../../components/PaddedId';

import { type ContractMutation } from '../../../types/contract-mutation-types';

type Props = {
  mutation: ContractMutation,
};

function MutationTitle({ mutation }: Props) {
  const formatDate = format('dd.MM.yyyy');

  return (
    <div className="row flex-items-md-bottom">
      <div className="col-sm-12 col-md-8 margin-bottom-3">
        <h1>
          <Trans i18nKey={`mutationtypes.${mutation.type.toLowerCase()}`} />{' '}
          <PaddedId id={mutation.id} />
        </h1>
      </div>
      <div className="col-sm-12 col-md-4 margin-bottom-3 text-align-right">
        {formatDate(new Date(mutation.createdAt))}
      </div>
    </div>
  );
}

export default MutationTitle;
