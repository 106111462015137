//@flow

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

import ButtonGroup from '../ButtonGroup';
import Button from '../Button';
import Modal from '../Modal';
import { useNotifications } from '../Notifications/NotificationsProvider';
import { Formik } from 'formik';
import { initialValues } from './initial-values';
import { validationSchema } from './validation-schema';
import FormField from '../FormField';
import ValidationErrorMessage from '../ValidationErrorMessage';
import DebtorTypeahead from '../DebtorTypeahead';

import useFormattedDebtorCostcenter from '../../hooks/useFormattedDebtorCostcenter';
import CostCenterPicker from '../CostCenterPicker';
import DeleteButton from '../DeleteButton';
import { type DebtorCostcenter } from '../../types/debtor-costcenter-types';

type Props = {
  debtorCostcenterId: number | null,
  title: string,
  onClose: Function,
  children?: any,
  createMapping: Function,
  updateMapping: Function,
  deleteMapping: Function
};

function DebtorCostCenterModal({
  debtorCostcenterId,
  title,
  onClose,
  children,
  createMapping,
  updateMapping,
  deleteMapping,
}: Props) {
  const { t } = useTranslation();
  const history = useHistory();
  const { addSuccess, addAlert } = useNotifications();

  const [formState, setFormState] = useState();
  const [formInitialValues, setFormInitialValues] = useState<?DebtorCostcenter>(initialValues);
  const { debtorCostcenter } = useFormattedDebtorCostcenter(debtorCostcenterId);

  useEffect(() => {
    let checkId = async () => {
      if (debtorCostcenterId) {
        setFormInitialValues(debtorCostcenter);
        setFormState('edit');
      } else {
        setFormState('create');
      }
    };
    checkId();
  }, [debtorCostcenterId, debtorCostcenter]);

  async function handleDelete(e, id) {
    await deleteMapping(id);

    onClose();
  }

  async function handleSubmit(values, actions) {
    try {
      const payload = {
        costcenter: values.costcenter.costcenter.identifier,
        debtorId: Number(values.debtor.value),
      };
      if (formState === 'edit') {
        const convertedId = Number(debtorCostcenterId);
        await updateMapping(convertedId, {
          id: convertedId,
          ...payload,
        });
      } else {
        await createMapping(payload);
      }
      addSuccess(
        debtorCostcenterId ? t('debtorform.update.success') : t('debtorform.submit.success'),
      );
      onClose();
      history.push('/debtorcostcenter');
    } catch (err) {
      const errorCode = err?.response?.data?.error;

      const alertText = code => {
        if (code === 'MISSING_ID') {
          return 'debtorform.delete.missingid';
        }
        return 'debtorform.delete.failure';
      };
      addAlert(t(alertText(errorCode)));
      window.scrollTo(0, 0);
      throw err;
    }
  }

  return (
    <Modal title={title} onClose={onClose}>
      {formInitialValues && (
        <Formik
          onSubmit={handleSubmit}
          initialValues={formInitialValues}
          validationSchema={validationSchema}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            setFieldTouched,
            isValid,
            submitCount,
          }) => {
            return (
              <>
                {children}
                <FormField label={t('debtorcostcenter.fields.costcenter')} required>
                  <CostCenterPicker
                    name="costcenter"
                    value={values.costcenter}
                    onChange={costcenter => setFieldValue('costcenter', costcenter)}
                    onBlur={() => setFieldTouched('costcenter', true)}
                    invalid={touched.costcenter && errors.costcenter}
                  />
                  <ValidationErrorMessage
                    invalid={touched.costcenter && errors.costcenter}
                    i18nKey={errors.costcenter}
                  />
                </FormField>
                <FormField label={t('debtorcostcenter.fields.debtor')} required>
                  <DebtorTypeahead
                    onChange={val => setFieldValue('debtor', val)}
                    value={values.debtor}
                  ></DebtorTypeahead>
                  <ValidationErrorMessage
                    invalid={touched.debtor && errors.debtor}
                    i18nKey={errors.debtor}
                  />
                </FormField>
                <ButtonGroup>
                  {debtorCostcenterId ? (
                    <DeleteButton text={t('debtorcostcenter.deletemapping')} onClick={e => handleDelete(e, debtorCostcenterId)} />
                  ) : null}
                  <Button color="secondary" type="cancel" onClick={() => onClose()}>
                    <Trans i18nKey="debtorform.debtormodal.buttons.cancel" />
                  </Button>
                  <Button color="primary" type="submit" onClick={handleSubmit}>
                    {formState === 'edit' ? (
                      <Trans i18nKey="debtorform.debtormodal.buttons.update" />
                    ) : (
                      <Trans i18nKey="debtorform.debtormodal.buttons.create" />
                    )}
                  </Button>
                </ButtonGroup>
              </>
            );
          }}
        </Formik>
      )}
    </Modal>
  );
}

export default DebtorCostCenterModal;
