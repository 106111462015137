import React from 'react';
import { useHistory } from 'react-router-dom';
import Card, { CardItem } from '../../../components/Card';
import MutationCardItem from './MutationCardItem';
import { useTranslation, Trans } from 'react-i18next';
import useMyMutations from '../../../hooks/useMyMutations';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import AnimateHeight from 'react-animate-height';
import EmptyState from '../../../components/EmptyState';
import ErrorState from '../../../components/ErrorState';

function MutationListCard() {
  const { t } = useTranslation();
  const history = useHistory();
  const { isLoading, hasError, mutations, totalCount } = useMyMutations();
  const isEmpty = !hasError && !isLoading && mutations.length === 0;

  const title =
    totalCount > 5
      ? `${t('dashboard.orderlist.title')} (${totalCount})`
      : t('dashboard.orderlist.title');

  return (
    <Card title={title} color="pink" dark className="margin-bottom-3">
      <AnimateHeight height={isLoading || hasError || isEmpty ? 150 : 'auto'} duration={100}>
        {!isEmpty &&
          mutations.map(mutation => <MutationCardItem mutation={mutation} key={mutation.id} />)}
        {(isLoading || hasError || isEmpty) && (
          <CardItem style={{ height: 150 }}>
            <div className="flex flex-center" style={{ height: '100%' }}>
              {isLoading && <LoadingSpinner />}
              {isEmpty && <EmptyState />}
              {hasError && !isLoading && <ErrorState />}
            </div>
          </CardItem>
        )}
      </AnimateHeight>
      {totalCount > 5 && (
        <div className="card__footer">
          <button className="link" onClick={() => history.push('/mutation-overview')}>
            <Trans i18nKey="common.showmore" />
          </button>
        </div>
      )}
    </Card>
  );
}

export default MutationListCard;
