//@flow

import React from 'react';

import { type Debtor } from '../../../types/debtor-types';
import styles from './DebtorListItem.module.css';
import Icon from '../../../components/Icon';
import { useHistory } from 'react-router-dom';

type Props = {
  debtor: Debtor,
};

function DebtorListItem({ debtor }: Props) {
  const history = useHistory();

  return (
    <tr className={styles.row}>
      <td>{debtor?.name}</td>
      <td>{debtor?.number}</td>
      <td>{debtor?.revenueAccountNumber}</td>
      <td>{debtor?.debtorType}</td>
      <td>{debtor?.status}</td>

      <td>
        <div role="button" tabIndex="-1" onClick={() => history.push(`debtor/edit/${debtor.id}`)}>
          <Icon name="edit" className="blue icon--s3"></Icon>
        </div>
      </td>
    </tr>
  );
}

export default DebtorListItem;
