// @flow

import React, { useContext } from 'react';
import FormField from '../../../components/FormField';
import RadioButtonGroup from '../../../components/RadioButtonGroup/RadioButtonGroup';
import RadioButton from '../../../components/RadioButton/RadioButton';
import { AuthContext } from '../../../components/AuthProvider/AuthProvider';
import MessageCard from '../../../components/MessageCard/MessageCard';
import ValidationErrorMessage from '../../../components/ValidationErrorMessage/ValidationErrorMessage';

import NewDriverPicker from './NewDriverPicker';
import DriverInformation from './DriverInformation';
import { useTranslation, Trans } from 'react-i18next';
import PhoneNumberModal from './PhoneNumberModal';

type Props = {
  values: {
    driver?: {
      label: string,
      value: string,
    },
    driverType?: string,
  },
  setFieldValue: Function,
  setFieldTouched: Function,
  errors: {
    driver?: string,
    driverType?: string,
  },
  touched: {
    driver?: boolean,
    driverType?: boolean,
  },
};

function SelectVehicleDriver({ values, setFieldValue, setFieldTouched, errors, touched }: Props) {
  const { profile } = useContext(AuthContext);
  const { t } = useTranslation();

  return (
    <>
      <FormField label={t('selectdriver.label')} required>
        <RadioButtonGroup
          value={values.driverType}
          onChange={val => setFieldValue('driverType', val)}
        >
          <RadioButton
            value="me"
            description={values.driverType === 'me' && <DriverInformation driver={profile} />}
          >
            <Trans i18nKey="selectdriver.options.me.label" />
          </RadioButton>
          <RadioButton
            value="otherDriver"
            description={
              values.driverType === 'otherDriver' && (
                <NewDriverPicker
                  value={values.driver}
                  onChange={val => setFieldValue('driver', val)}
                  onBlur={() => setFieldTouched('driver', true)}
                  touched={touched.driver}
                  error={errors.driver}
                />
              )
            }
          >
            <Trans i18nKey="selectdriver.options.otherdriver.label" />
          </RadioButton>
          <RadioButton
            value="newDriver"
            description={
              values.driverType === 'newDriver' && (
                <MessageCard title="Fahrerwechsel">
                  <Trans i18nKey="selectdriver.options.newdriver.notice" />
                </MessageCard>
              )
            }
          >
            <Trans i18nKey="selectdriver.options.newdriver.label" />
          </RadioButton>
        </RadioButtonGroup>
        <ValidationErrorMessage
          invalid={Boolean(errors.driverType) && Boolean(touched.driverType)}
          i18nKey={errors.driverType}
        />
      </FormField>
      {values.driverType === 'me' && <PhoneNumberModal />}
    </>
  );
}

export default SelectVehicleDriver;
