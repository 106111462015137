//@flow

import { Formik } from 'formik';
import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import format from 'date-fns/fp/format';

import { updateContractMutation } from '../../../../services/contractMutationService';

import Button from '../../../../components/Button';
import ButtonGroup from '../../../../components/ButtonGroup';
import FormField from '../../../../components/FormField';
import Modal from '../../../../components/Modal';

import { AuthContext } from '../../../../components/AuthProvider/AuthProvider';
import DatePicker from '../../../../components/DatePicker/DatePicker';
import { useNotifications } from '../../../../components/Notifications/NotificationsProvider';
import RadioButton from '../../../../components/RadioButton';
import RadioButtonGroup from '../../../../components/RadioButtonGroup';

type Props = {
  mutationId: number,
  onClose: Function,
  executionDate: Date,
};

function ConfirmDateModal({ mutationId, onClose, executionDate }: Props) {
  const { t } = useTranslation();
  const history = useHistory();
  const { addSuccess, addAlert } = useNotifications();
  const { fetchProfile } = useContext(AuthContext);
  const formatDate = format('dd.MM.yyyy');

  async function handleSubmit(values, actions) {
    try {
      await updateContractMutation(mutationId, values);
      addSuccess(t('mutationdetail.alerts.success'));
      window.scrollTo(0, 0);

      fetchProfile();
      history.push('/mutation-overview');
    } catch {
      addAlert(t('errorstate.defaultmessage'));
      window.scrollTo(0, 0);
      actions.setSubmitting(false);
    }
  }

  const validationSchema = Yup.object().shape({
    confirmExecutionDate: Yup.boolean().required('formvalidation.required.message'),
  });

  return (
    <Modal title={t('vehiclechangedetail.dateconfirmationform.title')} onClose={() => onClose()}>
      <Formik
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        initialValues={{
          confirmExecutionDate: true,
          newExecutionDate: null,
        }}
      >
        {({
          values,
          touched,
          errors,
          setFieldValue,
          setFieldTouched,
          handleChange,
          handleBlur,
          submitCount,
          handleSubmit,
          isSubmitting,
          isValid,
          submitForm,
        }) => {
          return (
            <form
              onSubmit={e => {
                e.stopPropagation();
                return handleSubmit(e);
              }}
            >
              <FormField label={t('vehiclechangedetail.dateconfirmationform.message', {date: formatDate(executionDate) })}>
                <RadioButtonGroup
                  value={values.confirmExecutionDate}
                  onChange={e => setFieldValue('confirmExecutionDate', e === 'true')}
                >
                  <RadioButton value={true}>
                    <Trans
                      values={{ date: formatDate(executionDate) }}
                      i18nKey={'vehiclechangedetail.dateconfirmationform.options.yes'}
                    />
                  </RadioButton>
                  <RadioButton value={false}>
                    <span>
                      <Trans i18nKey={'vehiclechangedetail.dateconfirmationform.options.no'} />
                    </span>
                    {!values.confirmExecutionDate && (
                      <FormField label={t('vehiclechangedetail.dateconfirmationform.newdate')}>
                        <DatePicker
                          name="newExecutionDate"
                          invalid={touched.newExecutionDate && errors.newExecutionDate}
                          value={values.newExecutionDate}
                          onChange={e => setFieldValue('newExecutionDate', e)}
                          onBlur={() => setFieldTouched('rentalStart', true)}
                          options={{ minDate: new Date() }}
                        />
                      </FormField>
                    )}
                  </RadioButton>
                </RadioButtonGroup>
              </FormField>
              <ButtonGroup responsive>
                <Button
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                >
                  <Trans i18nKey="vehiclechangedetail.dateconfirmationform.confirm" />
                </Button>
                <Button color="secondary" disabled={isSubmitting} onClick={() => onClose()}>
                  <Trans i18nKey="mutationdetail.buttons.cancel" />
                </Button>
              </ButtonGroup>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
}

export default ConfirmDateModal;
