// @flow

import React from 'react';
import cx from 'classnames';

type Props = {
  size: string,
  className?: string,
};

const LoadingSpinner = ({ size, className }: Props) => {
  const classes = cx('loader-spinner', className, {
    'loader-spinner--small': size === 'small',
    'loader-spinner--large': size === 'large',
  });
  return (
    <div className={classes}>
      <span className="sr-only">Loading. Please wait.</span>
    </div>
  );
};

LoadingSpinner.defaultProps = {
  size: 'large',
};

export default LoadingSpinner;
