// @flow

import React, { type Node } from 'react';
import cx from 'classnames';

import styles from './Container.module.css';

type Props = {
  fluid?: boolean,
  children: Node,
};

function Container({ fluid, children }: Props) {
  const classes = cx(styles.container, {
    container: !fluid,
    'container-fluid': fluid,
  });
  return <div className={classes}>{children}</div>;
}

export default Container;
