// @flow

import { Formik } from 'formik';
import React from 'react';
import { Trans } from 'react-i18next';
import Button from '../Button';
import ButtonGroup from '../ButtonGroup';
import Modal from '../Modal';

type Props = {
  title: string,
  onClose: Function,
  handleSubmit: Function,
  children?: any,
}
function ConfirmationModal({ title, onClose, handleSubmit, children }: Props) {
  return (
    <Modal title={title} onClose={onClose}>
      {children}
      <Formik
        onSubmit={handleSubmit}
      >
        {({
          handleSubmit,
        }) => {
          return (
            <>
              <ButtonGroup responsive={true}>
                <Button color="primary" type="submit" onClick={handleSubmit}>
                  <Trans i18nKey="common.confirm" />
                </Button>
                <Button color="secondary" type="cancel" onClick={() => onClose()}>
                  <Trans i18nKey="common.cancel" />
                </Button>
              </ButtonGroup>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
}

export default ConfirmationModal;
