// @flow

import { useState, useEffect, useCallback } from 'react';
import debounce from 'debounce-promise';

import { type Debtor } from '../types/debtor-types';
import { getDebtorOverview } from '../services/debtorService';

import usePagination from './usePagination.js';

const PAGE_SIZE = 10;

const debouncedFetch = debounce(getDebtorOverview, 350);

function useDebtors(initialPage?: number) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [debtors, setDebtors] = useState<Debtor[]>([]);
  const { page, setPagination, pagination, setPage } = usePagination(initialPage || 0, PAGE_SIZE);

  const fetchDebtors = useCallback(async () => {
    try {
      const skip = page ? page * PAGE_SIZE : 0;
      setHasError(false);
      setIsLoading(true);
      const { data: paginatedResponse } = await debouncedFetch({
        skip,
        take: PAGE_SIZE,
      });
      setDebtors(paginatedResponse);
      const { data, ...paginationProperties } = paginatedResponse;
      setPagination(paginationProperties);
      setIsLoading(false);
    } catch (err) {
      setHasError(true);
      setIsLoading(false);
      throw err;
    }
  }, [page, setPagination]);

  const loadMore = (page: { selected: number }) => {
    setPage(page.selected);
  };

  useEffect(() => {
    fetchDebtors();
  }, [fetchDebtors]);

  return { isLoading, debtors, hasError, loadMore, pagination, setPage };
}

export default useDebtors;
