//@flow

import { createRef, ReactElement, useEffect } from 'react';

type Props = {
  children: ReactElement;
  onClick: (e: any | undefined) => void;
};

const FlyoutMenuItem = ({ children, onClick }: Props): ReactElement => {
  const itemRef = createRef<HTMLSdxMenuFlyoutListItemElement>();

  useEffect(() => {
    if (itemRef.current) {
      itemRef.current.onclick = e => onClick(e);
    }

    return () => {
      if (itemRef.current?.onchange) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        itemRef.current.onchange = null;
      }
    };
  }, [itemRef, children, onClick]);

  return (
    <sdx-menu-flyout-list-item ref={itemRef}>
      <span>{children}</span>
    </sdx-menu-flyout-list-item>
  );
};

export default FlyoutMenuItem;
