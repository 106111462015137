import React from 'react';
import FormField from '../FormField';
import Input from '../Input';
import styles from './CategoryFileUpload.module.css';
import cx from 'classnames';
import Icon from '../Icon';
import { useTranslation } from 'react-i18next';

type Props = {
  onChange: Function,
  onClick: Function,
  descriptionFile: {
    lastModified: number,
    lastModifiedDate: Date,
    name: string,
    size: number,
    type: string,
  } | null
}

function CategoryFileUpload({ descriptionFile, onChange, onClick }: Props) {
  const { t } = useTranslation();
  return (
    <FormField label={t('common.uploadfile')} className={styles.fileUploadContainer}>
      <Input id="fileupload" onChange={onChange} value="" inputClassName={cx(styles.input, styles.fileUpload)} type="file" accept="application/pdf"></Input>
      {descriptionFile && (<div className={cx(styles.file, styles.newFileUpload)}>
        <span>{descriptionFile.name}</span>
        <div className={styles.deleteFile} onClick={onClick} role="button" tabIndex="-1">
          <Icon name="bin" className="icon--s2"></Icon>
        </div>
      </div>
      )}
    </FormField>
  );
}

export default CategoryFileUpload;
