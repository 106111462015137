import { addMonths } from 'date-fns';
import startOfDay from 'date-fns/fp/startOfDay';
import * as Yup from 'yup';
import { LANGUAGES } from '../../i18n';

export const validationSchema = ({ requireLabelingLanguage, category }) => {
  const labelingLanguageRequired = Yup.string()
    .oneOf(LANGUAGES)
    .required('formvalidation.required.message');

  const labelingLanguage = requireLabelingLanguage
    ? labelingLanguageRequired
    : Yup.string().notRequired();

  return Yup.object().shape({
    rentalStart: Yup.date()
      .when((rentalStart, schema) => {
        if (category.type === 'utilityvehicle') {
          return schema.min(startOfDay(addMonths(new Date(), 14)), 'formvalidation.date.min');
        } else {
          return schema.min(startOfDay(addMonths(new Date(), 4)), 'formvalidation.date.min');
        }
      })
      .required('formvalidation.required.message')
      .nullable(),
    registrationCanton: Yup.object().required('formvalidation.required.message').nullable(),
    deliveryCanton: Yup.object().required('formvalidation.required.message').nullable(),
    mileagePerYear: Yup.object().required('formvalidation.required.message').nullable(),
    acceptDriverConditions: Yup.boolean()
      .required('formvalidation.required.message')
      .oneOf([true], 'formvalidation.required.message'),
    driverType: Yup.string()
      .required('formvalidation.required.message')
      .oneOf(['me', 'otherDriver', 'newDriver'], 'formvalidation.required.message')
      .nullable(),
    driver: Yup.object()
      .when('driverType', {
        is: 'otherDriver',
        then: Yup.object().required('formvalidation.required.message').nullable(),
      })
      .nullable(),
    comment: Yup.string(),
    labelingLanguage,
  });
};
