// @flow

import React, { useEffect } from 'react';
import userManager from '../../auth/userManager';

const LoginRenewal = () => {
  useEffect(() => {
    userManager.signinSilentCallback();
  }, []);
  return <></>;
};

export default LoginRenewal;
