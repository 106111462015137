// @flow

import React from 'react';

import styles from './LoadingPage.module.css';
import LoadingSpinner from '../../components/LoadingSpinner';

type Props = {
  hasHeader: boolean,
};

function LoadingPage({ hasHeader }: Props) {
  return (
    <>
      {!hasHeader && <div style={{ height: 80 }} />}
      <div className={styles.wrapper}>
        <LoadingSpinner />
      </div>
    </>
  );
}

LoadingPage.defaultProps = {
  hasHeader: true,
};

export default LoadingPage;
