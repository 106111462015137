//@flow

import React from 'react';
import { useHistory } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { Formik } from 'formik';

import ButtonGroup from '../ButtonGroup';
import Button from '../Button';
import FormField from '../FormField';
import Modal from '../Modal';
import ValidationErrorMessage from '../ValidationErrorMessage';
import RadioButtonGroup from '../RadioButtonGroup';
import RadioButton from '../RadioButton';

import { validationSchema } from './validation-schema';

type Props = {
  mutationId: number,
  approvalPoint: string,
  onClose: Function,
  children?: any,
};

function PassengerVehicleFilterModal({ mutationId, approvalPoint, onClose, children }: Props) {
  const history = useHistory();
  const { t } = useTranslation();

  async function handleSubmit(values, actions) {
    history.push(
      '/categories/passengervehicle?representationVehicle=' + values.representationFilter,
    );
  }

  return (
    <Modal title={t('specificationmodal.passengervehicle.title')} onClose={onClose} size="wide">
      <Formik
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        initialValues={{ representationFilter: false }}
      >
        {({
          values,
          touched,
          errors,
          setFieldValue,
          setFieldTouched,
          handleChange,
          handleBlur,
          submitCount,
          handleSubmit,
          isSubmitting,
          isValid,
          submitForm,
        }) => {
          return (
            <form
              onSubmit={e => {
                e.stopPropagation();
                return handleSubmit(e);
              }}
            >
              <FormField label={t('specificationmodal.passengervehicle.standardvehicle')}>
                <RadioButtonGroup
                  value={values.representationFilter}
                  onChange={v => setFieldValue('representationFilter', v === 'true')}
                >
                  <RadioButton value={true}>
                    <Trans i18nKey="common.no" />
                  </RadioButton>
                  <RadioButton value={false}>
                    <Trans i18nKey="common.yes" />
                  </RadioButton>
                </RadioButtonGroup>
                <ValidationErrorMessage
                  invalid={touched.representationFilter && errors.representationFilter}
                  i18nKey={'formvalidation.required.message'}
                />
              </FormField>
              <ButtonGroup responsive>
                <Button
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                >
                  <Trans i18nKey="specificationmodal.buttons.confirm" />
                </Button>
                <Button color="secondary" disabled={isSubmitting} onClick={() => onClose()}>
                  <Trans i18nKey="specificationmodal.buttons.cancel" />
                </Button>
              </ButtonGroup>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
}

export default PassengerVehicleFilterModal;
