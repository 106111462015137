// @flow

import React from 'react';
import { format, parseISO } from 'date-fns';
import ProfileLink from '../../../components/ProfileLink';
import { type Bill } from '../../../types/bill-types';
import { formatPrice } from '../../../util/PriceFormatHelper';

type Props = {
  bill: Bill,
};

function BillListItem({ bill }: Props) {
  const formatDate = val => (!!val ? format(parseISO(val), 'dd.MM.yyyy') : '-');

  return (
    <tr>
      <td>{bill.id}</td>
      <td>{bill.billingCycleId}</td>
      <td>{bill.contractId}</td>
      <td>{bill.status}</td>
      <td>{bill.creator && <ProfileLink user={bill.creator} />}</td>
      <td>{formatDate(bill.billingDate)}</td>
      <td>{formatDate(bill.dateOfInvoice)}</td>
      <td>{bill.debtorNumber}</td>
      <td>{bill.debtorName}</td>
      <td>{bill.vehicleSNumber}</td>
      <td>{bill.orderNumber}</td>
      <td>{bill.debtorRevenueAccountNumber}</td>
      <td>{bill.driverSwisscomEmployeeNr && <ProfileLink user={{ firstname: bill.driverFirstname, lastname: bill.driverLastname, unit: bill.driverUnit, username: bill.driverNtAccount }} />}</td>
      <td>{bill.contractorEmployeeNr}</td>
      <td>{bill.costCenterIdentifier}</td>
      <td>{bill.servicePeriod}</td>
      <td>{bill.serviceType}</td>
      <td>{formatPrice(bill.price, { includeCurrency: false, includeCentimes: true })}</td>
      <td>{formatPrice(bill.priceIncludingTax, { includeCurrency: false, includeCentimes: true })}</td>
      <td>{bill.taxRate}</td>
    </tr>
  );
}
export default BillListItem;
