// @flow

import React from 'react';
import { Trans } from 'react-i18next';
import cx from 'classnames';

import { type SortFilters } from '../../util/QueryFilterHelper';

import Icon from '../Icon';

import styles from './SortableTableHead.module.css';
import { OrderDirections, type OrderDirection } from '../../types/order-types';

type Props = {
  i18nKey: string,
  sortProperties: string[],
  sortFilters?: SortFilters,
  setSort: (sortOrders: Array<[string, OrderDirection]>) => void,
};

function SortableTableHead({ i18nKey, sortProperties, sortFilters, setSort }: Props) {
  const filterValue = sortFilters ? sortFilters[sortProperties[0]] : null;

  const handleClick = event => {
    event.preventDefault();
    event.stopPropagation();
    const newSort = sortProperties.map(p => [
      p,
      filterValue === OrderDirections.DESC || !filterValue
        ? OrderDirections.ASC
        : OrderDirections.DESC,
    ]);
    setSort(newSort);
  };

  const icon =
    filterValue === OrderDirections.DESC ? '013-chevron-normal-down' : '016-chevron-normal-up';

  return (
    <button onClick={handleClick}>
      <span className={styles.title}>
        <Trans i18nKey={i18nKey} />
      </span>
      <Icon name={icon} className={cx({ invisible: !filterValue, [styles.selected]: filterValue })} />
    </button>
  );
}

export default SortableTableHead;
