// @flow

import { fetch } from './fetch';
import { type AxiosResponse } from 'axios';
import { type ReserveVehicle, type Vehicle } from '../types/vehicle-types';
import { parseQueryFilters } from '../util/QueryFilterHelper';
import { type QueryFilters } from '../util/QueryFilterHelper';

export async function getVehicles(filters: QueryFilters): Promise<AxiosResponse<ReserveVehicle[]>> {
  return fetch<ReserveVehicle[]>('/vehicles', 'GET', null, {
    params: parseQueryFilters(filters),
  });
}

export async function getVehicleBySNumber(sNumber: string): Promise<AxiosResponse<Vehicle>> {
  return fetch<Vehicle>(`/vehicles/${sNumber}`);
}
