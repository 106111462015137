export const defaultValues = {
  name_german: '',
  name_french: '',
  name_italian: '',
  description_german: '',
  description_french: '',
  description_italian: '',
  additionalFeatures_german: '',
  additionalFeatures_french: '',
  additionalFeatures_italian: '',
  fieldOfApplication_german: '',
  fieldOfApplication_french: '',
  fieldOfApplication_italian: '',
  roles_german: '',
  roles_french: '',
  roles_italian: '',
  purpose_german: '',
  purpose_french: '',
  purpose_italian: '',
  pricePerMonth: 0,
  type: 'passengervehicle',
  isRepresentationVehicle: false,
  hasTowHitch: false,
  hasNavigationSystem: false,
  hasParkingSensors: false,
  hasAirConditioning: false,
  hasCitySafetySystem: false,
  hasLDWS: false,
  hasBlindSpotMonitor: false,
  hasCruiseControl: false,
  maxEmission: 0,
  typeOfDrive: '',
  maxGrossWeight: 0,
  loadCapacity: 0,
  additionalFeatures: '',
  abbreviation: '',
  disIdentifier: '',
  towableMass: 0,
  minLoadVolume: 0,
  descriptionFile: null,
  descriptionFileLocation: null
};
