import cx from 'classnames';
import React, { ReactElement, ReactNode } from 'react';
import ReactDOM from 'react-dom';
import { RemoveScroll } from 'react-remove-scroll';
import styles from './Modal.module.css';


type Props = {
  title: string;
  titleStyle?: { color: string; fontWeight: number };
  children: ReactNode;
  onClose: () => void;
  size?: 'small' | 'medium' | 'wide' | 'ultrawide';
  closeDisabled?: boolean;
  className?: string;
  childModal?: boolean;
};

const Modal = ({
  title,
  titleStyle,
  children,
  onClose,
  size,
  closeDisabled,
  className,
  childModal,
}: Props): ReactElement | null => {
  const modalClasses = cx(className, 'modal__content', styles.modal, {
    [styles['modal--small']]: size === 'small',
    [styles['modal--medium']]: size === 'medium',
    [styles['modal--wide']]: size === 'wide',
  });

  const portalNode = document.querySelector('#modal-portal');
  if (!portalNode) {
    return null;
  }

  return ReactDOM.createPortal(
    <>
      <RemoveScroll>
        <div
          className={cx(styles.modal, 'modal modal--open', childModal ? styles.childModal : null)}
          role="dialog"
          tabIndex={-1}
        >
          <div className={modalClasses}>
            <div className="modal__header">
              <h1 style={titleStyle}>{title}</h1>
              {!closeDisabled && (
                <button
                  className="modal__close modal-cancel"
                  aria-label="Close"
                  onClick={onClose}
                  tabIndex={-1}
                >
                  <i className="icon icon-022-close" aria-hidden="true" />
                </button>
              )}
            </div>
            <div className={cx(styles.modalbody, 'modal__body')}>{children}</div>
          </div>
        </div>
      </RemoveScroll>
      <div
        className={cx('backdrop backdrop--open', childModal ? styles.childModalBackdrop : null)}
      />
    </>,
    portalNode,
  );
};

export default Modal;
