//@flow

import React from 'react';
import FormattedDate from '../FormattedDate';
import styles from './ChangedExecutionDate.module.css';

type Props = {
  oldDate: Date,
  newDate: Date,
};

function ChangedExecutionDate({ oldDate, newDate }: Props) {
  return (
    <>
      <span className={styles.oldDate}>
        <FormattedDate date={oldDate}></FormattedDate>*
      </span>

      <span className={styles.newDate}>
        <FormattedDate date={newDate}></FormattedDate>
      </span>
    </>
  );
}

export default ChangedExecutionDate;
