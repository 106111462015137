import React, { useEffect, useState, useContext } from 'react';
import { Formik } from 'formik';
import { useTranslation, Trans } from 'react-i18next';
import * as Yup from 'yup';

import Modal from '../../../components/Modal';
import styles from './PhoneNumberModal.module.css';
import Input from '../../../components/Input';
import { AuthContext } from '../../../components/AuthProvider/AuthProvider';
import FormField from '../../../components/FormField';
import Button from '../../../components/Button/Button';
import ValidationErrorMessage from '../../../components/ValidationErrorMessage';
import { updateProfile } from '../../../services/userService';

export const validationSchema = Yup.object().shape({
  preferredPhone: Yup.string()
    .matches(
      /^(0041|041|\+41|\+\+41|41)?(0|\(0\))?([1-9]\d{1})(\d{3})(\d{2})(\d{2})$/,
      'formvalidation.phone.invalid',
    )
    .required('formvalidation.required.message'),
});

function PhoneNumberModal() {
  const { t } = useTranslation();
  const { profile } = useContext(AuthContext);
  const [phoneNumberRequired, setPhoneNumberRequired] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const isPhoneNumberDefined = phoneNumber =>
      Boolean(phoneNumber) && typeof phoneNumber === 'string' && phoneNumber.length > 0;

    if (
      !profile ||
      (!isPhoneNumberDefined(profile.phone) &&
        !isPhoneNumberDefined(profile.mobile) &&
        !isPhoneNumberDefined(profile.preferredPhone))
    ) {
      setPhoneNumberRequired(true);
    }
  }, [profile]);

  async function handleSubmit(values, actions) {
    try {
      await updateProfile(values);
      setPhoneNumberRequired(false);
    } catch (err) {
      actions.setSubmitting(false);

      if (err.response && err.response.status === 400) {
        actions.setFieldError('preferredPhone', 'formvalidation.phone.invalid');
      } else {
        setHasError(true);
        throw err;
      }
    }
  }

  if (!phoneNumberRequired) {
    return null;
  }

  return (
    <Modal title={t('requestvehicleform.phonenumbermodal.title')} className={styles.modal} closeDisabled>
      <Formik
        initialValues={{ preferredPhone: '' }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          touched,
          errors,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          handleChange,
          handleBlur,
          submitCount,
          isSubmitting,
          isValid,
        }) => {
          return (
            <form
              onSubmit={e => {
                e.stopPropagation();
                return handleSubmit(e);
              }}
            >
              <p>
                <Trans i18nKey="requestvehicleform.phonenumbermodal.description" />
              </p>
              <FormField
                label={t('requestvehicleform.phonenumbermodal.fields.preferredphone.label')}
                required
              >
                <Input
                  placeholder="+41 79 123 45 67"
                  hasMarginTop={false}
                  onChange={handleChange}
                  name="preferredPhone"
                  value={values.preferredPhone}
                  onBlur={handleBlur}
                  invalid={errors.preferredPhone && touched.preferredPhone}
                />
                <ValidationErrorMessage
                  invalid={errors.preferredPhone && touched.preferredPhone}
                  i18nKey={errors.preferredPhone}
                />
              </FormField>
              <div className={styles.actions}>
                <Button
                  disabled={(submitCount > 0 && !isValid) || isSubmitting}
                  type="submit"
                  isLoading={isSubmitting}
                >
                  <Trans i18nKey="common.save" />
                </Button>
              </div>
              <ValidationErrorMessage invalid={hasError} i18nKey="errorstate.defaultmessage" />
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
}

export default PhoneNumberModal;
