// @flow

import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import useUser from '../../../hooks/useUser';

import {
  type ContractMutation,
  type ReturnVehicleMutation,
} from '../../../types/contract-mutation-types';

import { CardItem } from '../../../components/Card';
import ErrorState from '../../../components/ErrorState';
import FormattedDate from '../../../components/FormattedDate';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import EmployeeInformation from '../../../components/EmployeeInformation';

import { InformationCardItem, InformationCard } from '../common';
import Comments from '../common/Comments';
import VehicleName from '../../../components/VehicleName';
import { getFirstPossibleTerminationDate } from '../../../util/MutationProperties';

type Props = {
  mutation: ContractMutation,
  returnVehicleMutation: ReturnVehicleMutation,
};

function ReturnVehicleSummary({ mutation, returnVehicleMutation }: Props) {
  const { t } = useTranslation();

  const { isLoading, hasError, user } = useUser(mutation.contract ? mutation.contract.contractor.swisscomEmployeeNr : '');

  let firstPossibleTerminationDate = '';

  if (mutation.contract) {
    firstPossibleTerminationDate = mutation.sendToProvider ? mutation.dateOfExecution : getFirstPossibleTerminationDate(
      mutation.contract.vehicle.vehicleCategory.type,
      returnVehicleMutation.createdAt,
    );
  }

  return (
    <InformationCard color="purple">
      {(isLoading || hasError) && (
        <div className="flex flex-center" style={{ height: 300 }}>
          {isLoading && <LoadingSpinner />}
          {hasError && !isLoading && <ErrorState />}
        </div>
      )}
      {!isLoading && !hasError && user && (
        <>
          <CardItem>
            <EmployeeInformation user={user} />
          </CardItem>
          <CardItem>
            {mutation.contract && <InformationCardItem label={t('common.labels.vehicle')}>
              {mutation.contract.vehicle.manufacturerName}
              <br />
              <VehicleName vehicle={mutation.contract.vehicle} />
            </InformationCardItem>}
            {mutation.contract && <InformationCardItem label={t('common.labels.snumber')}>
              {mutation.contract.vehicle.sNumber}
            </InformationCardItem>}
            {mutation.contract && <InformationCardItem label={t('common.vehicleproperties.platenumber')}>
              {mutation.contract.vehicle.activeLicensePlate}
            </InformationCardItem>}
            <InformationCardItem label={t('common.vehicleproperties.cancellationperiod')}>
              <FormattedDate date={firstPossibleTerminationDate} />
            </InformationCardItem>
            <InformationCardItem label={t('returnvehicledetail.labels.requestedreturndate')}>
              <FormattedDate date={mutation.dateOfExecution} />
            </InformationCardItem>
            <InformationCardItem label={t('returnvehicledetail.labels.returncanton')}>
              <Trans i18nKey={`cantons.${returnVehicleMutation.cantonOfReturn}`} />
            </InformationCardItem>
            <InformationCardItem label={t('returnvehicledetail.labels.cancellationaction')}>
              {returnVehicleMutation.endLeasingContract ? (
                <Trans i18nKey="returnvehicledetail.leasingcontract.endcontract" />
              ) : (
                <Trans i18nKey="returnvehicledetail.leasingcontract.reserve" />
              )}
            </InformationCardItem>
          </CardItem>
          <Comments contractMutationId={mutation.id} />
        </>
      )}
    </InformationCard>
  );
}

export default ReturnVehicleSummary;
