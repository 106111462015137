//@flow

import React, { useContext, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { type ContractMutation } from '../../../../types/contract-mutation-types';
import { approveMutation, updateContractMutationApproval } from '../../../../services/contractMutationService';

import RejectMutationModal from '../../../../components/RejectMutationModal';
import Button from '../../../../components/Button';
import ButtonGroup from '../../../../components/ButtonGroup';
import FormField from '../../../../components/FormField';
import Input from '../../../../components/Input';
import ProviderSelect from '../../../../components/ProviderSelect';
import ValidationErrorMessage from '../../../../components/ValidationErrorMessage';
import { useNotifications } from '../../../../components/Notifications/NotificationsProvider';
import { AuthContext } from '../../../../components/AuthProvider/AuthProvider';
import CommentField from '../../../../components/CommentField';
import ReserveSelect from '../../../../components/ReserveSelect/ReserveSelect';
import { MutationDetailContext } from '../../MutationDetail';
import { updateRequestVehicleContractMutation } from '../../../../services/contractService';

type Props = {
  selectedCategory: { value: String, label: String },
  mutation: ContractMutation,
  selectedRequestDate?: string | Date
};

function ApprovalFormFleet({ mutation, selectedCategory, selectedRequestDate }: Props) {
  const [showRejectionModal, setShowRejectingModal] = useState<boolean>(false);
  const { currentApprovalPoint } = useContext(MutationDetailContext);

  const { t } = useTranslation();
  const history = useHistory();
  const { fetchProfile } = useContext(AuthContext);

  const { addSuccess, addAlert } = useNotifications();

  const validationSchema = Yup.object().shape({
    provider: Yup.object()
      .required('formvalidation.required.message')
      .nullable(),
    orderNumber: Yup.string()
      .required('formvalidation.required.message')
      .nullable(),
    reserveVehicle: Yup.mixed()
      .when('provider', {
        is: provider => provider?.hasReserve,
        then: Yup.object().required('formvalidation.required.message'),
        otherwise: Yup.mixed().notRequired(),
      })
      .nullable(),
  });

  const cacheApprovalStep = async val => {
    try {
      const payload = {
        selectedCategory,
        selectedRequestDate,
        ...currentApprovalPoint,
        ...val,
        approved: null
      };

      if (mutation.contract) {
        await updateRequestVehicleContractMutation(mutation.id, {
          orderNumber: payload.orderNumber,
          vehicleCategoryId: payload.selectedCategory.value,
          dateOfExecution: payload.selectedRequestDate,
        });
      }

      await updateContractMutationApproval(currentApprovalPoint.id, payload);
      addSuccess(t('mutationdetail.alerts.success'));
      window.scrollTo(0, 0);
    } catch (err) {
      addAlert(t('errorstate.defaultmessage'));
      window.scrollTo(0, 0);
    }
  };

  async function handleSubmit(values, actions) {
    try {
      let sNumber;
      if (values.provider?.hasReserve) {
        sNumber = values.reserveVehicle.sNumber;
      }

      await approveMutation(mutation.id, {
        vehicleCategoryId: selectedCategory.value,
        providerId: values.provider.id,
        sNumber,
        comment: values.comment,
        approvalPoint: values.approvalPoint,
        approved: values.approved,
        orderNumber: values.orderNumber,
        dateOfExecution: selectedRequestDate,
      });
      fetchProfile();

      addSuccess(t('mutationdetail.alerts.success'));
      window.scrollTo(0, 0);
      history.push('/mutation-overview');
    } catch {
      addAlert(t('errorstate.defaultmessage'));
      window.scrollTo(0, 0);
      actions.setSubmitting(false);
    }
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      initialValues={{
        approvalPoint: currentApprovalPoint.type,
        approved: true,
        provider: null,
        reserveVehicle: null,
        comment: currentApprovalPoint.comment || '',
        orderNumber: mutation.requestVehicleMutation?.orderNumber || ''
      }}
    >
      {({
        values,
        touched,
        errors,
        setFieldValue,
        setFieldTouched,
        handleChange,
        handleBlur,
        submitCount,
        handleSubmit,
        isSubmitting,
        isValid,
        submitForm,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            {showRejectionModal && (
              <RejectMutationModal
                mutationId={mutation.id}
                approvalPoint={values.approvalPoint}
                title={t('requestvehicledetail.rejectionform.title')}
                onClose={() => setShowRejectingModal(false)}
              />
            )}

            <h2>
              <Trans i18nKey="requestvehicledetail.labels.ordertitle" />
            </h2>
            <FormField label={t('requestvehicledetail.labels.providerselect')} htmlFor="provider">
              <ProviderSelect
                name="provider"
                onChange={provider => {
                  setFieldValue('provider', provider);
                  setFieldValue('reserveVehicle', null);
                }}
                onBlur={() => setFieldTouched('provider')}
                value={values.provider}
              />
              <ValidationErrorMessage
                invalid={touched.provider && errors.provider}
                i18nKey={errors.provider}
              />
            </FormField>
            {values.provider?.hasReserve && (
              <div className="margin-top-4">
                <FormField>
                  <ReserveSelect
                    provider={values.provider}
                    name="reserveVehicle"
                    value={values.reserveVehicle}
                    onChange={vehicleId => {
                      setFieldTouched('reserveVehicle', true);
                      setFieldValue('reserveVehicle', vehicleId);
                    }}
                    invalid={touched.reserveVehicle && errors.reserveVehicle}
                    errorI18nKey={errors.reserveVehicle}
                  />
                </FormField>
              </div>
            )}
            <FormField label={t('common.labels.ordernumber')} htmlFor="orderNumber" required>
              <Input
                hasMarginTop={false}
                invalid={touched.orderNumber && errors.orderNumber}
                name="orderNumber"
                onBlur={() => setFieldTouched('orderNumber')}
                onChange={e => setFieldValue('orderNumber', e.currentTarget.value)}
                value={values.orderNumber}
              />
              <ValidationErrorMessage
                invalid={touched.orderNumber && errors.orderNumber}
                i18nKey={errors.orderNumber}
              />
            </FormField>
            <CommentField value={values.comment} onChange={v => setFieldValue('comment', v)} />
            <p>{t('formvalidation.required.descriptions')}</p>
            <ButtonGroup responsive>
              <Button
                color="primary"
                disabled={isSubmitting}
                isLoading={isSubmitting}
                type="submit"
              >
                <Trans i18nKey="mutationdetail.buttons.sendorder" />
              </Button>
              <Button
                color="confirm"
                type="button"
                disabled={isSubmitting}
                isLoading={isSubmitting}
                onClick={() => cacheApprovalStep(values)}
              >
                <Trans i18nKey="common.save" />
              </Button>
              <Button
                color="secondary"
                type="button"
                disabled={isSubmitting}
                onClick={() => setShowRejectingModal(true)}
              >
                <Trans i18nKey="mutationdetail.buttons.reject" />
              </Button>
              <Button
                color="secondary"
                type="button"
                disabled={isSubmitting}
                onClick={history.goBack}
              >
                <Trans i18nKey="mutationdetail.buttons.cancel" />
              </Button>
            </ButtonGroup>
          </form>
        );
      }}
    </Formik>
  );
}

export default ApprovalFormFleet;
