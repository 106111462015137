// @flow

import React, { useContext, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import {
  type ContractMutation,
  type RequestVehicleMutation,
} from '../../../types/contract-mutation-types';

import { CardText, CardItem } from '../../../components/Card';
import PhoneLink from '../../../components/PhoneLink';

import { InformationCard, InformationCardItem } from '../common';
import { MutationDetailContext } from '../MutationDetail';
import CategoryPicker from '../../../components/CategoryPicker';
import { getCurrentVehicleCategory, formatCategoryName } from '../../../util/MutationProperties';
import VehicleCategoryHistoryPopup from '../RequestVehicleDetail/RequestVehicleInformation/VehicleCategoryHistoryPopup';
import ChangedExecutionDate from '../../../components/ChangedExecutionDate/ChangedExecutionDate';
import FormattedDate from '../../../components/FormattedDate';
import { AuthContext } from '../../../components/AuthProvider/AuthProvider';
import EmailLink from '../../../components/EmailLink';
import { formatPrice } from '../../../util/PriceFormatHelper';
import EmployeeInformation from '../../../components/EmployeeInformation';

type Props = {
  mutation: ContractMutation,
  requestVehicleMutation: RequestVehicleMutation,
  selectedCategoryChanged?: Function,
};

function NewVehicleInformation({
  mutation,
  requestVehicleMutation,
  selectedCategoryChanged,
}: Props) {
  const { userIsApprover, currentApprovalPoint } = useContext(MutationDetailContext);
  const { t } = useTranslation();
  const { profile, isProvider } = useContext(AuthContext);

  const currentCategory = getCurrentVehicleCategory(requestVehicleMutation);

  const [selectedCategory, setSelectedCategory] = useState({
    value: currentCategory.id,
    label: formatCategoryName(currentCategory),
  });

  const displayCategoryPicker =
    userIsApprover && mutation.status === 'PENDING' && currentApprovalPoint.type !== 'PROVIDER';

  return (
    <InformationCard title={t('vehiclechangedetail.labels.headers.newvehicle')} color="orchid">
      <CardItem>
        {mutation.contract && <EmployeeInformation
          user={mutation.contract.contractor}
          className="col-4"
          title={t('driverchangedetail.labels.currentdriver')}
        />}
        {mutation.contract && <CardText>
          <PhoneLink user={mutation.contract.contractor} />
        </CardText>}
        {mutation.contract && <CardText>
          <EmailLink user={mutation.contract.contractor} />
        </CardText>}
      </CardItem>
      <CardItem>
        <InformationCardItem label={t('vehiclechangedetail.labels.vehicle')}>
          {displayCategoryPicker ? (
            <CategoryPicker
              className="font--light"
              name="vehicleCategory"
              value={selectedCategory}
              compact={true}
              onChange={vehicleCategory => {
                selectedCategoryChanged && selectedCategoryChanged(vehicleCategory);
                setSelectedCategory(vehicleCategory);
              }}
            />
          ) : (
            <span>{formatCategoryName(currentCategory)}</span>
          )}
          <div>
            <VehicleCategoryHistoryPopup mutationId={mutation.id} />
          </div>
        </InformationCardItem>
        {profile?.isFleetMember && requestVehicleMutation.selectedProvider && (
          <InformationCardItem label={t('common.labels.provider')}>
            {requestVehicleMutation.selectedProvider.name}
          </InformationCardItem>
        )}
        {!isProvider && (
          <InformationCardItem label={t('vehiclechangedetail.labels.price')}>
            <Trans
              values={{ price: formatPrice(currentCategory.pricePerMonth, { includeCurrency: true, includeCentimes: true }) }}
              i18nKey="common.pricepermonth"
            />
          </InformationCardItem>
        )}
        {requestVehicleMutation.sNumber && (
          <InformationCardItem label={t('vehiclechangedetail.labels.snumber')}>
            {requestVehicleMutation.sNumber}
          </InformationCardItem>
        )}
        <InformationCardItem label={t('vehiclechangedetail.labels.registrationcanton')}>
          <Trans i18nKey={`cantons.${requestVehicleMutation.registrationCanton}`} />
        </InformationCardItem>
        <InformationCardItem label={t('vehiclechangedetail.labels.deliverycanton')}>
          <Trans i18nKey={`cantons.${requestVehicleMutation.deliveryCanton}`} />
        </InformationCardItem>
        <InformationCardItem label={t('vehiclechangedetail.labels.mileageperyear')}>
          {requestVehicleMutation.mileagePerYear}
        </InformationCardItem>
        <InformationCardItem label={t('vehiclechangedetail.labels.requestedrentalstart')}>
          {mutation.requestedDateOfExecution !== mutation.dateOfExecution && mutation.requestedDateOfExecution && mutation.dateOfExecution ? (
            <ChangedExecutionDate
              oldDate={mutation.requestedDateOfExecution}
              newDate={mutation.dateOfExecution}
            />
          ) : (
            <FormattedDate date={mutation.dateOfExecution}></FormattedDate>
          )}
        </InformationCardItem>
      </CardItem>
    </InformationCard>
  );
}

export default NewVehicleInformation;
