// @flow

import React from 'react';
import FormField from '../../../components/FormField';
import UserPicker from '../../../components/UserPicker';
import ValidationErrorMessage from '../../../components/ValidationErrorMessage';
import { useTranslation } from 'react-i18next';

type Props = {
  value?: {
    label: string,
    value: string,
  },
  onChange?: Function,
  onBlur?: Function,
  error?: string,
  touched?: boolean,
};

function NewDriverPicker({ value, onChange, onBlur, error, touched }: Props) {
  const { t } = useTranslation();
  return (
    <FormField label={t('selectdriver.options.otherdriver.driver.label')} required>
      <UserPicker value={value} onChange={onChange} onBlur={onBlur} invalid={error && touched} />
      <ValidationErrorMessage invalid={error && touched} i18nKey={error} />
    </FormField>
  );
}
export default NewDriverPicker;
