// @flow

import React from 'react';
import isEqual from 'lodash/fp/isEqual';

type Props = {
  name: string,
  onChange: Function,
  value: any,
  children: Node[],
  compare: Function
};

const SegmentControlGroup = ({ name, onChange, value, children, compare }: Props) => {
  return (
    <div className="segmented-controls-group">
      {React.Children.map(children, child => {
        const checked = compare(child.props.value, value);
        return React.cloneElement(child, {
          checked,
          name,
          onChange
        });
      })}
    </div>
  );
};

SegmentControlGroup.defaultProps = {
  compare: isEqual
};

export default SegmentControlGroup;
