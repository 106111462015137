//@flow

import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { confirmChangeVehicleReturn } from '../../../../services/contractMutationService';

import Button from '../../../../components/Button';
import ButtonGroup from '../../../../components/ButtonGroup';
import FormField from '../../../../components/FormField';
import Modal from '../../../../components/Modal';

import { useNotifications } from '../../../../components/Notifications/NotificationsProvider';
import { AuthContext } from '../../../../components/AuthProvider/AuthProvider';
import AutoId from '../../../../components/AutoId';
import Checkbox from '../../../../components/Checkbox';
import ValidationErrorMessage from '../../../../components/ValidationErrorMessage';

type Props = {
  mutationId: number,
  onClose: Function,
  executionDate: Date,
};

function ConfirmVehicleReturnModal({ mutationId, onClose, executionDate }: Props) {
  const { t } = useTranslation();
  const history = useHistory();
  const { addSuccess, addAlert } = useNotifications();
  const { fetchProfile } = useContext(AuthContext);

  async function handleSubmit(values, actions) {
    try {
      await confirmChangeVehicleReturn(mutationId, values);
      addSuccess(t('mutationdetail.alerts.success'));
      window.scrollTo(0, 0);

      fetchProfile();
      history.push('/mutation-overview');
    } catch {
      addAlert(t('errorstate.defaultmessage'));
      window.scrollTo(0, 0);
      actions.setSubmitting(false);
    }
  }

  const validationSchema = Yup.object().shape({
    confirmVehicleReturn: Yup.boolean()
      .oneOf([true], 'formvalidation.required.message')
      .required('formvalidation.required.message'),
  });

  return (
    <Modal
      title={t('vehiclechangedetail.vehiclereturnconfirmationform.title')}
      onClose={() => onClose()}
    >
      <Formik
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        initialValues={{
          confirmVehicleReturn: false,
        }}
      >
        {({
          values,
          touched,
          errors,
          setFieldValue,
          setFieldTouched,
          handleChange,
          handleBlur,
          submitCount,
          handleSubmit,
          isSubmitting,
          isValid,
          submitForm,
        }) => {
          return (
            <form
              onSubmit={e => {
                e.stopPropagation();
                return handleSubmit(e);
              }}
            >
              <FormField label={t('vehiclechangedetail.vehiclereturnconfirmationform.message')}>
                <AutoId>
                  {id => (
                    <>
                      <Checkbox
                        className="margin-bottom-3"
                        id={id}
                        label={t('vehiclechangedetail.vehiclereturnconfirmationform.confirmation')}
                        name="confirmVehicleReturn"
                        required
                        value={values.confirmVehicleReturn}
                        onChange={event => {
                          setFieldValue('confirmVehicleReturn', event.currentTarget.checked);
                          setFieldTouched('confirmVehicleReturn', true);
                        }}
                      />
                      <ValidationErrorMessage
                        className="margin-top-0 margin-bottom-4"
                        invalid={touched.confirmVehicleReturn && errors.confirmVehicleReturn}
                        i18nKey={errors.confirmVehicleReturn}
                      ></ValidationErrorMessage>
                    </>
                  )}
                </AutoId>
              </FormField>
              <ButtonGroup responsive>
                <Button
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                >
                  <Trans i18nKey="vehiclechangedetail.vehiclereturnconfirmationform.confirm" />
                </Button>
                <Button color="secondary" disabled={isSubmitting} onClick={() => onClose()}>
                  <Trans i18nKey="mutationdetail.buttons.cancel" />
                </Button>
              </ButtonGroup>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
}

export default ConfirmVehicleReturnModal;
