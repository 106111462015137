// @flow

import React from 'react';
import { type Node as ReactNode } from 'react';
import Card, { CardItem } from '../Card';
import { useTranslation } from 'react-i18next';
import AnimateHeight from 'react-animate-height';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import ErrorState from '../ErrorState';
import PhoneLink from '../PhoneLink';
import ProfileLink from '../ProfileLink';
import VehicleName from '../VehicleName';
import { type Contract } from '../../types/contract-types';
import { formatPrice } from '../../util/PriceFormatHelper';

export const OmitKeys = {
  driver: 'driver',
  mileageperyear: 'mileageperyear',
  registrationcanton: 'registrationcanton',
  pricePerMonth: 'pricePerMonth',
  contractId: 'contractid',
};

type OmitKey = $Keys<typeof OmitKeys>;

type Props = {
  contract: Contract,
  className?: string,
  titleKey?: string,
  omittedProperties?: OmitKey[],
  isLoading?: boolean,
  hasError?: boolean,
};

type BasicInformationRowProps = {
  i18nKey: string,
  value?: string | number,
  children?: ReactNode,
  omitKey?: string,
};

function VehicleChangeInformation({
  contract,
  className,
  titleKey = 'common.labels.driverandvehicleinformation',
  omittedProperties = [],
  isLoading,
  hasError,
}: Props) {
  const { t } = useTranslation();

  // Check if a dev implemented an OmitKey which is not available and show an according message
  const notImplementedOmitKeys = omittedProperties.filter(
    item => !Object.values(OmitKeys).includes(item),
  );
  if (notImplementedOmitKeys.length > 0) {
    console.warn(
      `You are using at least one omit key which is not available: '${notImplementedOmitKeys.join()}'`,
    );
  }

  // Helper component for basic info row
  const BasicInformationRow = ({
    i18nKey,
    value,
    children,
    omitKey = null,
  }: BasicInformationRowProps) =>
    (!omitKey || !omittedProperties.includes(omitKey)) && (
      <div className="row margin-bottom-1">
        <div className="col-xs-12 col-md-6">{t(i18nKey)}</div>
        <div className="col-xs-12 col-md-6 font-weight-semi-bold">{value ?? children}</div>
      </div>
    );

  return (
    <AnimateHeight height={isLoading || hasError ? 300 : 'auto'} className={className}>
      <Card
        color="turquoise"
        document={!isLoading && !hasError}
        className={isLoading || hasError ? 'padding-0' : undefined}
        title={isLoading || hasError ? undefined : t(titleKey)}
        dark
      >
        {(isLoading || hasError) && (
          <div className="flex flex-center" style={{ height: 300 }}>
            {isLoading && <LoadingSpinner />}
            {hasError && !isLoading && <ErrorState />}
          </div>
        )}
        {!isLoading && !hasError && contract && (
          <>
            {!omittedProperties.includes(OmitKeys.driver) && (
              <CardItem>
                <p className="font-size-default">{t('common.labels.driver')}</p>
                <ProfileLink user={contract.contractor} className="font-weight-semi-bold" />
                <PhoneLink user={contract.contractor} className="font-weight-semi-bold" />
              </CardItem>
            )}
            <CardItem>
              <BasicInformationRow i18nKey={'common.labels.vehicle'}>
                <VehicleName vehicle={contract.vehicle} />
              </BasicInformationRow>

              <BasicInformationRow
                i18nKey={'common.labels.snumber'}
                value={contract.vehicle.sNumber}
              />

              <BasicInformationRow
                i18nKey={'common.labels.registrationnumber'}
                value={contract.vehicle.activeLicensePlate}
              />

              <BasicInformationRow
                i18nKey={'common.labels.leasingcontractnumber'}
                value={contract.contractNumber}
                omitKey={OmitKeys.contractId}
              />

              <BasicInformationRow
                i18nKey={'common.labels.price'}
                value={t('common.pricepermonth', {
                  price: formatPrice(contract.vehicle.vehicleCategory.pricePerMonth, { includeCurrency: true, includeCentimes: true })
                })}
                omitKey={OmitKeys.pricePerMonth}
              />
            </CardItem>
          </>
        )}
      </Card>
    </AnimateHeight>
  );
}

export default VehicleChangeInformation;
