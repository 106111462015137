//@flow

import React from 'react';

import { type DebtorCostcenter } from '../../../types/debtor-costcenter-types';
import Icon from '../../../components/Icon';

type Props = {
  debtorCostcenter: DebtorCostcenter,
  openMapping: Function
};

function DebtorCostcenterListItem({ debtorCostcenter, openMapping }: Props) {
  let costcenter;
  if (debtorCostcenter) {
    costcenter = debtorCostcenter.costcenter.slice(0, 5);
  } else {
    costcenter = null;
  }
  return (
    <tr>
      <td>{costcenter}</td>
      <td>{debtorCostcenter?.debtor?.name}</td>

      <td>
        <div role="button" tabIndex="-1" onClick={() => openMapping(debtorCostcenter?.id)}>
          <Icon name="edit" className="blue icon--s3"></Icon>
        </div>
      </td>
    </tr>
  );
}

export default DebtorCostcenterListItem;
