import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
function useVehicleCategoryAdmin(id) {
  const [filterProps, setFilterProps] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    setFilterProps([
      { title: t('vehiclecategoryadmin.filter.titlestandard'), active: true, id: 1, type: 'passengervehicle', pageTitle: t('vehiclecategoryadmin.filter.pagetitlestandard'), isRepresentationVehicle: false, interior: false },
      { title: t('vehiclecategoryadmin.filter.titlerepresentation'), active: false, id: 2, type: 'passengervehicle', pageTitle: t('vehiclecategoryadmin.filter.pagetitlerepresentation'), isRepresentationVehicle: true, interior: false },
      { title: t('vehiclecategoryadmin.filter.titlenospecialInterior'), active: false, id: 3, type: 'utilityvehicle', pageTitle: t('vehiclecategoryadmin.filter.pagetitlenospecialinterior'), isRepresentationVehicle: false, interior: false },
      { title: t('vehiclecategoryadmin.filter.titlespecialinterior'), active: false, id: 4, type: 'utilityvehicle', pageTitle: t('vehiclecategoryadmin.filter.pagetitlespecialinterior'), isRepresentationVehicle: false, interior: true }
    ]);
  }, [t]);

  return { filterProps, setFilterProps };
}

export default useVehicleCategoryAdmin;
