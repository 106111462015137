import * as Yup from 'yup';
import addDays from 'date-fns/fp/addDays';
import startOfDay from 'date-fns/fp/startOfDay';

export const validationSchema = Yup.object().shape({
  driver: Yup.object()
    .required('formvalidation.required.message')
    .nullable(),
  dateOfChange: Yup.date()
    .min(startOfDay(addDays(1, new Date())), 'formvalidation.date.min')
    .required('formvalidation.required.message')
    .nullable(),
  mileagePerYear: Yup.object()
    .required('formvalidation.required.message')
    .nullable(),
  acceptDriverConditions: Yup.boolean()
    .required('formvalidation.required.message')
    .oneOf([true], 'formvalidation.required.message'),
  conditionProtocolRead: Yup.boolean()
    .oneOf([true], 'formvalidation.required.message')
    .required('formvalidation.required.message'),
  comment: Yup.string(),
  sendToProvider: Yup.boolean().default(true)
});
