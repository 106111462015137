// @flow

import { type AuthState } from './auth-types';

export const initialState = {
  profile: null,
  user: null,
  isLoading: false,
  isProvider: false,
  isAdmin: false,
};

const reducer = (state: AuthState = initialState, action: { type: string, [key: any]: any }) => {
  switch (action.type) {
    case 'updateUser': {
      return { ...state, user: action.user };
    }

    case 'updateProfile': {
      return { ...state, profile: action.profile };
    }

    case 'resetAuth': {
      return { ...state, user: null, isLoading: false };
    }

    case 'updateIsLoading': {
      return { ...state, isLoading: action.isLoading };
    }

    default:
      throw new Error();
  }
};

export default reducer;
