// @flow

import React from 'react';
import AutoId from '../AutoId';

type SegmentControlOptionProps = {
  name?: string,
  value: any,
  onChange: Function,
  children: any,
  checked: boolean
};

export const SegmentControlOption = ({
  name,
  value,
  onChange,
  children,
  checked
}: SegmentControlOptionProps) => {
  return (
    <AutoId>
      {id => (
        <>
          <input type="radio" name={name} id={id} onChange={() => onChange(value)} checked={checked}/>
          <label htmlFor={id}>{children}</label>
        </>
      )}
    </AutoId>
  );
};

export default SegmentControlOption;
