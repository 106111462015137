// @flow
import React from 'react';
import { type User } from '../../types/user-types';
import styles from './ProfileImage.module.css';

type Props = {
  profile: User,
};

function ProfileImage({ profile }: Props) {
  if (!profile?.swisscomEmployeeNr) {
    return <></>;
  }

  const src =
    'https://intranet.swisscom.com/Lists/CustomPortraits/' + profile.swisscomEmployeeNr + '.jpg';

  return (<div style={{ backgroundImage: `url(${src})` }} className={styles.image} />);
}

export default ProfileImage;
