// @flow

import React, { type Node } from 'react';
import cx from 'classnames';

type Props = {
  title?: string,
  rounded?: boolean,
  color?: 'turquoise' | 'blue' | 'purple' | 'iris' | 'pink',
  dark?: boolean,
  navigation?: boolean,
  document?: boolean,
  className?: string,
  children: Node,
  style?: Object,
  onClick?: Function,
  tabIndex?: number,
  role?: string,
};

function Card({
  title,
  document,
  rounded,
  color,
  dark,
  navigation,
  className,
  children,
  style,
  role,
  onClick,
  tabIndex,
}: Props) {
  const classes = cx('card', className, {
    'card--document': document,
    'card--navigation': navigation,
    'card--rounded': rounded,
    'card--turquoise': color === 'turquoise',
    'card--blue': color === 'blue',
    'card--purple': color === 'purple',
    'card--iris': color === 'iris',
    'card--pink': color === 'pink',
    'card--orchid': color === 'orchid',
    'card--dark': dark,
  });

  return (
    <div className={classes} style={style} role={role} tabIndex={tabIndex} onClick={onClick}>
      {title && <div className="card__header">{title}</div>}
      <div className="card__body">{children}</div>
    </div>
  );
}

Card.defaultProps = {
  document: true,
  role: 'region',
};

export default Card;
