// @flow

import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { findContract } from '../services/contractService';
import { type Contract } from '../types/contract-types';

function useContract(id: number) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [contract, setContract] = useState<?Contract>(null);
  const { t } = useTranslation();

  useEffect(() => {
    let isCancelled = false;

    async function fetchContract() {
      try {
        setIsLoading(true);
        const { data } = await findContract(id);
        if (!isCancelled) {
          let formattedData = {
            ...data,
            vehicle: {
              label: data.vehicle?.id
                ? `${data.vehicle?.activeLicensePlate} - ${data.vehicle?.sNumber}`
                : t('contractform.choosevehicle'),
              value: data.vehicle?.id ? data.vehicle?.id : null,
            },
            driver: {
              label: `${data.contractor?.lastname} ${data.contractor?.firstname}, ${data.contractor?.unit}`,
              user: data.contractor,
              value: data.contractor?.swisscomEmployeeNr,
            },
            selectedVehicleCategory: {
              label: data.vehicle?.vehicleCategory?.name,
              pricePerMonth: data.vehicle?.vehicleCategory?.pricePerMonth,
              value: data.vehicle?.vehicleCategory?.id,
            },
          };
          setContract(formattedData);
          setIsLoading(false);
        }
      } catch (err) {
        if (!isCancelled) {
          setHasError(true);
          setIsLoading(false);
        }
        throw err;
      }
    }

    fetchContract();

    return () => {
      isCancelled = true;
    };
  }, [id, t]);

  return { isLoading, contract, hasError };
}

export default useContract;
