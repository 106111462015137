// @flow

import React, { type Node } from 'react';
import cx from 'classnames';
import Icon from '../Icon';
import useIdentifier from '../../hooks/useIdentifier';

import styles from './MessageCard.module.css';

type Props = {
  type: string,
  compact?: Boolean,
  icon: string,
  title: string,
  children: Node,
  className?: string,
};

function MessageCard({ type, compact, icon, title, children, className }: Props) {
  const id = useIdentifier('MessageCard_');
  const classes = cx(
    'card',
    'card--icon',
    'card--border',
    {
      'card--message-info': type === 'info',
      'card--message-success': type === 'success',
      'card--message-important': type === 'important',
      'card--message-alert': type === 'alert',
      'card--compact': compact,
    },
    className,
  );

  return (
    <div className={classes} role="region" aria-labelledby={id}>
      <Icon name={icon} className="card__item-icon icon--s3"></Icon>
      <span className="sr-only">{title}</span>
      <div className="card__body">
        <h3 className={styles.title} id={id}>
          {title}
        </h3>
        <div>{children}</div>
      </div>
    </div>
  );
}

MessageCard.defaultProps = {
  type: 'info',
  icon: '026-exclamation-mark-circle',
};

export default MessageCard;
