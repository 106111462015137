import React from 'react';
import Icon from '../Icon';

import styles from './EmptyState.module.css';
import { useTranslation } from 'react-i18next';

function EmptyState({ className, text }) {
  const { t } = useTranslation();
  return (
    <div className={styles.wrapper}>
      <Icon name="237-robot-relaxed" className={styles.icon} />
      <p>{text || t('emptystate.defaultmessage')}</p>
    </div>
  );
}

export default EmptyState;
