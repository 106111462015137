// @flow

import React, { type Node } from 'react';
import cx from 'classnames';

import style from './FormField.module.css';
import AutoId from '../AutoId';

type Props = {
  label?: string,
  children: Node,
  htmlFor?: string,
  required?: boolean,
  className?: string,
  bold?: boolean
};

const FormField = ({ label, children, htmlFor, required, className, bold }: Props) => {
  if (!label) {
    return <>{children}</>;
  }

  const labelClasses = cx({
    [style.required]: required,
    [style.bold]: bold,
  });

  if (!htmlFor) {
    return (
      <AutoId>
        {id => (
          <div className={cx(style.field, className)}>
            <label htmlFor={id} className={labelClasses}>
              {label}
            </label>
            {React.Children.map(children, child =>
              child ? React.cloneElement(child, { id }) : null,
            )}
          </div>
        )}
      </AutoId>
    );
  }

  return (
    <div className={cx(style.field, className)}>
      <label htmlFor={htmlFor} className={labelClasses}>
        {label}
      </label>
      {children}
    </div>
  );
};

export default FormField;
