import React from 'react';
import RadioButtonGroup from '../RadioButtonGroup';
import RadioButton from '../RadioButton';
import { Trans } from 'react-i18next';

type Props = {
  value: boolean,
  onChange: Function,
};

const EndLeasingContractField = ({ value, onChange }: Props) => (
  <RadioButtonGroup value={value} onChange={onChange}>
    <RadioButton value={true}>
      <Trans i18nKey="common.yes" />
    </RadioButton>
    <RadioButton value={false}>
      <Trans i18nKey="common.no" />
    </RadioButton>
  </RadioButtonGroup>
);

export default EndLeasingContractField;
