// @flow

import React from 'react';
import useIdentifier from '../../hooks/useIdentifier';

type Props = {
  children: Function,
};

function AutoId({ children }: Props) {
  const id = useIdentifier();
  return <>{children(id)}</>;
}

export default AutoId;
