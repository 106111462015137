import React from 'react';
import { useTranslation } from 'react-i18next';
import Select from '../Select';

function ContractMutationArchivedFilter({ value, onChange, id, className }) {
  const { t } = useTranslation();
  const options = [
    {
      value: 'active',
      label: t('mutationoverview.archivedfilter.active'),
    },
    {
      value: 'archived',
      label: t('mutationoverview.archivedfilter.archived'),
    },
    {
      value: 'all',
      label: t('mutationoverview.archivedfilter.all'),
    },
    {
      value: 'draft',
      label: t('common.draft'),
    },
  ];

  return (
    <Select
      value={value || options[0]}
      onChange={onChange}
      options={options}
      className={className}
      inputId={id}
      isClearable={false}
    />
  );
}

export default ContractMutationArchivedFilter;
