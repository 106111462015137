// @flow

import React, { useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import cx from 'classnames';

import styles from './BillingCycleRun.module.css';
import tableStyles from '../../container/ContractOverview/ContractTable.module.css';

import Button from '../../components/Button';
import Container from '../../components/Container';
import LoadingSpinner from '../../components/LoadingSpinner';
import BackMenu from '../../components/BackMenu';
import { SDXCard } from '../../components/SDX-Card/SDX-Card';
import { useHistory } from 'react-router-dom';
import useBillingCycleConflicts from '../../hooks/useBillingCycleConflicts';
import { tableHeaderConfig } from './tableHeaderConfig';
import ConflictingContractListItem from '../ContractOverview/ConflictingContractListItem';
import BillingCycleMonthSelect from '../../components/BillingCycleMonthSelect/BillingCycleMonthSelect';
import { Formik } from 'formik';
import { validationSchema } from './validation-schema';
import { initialValues } from './initial-values';
import { runBillingCycle } from '../../services/billingCycleService';
import { useNotifications } from '../../components/Notifications/NotificationsProvider';

function BillingCycleRun() {
  const { t } = useTranslation();
  const history = useHistory();

  const [activeMode] = useState < 'control' | 'production' > ('control');
  const [isLoading, setIsLoading] = useState < boolean > (false);

  const [success, setSuccess] = useState < boolean > (false);
  const [attempt, setAttempt] = useState < number > (1);
  const { addSuccess, addAlert } = useNotifications();

  const { isLoading: isLoadingConflicts, conflictedContracts } = useBillingCycleConflicts(attempt);

  async function handleSubmit(values, actions) {
    try {
      const payload = {
        billingDate: values.billingCycleMonth.value,
      };
      await runBillingCycle(payload);
      addSuccess(t('billingcyclerun.submit.success'));
      setSuccess(true);
      history.push('/contracts');
    } catch (err) {
      const errorCode = err?.response?.data?.error;
      const alertText = code => {
        if (code === 'CONFLICT_BILLING_CYCLE_CONFLICTING_BILLING_CYCLE') {
          return 'billingcyclerun.submit.conflict';
        }
        return 'billingcyclerun.submit.failure';
      };

      actions.setSubmitting(false);
      addAlert(t(alertText(errorCode)));
      actions.setSubmitting(false);
      setAttempt(attempt + 1);
      window.scrollTo(0, 0);
      throw err;
    }
  }

  const endBillingCycle = () => {
    history.push('/contracts');
  };

  useEffect(() => {
    setIsLoading(isLoadingConflicts);
  }, [isLoadingConflicts]);

  return (
    <>
      <BackMenu to="/contracts" text={t('contractform.back')} />
      <Container>
        <h1>
          <Trans i18nKey="billingcyclerun.title" />
        </h1>
        {conflictedContracts.length > 0 && activeMode === 'control' && (
          <div className="flex flex-items-sm-top flex-items-sm-between">
            <SDXCard
              title={t('billingcyclerun.conflicttitle', {
                conflictCount: conflictedContracts.length,
              })}
              type="important"
              className={cx(styles.statusCard)}
            >
              <p>{t('billingcyclerun.conflictexplanation')}</p>
            </SDXCard>

            <Button onClick={() => setAttempt(attempt + 1)}>
              {t('billingcyclerun.actions.repeatcheck')}
            </Button>
          </div>
        )}
        {conflictedContracts.length === 0 && activeMode === 'control' && (
          <Formik
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            initialValues={initialValues}
          >
            {({
              values,
              touched,
              errors,
              handleSubmit,
              setFieldValue,
              setFieldTouched,
              handleChange,
              handleBlur,
              submitCount,
              isSubmitting,
              isValid,
            }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <SDXCard
                    title={t('billingcyclerun.noconflictstitle')}
                    type="success"
                    className={cx(styles.statusCard)}
                  >
                    <p>{t('billingcyclerun.noconflictsexplanation')}</p>
                    <BillingCycleMonthSelect
                      name="billingCycleMonth"
                      value={values?.billingCycleMonth}
                      onChange={billingCycleMonth =>
                        setFieldValue('billingCycleMonth', billingCycleMonth)
                      }
                      onBlur={() => setFieldTouched('billingCycleMonth', true)}
                      invalid={touched.billingCycleMonth && errors.billingCycleMonth}
                    />
                    <Button
                      type="submit"
                      disabled={(submitCount > 0 && !isValid) || isSubmitting}
                      isLoading={isSubmitting}
                    >
                      {t('billingcyclerun.actions.runproduction')}
                    </Button>
                  </SDXCard>
                </form>
              );
            }}
          </Formik>
        )}
        {activeMode === 'production' && success && (
          <SDXCard
            title={t('billingcyclerun.productionsuccesstitle')}
            type="success"
            className={cx(styles.statusCard)}
          >
            <p>{t('billingcyclerun.productionsuccessexplanation')}</p>
            <Button onClick={() => endBillingCycle()}>
              {t('billingcyclerun.actions.finishproduction')}
            </Button>
          </SDXCard>
        )}
        {isLoading && (
          <div className={cx(styles.loadingWrapper)}>
            <LoadingSpinner></LoadingSpinner>
          </div>
        )}

        {conflictedContracts.length > 0 && (
          <div className="row">
            <div className={cx(`table table--responsive ${tableStyles.orderlist}`)}>
              <div className={('table__wrapper', styles.maxHeight)}>
                <table className={cx({ [tableStyles.empty]: conflictedContracts.length === 0 })}>
                  <thead>
                    <tr>
                      {tableHeaderConfig.map(item => (
                        <td
                          style={{ width: item.width, minWidth: item.minWidth }}
                          key={item.i18nKey}
                        >
                          <Trans i18nKey={item.i18nKey} />
                        </td>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {!isLoading &&
                      conflictedContracts.length > 0 &&
                      conflictedContracts.map(c => (
                        <ConflictingContractListItem key={c.id} contract={c} />
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </Container>
    </>
  );
}

export default BillingCycleRun;
