// @flow
import React, { useEffect, useState, useCallback } from 'react';
import Container from '../../components/Container';
import { useParams } from 'react-router-dom';
import useMediaFile from '../../hooks/useMediaFile';
import { useNotifications } from '../../components/Notifications/NotificationsProvider';
import LoadingSpinner from '../../components/LoadingSpinner';

import styles from './MediaDownload.module.css';
import { useTranslation, Trans } from 'react-i18next';

function MediaDownload() {
  const [isAlerting, setIsAlerting] = useState(false);
  const [fileLoaded, setFileLoaded] = useState(false);
  const { bucketName, fileName } = useParams();
  const { isLoading, hasError, file } = useMediaFile(bucketName, fileName);
  const { addAlert } = useNotifications();
  const { t } = useTranslation();

  const embedFile =
    file?.contentType === 'application/pdf' &&
    window.screen.width > 480 &&
    !window.navigator.msSaveOrOpenBlob;

  const createObjectUrl = blob => window.URL.createObjectURL(blob);

  const alert = useCallback(async () => {
    addAlert(t('common.media.error'));
    setIsAlerting(true);
  }, [setIsAlerting, addAlert, t]);

  useEffect(() => {
    if (!isAlerting && hasError) {
      alert();
    }
  }, [isAlerting, alert, hasError]);

  useEffect(() => {
    if (!isLoading && !hasError && !fileLoaded && file) {
      if (!embedFile) {
        if (window?.navigator?.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(file.blob, fileName);
        } else {
          const a = window.document.createElement('a');
          a.href = createObjectUrl(file.blob);
          a.download = fileName;
          a.click();
        }
      }

      setFileLoaded(true);
    }
  }, [isLoading, hasError, fileLoaded, embedFile, file, fileName]);

  return (
    <Container>
      <div className={styles.wrapper}>
        {isLoading && (
          <>
            <div className={styles.status}>
              <Trans i18nKey="common.media.loading" values={{ fileName }} />
            </div>
            <LoadingSpinner className={styles.spinner} />
          </>
        )}
        {hasError && <div className={styles.status}>{t('common.media.error')}</div>}
        {embedFile && file && (
          <iframe
            frameBorder="0"
            className={styles.preview}
            title={fileName}
            src={createObjectUrl(file.blob)}
          />
        )}
        {file && !embedFile && (
          <div>
            <Trans i18nKey="common.media.success" />
          </div>
        )}
      </div>
    </Container>
  );
}

export default MediaDownload;
