//@flow

import React, { useContext } from 'react';
import { Trans } from 'react-i18next';

import { getConditionProtocolLink } from '../../util/ConditionProtocol';

import AutoId from '../AutoId';
import Checkbox from '../Checkbox';
import ValidationErrorMessage from '../ValidationErrorMessage';
import { AuthContext } from '../AuthProvider/AuthProvider';

type Props = {
  setFieldValue: Function,
  setFieldTouched: Function,
  errors: Object,
  touched: Object,
  values: Object,
  required?: boolean,
  name?: string,
  vehicleType?: string,
};

function ConditionProtocol({
  setFieldTouched,
  setFieldValue,
  errors,
  touched,
  values,
  required,
  name,
  vehicleType
}: Props) {
  const { profile } = useContext(AuthContext);

  const fieldName = name ? name : 'conditionProtocolRead';

  return (
    <AutoId>
      {id => (
        <>
          <Checkbox
            className="margin-bottom-3"
            id={id}
            label={
              <Trans i18nKey="driverchangeform.fields.conditionprotocol.label">
                Ich bestätige, dass ich das
                <a
                  href={getConditionProtocolLink(profile?.preferredLanguage || profile?.language, vehicleType)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Zustandsprotokoll
                </a>
                gelesen habe und allfällige Schäden, welche bei mir entstanden sind melde.
              </Trans>
            }
            name={fieldName}
            required={!!required}
            value={values.conditionProtocolRead}
            onChange={event => setFieldValue(fieldName, event.currentTarget.checked)}
            onBlur={() => setFieldTouched(fieldName, true)}
          />

          <ValidationErrorMessage
            className="margin-top-0 margin-bottom-4"
            invalid={touched.conditionProtocolRead && errors.conditionProtocolRead}
            i18nKey={errors.conditionProtocolRead}
          ></ValidationErrorMessage>
        </>
      )}
    </AutoId>
  );
}

export default ConditionProtocol;
