// @flow

import React from 'react';

function Redirect({ redirectRoute, ...props }: SelectProps) {
  window.location.href = redirectRoute;
  return <></>;
}

export default Redirect;
