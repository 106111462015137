// @flow

import React from 'react';
import cx from 'classnames';

type Props = {
  name: string,
  className?: string,
};

const Icon = ({ name, className }: Props) => {
  const classes = cx('icon', 'icon-' + name, className);
  return <i className={classes} aria-hidden="true"/>;
};

export default Icon;
