// @flow

import React from 'react';
import useVehicleCategoryHistory from '../../../../../hooks/useVehicleCategoryHistory';
import { renderToStaticMarkup } from 'react-dom/server';
import useIdentifier from '../../../../../hooks/useIdentifier';
import ReactTooltip from 'react-tooltip';

import styles from './VehicleCategoryHistoryPopup.module.css';
import Icon from '../../../../../components/Icon';
import { Trans } from 'react-i18next';
import { formatCategoryName } from '../../../../../util/MutationProperties';

type Props = {
  mutationId: number,
};

function VehicleCategoryHistoryPopup({ mutationId }: Props) {
  const { isLoading, vehicleCategoryHistory } = useVehicleCategoryHistory(mutationId);

  const tooltipContent = vehicleCategoryHistory.map(({ user, originalCategory, newCategory }) => {
    const username = `${user.lastname} ${user.firstname}, ${user.unit}`;
    return (
      <div className={styles.historyItem}>
        <span className={styles.historyItemTitle}>{username}</span>
        <span className={styles.historyItemContent}>
          <Trans
            i18nKey="requestvehicledetail.vehiclecategoryhistory.text"
            values={{
              originalCategoryName: formatCategoryName(originalCategory),
              newCategoryName: formatCategoryName(newCategory),
            }}
          >
            hat die Fahrzeugkategorie von
            <span className="line-through">{formatCategoryName(originalCategory)}</span> zu
            {formatCategoryName(newCategory)}
            geändert.
          </Trans>
        </span>
      </div>
    );
  });

  const tooltipContentHtml = renderToStaticMarkup(tooltipContent);

  const tooltipId = useIdentifier();

  if (isLoading || vehicleCategoryHistory.length === 0) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <p data-for={tooltipId} data-tip={tooltipContentHtml} className={styles.label}>
        <Icon className={styles.labelIcon} name="026-exclamation-mark-circle" />
        <span>
          <Trans i18nKey="requestvehicledetail.vehiclecategoryhistory.label" />
        </span>
      </p>
      <ReactTooltip
        id={tooltipId}
        effect="solid"
        type="light"
        border
        className={styles.tooltip}
        place="top"
        clickable
        html
      />
    </div>
  );
}

export default VehicleCategoryHistoryPopup;
