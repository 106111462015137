// @flow

import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';

import useMutation from '../../hooks/useMutation';

import Container from '../../components/Container';
import ErrorState from '../../components/ErrorState';
import LoadingSpinner from '../../components/LoadingSpinner';
import { AuthContext } from '../../components/AuthProvider/AuthProvider';

import RequestVehicleDetail from './RequestVehicleDetail';
import ReturnVehicleDetail from './ReturnVehicleDetail';
import DriverChangeDetail from './DriverChangeDetail';
import VehicleChangeDetail from './VehicleChangeDetail';

import styles from './MutationDetail.module.css';

import { isCurrentUserApprover, getCurrentApprovalPoint } from '../../util/MutationProperties';
import BackMenu from '../../components/BackMenu/BackMenu';
import { useLastLocation } from 'react-router-last-location';
import { useTranslation } from 'react-i18next';

export const MutationDetailContext = React.createContext<any>();

const MutationDetailByType = ({ mutation, protocol }) => {
  if (!mutation) {
    return null;
  }

  switch (mutation.type) {
    case 'REQUESTVEHICLE':
      if (mutation.requestVehicleMutation)
        return (
          <RequestVehicleDetail
            mutation={mutation}
            requestVehicleMutation={mutation.requestVehicleMutation}
          />
        );
      break;
    case 'RETURNVEHICLE':
      if (mutation.returnVehicleMutation)
        return (
          <ReturnVehicleDetail
            mutation={mutation}
            returnVehicleMutation={mutation.returnVehicleMutation}
          />
        );
      break;
    case 'DRIVERCHANGE':
      if (mutation.changeDriverMutation)
        return (
          <DriverChangeDetail
            mutation={mutation}
            driverChangeMutation={mutation.changeDriverMutation}
          />
        );
      break;
    case 'VEHICLECHANGE':
      if (
        mutation.returnVehicleMutation &&
        mutation.requestVehicleMutation &&
        mutation.changeVehicleMutation
      )
        return (
          <VehicleChangeDetail
            mutation={mutation}
            requestVehicleMutation={mutation.requestVehicleMutation}
            returnVehicleMutation={mutation.returnVehicleMutation}
            changeVehicleMutation={mutation.changeVehicleMutation}
          />
        );
      break;
    default:
      throw new Error('Invalid mutation type');
  }

  return null;
};

function MutationDetail() {
  const { id: mutationId } = useParams();
  const { profile } = useContext(AuthContext);
  const { t } = useTranslation();
  const lastLocation = useLastLocation();
  const {
    isLoading,
    protocolIsLoading,
    hasError,
    mutation,
    protocol,
    refetch,
    sendManualReminder,
    changeEligibleApprovers,
  } = useMutation(mutationId);

  if (isLoading || (!hasError && !mutation)) {
    return (
      <Container>
        <div className={styles.spinner__outer}>
          <LoadingSpinner />
        </div>
      </Container>
    );
  }

  if (!isLoading && hasError) {
    return (
      <Container>
        <ErrorState text={t('errorstate.deletedmutation')}/>
      </Container>
    );
  }

  if (!mutation) {
    return null;
  }

  const userIsApprover = isCurrentUserApprover(profile, mutation);
  const currentApprovalPoint = getCurrentApprovalPoint(mutation);

  return (
    <MutationDetailContext.Provider
      value={{
        refetch,
        mutation,
        sendManualReminder,
        changeEligibleApprovers,
        userIsApprover,
        currentApprovalPoint,
        protocol,
        protocolIsLoading,
      }}
    >
      <BackMenu text={t('common.back')} to={lastLocation || '/mutation-overview'} />
      <MutationDetailByType mutation={mutation} protocol={protocol} />
    </MutationDetailContext.Provider>
  );
}

export default MutationDetail;
