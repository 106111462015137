// @flow

import React from 'react';
import cx from 'classnames';

type Props = {
  user: {
    email: string,
  },
  className?: string,
};

function EmailLink({ user, className }: Props) {
  if (!user.email) {
    return null;
  }
  
  return (
    <a className={cx('link', className)} href={`mailto:${user.email}`}>
      {user.email}
    </a>
  );
}

export default EmailLink;
