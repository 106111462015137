//@flow

import React from 'react';

import { type Contract } from '../../../types/contract-types';
import FormattedDate from '../../../components/FormattedDate';
import ProfileLink from '../../../components/ProfileLink';
import styles from './ConflictingContractListItem.module.css';
import ContractConflict from '../../../components/ContractConflict/ContractConflict';
import { useTranslation } from 'react-i18next';
import { formatPrice } from '../../../util/PriceFormatHelper';

type Props = {
  contract: Contract,
};

function ConflictingContractListItem({ contract }: Props) {
  const { t } = useTranslation();
  const checkConflict = (conflicts, entry) => {
    let matchingConflicts = [];

    if (contract.conflicts) {
      contract.conflicts.forEach(
        conflict => {
          if (conflicts.filter(c => conflict === c.code).length > 0) {
            matchingConflicts.push(conflicts.filter(c => conflict === c.code)[0].i18n);
          }
        },
      );
    }
    if (matchingConflicts.length === 0) {
      return entry;
    }
    return <ContractConflict text={matchingConflicts} contract={contract} />;
  };
  return (
    <tr className={styles.row}>
      <td>
        <div className={styles.minHeight}>
          {checkConflict(
            [
              { code: 'DUPLICATE_CONTRACT', i18n: t('billingcyclerun.conflicts.duplicatecontract') },
            ],
            contract?.id,
          )}
        </div>
      </td>
      <td>
        {checkConflict(
          [
            { code: 'MISSING_VEHICLE', i18n: t('billingcyclerun.conflicts.missingvehicle') },
          ],
          contract?.vehicle?.sNumber,
        )}
      </td>
      <td>{contract?.contractor?.company}</td>
      <td> {checkConflict(
        [
          { code: 'MISSING_DEBTOR', i18n: t('billingcyclerun.conflicts.missingdebtor') },
        ],
        contract?.debtor?.number,
      )}</td>
      <td>
        <FormattedDate date={contract?.invoicingContractStartDate} />
      </td>
      <td>
        <FormattedDate date={contract?.invoicingContractEndDate} />
      </td>
      <td>
        {checkConflict(
          [{ code: 'MISSING_CONTRACTOR', i18n: t('billingcyclerun.conflicts.missingcontractor') }],
          contract.contractor && <ProfileLink user={contract.contractor} />,
        )}
      </td>
      <td>
        {contract?.contractor?.costCenter?.identifier}
      </td>
      <td>{contract?.contractor?.swisscomEmployeeNr}</td>
      <td>
        {checkConflict(
          [
            { code: 'MISSING_CATEGORY', i18n: t('billingcyclerun.conflicts.missingvehicle') },
            { code: 'MISSING_RATE', i18n: t('billingcyclerun.conflicts.missingrate') }
          ],
          formatPrice(contract?.vehicle?.vehicleCategory.pricePerMonth, { includeCurrency: true, includeCentimes: true })
        )}
      </td>

      <td>{contract?.status}</td>
      <td>
        <FormattedDate date={contract?.lastBillingDate} />
      </td>
      <td>
        <FormattedDate date={contract?.nextBillingDate} />
      </td>
    </tr>
  );
}

export default ConflictingContractListItem;
