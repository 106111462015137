// @flow

import cx from 'classnames';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';
import styles from './BackMenu.module.css';


type Props = {
  text: string,
  to: string,
};

function BackMenu({ text, to }: Props) {
  const history = useHistory();
  const lastLocation = useLastLocation();

  const handleClick = (event: SyntheticMouseEvent<HTMLLinkElement>): void => {
    event.preventDefault();
    if (lastLocation && lastLocation.pathname === to) {
      history.goBack();
    } else {
      history.push(to);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={cx('container', styles.container)}>
        <Link to={to} onClick={handleClick} className="icon-003-arrow-left">
          {text}
        </Link>
      </div>
    </div>
  );
}

export default BackMenu;
