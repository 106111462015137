// @flow

import { useEffect, useState } from 'react';
import { getVehicleCategoryHistory } from '../services/contractMutationService';

import { type VehicleCategoryHistoryItem } from '../types/vehicle-category-types';

function useVehicleCategoryHistory(contractMutationId: number) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [vehicleCategoryHistory, setVehicleCategoryHistory] = useState<
    Array<VehicleCategoryHistoryItem>,
  >([]);

  useEffect(() => {
    setIsLoading(true);
    getVehicleCategoryHistory(contractMutationId)
      .then(({ data }) => {
        setVehicleCategoryHistory(data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [contractMutationId]);

  return { isLoading, vehicleCategoryHistory };
}

export default useVehicleCategoryHistory;
