// @flow

import React, { useEffect } from 'react';
import SegmentControlGroup from '../SegmentControlGroup';
import SegmentControlOption from '../SegmentControlOption';
import useVehicleProviders from '../../hooks/useVehicleProviders';
import { useNotifications } from '../Notifications/NotificationsProvider';
import { useTranslation } from 'react-i18next';

type Props = {
  name: string,
  onChange: Function,
  value: any
};

function ProviderPicker({ value, name, onChange }: Props) {
  const { providers, hasError } = useVehicleProviders();
  const { addAlert } = useNotifications();
  const { t } = useTranslation();

  const compareChosenProvider = (childValue, currentValue) => {
    if (!currentValue) {
      return false;
    }

    return childValue.id === currentValue.id;
  };

  useEffect(() => {
    if (hasError) {
      addAlert(t('errorstate.providerloadingerror'));
    }
  }, [addAlert, hasError, t]);

  return (
    !hasError && (
      <SegmentControlGroup
        name={name}
        onChange={onChange}
        value={value}
        compare={compareChosenProvider}
      >
        {providers.map(provider => (
          <SegmentControlOption value={provider} key={provider.id} checked={(value && value.id === provider.id)}>
            {provider.name}
          </SegmentControlOption>
        ))}
      </SegmentControlGroup>
    )
  );
}

export default ProviderPicker;
