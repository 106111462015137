// @flow

import React, { useState, useContext, useEffect } from 'react';
import { Formik } from 'formik';
import isEqual from 'lodash/fp/isEqual';
import { useHistory, useParams } from 'react-router-dom';
import Container from '../../components/Container';
import styles from './VehicleChangeForm.module.css';
import FormField from '../../components/FormField';
import CategoryPicker from '../../components/CategoryPicker';
import CantonPicker from '../../components/CantonPicker';
import MileageInput from '../../components/MileageInput';
import RoutingPrompt from '../../components/RoutingPrompt';
import { useTranslation, Trans } from 'react-i18next';
import ValidationErrorMessage from '../../components/ValidationErrorMessage';
import MessageCard from '../../components/MessageCard';
import ButtonGroup from '../../components/ButtonGroup';
import Button from '../../components/Button';
import { initialValues } from './initial-values';
import { validationSchemaFleetMember } from './validation-schema-fleet';
import { changeVehicle, saveVehicleChangeDraft, deleteChangeVehicleDraft } from '../../services/contractService';
import { useNotifications } from '../../components/Notifications/NotificationsProvider';
import ProviderPicker from '../../components/ProviderPicker';
import ContractInformation from '../../components/ContractInformation';
import { OmitKeys } from '../../components/ContractInformation/ContractInformation';
import ReserveSelect from '../../components/ReserveSelect/ReserveSelect';
import DatePicker from '../../components/DatePicker/DatePicker';
import startOfDay from 'date-fns/fp/startOfDay';
import { AuthContext } from '../../components/AuthProvider/AuthProvider';
import BackMenu from '../../components/BackMenu';
import EndLeasingContractField from '../../components/EndLeasingContractField';
import CommentField from '../../components/CommentField';
import useContract from '../../hooks/useContract';
import Checkbox from '../../components/Checkbox';
import AutoId from '../../components/AutoId';
import Input from '../../components/Input';
import useQueryParams from '../../hooks/useQueryParams';
import { getMutation, updateContractMutation, getOpenMutation } from '../../services/contractMutationService';
import { getVehicleBySNumber } from '../../services/vehicleService';
import {type ContractMutation} from '../../types/contract-mutation-types';


function VehicleChangeFormFleet() {
  const { t } = useTranslation();
  const history = useHistory();
  const [hasSuccess, setHasSuccess] = useState(false);
  const { id: contractId } = useParams();
  const { contract, isLoading, hasError } = useContract(contractId);
  const { fetchProfile, profile } = useContext(AuthContext);
  const { addSuccess, addAlert } = useNotifications();
  const draftId = +useQueryParams('draftId');
  const [formInitialValues, setFormInitialValues] = useState();
  const [showPrompt, setShowPrompt] = useState <boolean>(true);
  const [hasOpenMutation, setHasOpenMutation] = useState<ContractMutation | null>(null);

  useEffect(() => {
    const fetchOpenMutation = async () => {
      const {data }= await getOpenMutation(+contractId, { contractMutationType: ['DRIVERCHANGE', 'RETURNVEHICLE', 'VEHICLECHANGE'], status: ['PENDING']});
      setHasOpenMutation(data);
    };


    fetchOpenMutation();
  }, [contractId]);

  useEffect(() => {
    if (!draftId) {
      setFormInitialValues(initialValues);
      return;
    }
    const getDraftMutation = async () => {
      const cantons = t('cantons', { returnObjects: true });
      const { data: initialValues } = await getMutation(draftId);
      let reserveVehicle = null;
      if (initialValues.requestVehicleMutation && initialValues.requestVehicleMutation.sNumber) {
        const {data: reserveVehicleData} = await getVehicleBySNumber(initialValues.requestVehicleMutation.sNumber);
        reserveVehicle = reserveVehicleData;
      }
      const mappedInitialValues = {
        ...initialValues,
        providerId: initialValues.requestVehicleMutation && initialValues.requestVehicleMutation.selectedProvider ? initialValues.requestVehicleMutation.selectedProvider.id : null,
        vehicleProvider: initialValues.requestVehicleMutation ? initialValues.requestVehicleMutation.selectedProvider : null,
        registrationCanton: initialValues.requestVehicleMutation ? { value: initialValues.requestVehicleMutation.registrationCanton, label: cantons[initialValues.requestVehicleMutation.registrationCanton] } : null,
        deliveryCanton: initialValues.requestVehicleMutation ? { value: initialValues.requestVehicleMutation.deliveryCanton, label: cantons[initialValues.requestVehicleMutation.deliveryCanton] } : null,
        mileagePerYear: initialValues.requestVehicleMutation ? { value: initialValues.requestVehicleMutation.mileagePerYear, label: initialValues.requestVehicleMutation.mileagePerYear } : null,
        dateOfChange: initialValues.requestedDateOfExecution,
        endLeasingContract: initialValues.returnVehicleMutation ? initialValues.returnVehicleMutation.endLeasingContract : null,
        comment: initialValues.comment,
        sNumber: initialValues.reserveVehicle?.sNumber,
        reserveVehicle,
        vehicleCategoryId: initialValues.vehicleCategory?.value,
        vehicleCategory: initialValues.requestVehicleMutation ? {label: initialValues.requestVehicleMutation.vehicleCategory.name, pricePerMonth: initialValues.requestVehicleMutation.vehicleCategory.pricePerMonth,  value: initialValues.requestVehicleMutation.vehicleCategory.id} : null,
        reserveVehicleId: initialValues.reserveVehicle?.id,
        sendToProvider: true,
        orderNumber: initialValues.requestVehicleMutation ? initialValues.requestVehicleMutation.orderNumber : '',
      };
      setFormInitialValues(mappedInitialValues);
    };
    getDraftMutation();

  }, [draftId, t]);


  async function handleSubmit(values, actions) {
    try {
      const payload = {
        providerId: values.vehicleProvider.id,
        registrationCanton: values.registrationCanton.value,
        deliveryCanton: values.deliveryCanton.value,
        mileagePerYear: values.mileagePerYear.value,
        dateOfChange: values.dateOfChange,
        endLeasingContract: values.endLeasingContract,
        comment: values.comment,
        sNumber: values.reserveVehicle?.sNumber,
        vehicleCategoryId: values.vehicleCategory?.value,
        reserveVehicleId: values.reserveVehicle?.id,
        sendToProvider: values.sendToProvider,
        orderNumber: values.orderNumber,
        draftId,
      };

      await changeVehicle(contractId, payload);
      fetchProfile();
      addSuccess(t('vehiclechangeform.submit.success'));
      setHasSuccess(true);
      history.push('/');
    } catch (err) {
      const errorCode = err?.response?.data?.error;

      actions.setSubmitting(false);
      addAlert(
        t(
          errorCode === 'CONFLICT_MUTATION_COLLISION'
            ? 'vehiclechangeform.submit.collision'
            : 'errorstate.defaultmessage',
          errorCode === 'CONFLICT_MUTATION_COLLISION' ? {id: hasOpenMutation?.id} : null
        ),
      );
      window.scrollTo(0, 0);
      throw err;
    }
  }

  const handleSaveDraft = async values => {
    try {
      const payload = {
        ...values,
        providerId: values.vehicleProvider ? values.vehicleProvider.id : null,
        registrationCanton: values.registrationCanton ? values.registrationCanton.value : null,
        deliveryCanton: values.deliveryCanton ? values.deliveryCanton.value : null,
        mileagePerYear: values.mileagePerYear ? values.mileagePerYear.value : null,
        dateOfChange: values.dateOfChange,
        endLeasingContract: values.endLeasingContract,
        comment: values.comment,
        sNumber: values.reserveVehicle?.sNumber,
        vehicleCategoryId: values.vehicleCategory?.value,
        reserveVehicleId: values.reserveVehicle?.id,
        sendToProvider: values.sendToProvider,
        orderNumber: values.orderNumber,
        requestedDateOfExecution: values.dateOfChange,
        draftId,
      };

      if (hasValue(payload)) {
        if (draftId) {
          await updateContractMutation(draftId, payload);
          addSuccess(t('drafts.updatedraft'));
        } else {
          await saveVehicleChangeDraft(contractId, payload);
          addSuccess(t('drafts.savedraft'));
        }
        setShowPrompt(false);
        history.push('/');
      } else {
        addAlert(t('drafts.emptydraft'));
      }
    } catch (err) {
      addAlert(t('drafts.failure'));
      throw err;
    }
    window.scrollTo(0, 0);
  };

  const removeDraft = async values => {
    try {
      await deleteChangeVehicleDraft(draftId);
      addSuccess(t('drafts.deletedraft'));
      setShowPrompt(false);
      history.push('/');
    } catch (err) {
      addAlert(t('drafts.faildeletedraft'));
      throw err;
    }
    window.scrollTo(0, 0);
  };

  const hasValue = payload => {
    return payload.comment !== '' || payload.dateOfChange || payload.deliveryCanton || payload.mileagePerYear || payload.orderNumber !== '' || payload.providerId || payload.registrationCanton || payload.reserveVehicle || payload.sNumber || payload.vehicleCategory || payload.vehicleProvider;
  };


  return (
    <>
      <BackMenu to="/" text={t('vehiclechangeform.back')} />
      <Container>
        {formInitialValues && <Formik
          initialValues={formInitialValues}
          validationSchema={validationSchemaFleetMember}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            isSubmitting,
            setFieldValue,
            setFieldTouched,
            isValid,
            submitCount,
            handleSubmit,
          }) => {
            return (
              <form onSubmit={handleSubmit}>
                <RoutingPrompt
                  when={!hasSuccess && !isEqual(values, initialValues) && showPrompt}
                  message={t('common.formprompt')}
                />
                <h1>
                  <Trans i18nKey="vehiclechangeform.title" />
                </h1>
                <ContractInformation
                  contract={contract}
                  isLoading={isLoading}
                  hasError={hasError}
                  className={styles.section}
                  titleKey="common.labels.vehicleinformation"
                  omittedProperties={[
                    OmitKeys.mileageperyear,
                    OmitKeys.pricePerMonth,
                    OmitKeys.contractId,
                  ]}
                />
                <div className={styles.section}>

                  {hasOpenMutation && <MessageCard
                    type="important"
                    title={t('common.blockingmutations.title')}
                    className="margin-bottom-4">
                    <Trans i18nKey="common.blockingmutations.text" values={{ id: hasOpenMutation.id} } />
                  </MessageCard>}

                  <h3>
                    <Trans i18nKey="vehiclechangeform.subtitle" />
                  </h3>
                  <FormField label={t('vehiclechangeform.fields.vehicleprovider.label')} required>
                    <ProviderPicker
                      name="vehicleProvider"
                      value={values.vehicleProvider}
                      onChange={vehicleProvider =>
                        setFieldValue('vehicleProvider', vehicleProvider)
                      }
                    />
                    <ValidationErrorMessage
                      invalid={touched.vehicleProvider && errors.vehicleProvider}
                      i18nKey={errors.vehicleProvider}
                    />
                  </FormField>

                  {values.vehicleProvider &&
                    (values.vehicleProvider.hasReserve ? (
                      <FormField>
                        <ReserveSelect
                          provider={values.vehicleProvider}
                          name="reserveVehicle"
                          value={values.reserveVehicle}
                          onChange={v => {
                            setFieldValue('reserveVehicle', v);
                            setFieldValue('vehicleCategory', { value: v.vehicleCategoryId });
                          }}
                          invalid={touched.reserveVehicle && errors.reserveVehicle}
                          errorI18nKey={errors.reserveVehicle}
                        />
                      </FormField>
                    ) : (
                      <FormField
                        label={t('vehiclechangeform.fields.vehiclecategory.label')}
                        required
                      >
                        <CategoryPicker
                          name="vehicleCategory"
                          value={values.vehicleCategory}
                          onChange={vehicleCategory => {
                            setFieldValue('vehicleCategory', vehicleCategory);
                            setFieldValue('reserveVehicle', null);
                          }}
                          onBlur={() => setFieldTouched('vehicleCategory', true)}
                          invalid={touched.vehicleCategory && errors.vehicleCategory}
                        />
                        <ValidationErrorMessage
                          invalid={touched.vehicleCategory && errors.vehicleCategory}
                          i18nKey={errors.vehicleCategory}
                        />
                      </FormField>
                    ))}
                  {values.vehicleProvider && (
                    <>
                      <FormField
                        label={t('vehiclechangeform.fields.registrationcanton.label')}
                        required
                      >
                        <CantonPicker
                          name="registrationCanton"
                          value={values.registrationCanton}
                          onChange={registrationCanton =>
                            setFieldValue('registrationCanton', registrationCanton)
                          }
                          onBlur={() => setFieldTouched('registrationCanton', true)}
                          invalid={touched.registrationCanton && errors.registrationCanton}
                        />
                        <ValidationErrorMessage
                          invalid={touched.registrationCanton && errors.registrationCanton}
                          i18nKey={errors.registrationCanton}
                        />
                      </FormField>
                      <FormField
                        label={t('vehiclechangeform.fields.deliverycanton.label')}
                        required
                      >
                        <CantonPicker
                          name="deliveryCanton"
                          value={values.deliveryCanton}
                          onChange={deliveryCanton =>
                            setFieldValue('deliveryCanton', deliveryCanton)
                          }
                          onBlur={() => setFieldTouched('deliveryCanton', true)}
                          invalid={touched.deliveryCanton && errors.deliveryCanton}
                        />
                        <ValidationErrorMessage
                          invalid={touched.deliveryCanton && errors.deliveryCanton}
                          i18nKey={errors.deliveryCanton}
                        />
                      </FormField>
                      <FormField
                        label={t('vehiclechangeform.fields.mileageperyear.label')}
                        required
                      >
                        <MileageInput
                          name="mileagePerYear"
                          hasMarginTop={false}
                          value={values.mileagePerYear}
                          onChange={value => setFieldValue('mileagePerYear', value)}
                          onBlur={() => setFieldTouched('mileagePerYear', true)}
                          invalid={touched.mileagePerYear && errors.mileagePerYear}
                        />
                        <ValidationErrorMessage
                          invalid={touched.mileagePerYear && errors.mileagePerYear}
                          i18nKey={errors.mileagePerYear}
                        />
                      </FormField>
                    </>
                  )}
                </div>
                <FormField label={t('vehiclechangeform.fields.dateofchange.label')} required>
                  <DatePicker
                    name="dateOfChange"
                    invalid={touched.dateOfChange && errors.dateOfChange}
                    value={values.dateOfChange}
                    options={{ minDate: startOfDay(new Date()) }}
                    onChange={date => setFieldValue('dateOfChange', date)}
                    onBlur={() => setFieldTouched('dateOfChange', true)}
                  />
                  <ValidationErrorMessage
                    invalid={touched.dateOfChange && errors.dateOfChange}
                    i18nKey={errors.dateOfChange}
                  />
                </FormField>

                <FormField label={t('vehiclechangeform.fields.leasingend.label')}>
                  <EndLeasingContractField
                    value={values.endLeasingContract}
                    onChange={value => setFieldValue('endLeasingContract', value === 'true')}
                  />
                  <ValidationErrorMessage
                    invalid={touched.endLeasingContract && errors.endLeasingContract}
                    i18nKey={errors.endLeasingContract}
                  />
                </FormField>

                <FormField label={t('common.labels.ordernumber')} htmlFor="orderNumber" required>
                  <Input
                    hasMarginTop={false}
                    invalid={touched.orderNumber && errors.orderNumber}
                    name="orderNumber"
                    onBlur={() => setFieldTouched('orderNumber')}
                    onChange={e => setFieldValue('orderNumber', e.currentTarget.value)}
                    value={values.orderNumber}
                  />
                  <ValidationErrorMessage
                    invalid={touched.orderNumber && errors.orderNumber}
                    i18nKey={errors.orderNumber}
                  />
                </FormField>
                <CommentField
                  value={values.comment}
                  onChange={v => setFieldValue('comment', v)}
                  onBlur={handleBlur}
                />
                {values.vehicleProvider && !values.vehicleProvider?.hasReserve && (
                  <MessageCard
                    type="important"
                    title={t('vehiclechangeform.noticeoftermination.title')}
                    className="margin-bottom-4"
                  >
                    <Trans i18nKey="vehiclechangeform.noticeoftermination.message" />
                  </MessageCard>
                )}



                <AutoId>{id => (<Checkbox className={styles.marginBottom} id={id} value={values.sendToProvider} onChange={v => setFieldValue('sendToProvider', v.target.checked)} >{t('driverchangeform.fields.sendtoprovider')}</Checkbox>)}</AutoId>

                <ButtonGroup responsive>
                  <Button
                    type="submit"
                    disabled={(submitCount > 0 && !isValid) || isSubmitting}
                    isLoading={isSubmitting}
                  >
                    {t('common.send')}
                  </Button>
                  {profile?.isFleetMember &&
                    <>
                      <Button
                        type="button"
                        onClick={() => handleSaveDraft(values)}
                        isLoading={isSubmitting}
                        color="confirm"
                      >{t('drafts.buttons.save')}</Button>
                      {!isNaN(draftId) && <Button
                        className="deleteDraftButton"
                        type="button"
                        onClick={() => removeDraft(values)}
                        isLoading={isSubmitting}
                      >{t('drafts.buttons.delete')}</Button>}
                    </>
                  }
                  <Button color="secondary" onClick={history.goBack} type="button">
                    {t('common.cancel')}
                  </Button>
                </ButtonGroup>
                <p>{t('formvalidation.required.descriptions')}</p>
              </form>
            );
          }}
        </Formik>}
      </Container>
    </>
  );
}

export default VehicleChangeFormFleet;
