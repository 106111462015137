// @flow
import { fetch } from './fetch';

import { type QueryFilters, parseQueryFilters } from '../util/QueryFilterHelper';
import { type AxiosResponse } from 'axios';
import { type BillingCycle } from '../types/billing-cycle-types';

export async function runBillingCycle(payload: any): Promise<AxiosResponse<Array<any>>> {
  return fetch<void>('/billing-cycle/init-cycle', 'POST', payload);
}

export async function findBillingCycle(id: number): Promise<AxiosResponse<BillingCycle>> {
  return fetch<BillingCycle[]>(`/billing-cycle/${id}`, 'GET', null);
}

export function getBillingCycleOverview(filter: QueryFilters): any {
  return fetch<BillingCycle[]>('/billing-cycle/', 'GET', null, {
    params: parseQueryFilters(filter),
  });
}

export async function deleteBillingCycle(id: number): Promise<AxiosResponse<void>> {
  return fetch<void>(`/billing-cycle/${id}`, 'DELETE', null);
}
