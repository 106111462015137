// @flow

import { useState, useEffect } from 'react';
import { type VehicleCategory } from '../types/vehicle-category-types';
import { getRawVehicleCategory } from '../services/vehicleCategoryService';
import i18n from '../i18n';

function useRawVehicleCategory(id: number) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [rawCategory, setRawtCategory] = useState<VehicleCategory | null>(null);

  useEffect(() => {
    let isCancelled = false;
    async function fetchCategory() {
      try {
        setIsLoading(true);
        const browserLanguage = i18n.language.substr(0, 2).toLowerCase();
        const { data: category } = await getRawVehicleCategory(id);
        const translatedCategory = {
          ...category,
          name: category.translations.find(elm => elm.languageCode === browserLanguage)?.name
        };
        if (!isCancelled) {
          setRawtCategory(translatedCategory);
          setIsLoading(false);
        }
      } catch (err) {
        if (!isCancelled) {
          setIsLoading(false);
          setHasError(true);
        }
        throw err;
      }
    }

    fetchCategory();

    return () => {
      isCancelled = true;
    };
  }, [id]);

  return { isLoading, rawCategory, hasError };
}

export default useRawVehicleCategory;
