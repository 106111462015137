import cx from 'classnames';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Truncate from 'react-truncate';
import Card from '../../components/Card';
import OpenFile from '../../components/OpenFile';
import { type VehicleCategory } from '../../types/vehicle-category-types';
import { formatPrice } from '../../util/PriceFormatHelper';
import styles from './CategoryCard.module.css';




type Props = {
  category: VehicleCategory,
  onClick: Function,
};

function CategoryCard({ category, onClick }: Props) {
  const { t } = useTranslation();
  return (
    <Card
      dark
      document={false}
      className={styles.card}
      color={category.deletedAt ? "blue" : "purple"}
      onClick={() => onClick(category.id)}
      role="button"
      tabIndex={0}
    >
      <h4 className="margin-bottom-1 flex flex-space-between flex-items-center">
        <span>
          {category.name}
          {category.interiorConstruction && (
            <>
              <br />
              {category.interiorConstruction?.name}
            </>
          )}
        </span>
        <span>
          <Trans values={{
            price: formatPrice(category.pricePerMonth, { includeCurrency: true, includeCentimes: true })
          }} i18nKey="common.pricepermonth" />
        </span>
      </h4>
      <div className={cx('flex flex-items-center', styles.cardContent)}>
        <p className="margin-bottom-1">
          <Truncate lines={3} ellipsis="...">
            {category.description}
          </Truncate>
        </p>
      </div>
      {category.descriptionFileLocation && (
        <OpenFile fileLocation={category.descriptionFileLocation} className="padding-top-1" />
      )}
      {category.deletedAt && <span className={styles.archived}>{t('common.archived')}</span>}
    </Card>
  );
}

export default CategoryCard;
