// @flow

import React from 'react';
import { Route, Switch } from 'react-router-dom';

import LoginRoute from './components/Auth/LoginRoute';
import ErrorBoundary from './components/ErrorBoundary';
import Header from './components/Header';

import Redirect from './components/Redirect/Redirect';

import config from './config';
import Login from './container/Auth/Login';
import LoginCallback from './container/Auth/LoginCallback';
import LoginRenewal from './container/Auth/LoginRenewal';
import BillingCycleRun from './container/BillingCycleRun';
import BillingCycleSuper from './container/BillingCycleSuper';
import ContractForm from './container/ContractForm';
import ContractOverview from './container/ContractOverview';
import ContractSuper from './container/ContractSuper';
import Dashboard from './container/Dashboard';
import DebtorCostcenterOverview from './container/DebtorCostcenter';
import DebtorForm from './container/DebtorForm';
import DebtorOverview from './container/DebtorOverview';
import DriverChangeForm from './container/DriverChangeForm';
import MediaDownload from './container/MediaDownload';
import MutationDetail from './container/MutationDetail';
import MutationOverview from './container/MutationOverview';
import ProviderOverview from './container/ProviderOverview';
import RequestVehicleForm from './container/RequestVehicleForm';
import ReturnVehicleForm from './container/ReturnVehicleForm';
import SelectVehicleCategory from './container/SelectVehicleCategory';
import VehicleCategoryAdmin from './container/VehicleCategoryAdmin';
import VehicleCategoryEdit from './container/VehicleCategoryEdit';
import VehicleChangeForm from './container/VehicleChangeForm';
import { generateDISLink } from './util/DISLink';

const App = () => {
  return (
    <ErrorBoundary>
      <Header />
      <Switch>
        <LoginRoute path="/" component={Dashboard} exact preventAccess={['provider']} />
        <LoginRoute
          path="/contracts/:id/driver-change"
          component={DriverChangeForm}
          exact
          preventAccess={['provider']}
        />
        <LoginRoute
          path="/contracts/:id/return-vehicle"
          component={ReturnVehicleForm}
          exact
          preventAccess={['provider']}
        />
        <LoginRoute
          path="/contracts/:id/vehicle-change"
          component={VehicleChangeForm}
          exact
          preventAccess={['provider']}
        />
        <LoginRoute
          path="/contracts/:id/edit"
          component={ContractForm}
          exact
          preventAccess={['non-admin']}
        />
        <LoginRoute
          path="/contracts/create"
          component={ContractForm}
          exact
          preventAccess={['non-admin']}
        />
        <LoginRoute
          path="/contracts/:id"
          component={ContractSuper}
          exact
          preventAccess={['non-admin']}
        />
        <LoginRoute
          path="/billing-cycle/:id"
          component={BillingCycleSuper}
          exact
          preventAccess={['non-admin']}
        />
        <LoginRoute
          path="/categories/:category"
          component={SelectVehicleCategory}
          exact
          preventAccess={['provider']}
        />
        <LoginRoute
          path="/request-vehicle"
          component={RequestVehicleForm}
          exact
          preventAccess={['provider']}
        />
        <LoginRoute path="/mutation-overview" component={MutationOverview} exact />
        <LoginRoute path="/mutations/:id" component={MutationDetail} exact />
        <LoginRoute path="/media/:bucketName/:fileName" component={MediaDownload} exact />
        <LoginRoute
          path="/contracts"
          component={ContractOverview}
          exact
          preventAccess={['non-admin']}
        />
        <LoginRoute
          path="/billing/create-contract"
          component={ContractForm}
          exact
          preventAccess={['non-admin']}
        />
        <LoginRoute
          path="/provider"
          component={ProviderOverview}
          exact
          preventAccess={['non-admin']}
        />
        <LoginRoute
          path="/billing/run-billing"
          component={BillingCycleRun}
          exact
          preventAccess={['non-admin']}
        />
        <LoginRoute
          path="/debtors"
          exact
          component={DebtorOverview}
          preventAccess={['non-admin']}
        />
        <LoginRoute
          path="/debtor/create"
          exact
          component={DebtorForm}
          preventAccess={['non-admin']}
        />
        <LoginRoute
          path="/debtor/edit/:id"
          exact
          component={DebtorForm}
          preventAccess={['non-admin']}
        />
        <LoginRoute
          path="/vehiclecategoryedit"
          component={VehicleCategoryAdmin}
          exact
          preventAccess={['provider']}
        />
        <LoginRoute
          path="/vehiclecategoryedit/:id"
          component={VehicleCategoryEdit}
          exact
          preventAccess={['provider']}
        />
        <LoginRoute
          path="/debtorcostcenter"
          exact
          component={DebtorCostcenterOverview}
          preventAccess={['non-admin']}
        />
        <Route
          path={`/${config.replacementPath}`}
          component={() => <Redirect redirectRoute={generateDISLink(config.replacementPath)} />}
          exact
        />
        <Route
          path={`/${config.vehicleResponsiblePath}`}
          component={() => (
            <Redirect redirectRoute={generateDISLink(config.vehicleResponsiblePath)} />
          )}
          exact
        />
        <Route
          path={`/${config.replacementDetailPath}/:id`}
          component={props => (
            <Redirect
              redirectRoute={generateDISLink(
                `${config.replacementDetailPath}/${props.match.params.id}`,
              )}
            />
          )}
          exact
        />
        <Route path="/auth/login" component={Login} exact />
        <Route path="/auth/callback" component={LoginCallback} exact />
        <Route path="/auth/renewal" component={LoginRenewal} exact />
      </Switch>
    </ErrorBoundary>
  );
};

export default App;
