// @flow

import { useState, useEffect, useCallback, useMemo } from 'react';
import debounce from 'debounce-promise';

import { type BillingCycle } from '../types/billing-cycle-types';
import { getBillingCycleOverview } from '../services/billingCycleService';
import { type QueryFilters } from '../util/QueryFilterHelper';
import { OrderDirections } from '../types/order-types.js';

import usePagination from './usePagination.js';

const defaultFilter = {
  sort: {
    invoicingContractStartDate: OrderDirections.DESC,
  },
};

const PAGE_SIZE = 10;

const debouncedFetch = debounce(getBillingCycleOverview, 350);

function useBillingCycles(filter?: QueryFilters, initialPage?: number) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [billingCycles, setbillingCycles] = useState<BillingCycle[]>([]);
  const { page, setPagination, pagination, setPage } = usePagination(initialPage || 0, PAGE_SIZE);

  const billingCycleFilter = useMemo(
    () => ({
      ...defaultFilter,
      ...filter,
    }),
    [filter],
  );

  const fetchBillingCycles = useCallback(async () => {
    try {
      const skip = page ? page * PAGE_SIZE : 0;
      setHasError(false);
      setIsLoading(true);
      const { data: paginatedResponse } = await debouncedFetch({
        ...billingCycleFilter,
        skip,
        take: PAGE_SIZE,
      });
      setbillingCycles(paginatedResponse.data);
      const { data, ...paginationProperties } = paginatedResponse;
      setPagination(paginationProperties);
      setIsLoading(false);
    } catch (err) {
      setHasError(true);
      setIsLoading(false);
      throw err;
    }
  }, [page, setPagination, billingCycleFilter]);

  const loadMore = (page: { selected: number }) => {
    setPage(page.selected);
  };

  useEffect(() => {
    fetchBillingCycles();
  }, [fetchBillingCycles]);

  return { isLoading, billingCycles, hasError, loadMore, pagination, setPage };
}

export default useBillingCycles;
