// @flow

import { fetch } from './fetch';
import { type User, type UpdateUserPayload } from '../types/user-types';
import { type AxiosResponse } from 'axios';

const userByEmployeeNrCache = {};

export function getProfile(): Promise<AxiosResponse<User>> {
  return fetch<User>('/users/me', 'GET');
}

export async function getUserByEmployeeNr(
  employeeNr: string,
  useCache: boolean = false,
): Promise<AxiosResponse<User>> {
  const lowerEmployeeNr = employeeNr.toLowerCase();
  if (useCache && userByEmployeeNrCache[lowerEmployeeNr]) {
    return userByEmployeeNrCache[lowerEmployeeNr];
  }
  const { data } = await fetch<User>(`/users/${employeeNr}`, 'GET');
  if (useCache) {
    userByEmployeeNrCache[lowerEmployeeNr] = data;
  }
  return data;
}

export function findUsers(query: string): Promise<AxiosResponse<User[]>> {
  return fetch<User[]>('/users', 'GET', null, {
    params: {
      q: query,
    },
  });
}

export function updateProfile(id: number, payload: UpdateUserPayload): Promise<AxiosResponse<User>> {
  return fetch<User>('/users', 'PUT', payload);
}
