// @flow

import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import {
  type ContractMutation,
  type ReturnVehicleMutation,
} from '../../../types/contract-mutation-types';

import { CardText, CardItem } from '../../../components/Card';
import ProfileLink from '../../../components/ProfileLink';
import PhoneLink from '../../../components/PhoneLink';

import { InformationCard, InformationCardItem } from '../common';
import Comments from '../common/Comments';
import VehicleName from '../../../components/VehicleName/VehicleName';
import FormattedDate from '../../../components/FormattedDate';
import { getFirstPossibleTerminationDate } from '../../../util/MutationProperties';
import { isAfter } from 'date-fns';

type Props = {
  mutation: ContractMutation,
  returnVehicleMutation: ReturnVehicleMutation,
};

function ReturnVehicleInformation({ mutation, returnVehicleMutation }: Props) {
  const { t } = useTranslation();

  let firstPossibleTerminationDate = '';
  if (mutation.contract) {
    firstPossibleTerminationDate = getFirstPossibleTerminationDate(
      mutation.contract.vehicle.vehicleCategory.type,
      returnVehicleMutation.createdAt,
    );
  }
  return (
    <InformationCard color="iris">
      <CardItem>
        {mutation.contract && <CardText>
          <ProfileLink user={mutation.contract.contractor} />
        </CardText>}
        {mutation.contract && <CardText>
          <PhoneLink user={mutation.contract.contractor} />
        </CardText>}
      </CardItem>
      <CardItem>
        <InformationCardItem label={t('returnvehicledetail.labels.vehicle')}>
          {mutation.contract && <VehicleName vehicle={mutation.contract.vehicle} />}
        </InformationCardItem>
        {mutation.contract && <InformationCardItem label={t('returnvehicledetail.labels.snumber')}>
          {mutation.contract.vehicle.sNumber}
        </InformationCardItem>}
        {mutation.contract && <InformationCardItem label={t('returnvehicledetail.labels.platenumber')}>
          {mutation.contract.vehicle.activeLicensePlate}
        </InformationCardItem>}
        <InformationCardItem label={t('returnvehicledetail.labels.cancellationperiod')}>
          {mutation.dateOfExecution && <FormattedDate date={isAfter(new Date(mutation.dateOfExecution), new Date(firstPossibleTerminationDate)) ? mutation.dateOfExecution : firstPossibleTerminationDate} />}
        </InformationCardItem>
        <InformationCardItem label={t('returnvehicledetail.labels.requestedreturndate')}>
          <FormattedDate date={mutation.dateOfExecution} />
        </InformationCardItem>
        <InformationCardItem label={t('returnvehicledetail.labels.returncanton')}>
          {mutation.returnVehicleMutation?.cantonOfReturn && (
            <Trans i18nKey={`cantons.${mutation.returnVehicleMutation?.cantonOfReturn || ''}`} />
          )}
        </InformationCardItem>
      </CardItem>
      <Comments contractMutationId={mutation.id} />
    </InformationCard>
  );
}

export default ReturnVehicleInformation;
