// @flow

import { type AxiosResponse } from 'axios';

import { fetch } from './fetch';
import { type BillingCycle } from '../types/billing-cycle-types';

export function findConflictedBillingCycle(billingDate: string): Promise<AxiosResponse<BillingCycle>> {
  return fetch<BillingCycle>('/billing-cycle/conflicting-billing-cycle', 'POST', { billingDate });
}

export async function exportBillsForBillCycle(billingCycleId: number): Promise<any> {
  return fetch<any>(`/billing-cycle/${billingCycleId}/export`, 'POST', {}, {
    responseType: 'arraybuffer'
  });
}
