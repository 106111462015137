// @flow

import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import format from 'date-fns/format';
import cx from 'classnames';

import { type VehicleProvider } from '../../types/vehicle-provider-types';
import Input from '../Input';
import { type ReserveVehicle } from '../../types/vehicle-types';
import useReserveVehicles from '../../hooks/useReserveVehicles';

import styles from './ReserveSelect.module.css';
import FormField from '../FormField';
import useQueryFilters from '../../hooks/useQueryFilters';
import SortableTableHead from '../SortableTableHead';
import { QueryComparators } from '../../types/query-comparator-types';
import Icon from '../Icon';
import ValidationErrorMessage from '../ValidationErrorMessage';
import { formatCategoryName } from '../../util/MutationProperties';

type ReserveListItemProps = {
  vehicle: ReserveVehicle,
  selected: boolean,
  onChange: Function,
  preselectVehicleId?: number | null
};
function ReserveListItem({ vehicle, selected, onChange, preselectVehicleId }: ReserveListItemProps) {
  const onVehicleSelected = (vehicle: ReserveVehicle) => {
    // Don't trigger form value change when blocked vehicle is chosen
    if (vehicle.isBlockedByMutation) {
      return;
    }
    onChange(vehicle);
  };
  return (
    <tr
      onClick={() => onVehicleSelected(vehicle)}
      className={cx(styles.vehiclelist, {
        [styles.active]: selected,
        [styles.disabled]: vehicle.isBlockedByMutation,
      })}
    >
      <td>{vehicle.sNumber}</td>
      <td>{vehicle.manufacturerName || '-'}</td>
      <td>{vehicle.baseModelDisplayName}</td>
      <td>{format(new Date(vehicle.immatriculation), 'dd.MM.yyyy')}</td>
      <td>{vehicle.vehicleCategory ? formatCategoryName(vehicle.vehicleCategory) : '-'}</td>
      {preselectVehicleId !== vehicle.id && <td>
        <Trans
          i18nKey={`common.vehicleproperties.status.${vehicle.isBlockedByMutation ? 'blockedByPendingMutation' : vehicle.status.toLowerCase()
          }`}
        ></Trans>
      </td>}
      {preselectVehicleId === vehicle.id && <td>
        <Trans
          i18nKey={'common.vehicleproperties.status.preselected'}
        ></Trans>
      </td>}
      <td>{selected && <Icon name="011-check-mark" className={styles.selectedIcon} />}</td>
    </tr>
  );
}

type Props = {
  provider: VehicleProvider,
  value: any,
  onChange: Function,
  invalid: boolean,
  errorI18nKey: string,
  preselectVehicleId?: number | null
};

function ReserveSelect({ provider, value, onChange, invalid, errorI18nKey, preselectVehicleId }: Props) {
  const { t } = useTranslation();
  const { filter, setExclusiveSort, setSearchQuery } = useQueryFilters({
    hardFilter: {
      vehicleProviderId: {
        value: provider.id,
        comparator: QueryComparators.EQUAL,
      },
      status: {
        value: 'RENTED',
        comparator: QueryComparators.NOT_EQUAL,
      },
    },
    take: 10,
  });
  const { vehicles } = useReserveVehicles(filter);

  return (
    <div className="margin-bottom-2">
      <h4 className="margin-bottom-1">
        {t('vehiclechangeform.fields.reserveSelection.label', { provider: provider.name })}
      </h4>
      <div className="row">
        <div className="col-md-12">
          <FormField label={t('vehiclechangeform.fields.reserveSelection.search.placeholder')}>
            <Input
              id="search"
              value={filter.query}
              onChange={evt => setSearchQuery(evt.currentTarget.value)}
              hasMarginTop={false}
            />
          </FormField>
        </div>
      </div>
      <div className="row">
        <div className={`table table--responsive ${styles.vehiclelist}`}>
          <div className="table__wrapper">
            <table>
              <thead>
                <tr>
                  <td>
                    <SortableTableHead
                      i18nKey="common.labels.snumber"
                      sortProperties={['sNumber']}
                      sortFilters={filter?.sort}
                      setSort={setExclusiveSort}
                    />
                  </td>
                  <td>
                    <SortableTableHead
                      i18nKey="common.vehicleproperties.manufacturer"
                      sortProperties={['avriosManufacturerName']}
                      sortFilters={filter?.sort}
                      setSort={setExclusiveSort}
                    />
                  </td>
                  <td>
                    <SortableTableHead
                      i18nKey="common.vehicleproperties.model"
                      sortProperties={['avriosBaseModelDisplayName']}
                      sortFilters={filter?.sort}
                      setSort={setExclusiveSort}
                    />
                  </td>
                  <td>
                    <SortableTableHead
                      i18nKey="common.vehicleproperties.immatriculationDate"
                      sortProperties={['immatriculation']}
                      sortFilters={filter?.sort}
                      setSort={setExclusiveSort}
                    />
                  </td>
                  <td>
                    <Trans i18nKey="common.labels.vehiclecategory"></Trans>
                  </td>
                  <td>Status</td>
                  <td style={{ width: 50 }}></td>
                </tr>
              </thead>

              <tbody>
                {vehicles.map(v => (
                  <ReserveListItem
                    key={v.id}
                    vehicle={v}
                    selected={value?.id === v.id}
                    onChange={onChange}
                    preselectVehicleId={preselectVehicleId}
                  />
                ))}
              </tbody>
            </table>
            <div style={{ marginLeft: 12 }}>
              <ValidationErrorMessage invalid={invalid} i18nKey={errorI18nKey} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReserveSelect;
