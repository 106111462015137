import React from 'react';
import Select from '../../components/Select';
import { useTranslation } from 'react-i18next';

function ContractMutationTypeSelect({ value, onChange, id, className }) {
  const { t } = useTranslation();

  const options = [
    {
      value: 'REQUESTVEHICLE',
      label: t('mutationtypes.requestvehicle'),
    },
    {
      value: 'DRIVERCHANGE',
      label: t('mutationtypes.driverchange'),
    },
    {
      value: 'RETURNVEHICLE',
      label: t('mutationtypes.returnvehicle'),
    },
    {
      value: 'VEHICLECHANGE',
      label: t('mutationtypes.vehiclechange'),
    },
  ];

  return (
    <Select
      placeholder={t('mutationoverview.filter.ordertype.placeholder')}
      value={value}
      onChange={onChange}
      options={options}
      inputId={id}
      className={className}
    />
  );
}

export default ContractMutationTypeSelect;
