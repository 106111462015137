import * as Yup from 'yup';
import startOfDay from 'date-fns/startOfDay';
import addWeeks from 'date-fns/addWeeks';

const validationObjects = {
  registrationCanton: Yup.object()
    .required('formvalidation.required.message')
    .nullable(),
  deliveryCanton: Yup.object()
    .required('formvalidation.required.message')
    .nullable(),
  mileagePerYear: Yup.object()
    .required('formvalidation.required.message')
    .nullable(),
  dateOfChange: Yup.date()
    .min(startOfDay(addWeeks(new Date(), 3)), 'formvalidation.date.min')
    .required('formvalidation.required.message')
    .nullable(),
  vehicleProvider: Yup.object()
    .required('formvalidation.required.message')
    .nullable(),
  comment: Yup.string(),
  sendToProivder: Yup.boolean().default(true),
  orderNumber: Yup.string()
    .required('formvalidation.required.message')
};

const fleetValidationObjects = {
  dateOfChange: Yup.date()
    .min(startOfDay(new Date()), 'formvalidation.date.min')
    .required('formvalidation.required.message')
    .nullable(),
};

export const validationSchema = Yup.object().shape(validationObjects);

export const validationSchemaFleetMember = Yup.object().shape({...validationObjects, ...fleetValidationObjects});
