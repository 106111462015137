import * as Yup from 'yup';
import startOfDay from 'date-fns/fp/startOfDay';
import { addWeeks } from 'date-fns';

export const validationSchema = Yup.object().shape({
  dateOfReturn: Yup.date()
    .min(startOfDay(addWeeks(new Date(), 3)), 'formvalidation.date.min')
    .required('formvalidation.required.message')
    .nullable(),
  cantonOfReturn: Yup.object()
    .required('formvalidation.required.message')
    .nullable(),
  comment: Yup.string(),
  sendToProvider: Yup.boolean().default(false)
});
