// @flow

import React, { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useParams } from 'react-router-dom';
import cx from 'classnames';
import { format, parseISO } from 'date-fns';

import useBillingCycle from '../../hooks/useBillingCycle';
import { exportBillsForBillCycle } from '../../services/billingService';

import styles from './BillingCycleDetail.module.css';
import LoadingSpinner from '../../components/LoadingSpinner';
import tableStyles from '../../container/ContractOverview/ContractTable.module.css';
import EmptyState from '../../components/EmptyState/EmptyState';
import ErrorState from '../../components/ErrorState/ErrorState';
import Button from '../../components/Button';
import * as download from 'downloadjs';
import { formatPrice } from '../../util/PriceFormatHelper';
import ConfirmationModal from '../../components/ConfirmationModal';
import { useHistory } from 'react-router-dom';
import { useNotifications } from '../../components/Notifications/NotificationsProvider';
import { deleteBillingCycle } from '../../services/billingCycleService';


const formatDate = val => (!!val ? format(parseISO(val.toString()), 'dd.MM.yyyy') : '-');

function BillingCycleDetail() {
  const history = useHistory();
  const [showModal, setShowModal] = useState<boolean>(false);
  const { addAlert, addSuccess } = useNotifications();

  const { t } = useTranslation();
  const { id: billingId } = useParams();
  const { billingCycle, isLoading, hasError } = useBillingCycle(billingId);

  const properties = [
    {
      labelI18nKey: 'common.billing.id',
      value: billingCycle?.id,
    },
    {
      labelI18nKey: 'common.billing.status',
      value: billingCycle?.status,
    },
    {
      labelI18nKey: 'common.billing.creator',
      value: `${billingCycle?.creator.firstname || ''} ${billingCycle?.creator.lastname || ''} ${billingCycle?.creator.unit || ''
      }`,
    },
    {
      labelI18nKey: 'common.billing.timestamp',
      value: formatDate(billingCycle?.createdAt),
    },
    {
      labelI18nKey: 'common.billing.price',
      value: billingCycle?.status !== "PENDING" ? formatPrice(billingCycle?.calculatedPrice, { includeCurrency: true, includeCentimes: true }) : ''
    },
  ];

  const exportBills = async () => {
    const exportBillsData = await exportBillsForBillCycle(billingId);
    download(
      exportBillsData.data,
      exportBillsData.headers['content-disposition'],
      exportBillsData.headers['content-type']
    );
  };

  const handleDelete = async () => {
    try {
      await deleteBillingCycle(billingId);
      addSuccess(t('billingcyclemodal.submit.success'));
      history.push('/contracts');
    } catch (err) {
      const errorCode = err?.response?.data?.error;
      addAlert(t(errorCode === 'CONFLICT_BILLING_CYCLE_TOO_OLD' ? 'billingcyclemodal.submit.failureold' : 'billingcyclemodal.submit.failure'));
      window.scrollTo(0, 0);
    }
  };

  return (
    <>
      {showModal && (
        <ConfirmationModal title={t('billingcyclemodal.title')} onClose={() => setShowModal(false)} handleSubmit={() => handleDelete()} />
      )}
      <div className={styles.header}>
        <h1>
          <Trans i18nKey="common.billing.title" values={{ billingCycleId: billingCycle?.name }} />
        </h1>
        <div className={styles.buttonContainer}>
          <Button onClick={() => exportBills()}
            icon="download"
            className={cx(styles.headerButton, 'margin-right-1 padding-left-2')}>
            {t('common.billing.export')}
          </Button>
          {!hasError && (
            <Button
              icon="bin"
              onClick={() => setShowModal(true)}
              className={cx(styles.headerButton, 'padding-left-2')}
            >
              {t('billingcycledetail.deletebillingcycle')}
            </Button>
          )}
        </div>
      </div>
      {
        isLoading && (
          <div className="flex flex-center">
            <div colSpan="12">
              <div className={tableStyles.empty__outer}>
                <LoadingSpinner />
              </div>
            </div>
          </div>
        )
      }
      {
        !isLoading && !hasError && !billingCycle?.id && (
          <div className="flex flex-center">
            <div colSpan="12">
              <div className={tableStyles.empty__outer}>
                <EmptyState />
              </div>
            </div>
          </div>
        )
      }
      {
        !isLoading && hasError && (
          <div className="flex flex-center">
            <div colSpan="12">
              <div className={tableStyles.empty__outer}>
                <ErrorState />
              </div>
            </div>
          </div>
        )
      }
      {
        !isLoading && !hasError && (
          <div className={cx(styles.propertyWrapper, 'margin-bottom-3')}>
            {properties.map((billingCycleProperty, i) => (
              <div className="flex flex-space-between" key={i}>
                <span>{t(billingCycleProperty.labelI18nKey)}</span>{' '}
                <p>{billingCycleProperty.value}</p>
              </div>
            ))}
          </div>
        )
      }
    </>
  );
}

export default BillingCycleDetail;
