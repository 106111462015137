// @flow

import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import RadioTabs from '../RadioTabs';

type Props = {
  name: string,
  onChange: Function,
  selected: String,
  onBlur?: Function,
  value?: string,
  label?: string,
  className?: string,
  placeholderI18nKey?: string,
};

function DebtorTypeSelect({
  value,
  name,
  selected,
  onBlur,
  onChange,
  label,
  className,
  placeholderI18nKey = 'common.select',
}: Props) {
  const { t } = useTranslation();
  const types = t('debtorform.fields.debtortype.types', { returnObjects: true });
  const options = Object.keys(types).map(key => ({
    value: key,
    label: types[key],
  }));

  return (
    <Fragment>
      <RadioTabs
        name={name}
        onChange={e => {
          onChange(options.find(p => p.value === e)?.value);
        }}
        value={value}
        selected={selected}
        inline={true}
        children={options}
      />
    </Fragment>
  );
}

export default DebtorTypeSelect;
