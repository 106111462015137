// @flow

export const QueryComparators: {
  EQUAL: 'eq',
  NOT_EQUAL: 'neq',
  GREATER_THAN: 'gt',
  LOWER_THAN: 'lt',
} = {
  EQUAL: 'eq',
  NOT_EQUAL: 'neq',
  GREATER_THAN: 'gt',
  LOWER_THAN: 'lt',
};

export type QueryComparator = 'eq' | 'neq' | 'gt' | 'lt';
