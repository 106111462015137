import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  name: Yup.string().required('formvalidation.required.message'),
  number: Yup.number()
    .required('formvalidation.required.message')
    .typeError('formvalidation.required.wrongtype'),
  revenueAccountNumber: Yup.string().required('formvalidation.required.message'),
  debtorType: Yup.string().required('formvalidation.required.message').nullable(),
  status: Yup.string().required('formvalidation.required.message'),
});
