// @flow

import cx from 'classnames';
import { addMonths } from 'date-fns';
import startOfDay from 'date-fns/fp/startOfDay';
import { Formik } from 'formik';
import isEqual from 'lodash/fp/isEqual';
import React, { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import AcceptDriverConditions from '../../components/AcceptDriverConditions';
import { AuthContext } from '../../components/AuthProvider/AuthProvider';
import AutoId from '../../components/AutoId';
import BackMenu from '../../components/BackMenu';
import Button from '../../components/Button/Button';
import ButtonGroup from '../../components/ButtonGroup/ButtonGroup';
import CantonPicker from '../../components/CantonPicker';
import Card, { CardText } from '../../components/Card';
import CommentField from '../../components/CommentField';
import Container from '../../components/Container';
import DatePicker from '../../components/DatePicker/DatePicker';
import DocumentLink from '../../components/DocumentLink';
import FormField from '../../components/FormField';
import Icon from '../../components/Icon';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import MessageCard from '../../components/MessageCard/MessageCard';
import MileageInput from '../../components/MileageInput';
import { useNotifications } from '../../components/Notifications/NotificationsProvider';
import RadioButton from '../../components/RadioButton/RadioButton';
import RadioButtonGroup from '../../components/RadioButtonGroup/RadioButtonGroup';
import RoutingPrompt from '../../components/RoutingPrompt';
import ValidationErrorMessage from '../../components/ValidationErrorMessage';
import useQueryParams from '../../hooks/useQueryParams';
import useVehicleCategory from '../../hooks/useVehicleCategory';
import i18n, { LANGUAGES } from '../../i18n';
import { getMutation, updateContractMutation } from '../../services/contractMutationService';
import {
  deleteRequestVehicleDraft,
  requestVehicle,
  saveRequestVehicleDraft,
} from '../../services/contractService';
import styles from './RequestVehicleForm.module.css';
import RequestVehicleInformation from './RequestVehicleInformation';
import SelectVehicleDriver from './SelectVehicleDriver';
import documents from './documents.json';
import { initialValues } from './initial-values';
import { validationSchema } from './validation-schema';

function RequestVehicleForm() {
  const { t } = useTranslation();
  const history = useHistory();
  const vehicleCategoryId = +useQueryParams('categoryId');
  const draftId = +useQueryParams('draftId');
  const { fetchProfile, profile } = useContext(AuthContext);
  const { addSuccess, addAlert } = useNotifications();
  const [hasSuccess, setHasSuccess] = useState(false);
  const [otherDriver, setOtherDriver] = useState(null);
  const { category, isLoading, hasError } = useVehicleCategory(vehicleCategoryId);
  const [formInitialValues, setFormInitialValues] = useState();
  const [showPrompt, setShowPrompt] = useState<boolean>(true);

  useEffect(() => {
    if (!draftId) {
      setFormInitialValues(initialValues);
      return;
    }
    const getDraftMutation = async () => {
      const cantons = t('cantons', { returnObjects: true });
      const { data: initialValues } = await getMutation(draftId);
      const mappedInitialValues = {
        ...initialValues,
        mileagePerYear: initialValues.requestVehicleMutation.mileagePerYear
          ? {
            value: initialValues.requestVehicleMutation.mileagePerYear,
            label: initialValues.requestVehicleMutation.mileagePerYear,
          }
          : null,
        deliveryCanton: initialValues.requestVehicleMutation.deliveryCanton
          ? {
            value: initialValues.requestVehicleMutation.deliveryCanton,
            label: cantons[initialValues.requestVehicleMutation.deliveryCanton],
          }
          : null,
        registrationCanton: initialValues.requestVehicleMutation.registrationCanton
          ? {
            value: initialValues.requestVehicleMutation.registrationCanton,
            label: cantons[initialValues.requestVehicleMutation.registrationCanton],
          }
          : null,
        driverType: initialValues.requestVehicleMutation.driverType,
        driver: initialValues.requestVehicleMutation.driver
          ? {
            label: `${initialValues.requestVehicleMutation.driver.lastname} ${initialValues.requestVehicleMutation.driver.firstname}, ${initialValues.requestVehicleMutation.driver.unit}`,
            value: initialValues.requestVehicleMutation.driver.swisscomEmployeeNr,
            user: initialValues.requestVehicleMutation.driver,
          }
          : null,
        comment: initialValues.comment ? initialValues.comment : '',
        rentalStart: initialValues.requestedDateOfExecution,
        acceptDriverConditions: false,
      };
      setFormInitialValues(mappedInitialValues);
    };
    getDraftMutation();
  }, [draftId, t]);

  const driverIsCablex = otherDriver
    ? otherDriver.company.toLowerCase().includes('cablex')
    : (profile?.company || '').toLowerCase().includes('cablex');

  const requireLabelingLanguage = category?.type === 'utilityvehicle' && driverIsCablex;

  async function handleSubmit(values, actions) {
    try {
      const payload = {
        rentalStart: values.rentalStart,
        registrationCanton: values.registrationCanton.value,
        deliveryCanton: values.deliveryCanton.value,
        mileagePerYear: values.mileagePerYear.value,
        driverEmployeeNr: values.driver ? values.driver.value : undefined,
        driverType: values.driverType,
        comment: values.comment,
        acceptDriverConditions: values.acceptDriverConditions,
        vehicleCategoryId,
        labelingLanguage: requireLabelingLanguage ? values.labelingLanguage : undefined,
        draftId,
      };
      await requestVehicle(payload);
      fetchProfile();
      addSuccess(t('requestvehicleform.submit.success'));
      setHasSuccess(true);
      history.push('/');
    } catch (err) {
      actions.setSubmitting(false);
      addAlert(t('requestvehicleform.submit.failure'));
      window.scrollTo(0, 0);
      throw err;
    }
  }

  const handleSaveDraft = async values => {
    try {
      const payload = {
        ...values,
        rentalStart: values.rentalStart,
        requestedDateOfExecution: values.rentalStart,
        registrationCanton: values.registrationCanton ? values.registrationCanton.value : null,
        deliveryCanton: values.deliveryCanton ? values.deliveryCanton.value : null,
        mileagePerYear: values.mileagePerYear ? values.mileagePerYear.value : null,
        driverEmployeeNr: values.driver ? values.driver.value : undefined,
        acceptDriverConditions: false,
        driverType: values.driverType,
        comment: values.comment,
        vehicleCategoryId,
        labelingLanguage: requireLabelingLanguage ? values.labelingLanguage : undefined,
        driver: values.driver,
      };

      if (hasValue(payload)) {
        if (draftId) {
          await updateContractMutation(draftId, payload);
          addSuccess(t('drafts.updatedraft'));
        } else {
          await saveRequestVehicleDraft(payload);
          addSuccess(t('drafts.savedraft'));
        }
        setShowPrompt(false);
        history.push('/');
      } else {
        addAlert(t('drafts.emptydraft'));
      }
    } catch (err) {
      addAlert(t('drafts.failure'));
      throw err;
    }
    window.scrollTo(0, 0);
  };

  const removeDraft = async values => {
    try {
      await deleteRequestVehicleDraft(draftId, values.requestVehicleMutation.id);
      addSuccess(t('drafts.deletedraft'));
      setShowPrompt(false);
      history.push('/');
    } catch (err) {
      addAlert(t('drafts.faildeletedraft'));
      throw err;
    }
    window.scrollTo(0, 0);
  };

  const hasValue = payload => {
    return (
      payload.rentalStart ||
      payload.registrationCanton ||
      payload.deliveryCanton ||
      payload.mileagePerYear ||
      payload.driverEmployeeNr ||
      payload.comment ||
      payload.driver
    );
  };

  if (!category?.type) {
    return (
      <div className="flex flex-center" style={{ height: '100%' }}>
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <>
      <BackMenu to="/" text={t('requestvehicleform.back')} />
      <Container>
        <RequestVehicleInformation category={category} hasError={hasError} isLoading={isLoading} />
        {formInitialValues && (
          <Formik
            initialValues={formInitialValues}
            validationSchema={validationSchema({
              requireLabelingLanguage,
              category,
            })}
            onSubmit={handleSubmit}
          >
            {({
              values,
              touched,
              errors,
              handleSubmit,
              setFieldValue,
              setFieldTouched,
              handleChange,
              handleBlur,
              submitCount,
              isSubmitting,
              isValid,
            }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <RoutingPrompt
                    when={!hasSuccess && !isEqual(initialValues, values) && showPrompt}
                    message={t('common.formprompt')}
                  />
                  <h1>
                    <Trans i18nKey="requestvehicleform.title"></Trans>
                  </h1>
                  <div className={styles.section}>
                    <FormField
                      label={
                        category?.type === 'utilityvehicle'
                          ? t('requestvehicleform.fields.rentalstart.labelutilityvehicle')
                          : t('requestvehicleform.fields.rentalstart.label')
                      }
                      required
                    >
                      <DatePicker
                        name="rentalStart"
                        invalid={touched.rentalStart && errors.rentalStart}
                        value={values.rentalStart}
                        onChange={rentalStart => setFieldValue('rentalStart', rentalStart)}
                        onBlur={() => setFieldTouched('rentalStart', true)}
                        options={{
                          minDate: startOfDay(
                            profile?.isFleetMember
                              ? new Date()
                              : category?.type === 'utilityvehicle'
                                ? addMonths(new Date(), 14)
                                : addMonths(new Date(), 4),
                          ),
                        }}
                      />
                      <ValidationErrorMessage
                        invalid={touched.rentalStart && errors.rentalStart}
                        i18nKey={errors.rentalStart}
                      />
                    </FormField>
                    <FormField
                      label={t('requestvehicleform.fields.registrationcanton.label')}
                      required
                    >
                      <CantonPicker
                        name="registrationCanton"
                        value={values.registrationCanton}
                        onChange={registrationCanton =>
                          setFieldValue('registrationCanton', registrationCanton)
                        }
                        onBlur={() => setFieldTouched('registrationCanton', true)}
                        invalid={touched.registrationCanton && errors.registrationCanton}
                      />
                      <ValidationErrorMessage
                        invalid={touched.registrationCanton && errors.registrationCanton}
                        i18nKey={errors.registrationCanton}
                      />
                    </FormField>
                    <FormField label={t('requestvehicleform.fields.deliverycanton.label')} required>
                      <CantonPicker
                        name="deliveryCanton"
                        value={values.deliveryCanton}
                        onChange={deliveryCanton => setFieldValue('deliveryCanton', deliveryCanton)}
                        onBlur={() => setFieldTouched('deliveryCanton', true)}
                        invalid={touched.deliveryCanton && errors.deliveryCanton}
                      />
                      <ValidationErrorMessage
                        invalid={touched.deliveryCanton && errors.deliveryCanton}
                        i18nKey={errors.deliveryCanton}
                      />
                    </FormField>
                    <FormField label={t('requestvehicleform.fields.mileageperyear.label')} required>
                      <MileageInput
                        name="mileagePerYear"
                        hasMarginTop={false}
                        value={values.mileagePerYear}
                        onChange={value => setFieldValue('mileagePerYear', value)}
                        onBlur={() => setFieldTouched('mileagePerYear', true)}
                        invalid={touched.mileagePerYear && errors.mileagePerYear}
                      />
                      <ValidationErrorMessage
                        invalid={touched.mileagePerYear && errors.mileagePerYear}
                        i18nKey={errors.mileagePerYear}
                      />
                    </FormField>

                    {requireLabelingLanguage && (
                      <FormField
                        label={t('requestvehicleform.fields.labelinglanguage.label')}
                        required
                      >
                        <RadioButtonGroup
                          value={values.labelingLanguage}
                          onChange={val => setFieldValue('labelingLanguage', val)}
                        >
                          {LANGUAGES.map(lang => (
                            <RadioButton value={lang} key={lang}>
                              <Trans i18nKey={`common.languages.${lang}`} />
                            </RadioButton>
                          ))}
                        </RadioButtonGroup>
                        <ValidationErrorMessage
                          invalid={
                            Boolean(errors.labelingLanguage) && Boolean(touched.labelingLanguage)
                          }
                          i18nKey={errors.labelingLanguage}
                        />
                      </FormField>
                    )}
                  </div>
                  <div className={styles.section}>
                    <h3>
                      <Trans i18nKey="common.labels.driver" />
                    </h3>
                    <SelectVehicleDriver
                      values={values}
                      setFieldValue={(field, value) => {
                        if (field !== 'driver') {
                          setFieldValue('driver', null);
                        }
                        if (field === 'driver' && values.driverType === 'otherDriver') {
                          setOtherDriver(value.user);
                        } else {
                          setOtherDriver(null);
                        }
                        return setFieldValue(field, value);
                      }}
                      setFieldTouched={setFieldTouched}
                      errors={errors}
                      touched={touched}
                    />
                  </div>
                  <div className={styles.section}>
                    <h3>
                      <Trans i18nKey="requestvehicleform.documents" />
                    </h3>
                    <div className="margin-bottom-4 flex flex-col">
                      {documents.map(doc => (
                        <DocumentLink
                          key={doc.i18nKey}
                          type={doc.type}
                          src={doc[`url_${i18n.language}`] || doc.url}
                          target="_blank"
                          className="margin-bottom-2"
                        >
                          <Trans i18nKey={doc.i18nKey} />
                        </DocumentLink>
                      ))}
                    </div>
                    <AutoId>
                      {id => (
                        <div className="margin-bottom-4">
                          <AcceptDriverConditions
                            required
                            name="acceptDriverConditions"
                            value={values.acceptDriverConditions}
                            id={id}
                            onChange={event =>
                              setFieldValue('acceptDriverConditions', event.currentTarget.checked)
                            }
                            onBlur={() => setFieldTouched('acceptDriverConditions', true)}
                          />
                          <ValidationErrorMessage
                            className="margin-top-0 margin-bottom-4"
                            invalid={
                              touched.acceptDriverConditions && errors.acceptDriverConditions
                            }
                            i18nKey={errors.acceptDriverConditions}
                          ></ValidationErrorMessage>
                        </div>
                      )}
                    </AutoId>
                    <CommentField
                      value={values.comment}
                      onChange={v => setFieldValue('comment', v)}
                      onBlur={handleBlur}
                    />
                  </div>
                  <div className={styles.section}>
                    <h3>
                      <Trans i18nKey="requestvehicleform.procedure.title" />
                    </h3>
                    <div className={cx('flex', styles.process)}>
                      <Card
                        title={t('requestvehicleform.procedure.steps.clearance.title')}
                        dark
                        color="turquoise"
                      >
                        <CardText className={styles.cardText}>
                          <Trans i18nKey="requestvehicleform.procedure.steps.clearance.text" />
                        </CardText>
                      </Card>
                      <div className={cx('flex flex-center', styles.cardSeparator)}>
                        <Icon className={styles.mobileSwitch} name="002-arrow-down" />
                        <Icon className={styles.mobileSwitch} name="004-arrow-right" />
                      </div>
                      <Card
                        title={t('requestvehicleform.procedure.steps.orderplacement.title')}
                        dark
                        color="purple"
                      >
                        <CardText className={styles.cardText}>
                          <Trans i18nKey="requestvehicleform.procedure.steps.orderplacement.text" />
                        </CardText>
                      </Card>
                      <div className={cx('flex flex-center', styles.cardSeparator)}>
                        <Icon className={styles.mobileSwitch} name="002-arrow-down" />
                        <Icon className={styles.mobileSwitch} name="004-arrow-right" />
                      </div>
                      <Card
                        title={t('requestvehicleform.procedure.steps.leasingpartner.title')}
                        dark
                        color="pink"
                      >
                        <CardText className={styles.cardText}>
                          <Trans i18nKey="requestvehicleform.procedure.steps.leasingpartner.text" />
                        </CardText>
                      </Card>
                    </div>
                  </div>
                  <MessageCard
                    type="important"
                    title={t('requestvehicleform.notice.deliverydelay.title')}
                    className="margin-bottom-2"
                  >
                    <Trans i18nKey="requestvehicleform.notice.deliverydelay.text" />
                  </MessageCard>
                  <MessageCard
                    type="important"
                    title={t('requestvehicleform.notice.vehiclereturn.title')}
                    className="margin-bottom-4"
                  >
                    <Trans i18nKey="requestvehicleform.notice.vehiclereturn.text" />
                  </MessageCard>
                  <ButtonGroup responsive>
                    <Button
                      type="submit"
                      disabled={(submitCount > 0 && !isValid) || isSubmitting}
                      isLoading={isSubmitting}
                    >
                      {t('common.send')}
                    </Button>
                    {profile?.isFleetMember && (
                      <>
                        <Button
                          type="button"
                          onClick={() => handleSaveDraft(values)}
                          isLoading={isSubmitting}
                          color="confirm"
                        >
                          {t('drafts.buttons.save')}
                        </Button>
                        {!isNaN(draftId) && (
                          <Button
                            className="deleteDraftButton"
                            type="button"
                            onClick={() => removeDraft(values)}
                            isLoading={isSubmitting}
                          >
                            {t('drafts.buttons.delete')}
                          </Button>
                        )}
                      </>
                    )}
                    <Button color="secondary" onClick={history.goBack} type="button">
                      {t('common.cancel')}
                    </Button>
                  </ButtonGroup>
                  <p>{t('formvalidation.required.descriptions')}</p>
                </form>
              );
            }}
          </Formik>
        )}
      </Container>
    </>
  );
}

export default RequestVehicleForm;
