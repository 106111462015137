import React from 'react';
import Select from '../../components/Select';
import { useTranslation } from 'react-i18next';

function ContractMutationStatusSelect({ value, onChange, id }) {
  const { t } = useTranslation();

  const options = [
    {
      value: 'FIRST_APPROVAL_PT',
      label: t('approvalpointstatus.firstapprovalpoint'),
    },
    {
      value: 'SECOND_APPROVAL_PT',
      label: t('approvalpointstatus.secondapprovalpoint'),
    },
    {
      value: 'FLEET_MGMT',
      label: t('approvalpointstatus.fleetmgmt'),
    },
    {
      value: 'PROVIDER',
      label: t('approvalpointstatus.provider'),
    },
  ];

  return (
    <Select
      placeholder={t('mutationoverview.filter.state.placeholder')}
      value={value}
      onChange={onChange}
      options={options}
      inputId={id}
    />
  );
}

export default ContractMutationStatusSelect;
