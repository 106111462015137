//@flow

import React, { useContext, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import { type ContractMutation } from '../../../../types/contract-mutation-types';
import { approveMutation, updateContractMutationApproval } from '../../../../services/contractMutationService';

import ButtonGroup from '../../../../components/ButtonGroup';
import Button from '../../../../components/Button';
import EndLeasingContractField from '../../../../components/EndLeasingContractField';
import FormField from '../../../../components/FormField';
import ValidationErrorMessage from '../../../../components/ValidationErrorMessage';
import { useNotifications } from '../../../../components/Notifications/NotificationsProvider';
import { AuthContext } from '../../../../components/AuthProvider/AuthProvider';
import CommentField from '../../../../components/CommentField';
import RejectMutationModal from '../../../../components/RejectMutationModal';
import { MutationDetailContext } from '../../MutationDetail';
import { updateReturnVehicleContractMutation } from '../../../../services/contractService';

type Props = {
  mutation: ContractMutation,
};

function ApprovalFormFleet({ mutation }: Props) {
  const { t } = useTranslation();
  const history = useHistory();
  const { fetchProfile } = useContext(AuthContext);
  const { currentApprovalPoint } = useContext(MutationDetailContext);

  const [showRejectionModal, setShowRejectingModal] = useState<boolean>(false);
  const { addSuccess, addAlert } = useNotifications();

  const validationSchema = Yup.object().shape({
    endLeasingContract: Yup.boolean()
      .required('formvalidation.required.message')
      .nullable(),
  });

  const cacheApprovalStep = async val => {
    try {
      const payload = {
        ...currentApprovalPoint,
        ...val,
        approved: null
      };

      if (mutation.contract) {
        await updateReturnVehicleContractMutation(mutation.id, {
          endLeasingContract: payload.endLeasingContract
        });
      }

      await updateContractMutationApproval(currentApprovalPoint.id, payload);
      addSuccess(t('mutationdetail.alerts.success'));
      window.scrollTo(0, 0);
    } catch (err) {
      addAlert(t('errorstate.defaultmessage'));
      window.scrollTo(0, 0);
    }
  };

  async function handleSubmit(values, actions) {
    try {
      await approveMutation(mutation.id, values);
      fetchProfile();
      addSuccess(t('mutationdetail.alerts.success'));
      window.scrollTo(0, 0);
      history.push('/mutation-overview');
    } catch {
      addAlert(t('errorstate.defaultmessage'));
      window.scrollTo(0, 0);
      actions.setSubmitting(false);
    }
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{
        approvalPoint: currentApprovalPoint.type,
        approved: true,
        comment: currentApprovalPoint.comment || '',
        endLeasingContract: mutation.returnVehicleMutation?.endLeasingContract
      }}
      validationSchema={validationSchema}
    >
      {({
        values,
        touched,
        errors,
        setFieldValue,
        setFieldTouched,
        handleChange,
        handleBlur,
        submitCount,
        handleSubmit,
        isSubmitting,
        isValid,
        submitForm,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            {showRejectionModal && (
              <RejectMutationModal
                mutationId={mutation.id}
                approvalPoint={values.approvalPoint}
                title={t('returnvehicledetail.rejectionform.title')}
                onClose={() => setShowRejectingModal(false)}
              />
            )}

            <h2>
              <Trans i18nKey="returnvehicledetail.labels.ordertitle" />
            </h2>
            <FormField label={t('returnvehicledetail.labels.endleasingcontract')}>
              <EndLeasingContractField
                value={values.endLeasingContract}
                onChange={value => setFieldValue('endLeasingContract', value === 'true')}
              />
              <ValidationErrorMessage
                invalid={touched.endLeasingContract && errors.endLeasingContract}
                i18nKey={errors.endLeasingContract}
              />
            </FormField>
            <CommentField value={values.comment} onChange={v => setFieldValue('comment', v)} />
            <ButtonGroup responsive>
              <Button
                color="primary"
                type="submit"
                disabled={isSubmitting}
                isLoading={isSubmitting}
              >
                <Trans i18nKey="mutationdetail.buttons.sendorder" />
              </Button>

              <Button
                color="confirm"
                type="button"
                disabled={isSubmitting}
                isLoading={isSubmitting}
                onClick={() => cacheApprovalStep(values)}
              >
                <Trans i18nKey="common.save" />
              </Button>
              <Button
                color="secondary"
                type="button"
                disabled={isSubmitting}
                onClick={() => setShowRejectingModal(true)}
              >
                <Trans i18nKey="mutationdetail.buttons.reject" />
              </Button>
              <Button
                color="secondary"
                type="button"
                disabled={isSubmitting}
                onClick={history.goBack}
              >
                <Trans i18nKey="mutationdetail.buttons.cancel" />
              </Button>
            </ButtonGroup>
          </form>
        );
      }}
    </Formik>
  );
}

export default ApprovalFormFleet;
