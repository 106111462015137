// @flow

import React from 'react';
import { Trans } from 'react-i18next';
import Container from '../../components/Container';
import BillingCycleTable from './BillingCycleTable';
import ContractTable from './ContractTable';
import { useLastLocation } from 'react-router-last-location';

function ContractOverview() {
  const lastLocation = useLastLocation();
  return (
    <Container>
      <h1>
        <Trans i18nKey="navigation.billing" />
      </h1>

      <sdx-tabs>
        <sdx-tabs-item label="Verträge">
          <ContractTable />
        </sdx-tabs-item>
        <sdx-tabs-item selected={lastLocation?.state?.selectedTab} label="Fakturaläufe">
          <BillingCycleTable />
        </sdx-tabs-item>
      </sdx-tabs>
    </Container>
  );
}

export default ContractOverview;
