// @flow

import React from 'react';
import { type Vehicle } from '../../types/vehicle-types';
import { useTranslation } from 'react-i18next';
import { formatCategoryName } from '../../util/MutationProperties';

type Props = {
  vehicle: Vehicle,
};

function VehicleName({ vehicle }: Props) {
  const { t } = useTranslation();

  const categoryName = vehicle.vehicleCategory
    ? formatCategoryName(vehicle.vehicleCategory)
    : t('common.labels.notapplicable');

  return (
    <>
      {vehicle.baseModelDisplayName && vehicle.baseModelDisplayName.length > 0
        ? vehicle.baseModelDisplayName
        : categoryName}
    </>
  );
}

export default VehicleName;
