// @flow

import React from 'react';
import cx from 'classnames';

type Props = {
  user: {
    phone?: string,
    mobile?: string,
    preferredPhone?: string,
  },
  className?: string,
};

function PhoneLink({ user, className }: Props) {
  const phoneNumber = user.preferredPhone || user.mobile || user.phone;
  if (!phoneNumber) {
    return null;
  }

  return (
    <a
      className={cx('link', className)}
      href={`tel:${phoneNumber}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {phoneNumber}
    </a>
  );
}

export default PhoneLink;
