// @flow

import { useState, useEffect, useCallback } from 'react';
import debounce from 'debounce-promise';

import { type Debtor } from '../types/debtor-types';
import { type DebtorCostcenter } from '../types/debtor-costcenter-types';
import {
  createDebtorCostcenter,
  deleteDebtorCostcenter,
  getDebtorCostcenterOverview,
  updateDebtorCostcenter,
} from '../services/debtorCostcenterService';

import usePagination from './usePagination.js';

const PAGE_SIZE = 10;

const debouncedFetch = debounce(getDebtorCostcenterOverview, 350);

function useDebtorCostcenters(initialPage?: number) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [debtorCostcenters, setDebtorCostcenters] = useState<Debtor[]>([]);
  const { page, setPagination, pagination, setPage } = usePagination(initialPage || 0, PAGE_SIZE);

  const createMapping = async (payload: DebtorCostcenter) => {
    const { data: newMapping } = await createDebtorCostcenter(payload);
    setDebtorCostcenters([...debtorCostcenters, newMapping]);
  };

  const updateMapping = async (id: number, payload: DebtorCostcenter) => {
    const { data: updatedMapping } = await updateDebtorCostcenter(id, payload);
    debtorCostcenters[
      debtorCostcenters.findIndex(debtorCostcenter => debtorCostcenter.id === id)
    ] = updatedMapping;
  };

  const deleteMapping = async (id: number) => {
    await deleteDebtorCostcenter(id);
    debtorCostcenters.splice(
      debtorCostcenters.findIndex(debtorCostcenter => debtorCostcenter.id === id),
      1,
    );
  };

  const fetchDebtorCostcenters = useCallback(async () => {
    try {
      const skip = page ? page * PAGE_SIZE : 0;
      setHasError(false);
      setIsLoading(true);
      const { data: paginatedResponse } = await debouncedFetch({
        skip,
        take: PAGE_SIZE,
      });
      setDebtorCostcenters(paginatedResponse);
      const { data, ...paginationProperties } = paginatedResponse;
      setPagination(paginationProperties);
      setIsLoading(false);
    } catch (err) {
      setHasError(true);
      setIsLoading(false);
      throw err;
    }
  }, [page, setPagination]);

  const loadMore = (page: { selected: number }) => {
    setPage(page.selected);
  };

  useEffect(() => {
    fetchDebtorCostcenters();
  }, [fetchDebtorCostcenters]);

  return {
    isLoading,
    debtorCostcenters,
    hasError,
    loadMore,
    pagination,
    setPage,
    createMapping,
    updateMapping,
    deleteMapping,
  };
}

export default useDebtorCostcenters;
