// @flow

import React from 'react';
import cx from 'classnames';

import styles from './SDX-Card.module.css';

const defaultIconConfig = {
  action: 'icon-023-download',
  info: 'icon-046-information-circle',
  success: 'icon icon-012-check-mark-circle',
  important: 'icon-026-exclamation-mark-circle',
  alert: 'icon-026-exclamation-mark-circle',
};

type Props = {
  title: string,
  children: any,
  type?: 'action' | 'info' | 'success' | 'important' | 'alert',
  compact?: boolean,
  className?: string,
};

export const SDXCard = ({ title, children, type = 'info', compact = false, className }: Props) => {
  return (
    <div
      className={cx(className, 'card card--icon card--border', `card--message-${type}`, {
        'card--compact': compact,
      })}
      role="region"
      aria-labelledby="Router_Problems"
    >
      <i
        className={cx('card__item-icon icon icon--s3', defaultIconConfig[type])}
        aria-hidden="true"
      ></i>
      <div className="card__body">
        <h1 className={cx(styles.cardTitle)}>{title}</h1>
        {children}
      </div>
    </div>
  );
};
