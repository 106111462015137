import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import Checkbox from '../Checkbox';

function AcceptDriverConditions({ ...props }) {
  const { t } = useTranslation();

  return (
    <>
      <Checkbox {...props} label={t('acceptdriverconditions.label')}>
        <ul className="list margin-top-2 font-size-default margin-bottom-0">
          <li>
            <Trans i18nKey="acceptdriverconditions.criteria.license">
              Im Besitz eines für die Schweiz gültigen Führerausweuses bin/ist und diesen in Fiori
              unter
              <a
                href="https://pmpgwd.apps.swisscom.com/fiori#ZDOKUMENTE_SO-display"
                target="_blank"
                rel="noopener noreferrer"
              >
                «Dokumente - Upload»
              </a>
              hochgelanden habe/hat.
            </Trans>
          </li>
          <li>
            <Trans i18nKey="acceptdriverconditions.criteria.usage">
              Die Nutzungsart in
              <a
                href="https://pmpgwd.apps.swisscom.com/fiori#ZPA_DFAHRZ-display"
                target="_blank"
                rel="noopener noreferrer"
              >
                Fiori
              </a>
              eingetragen hat/habe.
            </Trans>
          </li>
          <li>
            <Trans i18nKey="acceptdriverconditions.criteria.statusprotocol">
              Die aktuell geltende Weisung zur Dienstfahrzeugnutzung unter
              <a
                href="https://pmpgwd.apps.swisscom.com/fiori#ZPA_ECONF-display&/"
                target="_blank"
                rel="noopener noreferrer"
              >
                eConfirmation
              </a>
              akzeptiert hat/habe.
            </Trans>
          </li>
        </ul>
      </Checkbox>
    </>
  );
}

export default AcceptDriverConditions;
