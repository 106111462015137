// @flow

import { useState, useEffect } from 'react';
import { type VehicleCategory } from '../types/vehicle-category-types';
import { getVehicleCategory } from '../services/vehicleCategoryService';

function useVehicleCategory(id: number) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [category, setCategory] = useState<VehicleCategory | null>(null);

  useEffect(() => {
    let isCancelled = false;
    async function fetchCategory() {
      try {
        setIsLoading(true);
        const { data: category } = await getVehicleCategory(id);

        if (!isCancelled) {
          setCategory(category);
          setIsLoading(false);
        }
      } catch (err) {
        if (!isCancelled) {
          setIsLoading(false);
          setHasError(true);
        }
        throw err;
      }
    }

    fetchCategory();

    return () => {
      isCancelled = true;
    };
  }, [id]);

  return { isLoading, category, hasError };
}

export default useVehicleCategory;
