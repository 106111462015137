// @flow

import React from 'react';
import cx from 'classnames';
import { CardItem, CardText } from '../../../../components/Card';

import { type ContractMutation } from '../../../../types/contract-mutation-types';

import styles from './MutationCardItem.module.css';
import { Trans, useTranslation } from 'react-i18next';
import VehicleName from '../../../../components/VehicleName/VehicleName';
import ApprovalStatus from '../../../../components/ApprovalStatus/ApprovalStatus';
import PaddedId from '../../../../components/PaddedId';
import {
  getCurrentApprovalPoint,
  getCurrentVehicleCategory,
  formatCategoryName,
} from '../../../../util/MutationProperties';

type Props = {
  mutation: ContractMutation,
};

function MutationCardItem({ mutation }: Props) {
  const currentApprovalPoint = getCurrentApprovalPoint(mutation);
  const {t} = useTranslation();
  let route;

  if (mutation.status === 'DRAFT' && mutation.type === 'REQUESTVEHICLE') {
    if (mutation.requestVehicleMutation) {
      route = `/request-vehicle?categoryId=${mutation.requestVehicleMutation.vehicleCategory.id}&draftId=${mutation.id}`;
    }
  } else if (mutation.status === 'DRAFT' && mutation.type === 'RETURNVEHICLE' && mutation.contract) {
    route = `/contracts/${mutation.contract.id}/return-vehicle?draftId=${mutation.id}`;
  } else if (mutation.status === 'DRAFT' && mutation.type === 'DRIVERCHANGE' && mutation.contract) {
    route = `/contracts/${mutation.contract.id}/driver-change?draftId=${mutation.id}`;
  } else if (mutation.status === 'DRAFT' && mutation.type === 'VEHICLECHANGE' && mutation.contract) {
    route = `/contracts/${mutation.contract.id}/vehicle-change?draftId=${mutation.id}`;
  } else {
    route = `/mutations/${mutation.id}`;
  }

  return (
    <CardItem to={route} className={styles.container}>
      <div className="row">
        <div className="col-md-6">
          <span className={styles.title}>
            <Trans i18nKey={`mutationtypes.${mutation.type.toLowerCase()}`} />{' '}
            <span>
              <PaddedId id={mutation.id} />
            </span>
          </span>
        </div>
        <div className="col-md-6">
          <CardText className={cx(styles.text, 'font-size-default')}>
            {currentApprovalPoint && <ApprovalStatus status={currentApprovalPoint.type} mutationStatus={mutation.status} />}
            {mutation.status === 'DRAFT' && <span>{t('common.draft')}</span>}
          </CardText>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <CardText className={cx(styles.text, 'font-size-default')}>
            {mutation.type === 'REQUESTVEHICLE' && (
              <>{formatCategoryName(getCurrentVehicleCategory(mutation.requestVehicleMutation))}</>
            )}
            {mutation.type !== 'REQUESTVEHICLE' && mutation.contract && (
              <VehicleName vehicle={mutation.contract.vehicle} />
            )}
          </CardText>
        </div>
        <div className="col-md-6">
          <CardText className={cx(styles.text, 'font-size-default')}></CardText>
        </div>
      </div>
    </CardItem>
  );
}

export default MutationCardItem;
