// @flow

import React from 'react';

type Props = {
  id: number | string,
};

function PaddedId({ id }: Props) {
  return <>{String(id).padStart(6, '0')}</>;
}

export default PaddedId;
