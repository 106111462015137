//@flow

import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { Formik } from 'formik';

import { type ContractMutation } from '../../../../types/contract-mutation-types';
import { approveMutation } from '../../../../services/contractMutationService';

import ButtonGroup from '../../../../components/ButtonGroup';
import Button from '../../../../components/Button';
import { useNotifications } from '../../../../components/Notifications/NotificationsProvider';
import RejectMutationModal from '../../../../components/RejectMutationModal';
import { AuthContext } from '../../../../components/AuthProvider/AuthProvider';
import CommentField from '../../../../components/CommentField';
import { MutationDetailContext } from '../../MutationDetail';

type Props = {
  mutation: ContractMutation,
};

function ApprovalForm({ mutation }: Props) {
  const { t } = useTranslation();
  const history = useHistory();
  const { fetchProfile } = useContext(AuthContext);
  const { currentApprovalPoint } = useContext(MutationDetailContext);

  const [showRejectionModal, setShowRejectingModal] = useState<boolean>(false);

  const { addSuccess, addAlert } = useNotifications();
  async function handleSubmit(values, actions) {
    try {
      await approveMutation(mutation.id, values);
      fetchProfile();
      addSuccess(t('mutationdetail.alerts.success'));
      window.scrollTo(0, 0);
      history.push('/mutation-overview');
    } catch {
      addAlert(t('errorstate.defaultmessage'));
      window.scrollTo(0, 0);
      actions.setSubmitting(false);
    }
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{ approvalPoint: currentApprovalPoint.type, approved: true, comment: '' }}
    >
      {({
        values,
        touched,
        errors,
        setFieldValue,
        setFieldTouched,
        handleChange,
        handleBlur,
        submitCount,
        handleSubmit,
        isSubmitting,
        isValid,
        submitForm,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            {showRejectionModal && (
              <RejectMutationModal
                mutationId={mutation.id}
                approvalPoint={values.approvalPoint}
                title={t('returnvehicledetail.rejectionform.title')}
                onClose={() => setShowRejectingModal(false)}
              />
            )}
            <CommentField value={values.comment} onChange={v => setFieldValue('comment', v)} />
            <ButtonGroup responsive>
              <Button
                color="primary"
                type="submit"
                disabled={isSubmitting}
                isLoading={isSubmitting}
              >
                <Trans i18nKey="mutationdetail.buttons.accept" />
              </Button>
              <Button
                color="secondary"
                type="button"
                disabled={isSubmitting}
                onClick={() => setShowRejectingModal(true)}
              >
                <Trans i18nKey="mutationdetail.buttons.reject" />
              </Button>
              <Button
                color="secondary"
                type="button"
                disabled={isSubmitting}
                onClick={history.goBack}
              >
                <Trans i18nKey="mutationdetail.buttons.cancel" />
              </Button>
            </ButtonGroup>
          </form>
        );
      }}
    </Formik>
  );
}

export default ApprovalForm;
