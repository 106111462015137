// @flow

import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useParams, useLocation } from 'react-router-dom';

import Container from '../../components/Container';
import useContractQueryFilters from '../ContractOverview/useContractQueryFilters';

import BillList from '../../components/BillList/BillList';
import ContractDetail from '../../container/ContractDetail';
import useContract from '../../hooks/useContract';
import useBills from '../../hooks/useBills';

import EmptyState from '../../components/EmptyState/EmptyState';
import ErrorState from '../../components/ErrorState/ErrorState';
import LoadingSpinner from '../../components/LoadingSpinner';
import tableStyles from '../../container/ContractOverview/ContractTable.module.css';
import BackMenu from '../../components/BackMenu';

function ContractSuper() {
  const { t } = useTranslation();
  const { id: contractId } = useParams();
  const { contract, isLoading, hasError } = useContract(contractId);

  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const page = urlParams.get('p') ? +urlParams.get('p') - 1 : undefined;
  const urlParam = 'contracts';

  const { filter: billsFilter } = useContractQueryFilters();
  const { bills, pagination, loadMore, isLoadingBills } = useBills(
    contractId,
    urlParam,
    billsFilter,
    page,
  );
  return (
    <>
      <BackMenu to="/contracts" text={t('common.labels.backtooverview')} />
      <Container>
        {isLoading && (
          <div className="flex flex-center">
            <div colSpan="12">
              <div className={tableStyles.empty__outer}>
                <LoadingSpinner />
              </div>
            </div>
          </div>
        )}
        {!isLoading && !hasError && !contract?.id && (
          <div className="flex flex-center">
            <div colSpan="12">
              <div className={tableStyles.empty__outer}>
                <EmptyState />
              </div>
            </div>
          </div>
        )}
        {!isLoading && hasError && (
          <div className="flex flex-center">
            <div colSpan="12">
              <div className={tableStyles.empty__outer}>
                <ErrorState />
              </div>
            </div>
          </div>
        )}
        {!isLoading && !hasError && (
          <ContractDetail billingId={contractId} urlParam={'contracts'}></ContractDetail>
        )}
        {isLoadingBills && (
          <div className="flex flex-center">
            <div colSpan="12">
              <div className={tableStyles.empty__outer}>
                <LoadingSpinner />
              </div>
            </div>
          </div>
        )}
        {!hasError && !isLoadingBills && bills?.length !== 0 && (
          <div>
            <h3>
              <Trans i18nKey="contractdetail.billstitle" />
            </h3>
            <BillList
              billingId={contractId}
              urlParam={'contracts'}
              hasError={hasError}
              isLoading={isLoading}
              bills={bills}
              pagination={pagination}
              loadMore={loadMore}
            ></BillList>
          </div>
        )}
        {!hasError && !isLoadingBills && bills?.length === 0 && (
          <div>
            <h3>
              <Trans i18nKey="contractdetail.billstitle" />
            </h3>
            <div className="flex flex-center">
              <div colSpan="12">
                <div className={tableStyles.empty__outer}>
                  <EmptyState />
                </div>
              </div>
            </div>
            {/* <h5>
              <Trans i18nKey="contractdetail.nobillsfound" />
            </h5> */}
          </div>
        )}
      </Container>
    </>
  );
}

export default ContractSuper;
