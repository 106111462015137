import React from 'react';
import { Trans } from 'react-i18next';
import styles from './OpenFile.module.css';

type Props = {
  fileLocation: string
}

function OpenFile({ fileLocation }: Props) {
  const onCategoryInfoClick = async e => {
    e.stopPropagation();
    window.open(`/media/${fileLocation}`, '_blank');
  };

  return (
    <div className={styles.pdfContainer}>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        className={styles.pdfLink}
        onClick={e => onCategoryInfoClick(e)}
        role="link"
        tabIndex="0"
      >
        <Trans i18nKey="selectvehiclecategory.vehicleinfo"></Trans>
      </a>
    </div>
  );
};

export default OpenFile;
