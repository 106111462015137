// @flow

import React, { useState } from 'react';
import AutosizeTextarea from 'react-textarea-autosize';
import cx from 'classnames';

type Props = {
  value?: string,
  onChange?: Function,
  name?: string,
  readOnly?: boolean,
  disabled?: boolean,
  placeholder?: string,
  invalid?: boolean,
  onBlur?: Function,
  onFocus?: Function,
  hasMarginTop?: boolean,
  id?: string,
  maxLength?: number,
};

function Textarea({
  value,
  onChange,
  name,
  readOnly,
  disabled,
  placeholder,
  invalid,
  onBlur,
  onFocus,
  hasMarginTop,
  id,
  maxLength,
}: Props) {
  const [focused, setFocused] = useState(false);

  const inputClasses = cx({
    'invalid validate': invalid,
    'margin-top-0': !hasMarginTop,
  });

  return (
    <div className="input-field input-field--multiline input-field--fixed">
      <AutosizeTextarea
        name={name}
        readOnly={readOnly}
        disabled={disabled}
        className={inputClasses}
        value={value}
        onChange={onChange}
        id={id}
        onFocus={event => {
          setFocused(true);
          if (onFocus) onFocus(event);
        }}
        onBlur={event => {
          setFocused(false);
          if (onBlur) onBlur(event);
        }}
        minRows={focused || (value && value.length > 0) ? 4 : 1}
        placeholder={placeholder}
        maxLength={maxLength}
      />
    </div>
  );
}

export default Textarea;
