// @flow

import React from 'react';
import cx from 'classnames';


import CardText from '../Card/CardText';
import Icon from '../Icon';
import styles from './InteriorCard.module.css';
import { type Interior } from '../../types/interior-types';

type Props = {
  interior: Interior,
  onClick: Function,
  handleEditClick: Function
}

function InteriorCard({ interior, onClick, handleEditClick }: Props) {
  return (
    <>
      <div onClick={() => onClick(interior)} tabIndex='-1' role="button">
        <CardText className={cx('card card--dark', styles.interiorCardItem, interior.deletedAt ? 'card--blue' : 'card--purple')}
        >
          <span>{interior.name}</span>
          <span onClick={e => handleEditClick(e, interior, true)} role="button" tabIndex='-2'>
            <Icon name="edit" className={cx('icon--s3 blue', styles.edit)}></Icon>
          </span>
          <Icon name="chevron-normal-right" className={cx('icon--s3 blue', styles.chevron)}></Icon>
        </CardText>
      </div>
    </>
  );
}

export default InteriorCard;
