//@flow

import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { type ContractMutation } from '../../../../types/contract-mutation-types';
import { approveMutation } from '../../../../services/contractMutationService';

import RejectMutationModal from '../../../../components/RejectMutationModal';
import ButtonGroup from '../../../../components/ButtonGroup';
import Button from '../../../../components/Button';
import { useNotifications } from '../../../../components/Notifications/NotificationsProvider';
import { AuthContext } from '../../../../components/AuthProvider/AuthProvider';
import CommentField from '../../../../components/CommentField';
import { MutationDetailContext } from '../../MutationDetail';
import Checkbox from '../../../../components/Checkbox';
import AutoId from '../../../../components/AutoId';
import ValidationErrorMessage from '../../../../components/ValidationErrorMessage';

type Props = {
  mutation: ContractMutation,
};

function ApprovalForm({ mutation }: Props) {
  const [showRejectionModal, setShowRejectingModal] = useState<boolean>(false);
  const { currentApprovalPoint } = useContext(MutationDetailContext);

  const { t } = useTranslation();
  const history = useHistory();
  const { fetchProfile } = useContext(AuthContext);

  const { addSuccess, addAlert } = useNotifications();

  const validationSchema = Yup.object().shape({
    conditionProtocolRead: Yup.boolean()
      .required('formvalidation.required.message')
      .oneOf([true], 'formvalidation.required.message'),
  });

  async function handleSubmit(values, actions) {
    try {
      await approveMutation(mutation.id, values);
      fetchProfile();

      addSuccess(t('mutationdetail.alerts.success'));
      window.scrollTo(0, 0);
      history.push('/mutation-overview');
    } catch {
      addAlert(t('errorstate.defaultmessage'));
      window.scrollTo(0, 0);
      actions.setSubmitting(false);
    }
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{
        approvalPoint: currentApprovalPoint.type,
        approved: true,
        comment: '',
        conditionProtocolRead: false,
      }}
      validationSchema={validationSchema}
    >
      {({
        values,
        touched,
        errors,
        setFieldValue,
        setFieldTouched,
        handleSubmit,
        isSubmitting,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            {showRejectionModal && (
              <RejectMutationModal
                mutationId={mutation.id}
                approvalPoint={values.approvalPoint}
                title={t('driverchangedetail.rejectionform.title')}
                onClose={() => setShowRejectingModal(false)}
              />
            )}
            <h2>
              <Trans i18nKey="driverchangedetail.labels.conditionprotocol.label" />
            </h2>
            <AutoId>
              {id => (
                <>
                  <Checkbox
                    className="margin-bottom-3"
                    id={id}
                    label={t('driverchangedetail.labels.conditionprotocol.text')}
                    name={'conditionProtocolRead'}
                    value={values.conditionProtocolRead}
                    onChange={event =>
                      setFieldValue('conditionProtocolRead', event.currentTarget.checked)
                    }
                    onBlur={() => setFieldTouched('conditionProtocolRead', true)}
                    required
                  />

                  <ValidationErrorMessage
                    className="margin-top-0 margin-bottom-4"
                    invalid={touched.conditionProtocolRead && errors.conditionProtocolRead}
                    i18nKey={errors.conditionProtocolRead}
                  ></ValidationErrorMessage>
                </>
              )}
            </AutoId>
            <CommentField value={values.comment} onChange={v => setFieldValue('comment', v)} />
            <ButtonGroup responsive>
              <Button
                color="primary"
                type="submit"
                disabled={isSubmitting}
                isLoading={isSubmitting}
              >
                <Trans i18nKey="mutationdetail.buttons.accept" />
              </Button>
              <Button
                color="secondary"
                type="button"
                disabled={isSubmitting}
                onClick={() => setShowRejectingModal(true)}
              >
                <Trans i18nKey="mutationdetail.buttons.reject" />
              </Button>
              <Button
                color="secondary"
                type="button"
                disabled={isSubmitting}
                onClick={history.goBack}
              >
                <Trans i18nKey="mutationdetail.buttons.cancel" />
              </Button>
            </ButtonGroup>
            <p>{t('formvalidation.required.descriptions')}</p>
          </form>
        );
      }}
    </Formik>
  );
}

export default ApprovalForm;
