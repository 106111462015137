// @flow

import { type Debtor } from '../types/debtor-types';

import { type PaginatedResponse } from '../types/pagination-types';
import { fetch } from './fetch';
import { type AxiosResponse } from 'axios';

import { parseQueryFilters } from '../util/QueryFilterHelper';
import { type QueryFilters } from '../util/QueryFilterHelper';

export async function getDebtorOverview(): Promise<PaginatedResponse<Debtor>> {
  return fetch<Debtor[]>('/debtor', 'GET', null);
}

export async function getDebtorById(id: number): Promise<{ data: Debtor }> {
  return fetch<Debtor>(`/debtor/${id}`, 'GET', null);
}

export async function createDebtor(payload: any): Promise<Debtor> {
  return fetch<Debtor>(`/debtor`, 'POST', payload);
}

export async function updateDebtor(id: number, payload: any): Promise<Debtor> {
  return fetch<Debtor>(`/debtor/${id}`, 'PUT', payload);
}

export async function deleteDebtor(id: number): Promise<void> {
  return fetch<Debtor>(`debtor/${id}`, 'DELETE', null);
}

export async function getDebtors(filters: QueryFilters): Promise<AxiosResponse<Debtor[]>> {
  return fetch<Debtor[]>('/debtor', 'GET', null, {
    params: parseQueryFilters(filters),
  });
}
