import * as Yup from 'yup';
import startOfDay from 'date-fns/startOfDay';
import addWeeks from 'date-fns/addWeeks';

export const validationSchema = Yup.object().shape({
  registrationCanton: Yup.object()
    .required('formvalidation.required.message')
    .nullable(),
  deliveryCanton: Yup.object()
    .required('formvalidation.required.message')
    .nullable(),
  mileagePerYear: Yup.object()
    .required('formvalidation.required.message')
    .nullable(),
  dateOfChange: Yup.date()
    .min(startOfDay(addWeeks(new Date(), 3)), 'formvalidation.date.min')
    .required('formvalidation.required.message')
    .nullable(),
  comment: Yup.string(),
  selectedVehicleCategory: Yup.object()
    .required('formvalidation.required.message')
    .nullable(),
  acceptDriverConditions: Yup.boolean()
    .required('formvalidation.required.message')
    .oneOf([true], 'formvalidation.required.message'),
});
