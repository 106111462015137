// @flow

import { fetch } from './fetch';
import { type CostCenter, } from '../types/cost-center-types';
import { type AxiosResponse } from 'axios';

export function findCostCenters(query: string): Promise<AxiosResponse<CostCenter[]>> {
  return fetch<CostCenter[]>('/costcenter', 'GET', null, {
    params: {
      q: query,
    },
  });
}
