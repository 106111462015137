// @flow

import React, { useState, useContext, type Node } from 'react';
import uniqueId from 'lodash/fp/uniqueId';

import { type Notification } from './notification-types';

type NotificationContextType = {
  notifications: Notification[],
  removeNotification: Function,
  addSuccess: Function,
  addAlert: Function,
  addInfo: Function,
};

export type NotificationType = 'confirmation' | 'alert' | 'info';

export const NotificationTypes: { [key: string]: NotificationType } = {
  CONFIRMATION: 'confirmation',
  ALERT: 'alert',
  INFO: 'info',
};

const NotificationsContext = React.createContext<NotificationContextType>({
  notifications: [],
  removeNotification: () => {},
  addSuccess: () => {},
  addAlert: () => {},
  addInfo: () => {},
});

type Props = {
  children: Node,
};

export function useNotifications() {
  return useContext(NotificationsContext);
}

function NotificationsProvider({ children }: Props) {
  const [notifications, setNotifications] = useState<Notification[]>([]);

  const addNotification = (type: NotificationType) => (text: string) => {
    const id = uniqueId('notification_');
    setNotifications(notifs => [...notifs, { type, text, id }]);
  };

  const removeNotification = (id: string) => {
    setNotifications(notifs => notifs.filter(n => n.id !== id));
  };

  const addSuccess = addNotification(NotificationTypes.CONFIRMATION);

  const addAlert = addNotification(NotificationTypes.ALERT);

  const addInfo = addNotification(NotificationTypes.INFO);

  return (
    <NotificationsContext.Provider
      value={{
        notifications,
        removeNotification,
        addSuccess,
        addAlert,
        addInfo,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
}

export default NotificationsProvider;
