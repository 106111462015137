// @flow

import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import store2 from 'store2';
import userManager from '../../auth/userManager';
import LoadingPage from '../LoadingPage';

const LoginCallback = () => {
  const history = useHistory();
  useEffect(() => {
    let isCancelled = false;
    userManager.signinRedirectCallback().then(async () => {
      if (!isCancelled) {
        const lastLocation = store2.get('location') || '/';
        history.replace(lastLocation);
        store2.remove('location');
      }
    });

    return () => {
      isCancelled = true;
    };
  }, [history]);
  return <LoadingPage />;
};

export default LoginCallback;
