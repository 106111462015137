// @flow

import React, { type Node } from 'react';
import cx from 'classnames';

import CardItemLink from './CardItemLink';

import styles from './CardItem.module.css';

type Props = {
  children: Node,
  to?: string,
  className?: string,
  style?: Object
};

function CardItem({ children, to, className, style }: Props) {
  if (to && to.length) {
    return (
      <CardItemLink to={to} className={className} style={style}>
        {children}
      </CardItemLink>
    );
  }
  const classes = cx('card__item', className, styles.hover);

  return (
    <div className={classes} style={style}>
      {children}
    </div>
  );
}

export default CardItem;
