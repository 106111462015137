// @flow

import { type AxiosResponse } from 'axios';

import { fetch } from './fetch';
import { type Contract } from '../types/contract-types';
import { type Bill } from '../types/bill-types';
import { type ContractMutation } from '../types/contract-mutation-types';
import { type VehicleRequestDraft, type DriverChangeDraft, type VehicleReturnDraft, type ChangeVehicleDraft } from '../types/draft-types';
import { type QueryFilters, parseQueryFilters } from '../util/QueryFilterHelper';

export function findContracts(query: string): Promise<AxiosResponse<Array<Contract>>> {
  return fetch <Contract[]> ('/contracts', 'GET', null, {
    params: { q: query },
  });
}

export function getContractOverview(filter: QueryFilters): any {
  return fetch <Contract[]> ('/contracts/overview', 'GET', null, {
    params: parseQueryFilters(filter),
  });
}

export function findConflictedContracts(): Promise<AxiosResponse<Array<Contract>>> {
  return fetch <Contract[]> ('/contracts/incomplete', 'GET', null);
}

export function findMyMutations(): Promise<AxiosResponse<Array<ContractMutation>>> {
  return fetch <ContractMutation[]> ('/contracts/mutations/my', 'GET');
}

export async function findContract(id: number): Promise<AxiosResponse<Contract>> {
  return fetch<Contract>(`/contracts/${id}`, 'GET');
}

export async function softDeleteContract(id: number): Promise<AxiosResponse<void>> {
  return fetch<void>(`/contracts/${id}`, 'DELETE');
}

export async function findBills(
  id: number,
  urlParam: string,
  filter: QueryFilters,
): Promise<AxiosResponse<Bill>> {
  return await fetch <Bill[]> (`/${urlParam}/${id}/bills`, 'GET', null, {
    params: parseQueryFilters(filter),
  });
}

export function requestVehicle(payload: any) {
  return fetch('/contracts/mutations/request-vehicle', 'POST', payload);
}

export function updateRequestVehicleContractMutation(contractMutationId: number, payload: {orderNumber: number}) {
  return fetch(`/contracts/${contractMutationId}/mutations/request-vehicle`, 'PUT', payload);
}

export function saveRequestVehicleDraft(payload: VehicleRequestDraft) {
  return fetch('/contracts/mutations/draft/request-vehicle', 'POST', payload);
}

export function deleteRequestVehicleDraft(
  draftId: number,
  vehicleMutationId: number,
): Promise<AxiosResponse<void>> {
  return fetch<void>(`/contracts/mutations/draft/request-vehicle/${draftId}/${vehicleMutationId}`, 'DELETE');
}

export function changeDriver(contractId: number, payload: any) {
  return fetch(`/contracts/${contractId}/mutations/change-driver`, 'POST', payload);
}

export function saveChangeDriverDraft(contractId: number, payload: DriverChangeDraft) {
  return fetch(`/contracts/${contractId}/mutations/draft/change-driver`, 'POST', payload);
}

export function deleteChangeDriverDraft(draftId: number, changeDriverMutationId: number): Promise<AxiosResponse<void>> {
  return fetch<void>(`/contracts/mutations/draft/change-driver/${draftId}/${changeDriverMutationId}`, 'DELETE');
}

export function changeVehicle(contractId: number, payload: any) {
  return fetch(`/contracts/${contractId}/mutations/change-vehicle`, 'POST', payload);
}

export function saveVehicleChangeDraft(contractId: number, payload: ChangeVehicleDraft) {
  return fetch(`/contracts/${contractId}/mutations/draft/change-vehicle`, 'POST', payload);
}

export function deleteChangeVehicleDraft(draftId: number): Promise<AxiosResponse<void>> {
  return fetch<void>(`/contracts/mutations/draft/change-vehicle/${draftId}`, 'DELETE');
}

export function returnVehicle(contractId: number, payload: any) {
  return fetch(`/contracts/${contractId}/mutations/return-vehicle`, 'POST', payload);
}

export function updateReturnVehicleContractMutation(contractMutationId: number, payload: {endLeasingContract: boolean}) {
  return fetch(`/contracts/${contractMutationId}/mutations/return-vehicle`, 'PUT', payload);
}

export function saveReturnVehicleDraft(contractId: number, payload: VehicleReturnDraft) {
  return fetch(`/contracts/${contractId}/mutations/draft/return-vehicle`, 'POST', payload);
}

export function deleteReturnVehicleDraft(draftId: number): Promise<AxiosResponse<void>> {
  return fetch<void>(`/contracts/mutations/draft/return-vehicle/${draftId}`, 'DELETE');
}

export function createContract(payload: any) {
  return fetch('/contracts', 'POST', payload);
}

export function updateContract(contractId: number, payload: any) {
  return fetch(`/contracts/${contractId}`, 'PUT', payload);
}

export function exportBillsForContract(contractId: number): Promise<any> {
  return fetch <any> (`/contracts/${contractId}/export`, 'POST', {}, {
    responseType: 'arraybuffer'
  });
}
