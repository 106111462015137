// @flow

import React from 'react';
import {
  type ContractMutationProtocolEntry,
  type ContractMutation,
} from '../../types/contract-mutation-types';
import EmptyState from '../EmptyState';
import LoadingSpinner from '../LoadingSpinner';

import styles from './Protocol.module.css';
import ProtocolItem from './ProtocolItem';

type Props = {
  mutation: ContractMutation,
  items: ContractMutationProtocolEntry[],
  isLoading: Boolean,
};

function Protocol({ mutation, items, isLoading }: Props) {
  if (isLoading) {
    return (
      <div className="flex flex-center">
        <LoadingSpinner />
      </div>
    );
  }
  if (!items) {
    return <EmptyState />;
  }
  const activeIndex = items.findIndex(item => !item.completed);
  const protocolItems = items.map((item, index) => ({
    ...item,
    active: index === activeIndex && mutation.status === 'PENDING',
  }));

  return (
    <div className={styles.wrapper}>
      <ProtocolItem
        mutation={mutation}
        item={{
          status: 'ORDERED',
          completed: true,
          responsibleUsers: [mutation.orderer],
          overriddenResponsibleUsers: [],
          manualReminderSentAt: null,
        }}
        labellingDate={mutation.createdAt}
      />
      {protocolItems.map((item, index) => (
        <ProtocolItem
          mutation={mutation}
          item={item}
          // Show the closedAt date of the previous approval step. If it's the first approval, use the createdAt date
          labellingDate={item.closedAt}
          key={item.status + index}
        />
      ))}
      <ProtocolItem
        mutation={mutation}
        item={{
          status: 'COMPLETED',
          completed: mutation.closedAt != null,
          responsibleUsers: [],
          overriddenResponsibleUsers: [],
          manualReminderSentAt: null,
        }}
        labellingDate={mutation.closedAt}
      />
    </div>
  );
}

export default Protocol;
