// @flow

import { useState, useEffect, useCallback, useMemo } from 'react';
import { getMutations } from '../services/contractMutationService.js';

import { type ContractMutation } from '../types/contract-mutation-types';

import { type QueryFilters } from '../util/QueryFilterHelper';
import { OrderDirections } from '../types/order-types.js';
import usePagination from './usePagination.js';
import debounce from 'debounce-promise';
import {type Pagination} from '../types/pagination-types.js';


const defaultFilter = {
  sort: {
    dateOfExecution: OrderDirections.ASC,
  },
};

const PAGE_SIZE = 10;

const debouncedFetch = debounce(getMutations, 350);

function useMutations(filter?: QueryFilters,  initialPage?: number) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [mutations, setMutations] = useState<{mutations: ContractMutation[], checksum: string, pagination: Pagination} | null>(null);
  const { page, setPage } = usePagination(initialPage || 0, PAGE_SIZE);

  const mutationFilter = useMemo(
    () => ({
      ...defaultFilter,
      ...filter,
    }),
    [filter],
  );


  const fetchMutations = useCallback(async () => {
    try {
      const skip = page ? page * PAGE_SIZE : 0;
      setHasError(false);
      setIsLoading(true);

      const { data: paginatedResponse } = await debouncedFetch({
        ...mutationFilter,
        skip,
        take: PAGE_SIZE,
      });
      const { data, ...paginationProperties } = paginatedResponse;

      setMutations({
        mutations: paginatedResponse.data,
        checksum: JSON.stringify(mutationFilter).split('').sort().join('') + mutationFilter.query ,
        pagination: paginationProperties
      });

      setIsLoading(false);
    } catch (err) {
      setHasError(true);
      setIsLoading(false);
      throw err;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, mutationFilter]);

  const loadMore = (page: { selected: number }) => {
    setPage(page.selected);
  };

  useEffect(() => {
    fetchMutations();
  }, [fetchMutations]);

  return { isLoading, mutations, hasError, loadMore, setPage, mutationFilter };
}

export default useMutations;
