// @flow

import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import {
  type ContractMutation,
  type DriverChangeMutation,
} from '../../../types/contract-mutation-types';

import { CardItem, CardText } from '../../../components/Card';
import ProfileLink from '../../../components/ProfileLink';
import PhoneLink from '../../../components/PhoneLink';

import { InformationCardItem, InformationCard } from '../common';
import Comments from '../common/Comments';
import VehicleName from '../../../components/VehicleName/VehicleName';
import FormattedDate from '../../../components/FormattedDate';

type Props = {
  mutation: ContractMutation,
  driverChangeMutation: DriverChangeMutation,
};

function DriverChangeInformation({ mutation, driverChangeMutation }: Props) {
  const { t } = useTranslation();

  return (
    <InformationCard color="turquoise">
      <CardItem>
        <div className="row">
          <div className="col-md-6">
            <CardText>
              <Trans i18nKey="driverchangedetail.labels.currentdriver" />
            </CardText>
            <CardText>
              <ProfileLink user={driverChangeMutation.originalDriver} />
            </CardText>
            <CardText>
              <PhoneLink user={mutation.contract ? mutation.contract.contractor : null} />
            </CardText>
          </div>
          <div className="col-md-6">
            <CardText>
              <Trans i18nKey="driverchangedetail.labels.newdriver" />
            </CardText>
            <CardText>
              <ProfileLink user={driverChangeMutation.driver} />
            </CardText>
            <CardText>
              <PhoneLink user={driverChangeMutation.driver} />
            </CardText>
          </div>
        </div>
      </CardItem>
      <CardItem>
        <InformationCardItem label={t('common.labels.vehicle')}>
          {mutation.contract && <VehicleName vehicle={mutation.contract.vehicle} />}
        </InformationCardItem>
        { mutation.contract &&  <InformationCardItem label={t('common.labels.snumber')}>
          {mutation.contract.vehicle.sNumber}
        </InformationCardItem>}
        { mutation.contract && <InformationCardItem label={t('driverchangedetail.labels.platenumber')}>
          {mutation.contract.vehicle.activeLicensePlate}
        </InformationCardItem>}
        { mutation.contract && <InformationCardItem label={t('driverchangedetail.labels.leasingcontractnumber')}>
          {mutation.contract.contractNumber}
        </InformationCardItem>}
        <InformationCardItem label={t('driverchangedetail.labels.requestedchangedate')}>
          <FormattedDate date={mutation.requestedDateOfExecution} />
        </InformationCardItem>
        <InformationCardItem label={t('driverchangedetail.labels.mileageperyear')}>
          {driverChangeMutation.mileagePerYear}
        </InformationCardItem>
      </CardItem>
      <Comments contractMutationId={mutation.id} />
    </InformationCard>
  );
}

export default DriverChangeInformation;
