// @flow

import { fetch } from './fetch';
import { type AxiosResponse } from 'axios';

export type ClientError = {
  id: string,
  createdAt: Date,
  updatedAt: Date,
  message: string,
  stackTrace: string,
  componentStack: string,
  name: string,
};

export function logClientError(payload: {
  message?: string,
  stackTrace?: string,
  name?: string,
  componentStack?: string,
}): Promise<AxiosResponse<ClientError>> {
  return fetch<ClientError>('/client-error-logs', 'POST', payload);
}
