// @flow

import React from 'react';
import cx from 'classnames';

import { type User } from '../../types/user-types';

type Props = {
  user: User,
  className?: string,
  showUnit: boolean,
  style: Object,
};

const baseUrl = 'https://collaboration.swisscom.com/SwisscomPerson.aspx?accountName=CORPROOT%5C';

function ProfileLink({ className, user, showUnit, style }: Props) {
  return (
    <a
      style={style}
      href={baseUrl + user.username}
      className={cx('link', className)}
      target="_blank"
      rel="noopener noreferrer"
    >
      {`${user.swisscomEmployeeNr ? user.swisscomEmployeeNr + ' ' : ''}${user.lastname} ${
        user.firstname
      }${showUnit ? `, ${user.unit}` : ''}`}
    </a>
  );
}

ProfileLink.defaultProps = {
  showUnit: true,
};

export default ProfileLink;
