// @flow

import { useState, useEffect } from 'react';
import { findConflictedBillingCycle } from '../services/billingService';
import { getLastMonth } from '../util/BillingCycleMonthHelper';

function useBillingCycleConflicts() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [conflictedBillingCycle, setConflictedBillingCycle] = useState<boolean>(true);

  useEffect(() => {
    let isCancelled = false;

    async function fetchConflicts() {
      try {
        setIsLoading(true);
        const billingDate = getLastMonth();
        const billingCycle = await findConflictedBillingCycle(billingDate);

        if (!isCancelled) {
          billingCycle.data === '' && setConflictedBillingCycle(false);
          setIsLoading(false);
        }
      } catch (err) {
        if (!isCancelled) {
          setIsLoading(false);
          setHasError(true);
        }
        throw err;
      }
    }

    fetchConflicts();

    return () => {
      isCancelled = true;
    };
  }, []);

  return { isLoading, conflictedBillingCycle, hasError };
}

export default useBillingCycleConflicts;
