// @flow

import React, { useState, useEffect } from 'react';
import { Trans } from 'react-i18next';
import cx from 'classnames';
import useVehicleCategories from '../../hooks/useVehicleCategories';

import VehicleCategoryFilter from '../../components/VehicleCategoryFilter';
import VehicleCategories from '../../components/VehicleCategories';
import styles from './VehicleCategoryAdmin.module.css';

import Divider from '../../components/Divider';

import useVehicleCategoryAdmin from '../../hooks/useVehicleCategoryAdmin';
import { type VehicleCategory } from '../../types/vehicle-category-types';

import Container from '../../components/Container';
function VehicleCategoryAdmin() {
  const { categories, isLoading } = useVehicleCategories('', '', true);
  const { filterProps, setFilterProps } = useVehicleCategoryAdmin();
  const [categoriesToShow, setCategoriesToShow] = useState<VehicleCategory[]>([]);

  const handleClick = id => {
    const newFilterProps = filterProps.map(elm => {
      return { ...elm, active: elm.id === id };
    });
    setFilterProps(newFilterProps);
  };

  const resetInteriorCategories = () => {
    setCategoriesToShow(
      categories.filter(
        cat => !cat.interiorConstruction?.isStandard && cat.type === 'utilityvehicle',
      ),
    );
  };

  const setCategories = (data, update) => {
    let newCategories = categories;
    if (data && !update) {
      // create new
      const newData = {
        ...data,
        name: data.translations.find(elm => elm.languageCode === 'de').name,
        description: data.translations.find(elm => elm.languageCode === 'de').description,
      };
      newCategories.push(newData);
    } else if (data && update) {
      // update existing
      newCategories = newCategories.map(categorie => {
        if (categorie.id === data.id) return data;
        return categorie;
      });
    }

    // TODO: move filter to backend with props, prio 3
    const active = filterProps.find(elm => elm.active);
    if (active.type === 'passengervehicle' && active.isRepresentationVehicle) {
      setCategoriesToShow(
        newCategories.filter(cat => cat.isRepresentationVehicle && cat.type === 'passengervehicle')
      );
    } else if (active.type === 'passengervehicle' && !active.isRepresentationVehicle) {
      setCategoriesToShow(
        newCategories.filter(cat => !cat.isRepresentationVehicle && cat.type === 'passengervehicle')
      );
    } else if (active.type === 'utilityvehicle' && active.interior) {
      setCategoriesToShow(
        newCategories.filter(cat => !cat.interiorConstruction?.isStandard && cat.type === 'utilityvehicle')
      );
    } else if (active.type === 'utilityvehicle' && !active.interior) {
      setCategoriesToShow(
        newCategories.filter(cat => cat.interiorConstruction?.isStandard && cat.type === 'utilityvehicle')
      );
    }
  };

  useEffect(() => {
    if (!filterProps || !categories) return;
    setCategories();
    // eslint-disable-next-line
  }, [filterProps, categories]);

  return (
    <>
      <Container>
        <h1>
          <Trans i18nKey="vehiclecategoryadmin.title" />
        </h1>
        <Divider isFullWidth={true} />
        <div className={cx(styles.subContainer)}>
          <VehicleCategoryFilter handleClick={handleClick} filterProps={filterProps} />
          <VehicleCategories
            filterProps={filterProps}
            categories={categoriesToShow}
            resetInteriorCategories={resetInteriorCategories}
            isLoading={isLoading}
            updateCategories={setCategories}
          />
        </div>
      </Container>
    </>
  );
}

export default VehicleCategoryAdmin;
