import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Card, { CardItem, CardText } from '../../../components/Card';
import Icon from '../../../components/Icon';

import PassengerVehicleFilterModal from '../../../components/PassengerVehicleFilterModal';
import UtilityVehicleFilterModal from '../../../components/UtilityVehicleFilterModal';
import styles from './RequestVehicleCard.module.css';

function RequestVehicleCard() {
  const { t } = useTranslation();
  const [vehicleFilterModal, setVehicleFilterModal] = useState(null);

  return (
    <Card
      title={t('dashboard.requestvehicle.title')}
      color="purple"
      dark
      className="margin-bottom-3"
    >
      {vehicleFilterModal === 'interior' && (
        <UtilityVehicleFilterModal onClose={() => setVehicleFilterModal(null)} />
      )}
      {vehicleFilterModal === 'representation' && (
        <PassengerVehicleFilterModal onClose={() => setVehicleFilterModal(null)} />
      )}
      <CardItem>
        <CardText className="margin-bottom-1 font-size-default">
          {t('dashboard.requestvehicle.label')}
        </CardText>
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <div
              className={styles.vehicleTypeBox}
              role="button"
              tabIndex={-1}
              onClick={() => setVehicleFilterModal('representation')}
            >
              <Icon name="329-car" className="icon--s5"></Icon>
              <span>
                <Trans i18nKey="common.labels.passengervehicle" />
              </span>
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <div
              className={styles.vehicleTypeBox}
              role="button"
              tabIndex={-1}
              onClick={() => setVehicleFilterModal('interior')}
            >
              <Icon name="100-van" className="icon--s5"></Icon>
              <span>
                <Trans i18nKey="common.labels.utilityvehicle" />
              </span>
            </div>
          </div>
        </div>
      </CardItem>
    </Card>
  );
}

export default RequestVehicleCard;
