//@flow

import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { approveMutation } from '../../../../services/contractMutationService';

import ButtonGroup from '../../../../components/ButtonGroup';
import Button from '../../../../components/Button';
import FormField from '../../../../components/FormField';
import Input from '../../../../components/Input';
import Modal from '../../../../components/Modal';
import ValidationErrorMessage from '../../../../components/ValidationErrorMessage';
import { useNotifications } from '../../../../components/Notifications/NotificationsProvider';
import { AuthContext } from '../../../../components/AuthProvider/AuthProvider';
import MessageCard from '../../../../components/MessageCard';
import { type ContractMutation } from '../../../../types/contract-mutation-types';
import { MutationDetailContext } from '../../MutationDetail';

type Props = {
  mutation: ContractMutation,
  onClose: Function,
};

function RejectMutationModalFleet({ mutation, onClose }: Props) {
  const { t } = useTranslation();
  const history = useHistory();
  const { fetchProfile } = useContext(AuthContext);
  const { currentApprovalPoint } = useContext(MutationDetailContext);
  const { addSuccess, addAlert } = useNotifications();

  async function handleSubmit(values, actions) {
    try {
      await approveMutation(mutation.id, values);
      fetchProfile();
      addSuccess(t('mutationdetail.alerts.success'));
      window.scrollTo(0, 0);
      history.push('/mutation-overview');
    } catch {
      addAlert(t('errorstate.defaultmessage'));
      window.scrollTo(0, 0);
      actions.setSubmitting(false);
    }
  }

  const validationSchema = Yup.object().shape({
    approved: Yup.boolean(),
    endLeasingContract: Yup.boolean()
      .default(false)
      .nullable(),
    rejectionComment: Yup.string().required('formvalidation.required.message'),
  });

  return (
    <Modal title={t('vehiclechangedetail.rejectionform.title')} onClose={onClose}>
      <Formik
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        initialValues={{
          approvalPoint: currentApprovalPoint.type,
          approved: false,
          rejectionComment: null,
          endLeasingContract: false,
        }}
      >
        {({
          values,
          touched,
          errors,
          setFieldValue,
          setFieldTouched,
          handleChange,
          handleBlur,
          submitCount,
          handleSubmit,
          isSubmitting,
          isValid,
          submitForm,
        }) => {
          return (
            <form
              onSubmit={e => {
                e.stopPropagation();
                return handleSubmit(e);
              }}
            >
              <MessageCard
                type="important"
                title={t('vehiclechangedetail.rejectionform.notice.title')}
                className="margin-bottom-2"
              >
                <Trans i18nKey="vehiclechangedetail.rejectionform.notice.content" />
              </MessageCard>
              <FormField>
                <Input
                  name="rejectionComment"
                  invalid={touched.rejectionComment && errors.rejectionComment}
                  onChange={e => setFieldValue('rejectionComment', e.currentTarget.value)}
                  onBlur={() => setFieldTouched('rejectionComment')}
                  label={t('mutationdetail.labels.rejectionreason')}
                />
                <ValidationErrorMessage
                  invalid={touched.rejectionComment && errors.rejectionComment}
                  i18nKey={'formvalidation.required.message'}
                />
              </FormField>
              <ButtonGroup responsive>
                <Button
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                >
                  <Trans i18nKey="mutationdetail.buttons.confirmrejection" />
                </Button>
                <Button color="secondary" disabled={isSubmitting} onClick={() => onClose()}>
                  <Trans i18nKey="mutationdetail.buttons.cancel" />
                </Button>
              </ButtonGroup>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
}

export default RejectMutationModalFleet;
