// @flow

import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useParams, useLocation } from 'react-router-dom';

import Container from '../../components/Container';
import useContractQueryFilters from '../ContractOverview/useContractQueryFilters';

import BillList from '../../components/BillList/BillList';
import BillingCycleDetail from '../../container/BillingCycleDetail';
import useBillingCycle from '../../hooks/useBillingCycle';
import useBills from '../../hooks/useBills';

import EmptyState from '../../components/EmptyState/EmptyState';
import ErrorState from '../../components/ErrorState/ErrorState';
import LoadingSpinner from '../../components/LoadingSpinner';
import tableStyles from '../../container/ContractOverview/ContractTable.module.css';
import BackMenu from '../../components/BackMenu';

function ContractSuper() {
  const { t } = useTranslation();
  const { id: billingCycleId } = useParams();
  const { billingCycle, isLoading, hasError } = useBillingCycle(billingCycleId);

  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const page = urlParams.get('p') ? +urlParams.get('p') - 1 : undefined;
  const urlParam = 'billing-cycle';

  const { filter: billsFilter } = useContractQueryFilters();
  const { bills, pagination, loadMore, isLoadingBills } = useBills(
    billingCycleId,
    urlParam,
    billsFilter,
    page,
  );
  return (
    <>
      <BackMenu
        to={{
          pathname: '/contracts',
          state: {
            selectedTab: true,
          },
        }}
        text={t('common.labels.backtooverview')}
      />
      <Container>
        {isLoading && (
          <div className="flex flex-center">
            <div colSpan="12">
              <div className={tableStyles.empty__outer}>
                <LoadingSpinner />
              </div>
            </div>
          </div>
        )}
        {!isLoading && !hasError && !billingCycle?.id && (
          <div className="flex flex-center">
            <div colSpan="12">
              <div className={tableStyles.empty__outer}>
                <EmptyState />
              </div>
            </div>
          </div>
        )}
        {!isLoading && hasError && (
          <div className="flex flex-center">
            <div colSpan="12">
              <div className={tableStyles.empty__outer}>
                <ErrorState />
              </div>
            </div>
          </div>
        )}
        {!isLoading && !hasError && (
          <BillingCycleDetail
            billingId={billingCycleId}
            urlParam={'billing-cycle'}
          ></BillingCycleDetail>
        )}
        {isLoadingBills && (
          <div className="flex flex-center">
            <div colSpan="12">
              <div className={tableStyles.empty__outer}>
                <LoadingSpinner />
              </div>
            </div>
          </div>
        )}
        {!hasError && !isLoadingBills && bills?.length === 0 && (
          <div>
            <h3>
              <Trans i18nKey="contractdetail.billstitle" />
            </h3>
            {/* <div className="flex flex-center">
              <div colSpan="12">
                <div className={tableStyles.empty__outer}>
                  <EmptyState />
                </div>
              </div>
            </div> */}
            <h5>
              <Trans i18nKey="billingcycledetail.nobillsfound" />
            </h5>
          </div>
        )}
        {!hasError && !isLoadingBills && bills?.length !== 0 && (
          <div>
            <h3>
              <Trans i18nKey="contractdetail.billstitle" />
            </h3>
            <BillList
              billingId={billingCycleId}
              urlParam={'billing-cycle'}
              hasError={hasError}
              isLoading={isLoading}
              bills={bills}
              pagination={pagination}
              loadMore={loadMore}
            ></BillList>
          </div>
        )}
      </Container>
    </>
  );
}

export default ContractSuper;
