//@flow

import React from 'react';
import { useHistory } from 'react-router-dom';

import { type Contract } from '../../../types/contract-types';
import FormattedDate from '../../../components/FormattedDate';
import ProfileLink from '../../../components/ProfileLink';
import styles from './ContractListItem.module.css';
import Icon from '../../../components/Icon';
import { formatPrice } from '../../../util/PriceFormatHelper';

type Props = {
  contract: Contract,
};

function ContractListItem({ contract }: Props) {
  const history = useHistory();
  return (
    <tr onClick={() => history.push(`/contracts/${contract.id}`)} className={styles.row}>
      <td>{contract.id}</td>
      <td>{contract?.vehicle?.sNumber}</td>
      <td>{contract?.contractor?.company}</td>
      <td>
        <FormattedDate date={contract?.invoicingContractStartDate} />
      </td>
      <td>
        <FormattedDate date={contract?.invoicingContractEndDate} />
      </td>
      <td>{contract.contractor && <ProfileLink user={contract.contractor} />}</td>
      <td>{contract?.contractor?.swisscomEmployeeNr}</td>

      <td>
        {formatPrice(contract?.vehicle?.vehicleCategory?.pricePerMonth, {
          includeCurrency: true,
          includeCentimes: true,
        })}
      </td>

      <td>{contract?.status}</td>
      <td>
        <FormattedDate date={contract?.lastBillingDate} />
      </td>
      <td>
        <FormattedDate date={contract?.nextBillingDate} />
      </td>
      <td>
        <span
          role="button"
          tabIndex="-1"
          onClick={e => {
            e.stopPropagation();
            history.push(`/contracts/${contract.id}/edit`);
          }}
        >
          <Icon name="edit" className="blue icon--s2" />
        </span>
      </td>
    </tr>
  );
}

export default ContractListItem;
