//@flow

import React from 'react';
import { useTranslation } from 'react-i18next';
import { type ContractMutationApprovalAssignee } from '../../../types/contract-mutation-types';
import FormattedDate from '../../FormattedDate';
import styles from './AssigneeHistoryItem.module.css';
import cx from 'classnames';

type Props = {
  assignee: ContractMutationApprovalAssignee,
  lineThrough: boolean
};

function AssigneeHistoryItem({ assignee, lineThrough }: Props) {
  const { t } = useTranslation();

  return (
    <p className={cx(styles.assigneeText, lineThrough && styles.lineThrough)}>
      <span className={styles.date}>
        <FormattedDate date={assignee.createdAt} formatString="dd.MM.yyyy"></FormattedDate>
      </span>
      <br />
      {t('protocol.editedby')} {assignee.assigneeUser.firstname} {assignee.assigneeUser.lastname}
    </p>
  );
}

export default AssigneeHistoryItem;
