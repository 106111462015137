//@flow

import React, { useState, useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Container from '../../../components/Container';
import { CardItem } from '../../../components/Card';
import Protocol from '../../../components/Protocol';

import {
  type ContractMutation,
  type ReturnVehicleMutation,
  type RequestVehicleMutation,
  type VehicleChangeMutation,
} from '../../../types/contract-mutation-types';

import { MutationTitle, ProtocolCard } from '../common';
import OldVehicleInformation from './OldVehicleInformation';
import NewVehicleInformation from './NewVehicleInformation';
import ApprovalForm from './ApprovalForms/ApprovalForm';
import ApprovalFormFleet from './ApprovalForms/ApprovalFormFleet';
import ProviderModal from './ApprovalForms/ProviderModal';
import PaddedId from '../../../components/PaddedId';
import FormattedDate from '../../../components/FormattedDate';
import Button from '../../../components/Button';
import ConfirmDateModal from './ApprovalForms/ConfirmDateModal';
import { getCurrentVehicleCategory, formatCategoryName } from '../../../util/MutationProperties';
import MessageCard from '../../../components/MessageCard';
import { AuthContext } from '../../../components/AuthProvider/AuthProvider';
import { MutationDetailContext } from '../MutationDetail';
import ConfirmVehicleReturnModal from './ApprovalForms/ConfirmVehicleReturnModal';

type Props = {
  mutation: ContractMutation,
  returnVehicleMutation: ReturnVehicleMutation,
  requestVehicleMutation: RequestVehicleMutation,
  changeVehicleMutation: VehicleChangeMutation,
};

function ChangeVehicleDetail({
  mutation,
  returnVehicleMutation,
  requestVehicleMutation,
  changeVehicleMutation,
}: Props) {
  const [showProviderModal, setShowProviderModal] = useState<boolean>(false);
  const { t } = useTranslation();
  const { profile } = useContext(AuthContext);
  const { userIsApprover, currentApprovalPoint, protocol, protocolIsLoading } = useContext(
    MutationDetailContext,
  );

  const currentCategory = getCurrentVehicleCategory(requestVehicleMutation);

  const [selectedCategory, setSelectedCategory] = useState({
    value: currentCategory.id,
    label: formatCategoryName(currentCategory),
  });

  const [selectedReturnDate, setSelectedReturnDate] = useState(mutation.dateOfExecution);

  // Whether or not the provider has changed
  let providerChanged = false;
  if (mutation.contract) {
    providerChanged =
      mutation.contract.vehicle.provider.id !== requestVehicleMutation.selectedProvider?.id;
  }

  // User is provider of the vehicle that is being returned
  let currentUserIsOldProvider = false;
  if (mutation.contract) {
    currentUserIsOldProvider =
      mutation.contract.vehicle?.provider?.responsibles?.length &&
      mutation.contract.vehicle.provider.responsibles.find(
        e => e.swisscomEmployeeNr === profile?.swisscomEmployeeNr,
      );
  }

  // Enable if provider changed and the vehicle return hasn't been confirmed yet by the old provider
  const enableVehicleReturnConfirmationModal =
    providerChanged &&
    currentUserIsOldProvider &&
    !mutation.changeVehicleMutation?.vehicleReturnConfirmed;

  // Enable if the mutation is pending and the date hasn't been confirmed yet by the new provider
  const enableDateConfirmationModal =
    !mutation.closedAt && userIsApprover && !mutation.changeVehicleMutation?.exchangeDateConfirmed;

  // Enable if the date has been confirmed and the mutation hasn't been closed by the new provider
  const enableCloseMutationModal =
    !mutation.closedAt &&
    currentApprovalPoint.approved == null &&
    userIsApprover &&
    !enableDateConfirmationModal;

  if (currentApprovalPoint.type === 'PROVIDER') {
    return (
      <Container>
        <div className="row flex-items-md-bottom">
          {showProviderModal &&
            mutation.dateOfExecution &&
            (enableCloseMutationModal ? (
              <ProviderModal
                mutationId={mutation.id}
                requireSNumber={!requestVehicleMutation.sNumber}
                onClose={() => setShowProviderModal(false)}
              />
            ) : enableDateConfirmationModal ? (
              <ConfirmDateModal
                mutationId={mutation.id}
                onClose={() => setShowProviderModal(false)}
                executionDate={new Date(mutation.dateOfExecution)}
              />
            ) : (
              enableVehicleReturnConfirmationModal && (
                <ConfirmVehicleReturnModal
                  mutationId={mutation.id}
                  onClose={() => setShowProviderModal(false)}
                  executionDate={new Date(mutation.dateOfExecution)}
                />
              )
            ))}
          <div className="col-sm-12 col-md-8 margin-bottom-3">
            <h1>
              <Trans i18nKey={`mutationtypes.${mutation.type.toLowerCase()}`} />{' '}
              <PaddedId id={mutation.id} />
            </h1>
          </div>
          <div className="col-sm-12 col-md-4 margin-bottom-3 text-align-right">
            {enableCloseMutationModal || enableDateConfirmationModal ? (
              <Button onClick={() => setShowProviderModal(true)}>
                {changeVehicleMutation?.exchangeDateConfirmed ? (
                  <Trans i18nKey="vehiclechangedetail.labels.order" />
                ) : (
                  <Trans i18nKey="vehiclechangedetail.dateconfirmationform.label" />
                )}
              </Button>
            ) : enableVehicleReturnConfirmationModal ? (
              <Button onClick={() => setShowProviderModal(true)}>
                <Trans i18nKey="vehiclechangedetail.vehiclereturnconfirmationform.label" />
              </Button>
            ) : (
              <FormattedDate date={mutation.closedAt} />
            )}
          </div>
        </div>
        {providerChanged &&
          mutation.closedAt == null &&
          (userIsApprover ? (
            <div className="row">
              <div className="col-12 flex margin-bottom-3">
                <MessageCard type="info" title={t(`mutationtypes.${mutation.type.toLowerCase()}`)}>
                  {mutation.contract && (
                    <Trans
                      i18nKey="vehiclechangedetail.providernotice.newprovider"
                      values={{
                        providerName: mutation.contract.vehicle.provider.name,
                        providerEmail: mutation.contract.vehicle.provider.email || '-',
                      }}
                    />
                  )}
                </MessageCard>
              </div>
            </div>
          ) : (
            currentUserIsOldProvider &&
            !mutation.changeVehicleMutation?.vehicleReturnConfirmed && (
              <div className="row">
                <div className="col-12 flex margin-bottom-3">
                  <MessageCard
                    type="info"
                    title={t(`mutationtypes.${mutation.type.toLowerCase()}`)}
                  >
                    <Trans i18nKey="vehiclechangedetail.providernotice.oldprovider" />
                  </MessageCard>
                </div>
              </div>
            )
          ))}
        <div className="row">
          <div className="col-sm-12 col-md-7 flex margin-bottom-3">
            <OldVehicleInformation
              mutation={mutation}
              returnVehicleMutation={returnVehicleMutation}
            />
          </div>
          <div className="col-sm-12 col-md-5 flex margin-bottom-3">
            <ProtocolCard>
              <CardItem>
                <Protocol mutation={mutation} items={protocol} isLoading={protocolIsLoading} />
              </CardItem>
            </ProtocolCard>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-7 flex margin-bottom-3">
            {(!providerChanged || !currentUserIsOldProvider) && (
              <NewVehicleInformation
                mutation={{ ...mutation, dateOfExecution: selectedReturnDate }}
                requestVehicleMutation={requestVehicleMutation}
              />
            )}
          </div>
        </div>
      </Container>
    );
  }

  return (
    <Container>
      <MutationTitle mutation={mutation} />
      <div className="row">
        <div className="col-sm-12 col-md-7 flex  margin-bottom-3">
          <OldVehicleInformation
            mutation={mutation}
            returnVehicleMutation={returnVehicleMutation}
            selectedReturnDateChanged={e => setSelectedReturnDate(e)}
          />
        </div>
        <div className="col-sm-12 col-md-5 flex margin-bottom-3">
          <ProtocolCard>
            <CardItem>
              <Protocol mutation={mutation} items={protocol} isLoading={protocolIsLoading} />
            </CardItem>
          </ProtocolCard>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-7 flex  margin-bottom-3">
          <NewVehicleInformation
            mutation={{ ...mutation, dateOfExecution: selectedReturnDate }}
            requestVehicleMutation={requestVehicleMutation}
            selectedCategoryChanged={e => setSelectedCategory(e)}
          />
        </div>
      </div>
      {userIsApprover &&
        mutation.status === 'PENDING' &&
        (currentApprovalPoint.type === 'FLEET_MGMT' ? (
          <ApprovalFormFleet
            mutation={mutation}
            selectedCategory={selectedCategory}
            selectedReturnDate={selectedReturnDate}
          />
        ) : (
          <ApprovalForm
            mutation={mutation}
            selectedCategory={selectedCategory}
            selectedReturnDate={selectedReturnDate}
          />
        ))}
    </Container>
  );
}

export default ChangeVehicleDetail;
