/* eslint-disable arrow-parens */
// @flow

import { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../components/AuthProvider/AuthProvider';
import { addAssignee, getMutationAssignees } from '../services/contractMutationService';
import {
  type ContractMutation,
  type ContractMutationApprovalAssignee,
} from '../types/contract-mutation-types';

function useAssignees(mutation: ContractMutation, itemStatus: string) {
  const { profile } = useContext(AuthContext);
  const [assigneesLoading, setAssigneesLoading] = useState<boolean>(true);
  const [assignees, setAssignees] = useState<ContractMutationApprovalAssignee[]>([]);
  const [exchangeAssignees, setExchangeAssignees] = useState<ContractMutationApprovalAssignee[]>(
    [],
  );
  const [returnAssignees, setReturnAssignees] = useState<ContractMutationApprovalAssignee[]>([]);

  const addNewAssignee = async (referenceType: string, changeVehicleMutationId?: number) => {
    // typedAssignee gets the current assignees depending on type in driver change
    let typedAssignees;
    if (referenceType === 'EXCHANGE') {
      typedAssignees = exchangeAssignees;
    } else if (referenceType === 'RETURN') {
      typedAssignees = returnAssignees;
    } else {
      typedAssignees = assignees;
    }
    // if there was already an assignee it gets his Id to override him otherwise sets it to undefined
    const overridesId = typedAssignees.length > 0 ? typedAssignees[0].id : undefined;

    const payload = {
      employeeNr: profile?.swisscomEmployeeNr,
      approvalType: itemStatus,
      referenceType,
      overridesId,
      changeVehicleMutationId,
    };

    const { data: assignee } = await addAssignee(mutation.id, payload);
    if (referenceType === 'EXCHANGE') {
      setExchangeAssignees([assignee, ...exchangeAssignees]);
    } else if (referenceType === 'RETURN') {
      setReturnAssignees([assignee, ...returnAssignees]);
    } else {
      setAssignees([assignee, ...assignees]);
    }
  };

  useEffect(() => {
    setAssigneesLoading(true);

    const getContractMutationAssignees = async (id: number) => {
      const { data: contractMutation } = await getMutationAssignees(mutation.id);
      const approvalStep = contractMutation.approvals
        ? contractMutation.approvals.find((approval) => approval.type === itemStatus)
        : undefined;

      const approvalAssignees = approvalStep?.assignees || [];

      const mutationExchangeAssignees =
        contractMutation.changeVehicleMutation?.exchangeDateConfirmationAssignees || [];

      const mutationReturnAssignees =
        contractMutation.changeVehicleMutation?.vehicleReturnConfirmationAssignees || [];

      if (assignees.length < approvalAssignees.length) {
        setAssignees(approvalAssignees);
      }

      if (exchangeAssignees.length < mutationExchangeAssignees.length) {
        setExchangeAssignees(mutationExchangeAssignees);
      }

      if (returnAssignees.length < mutationReturnAssignees.length) {
        setReturnAssignees(mutationReturnAssignees);
      }      
      setAssigneesLoading(false);
    };

    getContractMutationAssignees();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutation, itemStatus]);

  return { assignees, exchangeAssignees, returnAssignees, addNewAssignee, assigneesLoading };
}

export default useAssignees;
