import React, { useContext, useState } from 'react';
import { Trans } from 'react-i18next';
import i18n, { DEFAULT_LANGUAGE, LANGUAGES } from '../../../i18n';
import { AuthContext } from '../../AuthProvider/AuthProvider';
import FlyoutMenuItem from '../../FlyOutMenuItem/FlyOutMenuItem';
import Icon from '../../Icon';
import styles from './LanguageSelect.module.css';
import LanguageSelectButton from './LanguageSelectButton/LanguageSelectButton';


function LanguageSelect() {
  const [open, setOpen] = useState(false);
  const { fetchProfile } = useContext(AuthContext);

  const chevronIcon = open ? '016-chevron-normal-up' : '013-chevron-normal-down';

  const handleToggle = event => {
    event.stopPropagation();
    setOpen(!open);
    fetchProfile();
  };

  const browserLanguage = i18n.language.substr(0, 2).toLowerCase();
  const activeLanguage = LANGUAGES.includes(browserLanguage) ? browserLanguage : DEFAULT_LANGUAGE;

  return (
    <>
      <FlyoutMenuItem onClick={(e: FlyOutListItemMouseEvent) => handleToggle(e)}>
        <div className={styles.container}>
          <span>
            <Trans i18nKey={'common.languages.' + activeLanguage}></Trans>
          </span>
          <Icon className={styles.icon} name={chevronIcon}></Icon>
        </div>
      </FlyoutMenuItem>
      {open &&
        LANGUAGES.filter(lang => lang !== activeLanguage).map((lang, key) => (
          <LanguageSelectButton key={key} lang={lang} handleToggle={handleToggle} />
        ))}
    </>
  );
}

export default LanguageSelect;
