// @flow

import React from 'react';
import cx from 'classnames';
import styles from './Divider.module.css';

type Props = {
  isFullWidth?: boolean,
  className?: string
};


function Divider({ isFullWidth, className }: Props) {
  const fullWidthClass = isFullWidth ? styles.fullWidthDivider : '';
  return <hr className={ cx(className, fullWidthClass) }></hr>;
}

export default Divider;
