// @flow

import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';

import cx from 'classnames';
import tableStyles from '../ContractOverview/ContractTable.module.css';
import debtorStyles from './DebtorListItem/DebtorListItem.module.css';

import Container from '../../components/Container';
import LoadingSpinner from '../../components/LoadingSpinner';
import EmptyState from '../../components/EmptyState';
import ErrorState from '../../components/ErrorState';
import Pagination from '../../components/Pagination';

import useDebtors from '../../hooks/useDebtors';
import DebtorListItem from './DebtorListItem';
import Button from '../../components/Button/Button';

function DebtorOverview() {
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const urlParams = new URLSearchParams(location.search);
  const page = urlParams.get('p') ? +urlParams.get('p') - 1 : undefined;

  const { debtors, isLoading, hasError, pagination, loadMore } = useDebtors(page);

  return (
    <Container>
      <div className={debtorStyles.header}>
        <h1>
          <Trans i18nKey="debtoroverview.title" />
        </h1>
        <Button onClick={() => history.push(`debtor/create`)}>
          {t('debtoroverview.createdebtor')}
        </Button>
      </div>

      <div className="row">
        <div className={`table table--responsive ${tableStyles.orderlist}`}>
          <div className="table__wrapper">
            <table className={cx({ [tableStyles.empty]: debtors?.length === 0 })}>
              <thead>
                <tr>
                  <td style={{ width: '10%' }}>
                    <Trans i18nKey="debtoroverview.labels.name" />
                  </td>
                  <td style={{ width: '15%' }}>
                    <Trans i18nKey="debtoroverview.labels.number" />
                  </td>

                  <td style={{ width: '15%' }}>
                    <Trans i18nKey="debtoroverview.labels.accountnr" />
                  </td>

                  <td style={{ width: '10%' }}>
                    <Trans i18nKey="debtoroverview.labels.type" />
                  </td>
                  <td style={{ width: '45%' }}>
                    <Trans i18nKey="debtoroverview.labels.status" />
                  </td>
                  <td style={{ width: '5%' }}></td>
                </tr>
              </thead>

              <tbody>
                {isLoading && (
                  <tr>
                    <td colSpan="12">
                      <div className={tableStyles.empty__outer}>
                        <LoadingSpinner />
                      </div>
                    </td>
                  </tr>
                )}
                {!isLoading && !hasError && debtors?.length === 0 && (
                  <tr>
                    <td colSpan="12">
                      <div className={tableStyles.empty__outer}>
                        <EmptyState />
                      </div>
                    </td>
                  </tr>
                )}
                {!isLoading && hasError && (
                  <tr>
                    <td colSpan="12">
                      <div className={tableStyles.empty__outer}>
                        <ErrorState />
                      </div>
                    </td>
                  </tr>
                )}

                {!isLoading &&
                  !hasError &&
                  debtors?.length > 0 &&
                  debtors.map(d => <DebtorListItem key={d.id} debtor={d} />)}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 flex flex-items-center flex-space-between">
          {pagination.totalCount > pagination.take && pagination.totalCount > 0 && (
            <Pagination pagination={pagination} loadMore={loadMore} />
          )}
        </div>
      </div>
    </Container>
  );
}

export default DebtorOverview;
