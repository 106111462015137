// @flow

import { useState, useEffect } from 'react';
import { type VehicleInteriorConstruction } from '../types/vehicle-category-types';
import { getVehicleInteriors } from '../services/vehicleCategoryService';
import { createVehicleInterior, updateVehicleInterior, deleteVehicleInterior } from '../services/vehicleCategoryService';

function useVehicleInteriors(withDeleted: boolean = false) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [interiors, setInteriors] = useState<VehicleInteriorConstruction[]>([]);
  const [filteredInteriors, setFilteredInteriors] = useState<VehicleInteriorConstruction[]>([]);

  useEffect(() => {
    let isCancelled = false;

    async function fetchInteriors() {
      try {
        setIsLoading(true);
        const { data } = await getVehicleInteriors(withDeleted);
        if (!isCancelled) {
          setInteriors(data);
          setIsLoading(false);
        }
      } catch (err) {
        if (!isCancelled) {
          setHasError(true);
          setIsLoading(false);
        }
        throw err;
      }
    }

    fetchInteriors();

    return () => {
      isCancelled = true;
    };
  }, [withDeleted]);

  const submitInterior = async (values: VehicleInteriorConstruction) => {
    if (!values.id) {
      const { data } = await createVehicleInterior(values);
      setFilteredInteriors([...filteredInteriors, data]);
    } else {
      const { data } = await updateVehicleInterior(values, values.id);
      const newInteriors = filteredInteriors.map(interior => {
        if (interior.id === data.id) return data;
        return interior;
      });
      setFilteredInteriors(newInteriors.filter(elm => !elm.isStandard));
    }
    return;
  };

  const replaceSingleInterior = (id: number, newInterior: VehicleInteriorConstruction) => {
    setFilteredInteriors([...filteredInteriors].map(i => {
      return i.id !== id ? i : newInterior;
    }));
  };

  const removeInterior = async (interiorId: number) => {
    const {data} = await deleteVehicleInterior(interiorId);
    replaceSingleInterior(interiorId, data);
  };

  const restoreInterior = async (interior: VehicleInteriorConstruction) => {
    const {data} = await updateVehicleInterior({...interior, deletedAt: null}, interior.id );

    replaceSingleInterior(interior.id, data);
  };

  return { isLoading, interiors, hasError, submitInterior, filteredInteriors, setFilteredInteriors, removeInterior, restoreInterior };
}

export default useVehicleInteriors;
