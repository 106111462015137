import common from './config.default';
import development from './config.dev';
import feature from './config.feature';
import integration from './config.int';
import production from './config.prod';
import local from './config.local';

const getConfig = () => {
  const stage = process.env.REACT_APP_STAGE;
  switch (stage) {
    case 'local':
      return { ...common, ...local };
    case 'feature':
      return { ...common, ...feature };
    case 'development':
      return { ...common, ...development };
    case 'integration':
      return { ...common, ...integration };
    case 'production':
      return { ...common, ...production };
    default:
      throw new Error('No stage specified');
  }
};

export default getConfig();
