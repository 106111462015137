// @flow

import React, { useEffect } from 'react';
import useVehicleProviders from '../../hooks/useVehicleProviders';
import { useNotifications } from '../Notifications/NotificationsProvider';
import { useTranslation } from 'react-i18next';
import Select from '../Select';
import { type VehicleProvider } from '../../types/vehicle-provider-types';

type Props = {
  name: string,
  onChange: Function,
  onBlur?: Function,
  value?: VehicleProvider,
  label?: string,
  className?: string,
  placeholderI18nKey?: string,
};

function ProviderSelect({
  value,
  name,
  onBlur,
  onChange,
  label,
  className,
  placeholderI18nKey = 'common.select',
}: Props) {
  const { providers, hasError } = useVehicleProviders();
  const { addAlert } = useNotifications();
  const { t } = useTranslation();

  useEffect(() => {
    if (hasError) {
      addAlert(t('errorstate.providerloadingerror'));
    }
  }, [addAlert, hasError, t]);

  return (
    !hasError && (
      <Select
        name={name}
        onChange={e => onChange(providers.find(p => p.id === e?.value))}
        onBlur={onBlur}
        value={value && { value: value.id, label: value.name, hasReserve: value.hasReserve }}
        options={providers.map(provider => ({ value: provider.id, label: provider.name }))}
        className={className}
        placeholder={t(placeholderI18nKey)}
      />
    )
  );
}

export default ProviderSelect;
