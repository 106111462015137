// @flow

import React from 'react';

import styles from './Comments.module.css';
import useComments from '../../../../hooks/useComments';
import { Trans } from 'react-i18next';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import { CardItem } from '../../../../components/Card';

type Props = {
  contractMutationId: number,
};

function Comments({ contractMutationId }: Props) {
  const { comments, isLoading } = useComments(contractMutationId);

  if (isLoading) {
    return (
      <div className="flex flex-center">
        <LoadingSpinner size="small" />
      </div>
    );
  }

  if (!isLoading && comments.length === 0) {
    return null;
  }

  return (
    <CardItem>
      <p>
        <Trans i18nKey="common.labels.comment" />
      </p>
      <div>
        {comments.map(comment => {
          return (
            <div className={styles.item} key={new Date(comment.createdAt).getTime()}>
              <p className={styles.commentHeading}>
                {comment.author?.lastname} {comment.author?.firstname}, {comment.author?.unit}
              </p>
              <p className={styles.commentHeading}>
                <Trans i18nKey={`protocol.status.` + comment.approvalPoint} />
              </p>
              <p className={styles.text}>{comment.comment}</p>
            </div>
          );
        })}
      </div>
    </CardItem>
  );
}

export default Comments;
