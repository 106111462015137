//@flow

import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Formik } from 'formik';

import Button from '../Button';
import ButtonGroup from '../ButtonGroup';
import Modal from '../Modal';
import FormField from '../FormField';
import Input from '../Input';
import ValidationErrorMessage from '../ValidationErrorMessage';
import RadioButtonGroup from '../RadioButtonGroup';
import RadioButton from '../RadioButton';
import UserPicker from '../UserPicker';
import { useNotifications } from '../Notifications/NotificationsProvider';
import OrderNumberPicker from '../OrderNumberPicker';
import { validationSchema } from './validation-schema';
import { type VehicleProvider } from '../../types/vehicle-provider-types';

import DeleteButton from '../DeleteButton';

type Props = {
  onClose: Function,
  provider: VehicleProvider,
  updateProvider: Function,
  deleteProvider: Function,
  addNewProvider: Function,
  type: 'edit' | 'create',
};

function EditProviderModal({ type, onClose, provider, updateProvider, deleteProvider, addNewProvider }: Props) {
  const [customError, setCustomError] = useState<?string>(null);
  const { t } = useTranslation();
  const { addSuccess, addAlert } = useNotifications();

  const initialValues = provider ? {
    ...provider,
    orderNumbers: provider.orderNumbers
      ? provider.orderNumbers.map(orderNumber => {
        return { label: orderNumber, value: orderNumber };
      })
      : [],
    responsibles: provider.responsibles
      ? provider.responsibles.map(user => {
        return {
          label: `${user.firstname} ${user.lastname}, ${user.unit}`,
          value: user.swisscomEmployeeNr,
        };
      })
      : [],
  } : {
    name: '',
    avriosIdentifier: '',
    hasReserve: false,
    email: '',
    orderNumbers: null,
    responsibles: null,
  };

  const handleSubmit = async (values, actions) => {
    setCustomError(null);
    try {
      const payload = {
        name: values.name,
        avriosIdentifier: values.avriosIdentifier,
        hasReserve: values.hasReserve,
        email: values.email,
        orderNumbers: values.orderNumbers
          ? values.orderNumbers.map(orderNumber => orderNumber.value)
          : [],
        responsiblesEmployeeNrs: values.responsibles
          ? values.responsibles.map(employee => employee.value)
          : [],
      };
      if (type === 'edit') {
        await updateProvider(provider.id, payload);
        addSuccess(t('provideradministration.alerts.editsuccess'));
      } else if (type === 'create') {
        await addNewProvider(payload);
        addSuccess(t('provideradministration.alerts.submitsuccess'));
      }

      onClose();
    } catch (err) {
      if (err?.response?.data?.error === 'CONFLICT_AVRIOS_IDENTIFIER_ALREADY_EXISTS') {
        setCustomError('provideradministration.error.providerexists');
      }
      addAlert(t('provideradministration.alerts.failure'));
      actions.setSubmitting(false);
    }
  };

  const handleDeleteProvider = async () => {
    try {
      await deleteProvider(provider.id);
      addSuccess(t('provideradministration.alerts.deletesuccess'));
      onClose();
    } catch {
      addAlert(t('provideradministration.alerts.failure'));
    }
  };

  return (
    <Modal title={type === 'edit' ? t('provideradministration.editprovider') : t('provideradministration.createprovider')} onClose={onClose} size="medium">
      <Formik
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        initialValues={initialValues}
      >
        {({
          values,
          touched,
          errors,
          setFieldValue,
          setFieldTouched,
          handleSubmit,
          isSubmitting,
        }) => {
          return (
            <form
              onSubmit={handleSubmit}
            >
              <FormField label={t('provideradministration.name')} required>
                <Input
                  id="name"
                  type="text"
                  value={values.name}
                  onChange={e => setFieldValue('name', e.currentTarget.value)}
                  hasMarginTop={false}
                />
                <ValidationErrorMessage
                  invalid={touched.name && errors.name}
                  i18nKey={errors.name}
                />
              </FormField>

              <FormField label={t('provideradministration.avriosidentifier')} required>
                <Input
                  id="avriosIdentifier"
                  type="text"
                  value={values.avriosIdentifier}
                  onChange={e => setFieldValue('avriosIdentifier', e.currentTarget.value)}
                  hasMarginTop={false}
                />
                <ValidationErrorMessage
                  invalid={touched.avriosIdentifier && errors.avriosIdentifier}
                  i18nKey={errors.avriosIdentifier}
                />
                <ValidationErrorMessage
                  invalid={!!customError}
                  i18nKey={customError}
                />
              </FormField>

              <FormField label={t('provideradministration.hasreserve')} required>
                <RadioButtonGroup
                  value={values.hasReserve}
                  onChange={e => setFieldValue('hasReserve', e === 'true')}
                >
                  <RadioButton value={false}>
                    <Trans i18nKey="common.no" />
                  </RadioButton>
                  <RadioButton value={true}>
                    <Trans i18nKey="common.yes" />
                  </RadioButton>
                </RadioButtonGroup>
                <ValidationErrorMessage
                  invalid={touched.hasReserve && errors.hasReserve}
                  i18nKey={errors.hasReserve}
                />
              </FormField>

              <FormField label={t('provideradministration.email')}>
                <Input
                  id="email"
                  type="email"
                  value={values.email}
                  onChange={e => setFieldValue('email', e.currentTarget.value)}
                  hasMarginTop={false}
                />
                <ValidationErrorMessage
                  invalid={touched.email && errors.email}
                  i18nKey={errors.email}
                />
              </FormField>

              <FormField label={t('provideradministration.ordernumbers')}>
                <OrderNumberPicker
                  name="orderNumbers"
                  value={values.orderNumbers}
                  onChange={numbers => setFieldValue('orderNumbers', numbers)}
                  onBlur={() => setFieldTouched('orderNumbers', true)}
                  invalid={touched.orderNumbers && errors.orderNumbers}
                  isMulti
                />
                <ValidationErrorMessage
                  invalid={touched.orderNumbers && errors.orderNumbers}
                  i18nKey={
                    Array.isArray(errors.orderNumbers)
                      ? errors.orderNumbers.find(
                        element => element != null,
                      )?.value
                      : errors.orderNumbers
                  }
                />
              </FormField>

              <FormField label={t('provideradministration.responsibles')}>
                <UserPicker
                  name="responsibles"
                  value={values.responsibles}
                  onChange={user => setFieldValue('responsibles', user)}
                  onBlur={() => setFieldTouched('responsibles', true)}
                  invalid={touched.responsibles && errors.responsibles}
                  isMulti
                />
                <ValidationErrorMessage
                  invalid={touched.responsibles && errors.responsibles}
                  i18nKey={errors.responsibles}
                />
              </FormField>

              <ButtonGroup responsive>
                <Button
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                >
                  <Trans i18nKey={type === 'create' ? 'provideradministration.create' : 'common.save'} />
                </Button>
                <Button color="secondary" disabled={isSubmitting} onClick={() => onClose()}>
                  <Trans i18nKey="common.cancel" />
                </Button>

                {type === 'edit' && <DeleteButton text={t('provideradministration.deleteprovider')} onClick={() => handleDeleteProvider()} />}
              </ButtonGroup>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
}

export default EditProviderModal;
