//@flow

import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { approveMutation } from '../../../../services/contractMutationService';

import AutoId from '../../../../components/AutoId';
import Button from '../../../../components/Button';
import ButtonGroup from '../../../../components/ButtonGroup';
import Checkbox from '../../../../components/Checkbox';
import FormField from '../../../../components/FormField';
import Modal from '../../../../components/Modal';
import ValidationErrorMessage from '../../../../components/ValidationErrorMessage';

import { useNotifications } from '../../../../components/Notifications/NotificationsProvider';
import Input from '../../../../components/Input';
import { AuthContext } from '../../../../components/AuthProvider/AuthProvider';
import VehicleExistsValidator from '../../common/VehicleExistsValidator';

type Props = {
  mutationId: number,
  onClose: Function,
  requireSNumber: boolean,
};

function ProviderModal({ mutationId, onClose, requireSNumber }: Props) {
  const { t } = useTranslation();
  const history = useHistory();
  const { addSuccess, addAlert } = useNotifications();
  const { fetchProfile } = useContext(AuthContext);

  async function handleSubmit(values, actions) {
    try {
      await approveMutation(mutationId, values);
      fetchProfile();
      addSuccess(t('mutationdetail.alerts.success'));
      window.scrollTo(0, 0);
      history.push('/mutation-overview');
    } catch {
      addAlert(t('errorstate.defaultmessage'));
      window.scrollTo(0, 0);
      actions.setSubmitting(false);
    }
  }

  const sNumberValidation = Yup.string()
    .required('formvalidation.required.message')
    .nullable();

  const validationSchema = Yup.object().shape({
    vehicleDeliveryConfirmation: Yup.boolean()
      .required('formvalidation.required.message')
      .oneOf([true], 'formvalidation.required.message'),
    sNumber: requireSNumber ? sNumberValidation : undefined,
  });

  return (
    <Modal title={t('vehiclechangedetail.providerform.title')} onClose={() => onClose()}>
      <Formik
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        initialValues={{
          approvalPoint: 'PROVIDER',
          approved: true,
          vehicleDeliveryConfirmation: false,
          vehicleReturnConfirmation: false,
          sNumber: '',
        }}
      >
        {({
          values,
          touched,
          errors,
          setFieldValue,
          setFieldTouched,
          handleChange,
          handleBlur,
          submitCount,
          handleSubmit,
          isSubmitting,
          isValid,
          submitForm,
        }) => {
          return (
            <form
              onSubmit={e => {
                e.stopPropagation();
                return handleSubmit(e);
              }}
            >
              <FormField label={t('vehiclechangedetail.providerform.message')}>
                <AutoId>
                  {id => (
                    <>
                      <Checkbox
                        className="margin-bottom-3"
                        id={id}
                        label={t('vehiclechangedetail.providerform.confirmation')}
                        name="vehicleDeliveryConfirmation"
                        required
                        value={values.vehicleDeliveryConfirmation}
                        onChange={event => {
                          setFieldValue('vehicleDeliveryConfirmation', event.currentTarget.checked);
                          setFieldValue('vehicleReturnConfirmation', event.currentTarget.checked);
                          setFieldTouched('vehicleDeliveryConfirmation', true);
                        }}
                      />
                      <ValidationErrorMessage
                        className="margin-top-0 margin-bottom-4"
                        invalid={
                          touched.vehicleDeliveryConfirmation && errors.vehicleDeliveryConfirmation
                        }
                        i18nKey={errors.vehicleDeliveryConfirmation}
                      ></ValidationErrorMessage>
                    </>
                  )}
                </AutoId>
              </FormField>
              {requireSNumber && (
                <FormField label={t('common.labels.snumber')} required>
                  <Input
                    hasMarginTop={false}
                    onChange={handleChange}
                    name="sNumber"
                    value={values.sNumber}
                    onBlur={handleBlur}
                    invalid={errors.sNumber && touched.sNumber}
                  />
                  <ValidationErrorMessage
                    invalid={errors.sNumber && touched.sNumber}
                    i18nKey={errors.sNumber}
                  />
                </FormField>
              )}
              {requireSNumber && values.sNumber && values.sNumber.length > 0 && (
                <VehicleExistsValidator sNumber={values.sNumber} />
              )}
              <ButtonGroup responsive>
                <Button
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                >
                  <Trans i18nKey="requestvehicledetail.providermodal.confirm" />
                </Button>
                <Button color="secondary" disabled={isSubmitting} onClick={() => onClose()}>
                  <Trans i18nKey="mutationdetail.buttons.cancel" />
                </Button>
              </ButtonGroup>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
}

ProviderModal.defaultProps = {
  requireSNumber: true,
};

export default ProviderModal;
