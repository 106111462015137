// @flow

import React, { type Node } from 'react';
import useIdentifier from '../../hooks/useIdentifier';
import cx from 'classnames';

import styles from './RadioButton.module.css';

type Props = {
  checked?: boolean,
  children: Node,
  onChange?: Function,
  disabled?: boolean,
  description?: string | Node,
  name?: string,
  id?: string,
  value?: string,
  className?: string,
};

function RadioButton({
  id,
  checked,
  children,
  disabled,
  description,
  name,
  onChange,
  value,
  className,
}: Props) {
  const autoGeneratedId = useIdentifier();
  return (
    <div className={cx(styles.wrapper, className)}>
      <div className="radio">
        <input
          type="radio"
          name={name}
          id={id || autoGeneratedId}
          checked={checked}
          value={value}
          disabled={disabled}
          onChange={onChange}
        />
        <label htmlFor={id || autoGeneratedId}>{children}</label>
      </div>
      <div className={styles.description}>{description}</div>
    </div>
  );
}

export default RadioButton;
