// @flow

import React, { useState, useEffect } from 'react';
import isEqual from 'lodash/fp/isEqual';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Formik } from 'formik';

import Container from '../../components/Container';
import UserPicker from '../../components/UserPicker';
import BackMenu from '../../components/BackMenu';

import DatePicker from '../../components/DatePicker/DatePicker';
import FormField from '../../components/FormField';
import ButtonGroup from '../../components/ButtonGroup/ButtonGroup';
import Button from '../../components/Button/Button';
import ValidationErrorMessage from '../../components/ValidationErrorMessage';
import { initialValues } from './initial-values';
import { validationSchema } from './validation-schema';
import RoutingPrompt from '../../components/RoutingPrompt';
import { useNotifications } from '../../components/Notifications/NotificationsProvider';
import Input from '../../components/Input';
import VehiclesTypeahead from '../../components/VehiclesTypeahead';
import useFormattedContract from '../../hooks/useFormattedContract';
import { createContract, softDeleteContract, updateContract } from '../../services/contractService';
import ConfirmationModal from '../../components/ConfirmationModal';
import DeleteButton from '../../components/DeleteButton';
import { type Contract } from '../../types/contract-types';
import Select from '../../components/Select';
import { ContractStatus } from '../../enums/contract-status';

function ContractForm() {
  const { t } = useTranslation();
  const history = useHistory();
  const [hasSuccess, setHasSuccess] = useState<boolean>(false);
  const { addSuccess, addAlert } = useNotifications();
  const { id: contractId } = useParams();
  const [formInitialValues, setFormInitialValues] = useState<?Contract>();
  const { contract } = useFormattedContract(contractId);
  const [confirmationModal, setConfirmationModal] = useState<boolean>(false);

  const statusOptions = Object.keys(ContractStatus).map(status => ({ label: status, value: status }));

  useEffect(() => {
    let checkId = async () => {
      if (contractId) {
        setFormInitialValues(contract);
      } else {
        setFormInitialValues(initialValues);
      }
    };
    checkId();
  }, [contractId, contract]);

  const toggleConfirmationModal = () => {
    setConfirmationModal(!confirmationModal);
  };

  const deleteContract = async () => {
    try {
      softDeleteContract(contractId);
      history.push('/contracts');
    } catch (err) {
      addAlert(t('contractform.submit.failure'));
      throw err;
    }
  };

  async function handleSubmit(values, actions) {
    try {
      const payload = {
        contractNumber: Number(values.contractNumber),
        driverEmployeeNumber: Number(values.driver?.value),
        invoicingContractStartDate: values.invoicingContractStartDate,
        invoicingContractEndDate: values.invoicingContractEndDate,
        vehicleId: Number(values.vehicle?.value),
        status: values.status
      };

      if (contractId) {
        await updateContract(contractId, payload);
      } else {
        await createContract(payload);
      }
      addSuccess(t('contractform.submit.success'));
      setHasSuccess(true);
      history.goBack();
    } catch (err) {
      actions.setSubmitting(false);
      addAlert(t('contractform.submit.failure'));
      window.scrollTo(0, 0);
      throw err;
    }
  }

  return (
    <>
      <BackMenu to="/contracts" text={t('contractform.back')} />
      <Container>
        {formInitialValues && (
          <Formik
            onSubmit={handleSubmit}
            initialValues={formInitialValues}
            validationSchema={validationSchema}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              setFieldTouched,
              isValid,
              submitCount,
            }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <RoutingPrompt
                    when={!hasSuccess && !isEqual(values, initialValues)}
                    message={t('common.formprompt')}
                  />
                  <h1>{contractId ? t('contractform.titleedit') : t('contractform.title')}</h1>

                  <FormField label={t('common.labels.contractnumber')} required>
                    <Input
                      id="contractNumber"
                      type="number"
                      value={contractId}
                      hasMarginTop={false}
                      disabled
                    />
                    <ValidationErrorMessage
                      invalid={touched.contractNumber && errors.contractNumber}
                      i18nKey={errors.contractNumber}
                    />
                  </FormField>

                  <FormField label={t('common.labels.vehicle')} required>
                    <VehiclesTypeahead
                      onChange={val => setFieldValue('vehicle', val)}
                      value={values.vehicle}
                    ></VehiclesTypeahead>
                    <ValidationErrorMessage
                      invalid={touched.vehicle && errors.vehicle}
                      i18nKey={errors.vehicle}
                    />
                  </FormField>

                  <FormField label={t('contractform.fields.driver')} required>
                    <UserPicker
                      name="driver"
                      value={values.driver}
                      onChange={driver => setFieldValue('driver', driver)}
                      onBlur={() => setFieldTouched('driver', true)}
                      invalid={touched.driver && errors.driver}
                    />
                    <ValidationErrorMessage
                      invalid={touched.driver && errors.driver}
                      i18nKey={errors.driver}
                    />
                  </FormField>
                  <FormField label="Status" required>
                    <Select
                      options={statusOptions}
                      isClearable={false}
                      isSearchable={false}
                      placeholder={t('common.select')}
                      loadingMessage={() => t('common.loading')}
                      onChange={v => setFieldValue('status', v?.value)}
                      onBlur={() => setFieldTouched('status', true)}
                      value={{ label: values.status, value: values.status }}
                    ></Select>
                    <ValidationErrorMessage
                      i18nKey={errors.status}
                      invalid={touched.status && errors.status}
                    />
                  </FormField>
                  <div className="row">
                    <FormField
                      label={t('contractform.fields.startdate')}
                      required
                      className="col-md-6"
                    >
                      <DatePicker
                        name="invoicingContractStartDate"
                        invalid={touched.invoicingContractStartDate && errors.invoicingContractStartDate}
                        value={values.invoicingContractStartDate}
                        onChange={invoicingContractStartDate => setFieldValue('invoicingContractStartDate', invoicingContractStartDate)}
                        onBlur={() => setFieldTouched('invoicingContractStartDate', true)}
                        disableWeekends={false}
                      />
                      <ValidationErrorMessage
                        invalid={touched.invoicingContractStartDate && errors.invoicingContractStartDate}
                        i18nKey={errors.invoicingContractStartDate}
                      />
                    </FormField>

                    <FormField
                      label={t('contractform.fields.enddate')}
                      className="col-md-6"
                    >
                      <DatePicker
                        name="invoicingContractEndDate"
                        invalid={touched.invoicingContractEndDate && errors.invoicingContractEndDate}
                        value={values.invoicingContractEndDate}
                        onChange={invoicingContractEndDate => setFieldValue('invoicingContractEndDate', invoicingContractEndDate)}
                        onBlur={() => setFieldTouched('invoicingContractEndDate', true)}
                        disableWeekends={false}
                      />
                      <ValidationErrorMessage
                        invalid={touched.invoicingContractEndDate && errors.invoicingContractEndDate}
                        i18nKey={errors.invoicingContractEndDate}
                      />
                    </FormField>
                  </div>

                  <ButtonGroup responsive>
                    <Button
                      type="submit"
                      disabled={(submitCount > 0 && !isValid) || isSubmitting}
                      isLoading={isSubmitting}
                    >
                      {t('common.send')}
                    </Button>
                    <Button color="secondary" onClick={history.goBack} type="button">
                      {t('common.cancel')}
                    </Button>
                    {contractId && <DeleteButton text={t('contractdetail.deletecontract')} onClick={() => toggleConfirmationModal()} />}

                  </ButtonGroup>
                  <p>{t('formvalidation.required.descriptions')}</p>
                </form>
              );
            }}
          </Formik>
        )}
        {confirmationModal && <ConfirmationModal title={t('contractdetail.modal.title')} onClose={() => toggleConfirmationModal()} handleSubmit={() => deleteContract()} />}

      </Container>
    </>
  );
}

export default ContractForm;
