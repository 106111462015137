// @flow

import React, { useReducer, useCallback, useState, useEffect, type Node } from 'react';
import store from 'store2';
import { useLocation } from 'react-router-dom';
import { type AuthState } from './auth-types';

import reducer, { initialState } from './reducer';
import userManager from '../../auth/userManager';
import { getProfile } from '../../services/userService';

import styles from './AuthProvider.module.css';
import ErrorState from '../ErrorState';

export const AuthContext = React.createContext<
  AuthState & { resetAuth: Function, fetchProfile: Function }
>({
  user: null,
  isProvider: false,
  isAdmin: false,
  profile: null,
  isLoading: true,
  resetAuth: () => {},
  fetchProfile: null,
});

const ignoreRoutes = ['/auth/callback', '/auth/silentrenew', '/auth/login'];

function AuthProvider({ children }: { children: Node }) {
  const [hasError, setHasError] = useState<boolean>(false);
  const [{ user, isLoading, profile }, dispatch] = useReducer(reducer, initialState);
  const location = useLocation();

  const isProvider = Boolean(profile?.provider);
  const isAdmin = Boolean(profile?.isAdmin);

  const updateUser = useCallback(async newUser => {
    if (!newUser) {
      return;
    }
    dispatch({ type: 'updateUser', user: newUser });
  }, []);

  const resetAuth = () => {
    dispatch({ type: 'resetAuth' });
  };

  useEffect(() => {
    userManager.events.addUserLoaded(updateUser);
    userManager.events.addAccessTokenExpired(resetAuth);
    userManager.events.addSilentRenewError(resetAuth);

    if (location && !ignoreRoutes.includes(location)) {
      store.session('location', location);
    }

    return () => {
      userManager.events.removeUserLoaded(updateUser);
      userManager.events.addAccessTokenExpired(resetAuth);
      userManager.events.removeSilentRenewError(resetAuth);
    };
  }, [location, updateUser]);

  const fetchProfile = useCallback(async () => {
    try {
      const { data: profile } = await getProfile();
      dispatch({ type: 'updateProfile', profile });
    } catch (err) {
      setHasError(true);
      throw err;
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        profile,
        isLoading,
        resetAuth,
        fetchProfile,
        isProvider,
        isAdmin,
      }}
    >
      {!hasError && children}
      {hasError && (
        <div className={styles.errorState}>
          <ErrorState />
        </div>
      )}
    </AuthContext.Provider>
  );
}

export default AuthProvider;
