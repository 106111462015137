// @flow

import { fetch } from './fetch';
import {
  type VehicleCategory,
  type VehicleInteriorConstruction,
} from '../types/vehicle-category-types';
import { type AxiosResponse } from 'axios';

export function getVehicleCategories(
  type?: string,
  interior?: string,
  withDeleted: boolean = false,
): Promise<AxiosResponse<VehicleCategory[]>> {
  return fetch<VehicleCategory[]>('/vehicle-categories', 'GET', null, {
    params: {
      type,
      interior,
      withDeleted: Number(withDeleted),
    },
  });
}

export function getVehicleCategory(id: number): Promise<AxiosResponse<VehicleCategory>> {
  return fetch<VehicleCategory>(`/vehicle-categories/${id}`);
}

export function getRawVehicleCategory(id: number): Promise<AxiosResponse<VehicleCategory>> {
  return fetch<VehicleCategory>(`/vehicle-categories/${id}/raw`);
}

export function getVehicleInteriors( withDeleted: boolean = false): Promise<AxiosResponse<VehicleInteriorConstruction>> {

  return fetch<VehicleInteriorConstruction>('/vehicle-categories/interiors', 'GET', null, {
    params: {
      withDeleted,
    }
  });
}

export function updateVehicleCategory(
  payload: VehicleCategory,
  id: number,
): Promise<AxiosResponse<VehicleCategory>> {
  return fetch<VehicleCategory>(`/vehicle-categories/${id}`, 'PUT', payload);
}

export function softDeleteCategory(id: number): Promise<AxiosResponse<VehicleCategory>> {
  return fetch<VehicleCategory>(`/vehicle-categories/${id}`, 'DELETE');
}

export function createVehicleCategory(
  payload: VehicleCategory,
): Promise<AxiosResponse<VehicleCategory>> {
  return fetch<VehicleCategory>('/vehicle-categories/', 'POST', payload);
}

export function createVehicleInterior(
  payload: VehicleInteriorConstruction,
): Promise<AxiosResponse<VehicleInteriorConstruction>> {
  return fetch<VehicleInteriorConstruction>('/vehicle-categories/interior', 'POST', payload);
}

export function updateVehicleInterior(
  payload: VehicleInteriorConstruction,
  id: number,
): Promise<AxiosResponse<VehicleInteriorConstruction>> {
  return fetch<VehicleInteriorConstruction>(`/vehicle-categories/interior/${id}`, 'PUT', payload);
}

export function deleteVehicleInterior(
  id: number,
): Promise<AxiosResponse<VehicleInteriorConstruction>> {
  return fetch<VehicleInteriorConstruction>(`/vehicle-categories/interior/${id}`, 'DELETE');
}


