// @flow

import * as React from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';

import Icon from '../Icon';

type Props = {
  children: React.Node,
  to: string,
  className?: string,
  style?: Object
};

function CardItemLink({ children, to, className, style }: Props) {
  const classes = cx('card__item', className);
  return (
    <Link to={to} className={classes} style={style}>
      <div className="row center-vertical">
        <div className="col-7 card__item-wrapper">{children}</div>
        <div className="col-1 text-align-right">
          <div className="card__item-detail">
            <Icon name="015-chevron-right"></Icon>
          </div>
        </div>
      </div>
    </Link>
  );
}

export default CardItemLink;
