// @flow

import { useState, useEffect, useCallback } from 'react';
import { getFile } from '../services/mediaService';

function useMediaFile(bucketName: string, fileName: string) {
  const [hasError, setHasError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [file, setFile] = useState(null);

  const loadFile = useCallback(async () => {
    try {
      setIsLoading(true);
      const data = await getFile(`${bucketName}/${fileName}`);
      setFile(data);
      setIsLoading(false);
    } catch (err) {
      setHasError(true);
      setIsLoading(false);
    }
  }, [bucketName, fileName]);

  useEffect(() => {
    if (!isLoading && !hasError && !file) loadFile();
  }, [isLoading, hasError, file, loadFile]);

  return {
    isLoading,
    hasError,
    file,
  };
}

export default useMediaFile;
