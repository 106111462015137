const config = {
  environment: 'production',
  //TODO: Change to fleet.swisscom.com in final release
  disUrl: 'https://fleet.swisscom.com',
  remoteUrl: 'https://fleet-backend.scapp.swisscom.com',
  clientId: 'bqu9ylp0Gk0acgHI2Rp02bVL44UsnqSb6ymy15HJUJFHr2nrwaFtJUSPeBH9FKFn',
  authority: 'https://sso-corproot-v2-mfa.scapp-services.swisscom.com',
  metadata: {
    authorization_endpoint:
      'https://sso-corproot-v2-mfa.scapp-services.swisscom.com/oauth/authorize',
    issuer: 'https://sso-corproot-v2-mfa.scapp-services.swisscom.com/oauth/token',
    user_info_endpoint: 'https://sso-corproot-v2-mfa.scapp-services.swisscom.com/userinfo',
    end_session_endpoint: 'https://sso-corproot-v2-mfa.scapp-services.swisscom.com/logout.do',
    jwks_uri: 'https://sso-corproot-v2-mfa.scapp-services.swisscom.com/token_keys',
    token_endpoint: 'https://sso-corproot-v2-mfa.scapp-services.swisscom.com/oauth/token',
    check_token_endpoint: 'https://sso-corproot-v2-mfa.scapp-services.swisscom.com/check_token',
    logout_endpoint: 'https://sso-corproot-v2-mfa.scapp-services.swisscom.com/logout.do',
    grant_types: 'implicit',
  },
};

export default config;
