// @flow

import { useState, useEffect } from 'react';
import { type ReserveVehicle } from '../types/vehicle-types';
import { getVehicles } from '../services/vehicleService';
import { type QueryFilters } from '../util/QueryFilterHelper';

function useReserveVehicles(filters: QueryFilters) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [vehicles, setVehicles] = useState<ReserveVehicle[]>([]);

  useEffect(() => {
    let isCancelled = false;

    async function fetchVehicles() {
      try {
        setIsLoading(true);
        const { data: vehicles } = await getVehicles(filters);

        if (!isCancelled) {
          setVehicles(vehicles);
          setIsLoading(false);
        }
      } catch (err) {
        if (!isCancelled) {
          setIsLoading(false);
          setHasError(true);
        }
        throw err;
      }
    }

    fetchVehicles();

    return () => {
      isCancelled = true;
    };
  }, [filters]);

  return { isLoading, vehicles, hasError };
}

export default useReserveVehicles;
