// @flow

import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';
import { type Pagination as PaginationType } from '../../types/pagination-types';
import styles from './Pagination.module.css';



type Props = {
  pagination: PaginationType,
  marginPagesDisplayed: number,
  pageRangeDisplayed: number,
  loadMore: ({ selected: number }) => any,
};

function Pagination({ loadMore, pageRangeDisplayed, marginPagesDisplayed, pagination }: Props) {
  const { t } = useTranslation();

  return (
    <ReactPaginate
      containerClassName={styles.wrapper}
      pageClassName={styles.page}
      breakClassName={styles.page}
      activeClassName={styles.activePage}
      disabledClassName={styles.disabled}
      nextClassName={styles.page}
      previousClassName={styles.page}
      pageCount={Math.ceil(pagination.totalCount / pagination.take)}
      pageRangeDisplayed={pageRangeDisplayed}
      marginPagesDisplayed={marginPagesDisplayed}
      forcePage={Math.floor(pagination.skip / pagination.take)}
      onPageChange={loadMore}
      previousLabel={t('common.previous')}
      nextLabel={t('common.next')}
    />
  );
}

Pagination.defaultProps = {
  pageRangeDisplayed: 5,
  marginPagesDisplayed: 0,
};

export default Pagination;
