//@flow

import React, { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { type ContractMutation } from '../../../../types/contract-mutation-types';
import { approveMutation, updateContractMutation, updateContractMutationApproval } from '../../../../services/contractMutationService';

import Button from '../../../../components/Button';
import ButtonGroup from '../../../../components/ButtonGroup';
import FormField from '../../../../components/FormField';
import Input from '../../../../components/Input';
import ProviderSelect from '../../../../components/ProviderSelect';
import ValidationErrorMessage from '../../../../components/ValidationErrorMessage';
import { useNotifications } from '../../../../components/Notifications/NotificationsProvider';
import { AuthContext } from '../../../../components/AuthProvider/AuthProvider';
import CommentField from '../../../../components/CommentField';
import ReserveSelect from '../../../../components/ReserveSelect/ReserveSelect';
import EndLeasingContractField from '../../../../components/EndLeasingContractField';
import RejectMutationModalFleet from './RejectMutationModalFleet';
import { MutationDetailContext } from '../../MutationDetail';
import { getVehicleBySNumber } from '../../../../services/vehicleService';
import { type Vehicle } from '../../../../types/vehicle-types';
import { updateRequestVehicleContractMutation, updateReturnVehicleContractMutation } from '../../../../services/contractService';

type Props = {
  selectedCategory: { value: number, label: string },
  mutation: ContractMutation,
  selectedReturnDate?: string | Date
};

function ApprovalFormFleet({ mutation, selectedCategory, selectedReturnDate }: Props) {
  const { t } = useTranslation();
  const history = useHistory();
  const { fetchProfile } = useContext(AuthContext);
  const { currentApprovalPoint } = useContext(MutationDetailContext);

  const [showRejectionModal, setShowRejectingModal] = useState<boolean>(false);
  const [formReady, setFormReady] = useState<boolean>(false);
  const [vehicleDetails, setVehicleDetails] = useState<Vehicle | null>(null);
  const { addSuccess, addAlert } = useNotifications();

  useEffect(() => {
    const getVehicle = async () => {
      if (mutation.requestVehicleMutation && mutation.requestVehicleMutation.sNumber) {
        const { data } = await getVehicleBySNumber(mutation.requestVehicleMutation.sNumber);
        setVehicleDetails(data);
      }
      setFormReady(true);
    };

    getVehicle();
  }, [mutation]);


  const validationSchema = Yup.object().shape({
    provider: Yup.object()
      .required('formvalidation.required.message')
      .nullable(),
    orderNumber: Yup.string()
      .required('formvalidation.required.message')
      .nullable(),
    reserveVehicle: Yup.mixed()
      .when('provider', {
        is: provider => provider?.hasReserve,
        then: Yup.object().required('formvalidation.required.message'),
        otherwise: Yup.mixed().notRequired(),
      })
      .nullable(),
  });


  const cacheApprovalStep = async val => {
    try {
      const payload = {
        ...currentApprovalPoint,
        ...val,
        approved: null,
        selectedReturnDate,
        selectedCategory
      };

      if (mutation.contract) {
        await Promise.all([
          updateReturnVehicleContractMutation(mutation.id, {
            endLeasingContract: payload.endLeasingContract
          }),
          updateRequestVehicleContractMutation(mutation.id, {
            orderNumber: payload.orderNumber,
            vehicleCategoryId: payload.selectedCategory.value
          }),
          updateContractMutation(mutation.id, {...mutation, dateOfExecution: payload.selectedReturnDate})
        ]);
      }

      await updateContractMutationApproval(currentApprovalPoint.id, payload);
      addSuccess(t('mutationdetail.alerts.success'));
      window.scrollTo(0, 0);
    } catch (err) {
      addAlert(t('errorstate.defaultmessage'));
      window.scrollTo(0, 0);
    }
  };

  async function handleSubmit(values, actions) {
    try {
      await approveMutation(mutation.id, {
        vehicleCategoryId: selectedCategory?.value,
        providerId: values.provider.id,
        sNumber: values.provider?.hasReserve ? values.reserveVehicle?.sNumber : null,
        dateOfExecution: selectedReturnDate,
        ...values,
      });
      fetchProfile();

      addSuccess(t('mutationdetail.alerts.success'));
      window.scrollTo(0, 0);
      history.push('/mutation-overview');
    } catch {
      addAlert(t('errorstate.defaultmessage'));
      window.scrollTo(0, 0);
      actions.setSubmitting(false);
    }
  }

  return (
    <>
      {formReady && (<Formik
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        initialValues={{
          approvalPoint: currentApprovalPoint.type,
          approved: true,
          provider: mutation.requestVehicleMutation ? mutation.requestVehicleMutation.selectedProvider || '' : null,
          reserveVehicle: vehicleDetails,
          endLeasingContract: mutation.returnVehicleMutation ? mutation.returnVehicleMutation.endLeasingContract || ''  : null,
          orderNumber: mutation.requestVehicleMutation ? mutation.requestVehicleMutation.orderNumber || ''  : '',
          comment: currentApprovalPoint.comment || '',
        }}
      >
        {({
          values,
          touched,
          errors,
          setFieldValue,
          setFieldTouched,
          handleChange,
          handleBlur,
          submitCount,
          handleSubmit,
          isSubmitting,
          isValid,
          submitForm,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              {showRejectionModal && (
                <RejectMutationModalFleet
                  mutation={mutation}
                  onClose={() => setShowRejectingModal(false)}
                />
              )}
              <h2>
                <Trans i18nKey="requestvehicledetail.labels.ordertitle" />
              </h2>
              <FormField label={t('requestvehicledetail.labels.providerselect')} htmlFor="provider">
                <ProviderSelect
                  name="provider"
                  onChange={provider => {
                    setFieldValue('provider', provider);
                    // If fleetmember preselects vehicle but decides to change the Provider
                    // and reselects Sixt --> add preselected vehicle to form data
                    setFieldValue('reserveVehicle', provider.name === 'Sixt' ? vehicleDetails : null);
                  }}
                  onBlur={() => setFieldTouched('provider')}
                  value={values.provider}
                />
                <ValidationErrorMessage
                  invalid={touched.provider && errors.provider}
                  i18nKey={errors.provider}
                />
              </FormField>
              {values.provider?.hasReserve && (
                <ReserveSelect
                  provider={values.provider}
                  value={values.reserveVehicle}
                  name="reserveVehicle"
                  onChange={vehicleId => {
                    setFieldTouched('reserveVehicle', true);
                    setFieldValue('reserveVehicle', vehicleId);
                  }}
                  preselectVehicleId={vehicleDetails ? vehicleDetails.id : null}
                  invalid={touched.reserveVehicle && errors.reserveVehicle}
                  errorI18nKey={errors.reserveVehicle}
                />
              )}
              <FormField label={t('common.labels.ordernumber')} htmlFor="orderNumber" required>
                <Input
                  hasMarginTop={false}
                  invalid={touched.orderNumber && errors.orderNumber}
                  name="orderNumber"
                  onBlur={() => setFieldTouched('orderNumber')}
                  onChange={e => setFieldValue('orderNumber', e.currentTarget.value)}
                  value={values.orderNumber}
                />
                <ValidationErrorMessage
                  invalid={touched.orderNumber && errors.orderNumber}
                  i18nKey={errors.orderNumber}
                />
              </FormField>
              <FormField label={t('returnvehicledetail.labels.endleasingcontract')}>
                <EndLeasingContractField
                  value={values.endLeasingContract}
                  onChange={value => setFieldValue('endLeasingContract', value === 'true')}
                />
                <ValidationErrorMessage
                  invalid={touched.endLeasingContract && errors.endLeasingContract}
                  i18nKey={errors.endLeasingContract}
                />
              </FormField>
              <CommentField value={values.comment} onChange={v => setFieldValue('comment', v)} />
              <ButtonGroup responsive>
                <Button
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                >
                  <Trans i18nKey="mutationdetail.buttons.accept" />
                </Button>
                <Button
                  color="confirm"
                  type="button"
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                  onClick={() => cacheApprovalStep(values)}
                >
                  <Trans i18nKey="common.save" />
                </Button>
                <Button
                  color="secondary"
                  type="button"
                  disabled={isSubmitting}
                  onClick={() => setShowRejectingModal(true)}
                >
                  <Trans i18nKey="mutationdetail.buttons.reject" />
                </Button>
                <Button
                  color="secondary"
                  type="button"
                  disabled={isSubmitting}
                  onClick={history.goBack}
                >
                  <Trans i18nKey="mutationdetail.buttons.cancel" />
                </Button>
              </ButtonGroup>
            </form>
          );
        }}
      </Formik>)}
    </>
  );
}

export default ApprovalFormFleet;
