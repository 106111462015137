// @flow

import React, { type Node } from 'react';
import cx from 'classnames';

type Props = {
  responsive?: boolean,
  fill?: boolean,
  children: Node,
};

const ButtonGroup = ({ responsive, fill, children }: Props) => {
  const classes = cx('button-group', {
    'button-group--responsive': responsive,
    'button-group--fill': fill,
  });

  return <div className={classes}>{children}</div>;
};

export default ButtonGroup;
