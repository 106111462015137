// @flow
import cx from 'classnames';
import React, { useContext } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import config from '../../config';

import userManager from '../../auth/userManager';
import Logo from '../Logo';
import Notification from '../Notifications/Notification';
import { useNotifications } from '../Notifications/NotificationsProvider';
import ProfileImage from '../ProfileImage';

import { useTranslation } from 'react-i18next';
import i18n, { LANGUAGES } from '../../i18n';
import { AuthContext } from '../AuthProvider/AuthProvider';
import FlyoutMenuItem from '../FlyOutMenuItem/FlyOutMenuItem';
import Icon from '../Icon';
import styles from './Header.module.css';
import LanguageSelect from './LanguageSelect';

const Header = () => {
  const { notifications, removeNotification } = useNotifications();
  const { profile, isProvider, isAdmin } = useContext(AuthContext);
  const { t } = useTranslation();
  const history = useHistory();

  const language = i18n.language.slice(0, 2);

  const validatedLanguage = LANGUAGES.includes(language) ? language : 'de';

  const navItems = [
    {
      text: t('navigation.dashboard'),
      routePath: '/',
      notificationCount: 0,
      hide: isProvider,
      exact: true,
    },
    {
      text: t('navigation.dislinks.replacement'),
      routePath: `${config.disUrl}/${validatedLanguage !== 'de' ? `${validatedLanguage}/` : ''}${
        config.replacementPath
      }`,
      notificationCount: 0,
      hide: isProvider,
      exact: true,
      isExternalLink: true,
    },
    {
      text: t('navigation.dislinks.secondreleasepoint'),
      routePath: `${config.disUrl}/${validatedLanguage !== 'de' ? `${validatedLanguage}/` : ''}${
        config.vehicleResponsiblePath
      }`,
      notificationCount: 0,
      hide: isProvider,
      exact: true,
      isExternalLink: true,
    },
    {
      text: t('navigation.mutationoverview'),
      routePath: '/mutation-overview',
      notificationCount: +profile?.assignedMutationsCount || 0,
      hide:
        !profile?.isApprovalGroupMember &&
        !profile?.hasAssociateUsers &&
        !profile?.isFleetMember &&
        !profile?.provider,
      exact: true,
    },
  ];

  return (
    <>
      <div className="nav">
        <nav role="navigation" className="nav__level1">
          <div className="container">
            <Logo onlyLifeform />
            <div className="nav__mainnav">
              <div className="nav__primary">
                <ul className="nav-items">
                  {navItems
                    .filter(item => !item.hide)
                    .map(item => (
                      <li className="nav-item" key={item.text}>
                        {item.isExternalLink ? (
                          <a
                            href={item.routePath}
                            rel="noopener noreferrer"
                            className={cx('nav-link nav-link--header', styles.disLinkStyling)}
                          >
                            {item.text}
                          </a>
                        ) : (
                          <NavLink
                            className={cx(
                              'nav-link nav-link--header badge badge--icon',
                              styles.disLinkStyling,
                            )}
                            activeClassName="is-active"
                            to={item.routePath}
                            exact={item.exact}
                          >
                            {item.notificationCount > 0 && (
                              <span className={cx(styles.badge, 'badge__content')}>
                                {item.notificationCount}
                              </span>
                            )}
                            <span className="text-underline">{item.text}</span>
                          </NavLink>
                        )}
                      </li>
                    ))}
                  {isAdmin && (
                    <li className="nav-item">
                      <sdx-menu-flyout direction="top-left" close-on-click="true">
                        <sdx-menu-flyout-toggle>
                          <div className="nav-link nav-link--header">
                            <span>Admin</span>
                            <Icon name="chevron-flat-down" className={'icon--s2'} />
                          </div>
                        </sdx-menu-flyout-toggle>
                        <sdx-menu-flyout-list>
                          <FlyoutMenuItem onClick={() => history.push('/contracts')}>
                            {t('navigation.billing')}
                          </FlyoutMenuItem>
                          <FlyoutMenuItem onClick={() => history.push('/debtors')}>
                            {t('navigation.debtor')}
                          </FlyoutMenuItem>
                          <FlyoutMenuItem onClick={() => history.push('/vehiclecategoryedit')}>
                            {t('navigation.vehiclecategoryedit')}
                          </FlyoutMenuItem>
                          <FlyoutMenuItem onClick={() => history.push('/provider')}>
                            {t('navigation.provider')}
                          </FlyoutMenuItem>
                          <FlyoutMenuItem onClick={() => history.push('/debtorcostcenter')}>
                            {t('navigation.debtorbilling')}
                          </FlyoutMenuItem>
                        </sdx-menu-flyout-list>
                      </sdx-menu-flyout>
                    </li>
                  )}
                </ul>
              </div>
              <div className="nav__secondary">
                <sdx-menu-flyout direction="bottom-left" close-on-click="true">
                  <sdx-menu-flyout-toggle>
                    <div>
                      <ProfileImage profile={profile} />
                      <Icon name="001-account" className="icon--s3" />
                    </div>
                  </sdx-menu-flyout-toggle>
                  <sdx-menu-flyout-list>
                    <LanguageSelect />
                    <sdx-menu-flyout-list-item onClick={() => userManager.signoutRedirect()}>
                      {t('common.logout')}
                    </sdx-menu-flyout-list-item>
                  </sdx-menu-flyout-list>
                </sdx-menu-flyout>
              </div>
            </div>
          </div>
        </nav>
      </div>
      {notifications.map(notification => (
        <Notification
          key={notification.id}
          onClose={removeNotification}
          notification={notification}
        />
      ))}
    </>
  );
};

export default Header;
