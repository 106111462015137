// @flow

import localeDe from 'date-fns/locale/de';
import localeFr from 'date-fns/locale/fr';
import localeIt from 'date-fns/locale/it';

import { type Language } from '../types/common-types';
import { LANGUAGES } from '../i18n';

export function getLocale(language: Language) {
  switch (language) {
    case LANGUAGES[2]: {
      return localeIt;
    }
    case LANGUAGES[1]: {
      return localeFr;
    }
    case LANGUAGES[0]: {
      return localeDe;
    }
    default: {
      return localeDe;
    }
  }
}
