// @flow

import React from 'react';
import SelectComponent from 'react-select';

import Icon from '../Icon';

import styles from './Select.module.css';

export type SelectOption = {
  label: string,
  value: string,
};

export type SelectGroup = {
  label: string,
  options: SelectOption[],
};

export type SelectProps = {
  value?: SelectOption,
  options: SelectOption[] | SelectGroup[],
  onChange?: Function,
  onBlur?: Function,
  placeholder?: string,
  noOptionsMessage?: Function,
  loadingMessage?: Function,
  className?: string,
  name?: string,
  isLoading?: boolean,
  isClearable?: boolean,
  id?: string,
  inputId?: string,
  components?: { [key: string]: Function },
  isSearchable: boolean,
};

function formatGroupLabel(data) {
  return <div className={ styles.optionGroupLabel }>{ data.label }</div>;
}

function Select({
  id,
  value,
  options,
  onChange,
  onBlur,
  placeholder,
  noOptionsMessage,
  loadingMessage,
  className,
  name,
  isLoading,
  isClearable,
  inputId,
  components,
  isSearchable,
  ...props
}: SelectProps) {
  return (
    <SelectComponent
      id={ id }
      inputId={ inputId }
      value={ value }
      options={ options }
      isClearable={ isClearable }
      onChange={ onChange }
      onBlur={ onBlur }
      noOptionsMessage={ noOptionsMessage }
      placeholder={ placeholder }
      loadingMessage={ loadingMessage }
      isSearchable={ isSearchable }
      formatGroupLabel={ formatGroupLabel }
      components={ {
        DropdownIndicator: () => (
          <div className="Select__indicator Select__dropdown-indicator Select__sdx-dropdown">
            <Icon name="017-chevron-flat-down" />
          </div>
        ),
        ...components,
      } }
      className={ className }
      classNamePrefix="Select"
      inputProps={ {
        name,
      } }
      isLoading={ isLoading }
      { ...props }
    />
  );
}

Select.defaultProps = {
  isClearable: true,
  isSearchable: true,
};

export default Select;
