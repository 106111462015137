// @flow

import React, { type Node } from 'react';
import cx from 'classnames';
import useIdentifier from '../../hooks/useIdentifier';

import styles from './Checkbox.module.css';

type Props = {
  name?: string,
  value?: boolean,
  onChange?: Function,
  onBlur?: Function,
  description?: string,
  label?: string,
  className?: string,
  disabled?: boolean,
  children?: Node,
  id?: string,
  required?: boolean,
};

const Checkbox = ({
  name,
  value,
  onChange,
  onBlur,
  description,
  label,
  className,
  disabled,
  children,
  id,
  required,
}: Props) => {
  const autoGeneratedDescriptionId = useIdentifier();
  const descId = `${id || autoGeneratedDescriptionId}-desc`;
  return (
    <div className={cx('checkbox', className)}>
      <input
        type="checkbox"
        name={name}
        id={id}
        aria-describedby={description ? descId : undefined}
        checked={value}
        onChange={onChange}
        disabled={disabled}
        onBlur={onBlur}
      />
      <label htmlFor={id}>
        <span className={cx({ [styles.required]: required })}>{label}</span>
        {children}
      </label>
      {description && <p id={descId}>{description}</p>}
    </div>
  );
};

export default Checkbox;
