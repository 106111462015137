// @flow

import { useState, useEffect } from 'react';
import { findBillingCycle } from '../services/billingCycleService';
import { type BillingCycle } from '../types/billing-cycle-types';

function useBillingCycle(id: number) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [billingCycle, setBillingCycle] = useState<?BillingCycle>(null);

  useEffect(() => {
    let isCancelled = false;

    async function fetchBillingCycle() {
      try {
        setIsLoading(true);
        const { data } = await findBillingCycle(id);
        if (!isCancelled) {
          setBillingCycle(data);
          setIsLoading(false);
        }
      } catch (err) {
        if (!isCancelled) {
          setHasError(true);
          setIsLoading(false);
        }
        throw err;
      }
    }

    fetchBillingCycle();

    return () => {
      isCancelled = true;
    };
  }, [id]);

  return { isLoading, billingCycle, hasError };
}

export default useBillingCycle;
