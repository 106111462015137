// @flow

import { useEffect, useState } from 'react';
import { getVehicleCategories } from '../services/vehicleCategoryService';

function useVehicleCategories(type?: string, interior?: string, withDeleted: boolean = false) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [categories, setCategories] = useState<VehicleCategory[]>([]);

  useEffect(() => {
    let isCancelled = false;

    async function fetchCategories() {
      try {
        setIsLoading(true);
        const { data } = await getVehicleCategories(type, interior, withDeleted);

        if (!isCancelled) {
          setCategories(data);
          setIsLoading(false);
        }
      } catch (err) {
        if (!isCancelled) {
          setHasError(true);
          setIsLoading(false);
        }
        throw err;
      }
    }

    fetchCategories();

    return () => {
      isCancelled = true;
    };
  }, [type, interior, withDeleted]);




  return { isLoading, categories, hasError };
}

export default useVehicleCategories;
