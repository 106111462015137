// @flow

import React from 'react';
import debounce from 'debounce-promise';
import Typeahead from '../Typeahead';
import { getVehicles } from '../../services/vehicleService';

type Props = {
  value?: any,
  onChange: Function,
  [any: string]: any,
};

const loadVehicles = debounce(getVehicles, 250, { leading: true });

function VehiclesTypeahead({ value, onChange, ...props }: Props) {
  const fetchOptions = async query => {
    const { data: vehicles } = await loadVehicles({ query, take: 20 });
    return vehicles.map(vehicle => ({
      value: vehicle.id,
      label: `${vehicle.activeLicensePlate} - ${vehicle.sNumber}`,
    }));
  };

  return (
    <Typeahead {...props} value={value} onChange={onChange} loadOptions={fetchOptions}></Typeahead>
  );
}

export default VehiclesTypeahead;
