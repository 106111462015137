const { protocol, hostname, port } = window.location;

const origin = `${protocol}//${hostname}${port ? `:${port}` : ''}`;

const tag = process.env.REACT_APP_TAG;

const config = {
  replacementPath: 'replacement',
  replacementDetailPath: 'replacement-details',
  vehicleResponsiblePath: 'vehicleresponsibleoverview',
  protocol,
  hostname,
  port,
  origin,
  authority: 'https://sso-corproot-v2.dev-scapp.swisscom.com',
  metadata: {
    authorization_endpoint: 'https://sso-corproot-v2.dev-scapp.swisscom.com/oauth/authorize',
    issuer: 'https://sso-corproot-v2.dev-scapp.swisscom.com/oauth/token',
    user_info_endpoint: 'https://sso-corproot-v2.dev-scapp.swisscom.com/userinfo',
    end_session_endpoint: 'https://sso-corproot-v2.dev-scapp.swisscom.com/logout.do',
    jwks_uri: 'https://sso-corproot-v2.dev-scapp.swisscom.com/token_keys',
    token_endpoint: 'https://sso-corproot-v2.dev-scapp.swisscom.com/oauth/token',
    check_token_endpoint: 'https://sso-corproot-v2.dev-scapp.swisscom.com/check_token',
    logout_endpoint: 'https://sso-corproot-v2.dev-scapp.swisscom.com/logout.do',
    grant_types: 'implicit',
  },
  tag,
};

export default config;
