// @flow

import axios, { type AxiosInstance, type AxiosRequestConfig, type AxiosResponse } from 'axios';

import config from '../config';
import userManager from '../auth/userManager';
import i18next from '../i18n';

type Headers = {
  [headerName: string]: string | void,
};

const getToken = async (): Promise<string | null> => {
  const user = await userManager.getUser();
  if (!user) {
    return null;
  }

  return user.access_token;
};

const getDefaultHeaders = (token: string | null): Headers => {
  return {
    Accept: 'application/json',
    'Accept-Language': i18next.language,
    Authorization: token ? `Bearer ${token}` : undefined,
  };
};

const instance: AxiosInstance = axios.create({
  baseURL: config.remoteUrl + '/api/',
});

export const buildUrl = (endpoint: string): string => `${config.remoteUrl}/api/${endpoint}`;

export const fetch = async<T>(
  url: string,
  method: string = 'GET',
  body: any,
  options: AxiosRequestConfig,
  headers:?Headers,
): Promise<AxiosResponse<T>> => {
  if (!url) {
    throw new Error('No url defined');
  }

  const token = await getToken();

  const defaultHeaders = getDefaultHeaders(token);
  const otherHeaders: Headers = options && options.headers ? options.headers : {};

  return instance.request<T>({
    url: url,
    method,
    headers: {...defaultHeaders, ...otherHeaders, ...headers },
    data: body,
    ...options,
  });
};
