//@flow

import React from 'react';
import Card from '../../../components/Card';
import { useTranslation } from 'react-i18next';

type Props = {
  color: String,
  children?: any,
  title?: String,
};

function InformationCard({ color, children, title }: Props) {
  const { t } = useTranslation();

  return (
    <Card
      title={title ?? t('common.labels.driverandvehicleinformation')}
      color={color || 'turquoise'}
      dark
      style={{ overflow: 'visible' }}
    >
      {children}
    </Card>
  );
}

export default InformationCard;
