// @flow

import React from 'react';
import format from 'date-fns/fp/format';

type Props = {
  date?: string | Date,
  formatString?: string,
};

function FormattedDate({ date, formatString }: Props) {
  if (!date) {
    return null;
  }

  const formatDate = format(formatString);

  return <>{formatDate(new Date(date))}</>;
}

FormattedDate.defaultProps = {
  formatString: 'dd.MM.yyyy',
};

export default FormattedDate;
