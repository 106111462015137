//@flow

import React from 'react';
import Card from '../../../components/Card';
import { useTranslation } from 'react-i18next';

type Props = {
  children?: any,
};

function ProtocolCard({ children }: Props) {
  const { t } = useTranslation();

  return (
    <Card
      title={t('common.labels.protocol')}
      color="turquoise"
      dark
      style={{ overflow: 'visible' }}
    >
      {children}
    </Card>
  );
}

export default ProtocolCard;
