// @flow

import useQueryFilters from '../../hooks/useQueryFilters';
import { type QueryFilters } from '../../util/QueryFilterHelper';

import { QueryComparators } from '../../types/query-comparator-types';

import { OrderDirections } from '../../types/order-types';
import subDays from 'date-fns/subDays';

function useMutationQueryFilters() {
  const defaultClosedAtFilter = subDays(new Date(), 1).toISOString();

  const initialFilter: QueryFilters = {
    softFilter: {
      closedAt: {
        value: subDays(new Date(), 2).toISOString(),
        comparator: QueryComparators.GREATER_THAN,
      },
      status: {
        value: 'PENDING',
        comparator: QueryComparators.EQUAL,
      },
    },
    hardFilter: {
      'approvals.current': {
        value: true,
        comparator: QueryComparators.EQUAL,
      },
      deletedAt: {
        value: null,
        comparator: QueryComparators.EQUAL
      },
    },
    sort: {
      closedAt: OrderDirections.ASC,
      dateOfExecution: OrderDirections.ASC,
    },
  };

  const {
    filter,
    setFilter,
    setSearchQuery,
    setExclusiveSort,
    setSort,
    setWhereFilter,
    removeWhereFilter,
  } = useQueryFilters(initialFilter);

  const showArchivedMutations = () => {
    removeWhereFilter('status');
    setWhereFilter('closedAt', defaultClosedAtFilter, QueryComparators.LOWER_THAN, false, true);
  };

  const showNonArchivedMutations = () => {
    setWhereFilter('closedAt', defaultClosedAtFilter, QueryComparators.GREATER_THAN, true, true);
    setWhereFilter('status', 'PENDING', QueryComparators.EQUAL, true, true);
  };

  const showAllMutations = () => {
    removeWhereFilter('closedAt');
    removeWhereFilter('status');
  };

  const showDraftMutations = () => {
    setWhereFilter('closedAt', '', QueryComparators.EQUAL, true, true);
    setWhereFilter('status', 'DRAFT', QueryComparators.EQUAL, true, true);
    setWhereFilter('approvals.current', '', QueryComparators.EQUAL, false, true);
  };

  const toggleArchivedMutations = (show: string) => {
    if (show === 'archived') {
      showArchivedMutations();
    } else if (show === 'active') {
      showNonArchivedMutations();
    } else if (show === 'draft') {
      showDraftMutations();
    } else {
      showAllMutations();
    }
  };

  return {
    filter,
    setFilter,
    setSearchQuery,
    setExclusiveSort,
    setSort,
    setWhereFilter,
    removeWhereFilter,
    toggleArchivedMutations,
  };
}

export default useMutationQueryFilters;
