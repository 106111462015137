// @flow

import React, { useContext, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import {
  type ContractMutation,
  type ReturnVehicleMutation,
} from '../../../types/contract-mutation-types';

import { CardText, CardItem } from '../../../components/Card';
import PhoneLink from '../../../components/PhoneLink';

import { InformationCard, InformationCardItem } from '../common';
import FormattedDate from '../../../components/FormattedDate';
import ChangedExecutionDate from '../../../components/ChangedExecutionDate/ChangedExecutionDate';
import { AuthContext } from '../../../components/AuthProvider/AuthProvider';
import Comments from '../common/Comments';
import EmailLink from '../../../components/EmailLink';
import { MutationDetailContext } from '../MutationDetail';
import VehicleName from '../../../components/VehicleName';
import { formatPrice } from '../../../util/PriceFormatHelper';
import EmployeeInformation from '../../../components/EmployeeInformation';
import DatePicker from '../../../components/DatePicker/DatePicker';
import {addDays, isBefore, startOfDay} from 'date-fns';
import styles from '../MutationDetail.module.css';

type Props = {
  mutation: ContractMutation,
  returnVehicleMutation: ReturnVehicleMutation,
  selectedReturnDateChanged?: Function
};

function OldVehicleInformation({ mutation, returnVehicleMutation, selectedReturnDateChanged }: Props) {
  const { t } = useTranslation();
  const { profile, isProvider } = useContext(AuthContext);
  const { userIsApprover, currentApprovalPoint } = useContext(MutationDetailContext);
  const [selectedDate, setSelectedDate] = useState<Date>(mutation.dateOfExecution ? mutation.dateOfExecution : new Date());

  const showProviderName =
    (((isProvider && userIsApprover) || profile?.isFleetMember) && mutation.contract) &&
    mutation.contract.vehicle.provider;


  const displayDatePicker =
  userIsApprover && mutation.status === 'PENDING' && currentApprovalPoint.type !== 'PROVIDER';

  return (
    <InformationCard title={t('vehiclechangedetail.labels.headers.currentvehicle')} color="orchid">
      <CardItem>
        {mutation.contract && <EmployeeInformation
          user={mutation.contract.contractor}
          className="col-4"
          title={t('driverchangedetail.labels.currentdriver')}
        />}
        {mutation.contract && <CardText>
          <PhoneLink user={mutation.contract.contractor} />
        </CardText>}
        {mutation.contract && <CardText>
          <EmailLink user={mutation.contract.contractor} />
        </CardText>}
      </CardItem>
      <CardItem>
        {mutation.contract && <InformationCardItem label={t('vehiclechangedetail.labels.vehicle')}>
          <VehicleName vehicle={mutation.contract.vehicle} />
        </InformationCardItem>}
        {showProviderName && mutation.contract && (
          <InformationCardItem label={t('common.labels.provider')}>
            {mutation.contract.vehicle.provider.name}
          </InformationCardItem>
        )}
        {mutation.contract && <InformationCardItem label={t('vehiclechangedetail.labels.snumber')}>
          {mutation.contract.vehicle.sNumber}
        </InformationCardItem>}
        {mutation.contract && <InformationCardItem label={t('vehiclechangedetail.labels.platenumber')}>
          {mutation.contract.vehicle.activeLicensePlate}
        </InformationCardItem>}
        {mutation.contract && <InformationCardItem label={t('vehiclechangedetail.labels.leasingcontractnumber')}>
          {mutation.contract.contractNumber}
        </InformationCardItem>}
        {!isProvider && (
          <InformationCardItem label={t('vehiclechangedetail.labels.price')}>
            {mutation.contract && <Trans
              values={{ price: formatPrice(mutation.contract.vehicle.vehicleCategory.pricePerMonth, { includeCurrency: true, includeCentimes: true }) }}
              i18nKey="common.pricepermonth"
            />}
          </InformationCardItem>
        )}

        {currentApprovalPoint.type === 'PROVIDER' && (
          <InformationCardItem label={t('returnvehicledetail.labels.cancellationaction')}>
            {returnVehicleMutation.endLeasingContract ? (
              <Trans i18nKey="returnvehicledetail.leasingcontract.endcontract" />
            ) : (
              <Trans i18nKey="returnvehicledetail.leasingcontract.reserve" />
            )}
          </InformationCardItem>
        )}

        <InformationCardItem label={t('vehiclechangedetail.labels.requestedreturndate')}>
          {!displayDatePicker  ? mutation.requestedDateOfExecution !== mutation.dateOfExecution && mutation.requestedDateOfExecution && mutation.dateOfExecution ? (
            <ChangedExecutionDate
              oldDate={mutation.requestedDateOfExecution}
              newDate={mutation.dateOfExecution}
            />
          ) : (
            <FormattedDate date={mutation.dateOfExecution}></FormattedDate>
          ) :
            (
              <>
                {isBefore(new Date(selectedDate), addDays(new Date(), 1)) && <span className={styles.dateTooOld}>{t('common.datetooold')}<span><FormattedDate date={selectedDate}/></span></span>}
                <DatePicker
                  name={t('vehiclechangedetail.labels.requestedreturndate')}
                  value={selectedDate.toString()}
                  onChange={newReturnDate => {
                    selectedReturnDateChanged && selectedReturnDateChanged(newReturnDate);
                    setSelectedDate(selectedDate);}}
                  disableWeekends={true}
                  options={{ minDate: startOfDay(addDays(new Date(), 1)) }}
                />
              </>)

          }
        </InformationCardItem>
      </CardItem>
      {(profile?.isFleetMember || currentApprovalPoint.type !== 'PROVIDER' || userIsApprover) && (
        <Comments contractMutationId={mutation.id} />
      )}
    </InformationCard>
  );
}

export default OldVehicleInformation;
