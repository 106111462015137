// @flow

import React from 'react';
import { Trans } from 'react-i18next';
import { type User } from '../../types/user-types';
import { CardText } from '../Card';
import PhoneLink from '../PhoneLink';
import ProfileLink from '../ProfileLink';
import styles from './EmployeeInformation.module.css';

type Props = {
  user: User,
  className?: string,
  title?: string,
};

function EmployeeInformation({ user, className, title }: Props) {
  return (
    <section className={className}>
      <CardText>{title ?? <Trans i18nKey="common.labels.driver" />}</CardText>
      <div className="gray font--semi-bold">
        <ProfileLink user={user} className={styles.no__margin} showUnit={false} />
      </div>
      <div className="gray font--semi-bold">
        <PhoneLink user={user} className={styles.inline} />
      </div>
      {user.email && (
        <div className="gray font--semi-bold">
          <a className="link" href={`mailto:${user.email}`}>
            {user.email}
          </a>
        </div>
      )}
      <div className="gray font--semi-bold">
        <Trans i18nKey="common.labels.employeenumber" />: {user.swisscomEmployeeNr}
      </div>
      <div className="gray font--semi-bold">
        <Trans i18nKey="common.labels.languagecode" />: {user.language.toUpperCase()}
      </div>
      <br />
      <CardText>
        <Trans i18nKey="common.labels.address" />:
      </CardText>
      <div className="gray font--semi-bold">{user.company}</div>
      <div className="gray font--semi-bold">{user.unit}</div>
      <div className="gray font--semi-bold">
        {user.firstname} {user.lastname}
      </div>
      <div className="gray font--semi-bold">{user.address ? user.address : '-'}</div>
      <div className="gray font--semi-bold">{user.zipCode && user.location ? `${user.zipCode} ${user.location}` : '-'}</div>
    </section>
  );
}

export default EmployeeInformation;
