// @flow

import { fetch } from './fetch';
import { type AxiosResponse } from 'axios';
import { type MediaFile, type UploadFile } from '../types/media-types';

export async function uploadFile(file: any, bucket: string): Promise<AxiosResponse<UploadFile>> {
  return fetch<UploadFile>('/media', 'POST', file, {}, { bucket });
}

export async function getFile(filePath: string): Promise<MediaFile> {
  const { data, headers } = await fetch(`/media/${filePath}`, 'GET', {}, { responseType: 'arraybuffer' });

  const contentType = headers['content-type'] ?? 'application/octet-stream';
  const blob = new Blob([data], { type: contentType });
  const name = headers['content-disposition'] ?? filePath.split('/').pop();

  return {
    blob,
    name,
    contentType,
  };
};
