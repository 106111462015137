// @flow

import { useState, useEffect } from 'react';
import { type Contract } from '../types/contract-types';
import { findConflictedContracts } from '../services/contractService';

function useBillingCycleConflicts(attempt: number) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [conflictedContracts, setConflictedContracts] = useState<Contract[]>([]);

  useEffect(() => {
    let isCancelled = false;

    async function fetchContracts() {
      try {
        setIsLoading(true);
        const { data: contracts } = await findConflictedContracts();

        if (!isCancelled) {
          setConflictedContracts(contracts);
          setIsLoading(false);
        }
      } catch (err) {
        if (!isCancelled) {
          setIsLoading(false);
          setHasError(true);
        }
        throw err;
      }
    }

    fetchContracts();

    return () => {
      isCancelled = true;
    };
  }, [attempt]);

  return { isLoading, conflictedContracts, hasError };
}

export default useBillingCycleConflicts;
