/* eslint-disable arrow-parens */
// @flow

import {
  type ContractMutation,
  type RequestVehicleMutation,
} from '../types/contract-mutation-types';

import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';

export const getCurrentApprovalPoint = (mutation: ContractMutation): ContractMutationApproval => {
  return mutation.approvals.find((approval) => approval.current);
};

export const getCurrentVehicleCategory = (
  requestVehicleMutation: RequestVehicleMutation,
): VehicleCategory => {
  if (requestVehicleMutation.fleetApprovalPointCategory?.id) {
    return requestVehicleMutation.fleetApprovalPointCategory;
  }

  if (requestVehicleMutation.secondApprovalPointCategory?.id) {
    return requestVehicleMutation.secondApprovalPointCategory;
  }

  if (requestVehicleMutation.firstApprovalPointCategory?.id) {
    return requestVehicleMutation.firstApprovalPointCategory;
  }

  return requestVehicleMutation.vehicleCategory;
};

export const isCurrentUserApprover = (user: User, mutation: ContractMutation) => {
  return (
    getCurrentApprovalPoint(mutation).eligibleApprovers.find(
      (eligibleApprover) =>
        eligibleApprover.swisscomEmployeeNr.toLowerCase() === user.swisscomEmployeeNr.toLowerCase(),
    ) != null
  );
};

export const formatCategoryName = (category: Category) => {
  const interiorConstructionName = category.interiorConstruction?.name
    ? ` (${category.interiorConstruction.name})`
    : '';

  return category.name + interiorConstructionName;
};

export const getFirstPossibleTerminationDate = (type: string, date: Date) =>
  endOfMonth(addMonths(new Date(date), type === 'passengervehicle' ? 3 : 6));
