// @flow

import cx from 'classnames';
import debounce from 'debounce-promise';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactSelect from 'react-select/async';
import { findCostCenters } from '../../services/costCenterService';
import { type CostCenter } from '../../types/cost-center-types';
import Option from '../Typeahead/Option';

const fetchCostCenters = debounce(findCostCenters, 250);
type Props = {
  name?: string,
  value?: { label: string, value: string },
  onChange?: Function,
  onBlur?: Function,
  id?: string,
  invalid?: boolean,
  isMulti?: boolean,
  hiddenCostCenters?: CostCenter[],
  hiddenCostCenterLabelI18nKey?: string,
};

function CostCenterPicker({
  name,
  value,
  onChange,
  onBlur,
  id,
  invalid,
  isMulti,
  hiddenCostCenters,
  hiddenCostCenterLabelI18nKey,
}: Props) {
  const { t } = useTranslation();

  async function loadOptions(query) {
    const { data: costcenters } = await fetchCostCenters(query);
    return costcenters.map(c => {
      const isDisabled =
        hiddenCostCenters && hiddenCostCenters.find(costcenter => costcenter.identifier === c.identifier);
      return {
        value: c.identifier,
        label: c.identifier,
        isDisabled,
        costcenter: c,
      };
    });
  }

  const containerClasses = cx('Select__Container', {
    'Select__Container--invalid': invalid,
  });

  return (
    <ReactSelect
      name={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      inputId={id}
      className={containerClasses}
      classNamePrefix="Select"
      loadOptions={loadOptions}
      placeholder={t('common.search')}
      isMulti={isMulti}
      cacheOptions
      defaultOptions={[]}
      onInputChange={() => { }}
      loadingMessage={() => t('common.loading')}
      noOptionsMessage={() => t('typeahead.nooptions')}
      components={{ Option: Option, DropdownIndicator: null }}
    />
  );
}

export default CostCenterPicker;
