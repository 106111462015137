// @flow

import { fetch } from './fetch';
import { type QueryFilters } from '../util/QueryFilterHelper';
import { type PaginatedResponse } from '../types/pagination-types';

import { type AxiosResponse } from 'axios';
import {
  type OpenMutationRequest,
  type ContractMutation,
  type ContractMutationProtocolEntry,
  type ContractMutationApproval,
  type Comment,
} from '../types/contract-mutation-types';
import { type VehicleCategoryHistoryItem } from '../types/vehicle-category-types';

import { parseQueryFilters } from '../util/QueryFilterHelper';

export function getMutations(
  filter?: QueryFilters,
): Promise<AxiosResponse<PaginatedResponse<ContractMutation[]>>> {
  return fetch<ContractMutation[]>('/mutations', 'GET', null, {
    params: parseQueryFilters(filter),
  });
}

export function getMutationProtocol(
  id: number,
): Promise<AxiosResponse<Array<ContractMutationProtocolEntry>>> {
  return fetch(`/mutations/${id}/protocol`, 'GET');
}

export function getComments(id: number): Promise<AxiosResponse<Array<Comment>>> {
  return fetch(`/mutations/${id}/comments`, 'GET');
}

export function getVehicleCategoryHistory(
  id: number,
): Promise<AxiosResponse<Array<VehicleCategoryHistoryItem>>> {
  return fetch(`/mutations/${id}/vehicle-category-history`, 'GET');
}

export function getMutation(id: number): Promise<AxiosResponse<ContractMutation>> {
  return fetch<ContractMutation>(`/mutations/${id}`, 'GET');
}

export function approveMutation(
  id: number,
  payload: Object,
): Promise<AxiosResponse<ContractMutationApproval>> {
  return fetch(`/mutations/${id}/approval`, 'PUT', payload);
}

export function changeEligibleApprovers(
  contractMutationId: number,
  payload: { employeeNrs: string[] },
): Promise<AxiosResponse<ContractMutationApproval>> {
  return fetch(`/mutations/${contractMutationId}/approvers`, 'PUT', payload);
}

export function sendManualReminder(
  contractMutationId: number,
): Promise<AxiosResponse<{ success: boolean }>> {
  return fetch(`/mutations/${contractMutationId}/reminder`, 'POST');
}

export function confirmChangeVehicleReturn(
  contractMutationId: number,
  payload: Object,
): Promise<AxiosResponse<ContractMutation>> {
  return fetch(`/mutations/${contractMutationId}`, 'PUT', payload);
}

export function updateContractMutation(
  contractMutationId: number,
  payload: Object,
): Promise<AxiosResponse<ContractMutation>> {
  return fetch(`/mutations/${contractMutationId}`, 'PUT', payload);
}

export function addAssignee(
  contractMutationId: number,
  payload: Object,
): Promise<AxiosResponse<ContractMutation>> {
  return fetch(`/mutations/${contractMutationId}/assignee`, 'POST', payload);
}

export function getMutationAssignees(
  contractMutationId: Number,
): Promise<AxiosResponse<ContractMutation>> {
  return fetch(`/mutations/assignees/${contractMutationId}`);
}

export function getOpenMutation(
  contractId: number,
  payload: OpenMutationRequest,
): Promise<AxiosResponse<ContractMutation | null>> {
  return fetch(`/mutations/open/${contractId}`, 'POST', payload);
}

export function updateContractMutationApproval(
  approvalId: number,
  payload: ContractMutationApproval,
): Promise<AxiosResponse<ContractMutationApproval>> {
  return fetch(`/mutations/approvals/${approvalId}`, 'PUT', payload);
}
