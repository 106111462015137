// @flow

import { useState, useEffect } from 'react';
import {
  createVehicleProvider,
  deleteVehicleProvider,
  getVehicleProviderResponsibles,
  getVehicleProviders,
  updateVehicleProvider,
} from '../services/vehicleProviderService';
import { type VehicleProvider } from '../types/vehicle-provider-types';

function useVehicleProviders() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [providers, setProviders] = useState<VehicleProvider[]>([]);

  const addNewProvider = async (payload: VehicleProvider) => {
    const { data: newProvider } = await createVehicleProvider(payload);
    setProviders([...providers, newProvider]);
  };

  const updateProvider = async (id: number, payload: VehicleProvider) => {
    const { data: updatedProvider } = await updateVehicleProvider(id, payload);
    providers[providers.findIndex((provider => provider.id === id))] = updatedProvider;
  };

  const deleteProvider = async (id: number) => {
    await deleteVehicleProvider(id);
    providers.splice(providers.findIndex((provider => provider.id === id)), 1);
  };

  useEffect(() => {
    let isCancelled = false;

    async function fetchProviders() {
      try {
        setIsLoading(true);
        const { data } = await getVehicleProviders();
        const mappedProviders = await Promise.all(data.map(async provider => {
          const { data: responsibles } = await getVehicleProviderResponsibles(provider.id);
          return {
            ...provider,
            responsibles
          };
        }));

        if (!isCancelled) {
          setProviders(mappedProviders);
          setIsLoading(false);
        }
      } catch (err) {
        if (!isCancelled) {
          setIsLoading(false);
          setHasError(true);
        }
        throw err;
      }
    }

    fetchProviders();

    return () => {
      isCancelled = true;
    };
  }, []);

  return { isLoading, providers, hasError, addNewProvider, updateProvider, deleteProvider };
}

export default useVehicleProviders;
