//@flow

import React from 'react';
import { useHistory } from 'react-router-dom';
import styles from './BillCycleListItem.module.css';
import ProfileLink from '../../../components/ProfileLink';
import { format, parseISO } from 'date-fns';
import { type BillingCycle } from '../../../types/billing-cycle-types';
import { formatPrice } from '../../../util/PriceFormatHelper';

type Props = {
  billingCycle: BillingCycle,
};

function BillingCycleListItem({ billingCycle }: Props) {
  const history = useHistory();
  const formatDate = val => (!!val ? format(parseISO(val.toString()), 'dd.MM.yyyy') : '-');
  return (
    <tr onClick={() => history.push(`/billing-cycle/${billingCycle.id}`)} className={styles.row}>
      <td>{billingCycle.name}</td>
      <td>{billingCycle.id}</td>
      <td>{billingCycle.status}</td>
      <td>{billingCycle.creator && <ProfileLink user={billingCycle.creator} />}</td>
      <td>{formatDate(billingCycle.createdAt)}</td>
      <td>{billingCycle.status !== "PENDING" && formatPrice(billingCycle.calculatedPrice, { includeCurrency: false, includeCentimes: true })}</td>
    </tr>
  );
}

export default BillingCycleListItem;
