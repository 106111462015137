// @flow

import React from 'react';
import { useHistory } from 'react-router-dom';
import { type Contract } from '../../types/contract-types';
import Icon from '../Icon';
import styles from './ContractConflict.module.css';

type Props = {
  text: Array<string>,
  contract: Contract,
};

const ContractConflict = ({ text, contract, ...props }: Props) => {
  const history = useHistory();
  return (
    <div className={styles.contractconflict}>
      -
      <button
        onClick={e => {
          e.stopPropagation();
          history.push(`/contracts/${contract.id}/edit`);
        }}
      >
        <Icon name="edit" className="blue icon--s2" />
      </button>
      <span className={styles.errorButton}>
        <Icon name="exclamation-mark-circle"></Icon> [<>{text}</>]
      </span>
    </div>
  );
};

export default ContractConflict;
