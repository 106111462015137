// @flow

import React, { useState, useContext, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import cx from 'classnames';
import { useLocation } from 'react-router-dom';

import Container from '../../components/Container';
import LoadingSpinner from '../../components/LoadingSpinner';
import Input from '../../components/Input';
import MutationListItem from './MutationListItem';

import { AuthContext } from '../../components/AuthProvider/AuthProvider';
import useMutations from '../../hooks/useMutations';
import tableStyles from '../ContractOverview/ContractTable.module.css';
import SortableTableHead from '../../components/SortableTableHead';
import ContractMutationStatusSelect from '../../components/ContractMutationStatusSelect';
import ContractMutationTypeSelect from '../../components/ContractMutationTypeSelect';
import ProviderSelect from '../../components/ProviderSelect/ProviderSelect';
import { QueryComparators } from '../../types/query-comparator-types';
import EmptyState from '../../components/EmptyState';
import ErrorState from '../../components/ErrorState';
import Pagination from '../../components/Pagination';
import ContractMutationArchivedFilter from '../../components/ContractMutationArchivedFilter';

import { type OrderDirection } from '../../types/order-types';
import useMutationQueryFilters from './useMutationQueryFilters';
import { type VehicleProvider } from '../../types/vehicle-provider-types';
import { type ContractMutation } from '../../types/contract-mutation-types';

function MutationOverview() {
  const { t } = useTranslation();
  const { profile } = useContext(AuthContext);
  const [localMutations, setLocalMutations] = useState<ContractMutation[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [pagination, setPagination] = useState<Pagination>({
    skip: 0,
    take: 0,
    totalCount: 0,
  });

  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const page = urlParams.get('p') ? +urlParams.get('p') - 1 : undefined;

  const {
    filter: mutationFilter,
    setWhereFilter,
    setExclusiveSort,
    setSearchQuery,
    toggleArchivedMutations,
  } = useMutationQueryFilters();

  const { mutations, hasError, loadMore, setPage,  isLoading: areMutationsLoading } = useMutations(
    mutationFilter,
    page
  );

  useEffect(() => {
    if(areMutationsLoading){
      setIsLoading(true);
    }
  }, [areMutationsLoading]);

  useEffect(() => {
    // compares the current filter in this overview with the filter coming from the hook
    // if they are the same the mutations get set, otherwise not
    if (mutations && (JSON.stringify(mutationFilter).split('').sort().join('') + (mutationFilter.query ?? '')) === mutations.checksum) {
      setLocalMutations(mutations.mutations);
      setPagination(mutations.pagination);
      setIsLoading(false);
    }
  }, [ mutations, mutationFilter]);

  const [status, setStatus] = useState<{ value: string, label: string } | null>(null);
  const [type, setType] = useState<{ value: string, label: string } | null>(null);
  const [archived, setArchived] = useState<{ value: string, label: string } | null>(null);
  const [provider, setProvider] = useState<?VehicleProvider>();

  const setSort = (sortOrders: Array<[string, OrderDirection]>) => {
    setPage(0);
    setExclusiveSort(sortOrders);
  };

  useEffect(() => {
    setPage(0);
    setSearchQuery(sessionStorage.getItem('searchQuery') || '');

    if(sessionStorage.getItem('statusValue') && sessionStorage.getItem('statusLabel')){
      setStatus({
        value: sessionStorage.getItem('statusValue') || '',
        label: sessionStorage.getItem('statusLabel') || ''
      });
      setWhereFilter('approvals.type', sessionStorage.getItem('statusValue') || '', QueryComparators.EQUAL);
    }

    if(sessionStorage.getItem('typeValue') && sessionStorage.getItem('typeLabel')){
      setType({
        value: sessionStorage.getItem('typeValue') || '',
        label: sessionStorage.getItem('typeLabel') || ''
      });
      setWhereFilter('type', sessionStorage.getItem('typeValue') || '', QueryComparators.EQUAL);
    }

    if(sessionStorage.getItem('archivedValue') && sessionStorage.getItem('archivedLabel')){
      toggleArchivedMutations(sessionStorage.getItem('archivedValue') || '');
      setArchived({
        value: sessionStorage.getItem('archivedValue') || '',
        label: sessionStorage.getItem('archivedLabel') || ''
      });
    }

    if(+sessionStorage.getItem('providerValue') && sessionStorage.getItem('providerLabel') && sessionStorage.getItem('providerHasReserve')){
      setProvider({
        value: +sessionStorage.getItem('providerValue') || '',
        label: sessionStorage.getItem('providerLabel') || '',
        name: sessionStorage.getItem('providerLabel') || '',
        id: +sessionStorage.getItem('providerValue'),
        orderNumbers: [],
        responsibles: [],
        hasReserve: sessionStorage.getItem('providerHasReserve') === 'true',
        avriosIdentifier: ''
      });

      setWhereFilter(
        'contract.vehicle.provider.id$requestVehicleMutation.selectedProvider.id',
        sessionStorage.getItem('providerValue') || '',
        QueryComparators.EQUAL,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return (
    <Container>
      <h1>
        <Trans i18nKey="mutationoverview.title" />
      </h1>

      <div className="row">
        <div className="col-md-3">
          <Input
            id="search"
            placeholder={t('mutationoverview.filter.search.placeholder')}
            onChange={evt => {
              setPage(0);
              setSearchQuery(evt.currentTarget.value);
              sessionStorage.setItem('searchQuery', evt.currentTarget.value);
            }}
            value={mutationFilter?.query || ''}
          />
        </div>
        <div className="col-md-2">
          <label htmlFor="filter-state">{t('mutationoverview.filter.title')}</label>
          <ContractMutationStatusSelect
            id="filter-state"
            value={status}
            onChange={val => {
              setStatus(val);
              setPage(0);
              setWhereFilter('approvals.type', val?.value, QueryComparators.EQUAL);
              if(val){
                sessionStorage.setItem('statusValue', val.value);
                sessionStorage.setItem('statusLabel', val.label);
              }else{
                sessionStorage.removeItem('statusValue');
                sessionStorage.removeItem('statusLabel');
              }
            }}
          />
        </div>
        <div className="col-md-2">
          <ContractMutationTypeSelect
            id="filter-category"
            className={tableStyles.filter}
            value={type}
            onChange={val => {
              setType(val);
              setPage(0);
              setWhereFilter('type', val?.value, QueryComparators.EQUAL);
              if(val){
                sessionStorage.setItem('typeValue', val.value);
                sessionStorage.setItem('typeLabel', val.label);
              }else{
                sessionStorage.removeItem('typeValue');
                sessionStorage.removeItem('typeLabel');
              }
            }}
          />
        </div>
        <div className="col-md-2">
          <ContractMutationArchivedFilter
            id="archived-filter"
            value={archived}
            onChange={(val: { label: string, value: string }) => {
              toggleArchivedMutations(val.value);
              setPage(0);
              setArchived(val);
              sessionStorage.setItem('archivedValue', val.value);
              sessionStorage.setItem('archivedLabel', val.label);
            }}
            className={tableStyles.filter}
          />
        </div>

        {profile?.isFleetMember && (
          <div className="col-md-2">
            <ProviderSelect
              name="provider-select"
              value={provider ? provider : undefined}
              onChange={val => {
                setProvider(val);
                setPage(0);
                setWhereFilter(
                  'contract.vehicle.provider.id$requestVehicleMutation.selectedProvider.id',
                  val?.id,
                  QueryComparators.EQUAL,
                );
                if(val){
                  sessionStorage.setItem('providerValue', val.id);
                  sessionStorage.setItem('providerLabel', val.name);
                  sessionStorage.setItem('providerHasReserve', val.hasReserve);
                }else{
                  sessionStorage.removeItem('providerValue');
                  sessionStorage.removeItem('providerLabel');
                  sessionStorage.removeItem('providerHasReserve');
                }
              }}
              className={tableStyles.filter}
              placeholderI18nKey="mutationoverview.filter.provider.placeholder"
            />
          </div>
        )}
      </div>
      <div className="row">
        <div className={'col-md-12 flex flex-items-center margin-top-3'}>
          <span>
            {t('mutationoverview.pagination.totalCount')} {pagination.totalCount}
          </span>
        </div>
      </div>
      <div className="row">
        <div className={`table table--responsive ${tableStyles.orderlist}`}>
          <div className="table__wrapper">
            <table className={cx({ [tableStyles.empty]: localMutations.length === 0 })}>
              <thead>
                <tr>
                  <td style={{ width: '3%' }}></td>
                  <td style={{ width: '5%' }}>
                    <SortableTableHead
                      i18nKey="mutationoverview.listheader.id"
                      sortProperties={['id']}
                      sortFilters={mutationFilter?.sort}
                      setSort={setSort}
                    />
                  </td>
                  <td style={{ width: '10%' }}>
                    <Trans i18nKey="mutationoverview.listheader.category" />
                  </td>
                  <td style={{ width: '17.5%' }}>
                    <SortableTableHead
                      i18nKey="mutationoverview.listheader.driver"
                      sortProperties={[
                        'contract.contractor.lastname',
                        'contract.contractor.firstname',
                        'contract.contractor.unit',
                      ]}
                      sortFilters={mutationFilter?.sort}
                      setSort={setSort}
                    />
                  </td>
                  <td style={{ width: '17%' }}>
                    <SortableTableHead
                      i18nKey="mutationoverview.listheader.vehicletype"
                      sortProperties={['contract.vehicle.vehicleCategory.id']}
                      sortFilters={mutationFilter?.sort}
                      setSort={setSort}
                    />
                  </td>
                  <td style={{ width: '10%' }}>
                    <SortableTableHead
                      i18nKey="mutationoverview.listheader.orderaction"
                      sortProperties={['dateOfExecution']}
                      sortFilters={mutationFilter?.sort}
                      setSort={setSort}
                    />
                  </td>
                  <td style={{ width: '5%' }}>
                    <SortableTableHead
                      i18nKey="common.labels.snumber"
                      sortProperties={['contract.vehicle.sNumber']}
                      sortFilters={mutationFilter?.sort}
                      setSort={setSort}
                    />
                  </td>
                  <td style={{ width: '10%' }}>
                    <SortableTableHead
                      i18nKey="mutationoverview.listheader.statetext"
                      sortProperties={['approvals.createdAt']}
                      sortFilters={mutationFilter?.sort}
                      setSort={setSort}
                    />
                  </td>
                  <td style={{ width: '10%' }}>
                    <SortableTableHead
                      i18nKey="mutationoverview.listheader.provider"
                      sortProperties={['contract.vehicle.provider.name']}
                      sortFilters={mutationFilter?.sort}
                      setSort={setSort}
                    />
                  </td>
                  <td style={{ width: '17.5%' }}>
                    <SortableTableHead
                      i18nKey="mutationoverview.listheader.inprogressby"
                      sortProperties={['contract.vehicle.provider.name']}
                      sortFilters={mutationFilter?.sort}
                      setSort={setSort}
                    />
                  </td>
                </tr>
              </thead>

              <tbody>
                {isLoading && (
                  <tr>
                    <td colSpan="10">
                      <div className={tableStyles.empty__outer}>
                        <LoadingSpinner />
                      </div>
                    </td>
                  </tr>
                )}
                {!isLoading && !hasError && localMutations.length === 0 && (
                  <tr>
                    <td colSpan="10">
                      <div className={tableStyles.empty__outer}>
                        <EmptyState />
                      </div>
                    </td>
                  </tr>
                )}
                {!isLoading && hasError && (
                  <tr>
                    <td colSpan="10">
                      <div className={tableStyles.empty__outer}>
                        <ErrorState />
                      </div>
                    </td>
                  </tr>
                )}

                {!isLoading &&
                  !hasError &&
                  localMutations.length > 0 &&
                  localMutations.map(m => <MutationListItem key={m.id} mutation={m} />)}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {!isLoading && <div className="row">
        <div className="col-md-12 flex flex-items-center flex-space-between">
          {pagination.totalCount > pagination.take && pagination.totalCount > 0 && (
            <Pagination pagination={pagination} loadMore={loadMore} />
          )}
        </div>
      </div>}
    </Container>
  );
}

export default MutationOverview;
