// @flow

import { useState, useEffect } from 'react';
import { getComments } from '../services/contractMutationService';

import { type Comment } from '../types/contract-mutation-types';

function useComments(id: number) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [comments, setComments] = useState<Comment[]>([]);

  useEffect(() => {
    setIsLoading(true);
    getComments(id).then(({ data }) => {
      const comments = data.filter(
        comment => comment && comment.comment && comment.comment.trim().length > 0,
      );
      setComments(comments);
      setIsLoading(false);
    });
  }, [id]);

  return { isLoading, comments };
}

export default useComments;
