// @flow

import React from 'react';

type Props = {
  swisscomEmployeeNr: string,
};

function EmployeeNumber({ swisscomEmployeeNr }: Props) {
  return <>{`P-${swisscomEmployeeNr}`}</>;
}

export default EmployeeNumber;
