// @flow

import { useState, useEffect, useCallback } from 'react';
import {
  type ContractMutation,
  type ContractMutationProtocolEntry,
} from '../types/contract-mutation-types';
import {
  getMutation,
  getMutationProtocol,
  sendManualReminder,
  changeEligibleApprovers,
} from '../services/contractMutationService';
import { useNotifications } from '../components/Notifications/NotificationsProvider';
import { useTranslation } from 'react-i18next';

function useMutation(id: number) {
  const [mutationIsLoading, setMutationIsLoading] = useState<boolean>(false);
  const [protocolIsLoading, setProtocolIsLoading] = useState<boolean>(false);

  const [hasError, setHasError] = useState<boolean>(false);
  const { addSuccess, addAlert } = useNotifications();
  const { t } = useTranslation();
  const [mutation, setMutation] = useState<ContractMutation | null>();
  const [protocol, setProtocol] = useState<Array<ContractMutationProtocolEntry> | null>();

  const fetchMutation = useCallback(async () => {
    try {
      setMutationIsLoading(true);
      const { data: contractMutation } = await getMutation(id);
      setMutation(contractMutation);
      setMutationIsLoading(false);
    } catch (err) {
      setHasError(true);
      setMutationIsLoading(false);
      throw err;
    }
  }, [id]);
  const fetchProtocol = useCallback(async () => {
    try {
      setProtocolIsLoading(true);
      const { data: protocol } = await getMutationProtocol(id);
      setProtocol(protocol);
      setProtocolIsLoading(false);
    } catch (err) {
      setHasError(true);
      setProtocolIsLoading(false);
      throw err;
    }
  }, [id]);

  const sendReminder = useCallback(async () => {
    try {
      await sendManualReminder(id);
      fetchMutation();
      addSuccess(t('mutationdetail.manualreminder.success'));
    } catch {
      addAlert(t('mutationdetail.manualreminder.failure'));
    }
  }, [id, fetchMutation, addSuccess, t, addAlert]);

  const changeApprovers = useCallback(
    async (payload: { employeeNrs: string[] }) => {
      try {
        await changeEligibleApprovers(id, payload);
        addSuccess(t('mutationdetail.alerts.success'));
        fetchMutation();
      } catch {
        addAlert(t('mutationdetail.alerts.failure'));
      }
    },
    [addAlert, addSuccess, fetchMutation, id, t],
  );

  useEffect(() => {
    fetchMutation();
    fetchProtocol();
  }, [fetchMutation, fetchProtocol]);

  return {
    isLoading: mutationIsLoading,
    protocolIsLoading,
    hasError,
    mutation,
    protocol,
    refetch: () => {
      fetchMutation();
      fetchProtocol();
    },
    sendManualReminder: sendReminder,
    changeEligibleApprovers: changeApprovers,
  };
}

export default useMutation;
