// @flow
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

function useQueryParams(property: string): string | { [key: string]: string } {
  const location = useLocation();
  const parsed = queryString.parse(location.search);

  if (property) {
    return parsed[property];
  }

  return parsed;
}

export default useQueryParams;
