import React from 'react';
import { logClientError } from '../../services/errorService';
import ErrorState from '../ErrorState';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

import styles from './ErrorBoundary.module.css';
import { withTranslation } from 'react-i18next';

class ErrorBoundary extends React.Component {
  state = {
    hasError: false,
    error: null,
    isSaving: false,
  };

  async componentDidCatch(error, componentStack) {
    const { message, stack, name } = error;
    this.setState({
      hasError: true,
      isSaving: true,
    });

    const { data: savedError } = await logClientError({
      message,
      stackTrace: stack,
      name,
      componentStack: JSON.stringify(componentStack),
    });
    this.setState({ error: savedError, isSaving: false });
  }

  render = () => {
    const { hasError, isSaving, error } = this.state;
    const { t, children } = this.props;

    if (hasError) {
      if (isSaving) {
        return (
          <div className={styles.errorWrapper}>
            <LoadingSpinner />
          </div>
        );
      }

      if (!isSaving && error) {
        return (
          <div className={styles.errorWrapper}>
            <div className={styles.innerError}>
              <ErrorState text={t('errorstate.globalerror')} />
              <p className={styles.errorId}>
                Error ID: <span className="font-weight-semi-bold">{error.id}</span>
              </p>
            </div>
          </div>
        );
      }

      if (!isSaving && !error) {
        return (
          <div className={styles.errorWrapper}>
            <div className={styles.innerError}>
              <ErrorState />
            </div>
          </div>
        );
      }
    }

    return <>{children}</>;
  };
}

export default withTranslation()(ErrorBoundary);
