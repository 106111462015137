//@flow

import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import {
  type ContractMutation,
  type ReturnVehicleMutation,
} from '../../../types/contract-mutation-types';

import { CardItem } from '../../../components/Card';
import Container from '../../../components/Container';
import Protocol from '../../../components/Protocol';

import { MutationTitle, ProtocolCard, ProviderForm } from '../common';
import { ApprovalForm, ApprovalFormFleet, ProviderModal } from './ApprovalForms';
import ReturnVehicleInformation from './ReturnVehicleInformation';
import ReturnVehicleSummary from './ReturnVehicleSummary';
import { MutationDetailContext } from '../MutationDetail';

type Props = {
  mutation: ContractMutation,
  returnVehicleMutation: ReturnVehicleMutation,
};

function ReturnVehicleDetail({ mutation, returnVehicleMutation }: Props) {
  const [showProviderModal, setShowProviderModal] = useState<boolean>(false);
  const { userIsApprover, currentApprovalPoint, protocol, protocolIsLoading } = useContext(
    MutationDetailContext,
  );

  const { t } = useTranslation();

  if (currentApprovalPoint.type === 'PROVIDER') {
    return (
      <ProviderForm
        mutation={mutation}
        buttonLabel={t('returnvehicledetail.providermodal.label')}
        buttonClick={() => setShowProviderModal(true)}
      >
        {showProviderModal && (
          <ProviderModal mutationId={mutation.id} onClose={() => setShowProviderModal(false)} />
        )}
        <ReturnVehicleSummary mutation={mutation} returnVehicleMutation={returnVehicleMutation} />
      </ProviderForm>
    );
  }

  return (
    <Container>
      <MutationTitle mutation={mutation} />
      <div className="row">
        <div className="col-sm-12 col-md-7 flex  margin-bottom-3">
          <ReturnVehicleInformation
            mutation={mutation}
            returnVehicleMutation={returnVehicleMutation}
          />
        </div>
        <div className="col-sm-12 col-md-5 flex margin-bottom-3">
          <ProtocolCard>
            <CardItem>
              <Protocol mutation={mutation} items={protocol} isLoading={protocolIsLoading} />
            </CardItem>
          </ProtocolCard>
        </div>
      </div>
      {mutation.status === 'PENDING' &&
        (currentApprovalPoint.type !== 'FLEET_MGMT' && userIsApprover ? (
          <ApprovalForm mutation={mutation} />
        ) : (
          userIsApprover && (
            <div className="margin-top-4">
              <ApprovalFormFleet mutation={mutation} />
            </div>
          )
        ))}
    </Container>
  );
}

export default ReturnVehicleDetail;
