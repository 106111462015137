// @flow

import { useState, useEffect } from 'react';

import { type User } from '../types/user-types';

import { getUserByEmployeeNr } from '../services/userService';

function useUser(employeeNr: string) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [user, setUser] = useState<?User>(null);

  useEffect(() => {
    async function fetchUser() {
      try {
        setIsLoading(true);

        const data = await getUserByEmployeeNr(employeeNr);

        setUser(data);
        setIsLoading(false);
      } catch (err) {
        setHasError(true);
        setIsLoading(false);
        throw err;
      }
    }

    if (!isLoading && !user) fetchUser();
  }, [isLoading, employeeNr, user]);

  return { isLoading, hasError, user };
}

export default useUser;
