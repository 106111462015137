// @flow

import React, { useState } from 'react';
import cx from 'classnames';
import AnimateHeight from 'react-animate-height';

import Card from '../../../components/Card';

import styles from './RequestVehicleInformation.module.css';
import Icon from '../../../components/Icon';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import { Trans } from 'react-i18next';
import ErrorState from '../../../components/ErrorState';
import { type VehicleCategory } from '../../../types/vehicle-category-types';
import { formatCategoryName } from '../../../util/MutationProperties';
import { formatPrice } from '../../../util/PriceFormatHelper';
import OpenFile from '../../../components/OpenFile';

type Props = {
  category: VehicleCategory,
  hasError: any,
  isLoading: Boolean,
};

function RequestVehicleInformation({ category, isLoading, hasError }: Props) {
  const [expanded, setExpanded] = useState(false);

  return (
    <AnimateHeight
      height={isLoading || hasError ? 294 : 'auto'}
      className={cx({ [styles.expanded]: expanded }, 'margin-bottom-4')}
    >
      <Card dark document={false} color={category && category.deletedAt ? 'blue' : 'purple'} className="padding-0">
        {(isLoading || hasError) && (
          <div className={cx(styles.emptyState, 'flex flex-center')}>
            {isLoading && <LoadingSpinner />}
            {hasError && !isLoading && <ErrorState />}
          </div>
        )}
        {category && !hasError && !isLoading && (
          <>
            <div className={styles.container}>
              <h4 className="margin-bottom-1 flex flex-space-between flex-items-center">
                <span>{formatCategoryName(category)}</span>
                <span>
                  <Trans
                    i18nKey="common.pricepermonth"
                    values={{ price: formatPrice(category.pricePerMonth, { includeCurrency: true, includeCentimes: true }) }}
                  />
                </span>
              </h4>
              <p className="margin-bottom-2">{category.description}</p>
              <div className="row">
                <div className={styles.specs}>
                  <div className="font-weight-semi-bold">
                    <Trans i18nKey="common.vehiclecategory.labels.technicalinformation" />
                  </div>
                  <ul className="list margin-top-1 margin-bottom-0">
                    <li>
                      <span>
                        <Trans i18nKey="common.vehiclecategory.energyefficiencyclass.label" />
                      </span>
                      <span>
                        {category.energyEfficiencyClass ?? (
                          <Trans i18nKey="common.labels.notapplicable" />
                        )}
                      </span>
                    </li>
                    <li>
                      <span>
                        <Trans i18nKey="common.vehiclecategory.maxemission.label" />
                      </span>
                      <span>
                        {category.maxEmission ? (
                          <Trans
                            i18nKey="common.vehiclecategory.maxemission.value"
                            values={{ emission: category.maxEmission }}
                          />
                        ) : (
                          <Trans i18nKey="common.labels.notapplicable" />
                        )}
                      </span>
                    </li>
                    <li>
                      <span>
                        <Trans i18nKey="common.vehiclecategory.labels.typeofdrive" />
                      </span>
                      <span>
                        {category.typeOfDrive ?? <Trans i18nKey="common.labels.notapplicable" />}
                      </span>
                    </li>
                    <li>
                      <span>
                        <Trans i18nKey="common.vehiclecategory.loadcapacity.label" />
                      </span>
                      <span>
                        {category.loadCapacity ? (
                          <Trans
                            i18nKey="common.vehiclecategory.loadcapacity.value"
                            values={{ weight: category.loadCapacity }}
                          />
                        ) : (
                          <Trans i18nKey="common.labels.notapplicable" />
                        )}
                      </span>
                    </li>
                    <li>
                      <span>
                        <Trans i18nKey="common.vehiclecategory.minloadvolume.label" />
                      </span>
                      <span>
                        {category.minLoadVolume ? (
                          <Trans
                            i18nKey="common.vehiclecategory.minloadvolume.value"
                            values={{ volume: category.minLoadVolume }}
                          />
                        ) : (
                          <Trans i18nKey="common.labels.notapplicable" />
                        )}
                      </span>
                    </li>
                  </ul>
                </div>
                <div className={styles.specs}>
                  <div className="font-weight-semi-bold">
                    <Trans i18nKey="common.vehiclecategory.labels.features" />
                  </div>
                  <ul className="list margin-top-1 margin-bottom-0">
                    {category.hasAirConditioning && (
                      <li>
                        <Trans i18nKey="common.vehiclecategory.labels.airconditioning" />
                      </li>
                    )}
                    {category.hasTowHitch && (
                      <li>
                        <Trans i18nKey="common.vehiclecategory.labels.towhitch" />
                      </li>
                    )}
                    {category.hasNavigationSystem && (
                      <li>
                        <Trans i18nKey="common.vehiclecategory.labels.navigationsystem" />
                      </li>
                    )}
                    {category.hasCruiseControl && (
                      <li>
                        <Trans i18nKey="common.vehiclecategory.labels.cruisecontrol" /> (
                        <Trans
                          i18nKey={`common.vehiclecategory.labels.cruisecontroltype.${category.cruiseControlType.toLowerCase()}`}
                        />
                        )
                      </li>
                    )}
                    {category.hasCitySafetySystem && (
                      <li>
                        <Trans i18nKey="common.vehiclecategory.labels.citysafetysystem" />
                      </li>
                    )}
                    {category.hasLDWS && (
                      <li>
                        <Trans i18nKey="common.vehiclecategory.labels.ldws" />
                      </li>
                    )}
                    {category.hasBlindSpotMonitor && (
                      <li>
                        <Trans i18nKey="common.vehiclecategory.labels.blindspotmonitor" />
                      </li>
                    )}
                    {category.hasParkingSensors && (
                      <li>
                        <Trans i18nKey="common.vehiclecategory.labels.parkingsensors" />
                      </li>
                    )}
                    {category.additionalFeatures &&
                      category.additionalFeatures.split(',').map((f, i) => <li key={i}>{f}</li>)}
                    {category.descriptionFileLocation && (
                      <OpenFile className="padding-top-1" fileLocation={category.descriptionFileLocation} />
                    )}
                  </ul>
                </div>
              </div>
            </div>
            <div className={styles.toggleExpanded}>
              <button className="link" onClick={() => setExpanded(!expanded)}>
                {!expanded && (
                  <>
                    <span>
                      <Trans i18nKey="common.labels.showmoredetails" />
                    </span>
                    <Icon name="017-chevron-flat-down" />
                  </>
                )}
                {expanded && (
                  <>
                    <span>
                      <Trans i18nKey="common.labels.showlessdetails" />
                    </span>
                    <Icon name="020-chevron-flat-up" />
                  </>
                )}
              </button>
            </div>
          </>
        )}
      </Card>
    </AnimateHeight>
  );
}

export default RequestVehicleInformation;
