//@flow

import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import Container from '../../../components/Container';
import { CardItem } from '../../../components/Card';
import Protocol from '../../../components/Protocol';

import {
  type ContractMutation,
  type DriverChangeMutation,
} from '../../../types/contract-mutation-types';

import { MutationTitle, ProtocolCard, ProviderForm } from '../common';
import DriverChangeInformation from './DriverChangeInformation';
import { ApprovalForm, ProviderModal } from './ApprovalForms';
import DriverChangeSummary from './DriverChangeSummary';
import { MutationDetailContext } from '../MutationDetail';

type Props = {
  mutation: ContractMutation,
  driverChangeMutation: DriverChangeMutation,
};

function DriverChangeDetail({ mutation, driverChangeMutation }: Props) {
  const [showProviderModal, setShowProviderModal] = useState<boolean>(false);
  const { userIsApprover, currentApprovalPoint, protocol, protocolIsLoading } = useContext(
    MutationDetailContext,
  );
  const { t } = useTranslation();

  if (currentApprovalPoint.type === 'PROVIDER') {
    return (
      <ProviderForm
        mutation={mutation}
        currentUserIsApprover={userIsApprover}
        buttonLabel={t('driverchangedetail.providermodal.label')}
        buttonClick={() => setShowProviderModal(true)}
      >
        {showProviderModal && (
          <ProviderModal mutationId={mutation.id} onClose={() => setShowProviderModal(false)} />
        )}
        <DriverChangeSummary mutation={mutation} driverChangeMutation={driverChangeMutation} />
      </ProviderForm>
    );
  }

  return (
    <Container>
      <MutationTitle mutation={mutation} />
      <div className="row">
        <div className="col-sm-12 col-md-7 flex  margin-bottom-3">
          <DriverChangeInformation
            mutation={mutation}
            driverChangeMutation={driverChangeMutation}
          />
        </div>
        <div className="col-sm-12 col-md-5 flex margin-bottom-3">
          <ProtocolCard>
            <CardItem>
              <Protocol mutation={mutation} items={protocol} isLoading={protocolIsLoading} />
            </CardItem>
          </ProtocolCard>
        </div>
      </div>
      {mutation.status === 'PENDING' && userIsApprover && (
        <div className="margin-top-4">
          <ApprovalForm mutation={mutation} />
        </div>
      )}
    </Container>
  );
}

export default DriverChangeDetail;
