// @flow

import React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import Option from '../Typeahead/Option';
import Select from '../Select/';
import { getMonths } from '../../util/BillingCycleMonthHelper';
import useBillingCycleExecutionConflicts from '../../hooks/useBillingCycleExecutionConflicts';

import styles from './BillingCycleMonthSelect.module.css';

type Props = {
  value?: { value: string, label: string },
  onChange?: Function,
  id?: string,
  invalid?: boolean,
  onBlur?: Function,
  name?: string,
};

function BillingCycleMonthSelect({ value, onChange, id, invalid, onBlur, name }: Props) {
  const { t } = useTranslation();
  const translations = t('months', { returnObjects: true });

  const { conflictedBillingCycle } = useBillingCycleExecutionConflicts();

  const months = getMonths(conflictedBillingCycle);
  const options = months.map(month => ({
    value: new Date(new Date().getFullYear(), month + 1, 0).toISOString(),
    label: translations[month],
  }));

  const containerClasses = cx('Select__Container', {
    'Select__Container--invalid': invalid,
  });

  return (
    <Select
      name={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      inputId={id}
      className={`${containerClasses} ${styles.billingCycleMonthSelect}`}
      options={options}
      placeholder={t('common.select')}
      loadingMessage={() => t('common.loading')}
      noOptionsMessage={() => t('typeahead.nooptions')}
      components={{ Option: Option }}
    ></Select>
  );
}

export default BillingCycleMonthSelect;
