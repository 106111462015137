// @flow

import React from 'react';
import cx from 'classnames';
import { useTranslation, Trans } from 'react-i18next';
import { components } from 'react-select';

import Select from '../Select/';
import useVehicleCategories from '../../hooks/useVehicleCategories';

import './styles.css';
import styles from './CategoryPicker.module.css';
import { formatCategoryName } from '../../util/MutationProperties';
import { formatPrice } from '../../util/PriceFormatHelper';


type Props = {
  value?: { value: string, label: string },
  onChange?: Function,
  id?: string,
  invalid?: boolean,
  onBlur?: Function,
  name?: string,
  compact?: boolean,
};

const Option = (props: any) => {
  const {
    data: { label, pricePerMonth },
  } = props;

  return (
    <components.Option {...props}>
      <div className={props.data.compact ? styles.compact : styles.line}>
        {label}
        {props.data.compact && <br />}
        <span
          className={cx(
            'CategoryPicker__price',
            !props.data.compact && 'CategoryPicker__delimiter',
          )}
        >
          <Trans values={{ price: formatPrice(pricePerMonth, { includeCurrency: true, includeCentimes: true }) }} i18nKey="common.pricepermonth"></Trans>
        </span>
      </div>
    </components.Option>
  );
};

function CategoryPicker({ value, onChange, id, invalid, onBlur, name, compact }: Props) {
  const { t } = useTranslation();
  const { categories, isLoading } = useVehicleCategories();

  let selectedCategory = null;

  function formatOptions(type) {
    return {
      label: t('common.labels.' + type),
      options: categories
        .filter(c => c.type === type)
        .map(c => {
          const option = {
            label: formatCategoryName(c),
            pricePerMonth: c.pricePerMonth,
            value: c.id,
            compact: compact,
          };

          if (value?.value === c.id) selectedCategory = option;
          return option;
        }),
    };
  }

  const options = [formatOptions('passengervehicle'), formatOptions('utilityvehicle')];

  const containerClasses = cx('Select__Container', {
    'Select__Container--invalid': invalid,
  });

  return (
    <Select
      name={name}
      value={selectedCategory}
      onChange={onChange}
      onBlur={onBlur}
      inputId={id}
      className={containerClasses}
      options={options}
      isLoading={isLoading}
      isSearchable={false}
      placeholder={t('common.select')}
      loadingMessage={() => t('common.loading')}
      noOptionsMessage={() => t('typeahead.nooptions')}
      components={{ Option }}
    ></Select>
  );
}

export default CategoryPicker;
