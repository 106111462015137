//  @flow

import React from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import FormField from '../FormField';
import ValidationErrorMessage from '../ValidationErrorMessage';
import Input from '../Input';
import ButtonGroup from '../ButtonGroup';
import Button from '../Button';
import { useTranslation } from 'react-i18next';

import styles from './InteriorEditModal.module.css';
import Modal from '../Modal';
import Checkbox from '../Checkbox';
import DeleteButton from '../DeleteButton';
import { type Interior } from '../../types/interior-types';
import Icon from '../Icon';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('formvalidation.required.message'),
  disIdentifier: Yup.string().required('formvalidation.required.message'),
  isStandard: Yup.boolean()
});

type Props = {
  toggleInteriorModal: Function,
  interior: Interior,
  deleteInterior: Function,
  submit: Function,
  restoreInterior: Function,
}


function InteriorEditModal({ toggleInteriorModal, interior, deleteInterior, submit, restoreInterior }: Props) {
  const { t } = useTranslation();

  const newInitialValues = interior ? interior : {
    disIdentifier: '',
    isStandard: false,
    name: ''
  };

  return (
    <Modal title={t('interioreditmodal.title')} size="wide" onClose={toggleInteriorModal} closeDisabled={false}>
      <Formik initialValues={newInitialValues}
        onSubmit={submit}
        validationSchema={validationSchema}>

        {
          ({
            values,
            touched,
            errors,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
            handleChange,
            handleBlur,
            submitCount,
            isSubmitting,
            isValid,
          }) => {
            return (
              <form onSubmit={handleSubmit}>
                <FormField label={t('interioreditmodal.interior')} required>
                  <Input onChange={v => setFieldValue('name', v.target.value)} onBlur={() => setFieldTouched('name', true)} value={values.name} type="text" inputClassName={styles.input}></Input>
                  <ValidationErrorMessage
                    i18nKey={errors.name}
                    invalid={touched.name && errors.name}
                  />
                </FormField>
                <FormField label={t('interioreditmodal.identifier')} required>
                  <Input onChange={v => setFieldValue('disIdentifier', v.target.value)} onBlur={() => setFieldTouched('disIdentifier', true)} value={values.disIdentifier} type="text" inputClassName={styles.input}></Input>
                  <ValidationErrorMessage
                    i18nKey={errors.disIdentifier}
                    invalid={touched.disIdentifier && errors.disIdentifier}
                  />
                </FormField>

                <FormField label={t('interioreditmodal.standard')} required>
                  <Checkbox id="1" value={values.isStandard} onChange={v => { const { checked } = v.target; setFieldValue('isStandard', checked); }} >{t('vehiclecategoryadmin.filter.titlestandard')}</Checkbox>
                  <ValidationErrorMessage
                    i18nKey={errors.isStandard}
                    invalid={touched.isStandard && errors.isStandard}
                  />
                </FormField>

                <ButtonGroup responsive={true}>
                  <Button color="primary" type="submit" disabled={!isValid || isSubmitting}>{interior ? t('common.update') : t('common.create')}</Button>
                  <Button color="secondary" type="reset" onClick={toggleInteriorModal}>{t('common.cancel')}</Button>
                  {interior && !interior.deletedAt && <DeleteButton text="Fahrzeugausbauart löschen" onClick={() => deleteInterior(interior.id)} />}
                  {interior && interior.deletedAt && <button onClick={() => restoreInterior(interior)} type="button" className={styles.restore}>
                    <Icon name="retry" className="icon--s2 margin-right-1" />
                    <span className={styles.restoreText}>{t('common.restore')}</span>
                  </button>}

                </ButtonGroup>
              </form>
            );
          }
        }

      </Formik>
    </Modal>
  );
}

export default InteriorEditModal;
