// @flow

import cx from 'classnames';
import React, { type Node } from 'react';
import styles from './DocumentLink.module.css';


type Props = {
  type: 'document' | 'link',
  target?: '_blank' | null,
  src: string,
  children: Node,
  className?: string,
};

function DocumentLink({ type, target, src, children, className }: Props) {
  const iconName = type === 'link' ? '006-attachment' : '118-document';
  const rel = target === '_blank' ? 'noopener noreferrer' : undefined;

  const classes = cx(styles.container, className,  `margin-left-0 icon-${iconName}`, {
    [styles.document]: type === 'document',
    [styles.link]: type === 'link',
  });
  return (
    <a className={classes} href={src} target={target} rel={rel}>
      {children}
    </a>
  );
}

DocumentLink.defaultProps = {
  target: '_blank',
};

export default DocumentLink;
