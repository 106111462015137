// @flow

import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import AnimateHeight from 'react-animate-height';
import { type Notification as NotificationType } from './notification-types';

import Icon from '../Icon';
import { NotificationTypes } from './NotificationsProvider';

type Props = {
  onClose: Function,
  notification: NotificationType,
};

const Notification = ({ notification, onClose }: Props) => {
  const [isClosing, setIsClosing] = useState(false);

  // Initialize closing after 3 seconds
  useEffect(() => {
    const timer = setTimeout(
      () => {
        setIsClosing(true);
      },
      notification.type === NotificationTypes.CONFIRMATION ? 5000 : 8000
    );

    return () => {
      clearTimeout(timer);
    };
  }, [notification.type]);

  // After 3 seconds initialize closing timeout
  // for animation duration
  useEffect(() => {
    let timer;

    if (isClosing) {
      timer = setTimeout(() => {
        onClose(notification.id);
      }, 250);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [isClosing, notification.id, onClose]);

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      onClose(notification.id);
    }, 250);
  };

  const classes = cx('notification-header notification--open', {
    'notification--confirmation': notification.type === NotificationTypes.CONFIRMATION,
    'notification--alert': notification.type === NotificationTypes.ALERT,
  });

  return (
    <AnimateHeight duration={250} height={isClosing ? 0 : 'auto'}>
      <div className={classes}>
        <div className="notification__content">
          <div className="notification__body">
            <p>{notification.text}</p>
          </div>
          <button
            className="notification__close notification-cancel"
            aria-label="Close"
            onClick={handleClose}
          >
            <Icon name="022-close"></Icon>
          </button>
        </div>
      </div>
    </AnimateHeight>
  );
};

export default Notification;
