//@flow

import React, { useState, useEffect } from 'react';
import isEqual from 'lodash/fp/isEqual';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Formik } from 'formik';

import styles from './DebtorForm.module.css';

import Container from '../../components/Container';
import BackMenu from '../../components/BackMenu';

import FormField from '../../components/FormField';
import ButtonGroup from '../../components/ButtonGroup/ButtonGroup';
import Button from '../../components/Button/Button';
import ValidationErrorMessage from '../../components/ValidationErrorMessage';
import { initialValues } from './initial-values';
import { validationSchema } from './validation-schema';
import RoutingPrompt from '../../components/RoutingPrompt';
import { useNotifications } from '../../components/Notifications/NotificationsProvider';
import StatusPicker from '../../components/StatusPicker/StatusPicker';
import Input from '../../components/Input';
import DebtorTypeSelect from '../../components/DebtorTypeSelect';
import { getDebtorById, updateDebtor, createDebtor, deleteDebtor } from '../../services/debtorService';
import DeleteButton from '../../components/DeleteButton';
import ConfirmationModal from '../../components/ConfirmationModal';
import { type Debtor } from '../../types/debtor-types';

function DebtorForm() {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams();

  const [hasSuccess, setHasSuccess] = useState<boolean>(false);
  const [formState, setFormState] = useState<?string>();

  const [showDebtorModal, setShowDebtorModal] = useState<boolean>(false);
  const [formInitialValues, setFormInitialValues] = useState<?Debtor>(initialValues);
  const { addSuccess, addAlert } = useNotifications();

  useEffect(() => {
    let checkId = async () => {
      if (id) {
        const { data: debtor } = await getDebtorById(id);
        setFormInitialValues(debtor);
        setFormState('edit');
      } else {
        setFormState('create');
      }
    };
    checkId();
  }, [id]);
  async function handleSubmit(values, actions) {
    try {
      const payload = {
        name: values.name,
        number: values.number,
        revenueAccountNumber: values.revenueAccountNumber,
        debtorType: values.debtorType,
        status: values.status,
      };
      if (formState === 'edit') {
        const convertedId = Number(id);
        await updateDebtor(id, { id: convertedId, ...payload });
      } else {
        await createDebtor(payload);
      }
      addSuccess(id ? t('debtorform.update.success') : t('debtorform.submit.success'));
      setHasSuccess(true);
      history.push('/debtors');
    } catch (err) {
      const errorCode = err?.response?.data?.error;

      const alertText = code => {
        if (code === 'MISSING_ID') {
          return 'debtorform.submit.missingid';
        }
        return 'debtorform.submit.failure';
      };

      actions.setSubmitting(false);
      addAlert(t(alertText(errorCode)));
      window.scrollTo(0, 0);
      throw err;
    }
  }


  async function removeDeptor() {
    try {
      await deleteDebtor(id);
      addSuccess(t('debtorform.delete.success'));
      history.push('/debtors');
    } catch (err) {
      const errorCode = err?.response?.data?.error;

      const alertText = code => {
        if (code === 'MISSING_ID') {
          return 'debtorform.delete.missingid';
        }
        return 'debtorform.delete.failure';
      };
      addAlert(t(alertText(errorCode)));
      window.scrollTo(0, 0);
      throw err;
    }
  }

  return (
    <>
      <BackMenu to="/debtors" text={t('driverchangeform.back')} />
      <Container>
        <Formik
          enableReinitialize={true}
          onSubmit={handleSubmit}
          initialValues={formInitialValues}
          validationSchema={validationSchema}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            getFieldValue,
            setFieldTouched,
            isValid,
            submitCount,
          }) => {
            return (
              <form onSubmit={handleSubmit}>
                {showDebtorModal && (
                  <ConfirmationModal children={<Trans i18nKey="debtorform.debtormodal.description" />} title={'Debitor löschen'} onClose={() => setShowDebtorModal(false)} handleSubmit={() => removeDeptor()} />

                )}
                <RoutingPrompt
                  when={!hasSuccess && !isEqual(values, formInitialValues)}
                  message={t('common.formprompt')}
                />
                <div className={styles.header}>
                  <h1>{id ? t('debtorform.updatetitle') : t('debtorform.createtitle')}</h1>
                  {id ? (
                    <DeleteButton text={t('debtorform.deletedebtor')} onClick={e => setShowDebtorModal(true)}/>
                  ) : null}
                </div>

                <FormField label={t('debtorform.fields.debtorname.label')} required>
                  <Input
                    name="name"
                    value={values.name}
                    onChange={event => setFieldValue('name', event.currentTarget.value)}
                    onBlur={() => setFieldTouched('name', true)}
                    hasMarginTop={false}
                    required
                  />
                  <ValidationErrorMessage
                    invalid={touched.name && errors.name}
                    i18nKey={errors.name}
                  />
                </FormField>
                <FormField label={t('debtorform.fields.debtornumber.label')} required>
                  <Input
                    name="number"
                    value={values.number}
                    onChange={event => setFieldValue('number', event.currentTarget.value)}
                    onBlur={() => setFieldTouched('number', true)}
                    hasMarginTop={false}
                    required
                  />
                  <ValidationErrorMessage
                    invalid={touched.number && errors.number}
                    i18nKey={errors.number}
                  />
                </FormField>
                <FormField label={t('debtorform.fields.debtoraccountnr.label')} required>
                  <Input
                    name="revenueAccountNumber"
                    value={values.revenueAccountNumber}
                    onChange={event =>
                      setFieldValue('revenueAccountNumber', event.currentTarget.value)
                    }
                    onBlur={() => setFieldTouched('revenueAccountNumber', true)}
                    hasMarginTop={false}
                    required
                  />
                  <ValidationErrorMessage
                    invalid={touched.revenueAccountNumber && errors.revenueAccountNumber}
                    i18nKey={errors.revenueAccountNumber}
                  />
                </FormField>
                <FormField label={t('debtorform.fields.debtortype.label')} required>
                  <DebtorTypeSelect
                    name="debtorType"
                    onChange={debtorType => {
                      setFieldValue('debtorType', debtorType);
                    }}
                    selected={values.debtorType ? values.debtorType : null}
                    required
                  />
                  <ValidationErrorMessage
                    invalid={touched.debtorType && errors.debtorType}
                    i18nKey={errors.debtorType}
                  />
                </FormField>
                <FormField label={t('debtorform.fields.debtorstatus.label')} required>
                  <StatusPicker
                    name="status"
                    value={values.status}
                    onChange={status => setFieldValue('status', status)}
                    onBlur={() => setFieldTouched('status', true)}
                    required
                  />
                  <ValidationErrorMessage
                    invalid={touched.status && errors.status}
                    i18nKey={errors.status}
                  />
                </FormField>
                <ButtonGroup responsive>
                  <Button
                    type="submit"
                    disabled={(submitCount > 0 && !isValid) || isSubmitting}
                    isLoading={isSubmitting}
                  >
                    {t('common.save')}
                  </Button>
                  <Button color="secondary" onClick={history.goBack} type="button">
                    {t('common.cancel')}
                  </Button>
                </ButtonGroup>
                <p>{t('formvalidation.required.descriptions')}</p>
              </form>
            );
          }}
        </Formik>
      </Container>
    </>
  );
}

export default DebtorForm;
