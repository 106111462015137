// @flow

import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { type Pagination } from '../types/pagination-types';

function usePagination(initialPage: number = 0, pageSize: number = 10) {
  const history = useHistory();
  const location = useLocation();
  const [page, setPage] = useState<number>(initialPage);
  const [pagination, setPagination] = useState<Pagination>({
    skip: 0,
    take: pageSize,
    totalCount: 0,
  });

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);

    if (page > 0) {
      urlParams.set('p', (page + 1).toString());
    } else {
      urlParams.delete('p');
    }

    history.replace(location.pathname + `?${urlParams.toString()}`);
  }, [page, history, location.pathname, location.search]);

  return { page, setPage, pagination, setPagination };
}

export default usePagination;
