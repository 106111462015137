// @flow

import { useState, useEffect, useCallback, useMemo } from 'react';
import debounce from 'debounce-promise';
import { findBills } from '../services/contractService';

import { type QueryFilters } from '../util/QueryFilterHelper';
import { OrderDirections } from '../types/order-types.js';

import usePagination from './usePagination.js';

const defaultFilter = {
  sort: {
    invoicingContractStartDate: OrderDirections.DESC,
  },
};

const PAGE_SIZE = 10;
const debouncedFetch = debounce(findBills, 350);

function useBills(id?: number, urlParam?: string, filter?: QueryFilters, initialPage?: number) {
  const [isLoadingBills, setIsLoadingBills] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [bills, setBills] = useState([]);
  const { page, setPagination, pagination, setPage } = usePagination(initialPage || 0, PAGE_SIZE);

  const billFilter = useMemo(
    () => ({
      ...defaultFilter,
      ...filter,
    }),
    [filter],
  );

  const fetchBills = useCallback(async () => {
    try {
      const skip = page ? page * PAGE_SIZE : 0;
      setHasError(false);
      setIsLoadingBills(true);
      const { data: paginatedResponse } = await debouncedFetch(id, urlParam, {
        ...billFilter,
        skip,
        take: PAGE_SIZE,
      });
      setBills(paginatedResponse.data);
      const { data, ...paginationProperties } = paginatedResponse;
      setPagination(paginationProperties);
      setIsLoadingBills(false);
    } catch (err) {
      setHasError(true);
      setIsLoadingBills(false);
      throw err;
    }
  }, [page, setPagination, billFilter, id, urlParam]);

  const loadMore = (page: { selected: number }) => {
    setPage(page.selected);
  };

  useEffect(() => {
    fetchBills();
  }, [fetchBills]);

  return { isLoadingBills, bills, hasError, loadMore, pagination, setPage };
}

export default useBills;
