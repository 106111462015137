// @flow

import React from 'react';
import { components } from 'react-select';
import Highlighter from 'react-highlight-words';

type Props = {
  word: string,
  text: string,
};

const Highlight = ({ word, text }: Props) => {
  const searchWords = word.split(' ');
  return (
    <Highlighter
      highlightClassName="Select__option--highlight"
      searchWords={searchWords}
      autoEscape={true}
      textToHighlight={text}
    />
  );
};

const Option = (props: any) => {
  const {
    selectProps: { inputValue },
    data: { label: originalLabel },
  } = props;
  const label = inputValue ? <Highlight word={inputValue} text={originalLabel} /> : originalLabel;
  return components.Option && <components.Option {...props}>{label}</components.Option>;
};

export default Option;
