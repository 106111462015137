import '@swisscom/sdx/dist/css/sdx.css';
import { defineCustomElements } from '@swisscom/sdx/dist/js/webcomponents/loader';
import React, { Suspense } from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import App from './App.jsx';
import AuthProvider from './components/AuthProvider/AuthProvider';
import NotificationsProvider from './components/Notifications/NotificationsProvider';
import config from './config';
import LoadingPage from './container/LoadingPage';
import './i18n';
import * as serviceWorker from './serviceWorker';
import './styles/_index.css';

window.version = config.tag;

const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
  <Router>
    <Suspense fallback={<LoadingPage hasHeader={false} />}>
      <LastLocationProvider>
        <NotificationsProvider>
          <AuthProvider>
            <App />
          </AuthProvider>
        </NotificationsProvider>
      </LastLocationProvider>
    </Suspense>
  </Router>,
);

serviceWorker.register();
defineCustomElements(window);
