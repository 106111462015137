// @flow

import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import tableStyles from '../../container/ContractOverview/ContractTable.module.css';
import { type Bill } from '../../types/bill-types';
import EmptyState from '../EmptyState';
import ErrorState from '../ErrorState';
import LoadingSpinner from '../LoadingSpinner';
import Pagination from '../Pagination';
import BillListItem from './BillListItem/BillListItem';


type Props = {
  billingId: number,
  urlParam: string,
  hasError: boolean,
  isLoading: boolean,
  bills: Bill[],
  pagination: {
    skip: number,
    take: number,
    totalCount: number
  },
  loadMore: Function,
};

function BillList({
  billingId,
  urlParam,
  hasError,
  isLoading,
  bills,
  pagination,
  loadMore,
}: Props) {
  const { t } = useTranslation();

  return (
    <div>
      <div className="row">
        <div className={`table table--responsive ${tableStyles.orderlist}`}>
          <div className="table__wrapper">
            <table className={cx({ [tableStyles.empty]: bills?.length === 0 })}>
              <thead>
                <tr>
                  <td style={{ width: '5%' }}>{t('billlist.id')}</td>
                  <td style={{ width: '5%' }}>{t('billlist.billcycleid')}</td>
                  <td style={{ width: '5%' }}>{t('billlist.contractid')}</td>
                  <td style={{ width: '15%' }}>{t('billlist.status')}</td>
                  <td style={{ minWidth: '300px' }}>{t('billlist.creator')}</td>
                  <td style={{ width: '10%' }}>{t('billlist.billcycledate')}</td>
                  <td style={{ width: '10%' }}>{t('billlist.billdate')}</td>
                  <td style={{ width: '25%' }}>{t('billlist.debtornumber')}</td>
                  <td style={{ width: '10%' }}>{t('billlist.debtorname')}</td>
                  <td style={{ width: '5%' }}>{t('billlist.snumber')}</td>
                  <td style={{ width: '5%' }}>{t('billlist.ordernumber')}</td>
                  <td style={{ width: '10%' }}>{t('billlist.incomeaccount')}</td>
                  <td style={{ minWidth: '300px' }}>{t('billlist.driver')}</td>
                  <td style={{ width: '10%' }}>{t('billlist.employeenumber')}</td>
                  <td style={{ width: '10%' }}>{t('billlist.drivercostcenter')}</td>
                  <td style={{ width: '10%' }}>{t('billlist.serviceperiod')}</td>
                  <td style={{ width: '10%' }}>{t('billlist.servicetype')}</td>
                  <td style={{ width: '10%' }}>{t('billlist.price')}</td>
                  <td style={{ width: '10%' }}>{t('billlist.grossprice')}</td>
                  <td style={{ width: '10%' }}>{t('billlist.taxrate')}</td>
                </tr>
              </thead>

              <tbody>
                {isLoading && (
                  <tr>
                    <td colSpan="12">
                      <div className={tableStyles.empty__outer}>
                        <LoadingSpinner />
                      </div>
                    </td>
                  </tr>
                )}
                {!isLoading && !hasError && bills?.length === 0 && (
                  <tr>
                    <td colSpan="12">
                      <div className={tableStyles.empty__outer}>
                        <EmptyState />
                      </div>
                    </td>
                  </tr>
                )}
                {!isLoading && hasError && (
                  <tr>
                    <td colSpan="12">
                      <div className={tableStyles.empty__outer}>
                        <ErrorState />
                      </div>
                    </td>
                  </tr>
                )}

                {!isLoading &&
                  bills &&
                  !hasError &&
                  bills.length > 0 &&
                  bills.map(bill => <BillListItem key={bill.id} bill={bill} />)}
              </tbody>
            </table>
            <div className="row">
              <div className="col-md-12 flex flex-items-center flex-space-between">
                {pagination?.totalCount > pagination?.take && pagination?.totalCount > 0 && (
                  <Pagination pagination={pagination} loadMore={loadMore} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default BillList;
