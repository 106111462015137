// @flow

import cx from 'classnames';
import { format, parseISO } from 'date-fns';
import * as download from 'downloadjs';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import Button from '../../components/Button';
import ConfirmationModal from '../../components/ConfirmationModal';
import EmptyState from '../../components/EmptyState/EmptyState';
import ErrorState from '../../components/ErrorState/ErrorState';
import LoadingSpinner from '../../components/LoadingSpinner';
import tableStyles from '../../container/ContractOverview/ContractTable.module.css';
import useContract from '../../hooks/useContract';
import { exportBillsForContract, softDeleteContract } from '../../services/contractService';
import { formatPrice } from '../../util/PriceFormatHelper';
import styles from './ContractDetail.module.css';


const formatDate = val => (!!val ? format(parseISO(val.toString()), 'dd.MM.yyyy') : '-');

function ContractDetail() {
  const { t } = useTranslation();
  const history = useHistory();

  const { id: contractId } = useParams();
  const { contract, isLoading, hasError } = useContract(contractId);
  const [confirmationModal, setConfirmationModal] = useState<boolean>(false);

  const properties = [
    {
      labelI18nKey: 'common.labels.contractnumber',
      value: contract?.id,
    },
    {
      labelI18nKey: 'common.labels.snumber',
      value: contract?.vehicle?.sNumber,
    },
    {
      labelI18nKey: 'contractoverview.listheader.company',
      value: contract?.contractor.company,
    },
    {
      labelI18nKey: 'contractoverview.listheader.contractstart',
      value: formatDate(contract?.invoicingContractStartDate),
    },
    {
      labelI18nKey: 'contractoverview.listheader.contractend',
      value: formatDate(contract?.invoicingContractEndDate),
    },
    {
      labelI18nKey: 'contractoverview.listheader.driver',
      value: `${contract?.contractor.firstname || ''} ${contract?.contractor.lastname || ''} ${contract?.contractor.unit || ''
      }`,
    },
    {
      labelI18nKey: 'common.labels.employeenumber',
      value: contract?.contractor.swisscomEmployeeNr,
    },
    {
      labelI18nKey: 'common.labels.price',
      value: t('common.priceformatted', {
        price: formatPrice(contract?.vehicle?.vehicleCategory.pricePerMonth, {
          includeCurrency: true,
          includeCentimes: true,
        }),
      }),
    },
    {
      labelI18nKey: 'contractoverview.listheader.state',
      value: t('contractoverview.billingstates.' + (contract?.status || '')),
    },
    {
      labelI18nKey: 'contractoverview.listheader.nextbilldate',
      value: formatDate(contract?.nextBillingDate),
    },
    {
      labelI18nKey: 'contractoverview.listheader.lastbilldate',
      value: formatDate(contract?.lastBillingDate),
    },
  ];

  const exportBills = async () => {
    const exportBillsData = await exportBillsForContract(contractId);
    download(
      exportBillsData.data,
      exportBillsData.headers['content-disposition'],
      exportBillsData.headers['content-type']
    );
  };

  const toggleConfirmationModal = () => {
    setConfirmationModal(!confirmationModal);
  };

  const deleteContract = async () => {
    softDeleteContract(contractId);
    history.push('/contracts');
  };

  return (
    <>
      <div className={styles.header}>
        <h1>
          <Trans
            i18nKey="contractdetail.title"
            values={{ contractNumber: contract?.id }}
          />
        </h1>
        <div className={styles.buttonContainer}>
          <Button onClick={() => exportBills()}
            icon="download"
            className={cx(styles.headerButton, 'margin-right-1 padding-left-2')}>
            {t('contractdetail.export')}
          </Button>
          {!hasError && (
            <Button
              icon="edit"
              onClick={() => {
                history.push(`/contracts/${contract?.id || ''}/edit`);
              }}
              className={cx(styles.editLink, 'margin-right-1 padding-left-2')}
            >
              {t('contractdetail.editcontract')}
            </Button>
          )}
          {!hasError && (
            <Button
              icon="bin"
              onClick={() => {
                toggleConfirmationModal();
              }}
              className={cx(styles.deleteLink, 'padding-left-2')}
            >
              {t('contractdetail.deletecontract')}
            </Button>
          )}
        </div>
      </div>
      {isLoading && (
        <div className="flex flex-center">
          <div colSpan="12">
            <div className={tableStyles.empty__outer}>
              <LoadingSpinner />
            </div>
          </div>
        </div>
      )}
      {!isLoading && !hasError && !contract?.id && (
        <div className="flex flex-center">
          <div colSpan="12">
            <div className={tableStyles.empty__outer}>
              <EmptyState />
            </div>
          </div>
        </div>
      )}
      {!isLoading && hasError && (
        <div className="flex flex-center">
          <div colSpan="12">
            <div className={tableStyles.empty__outer}>
              <ErrorState />
            </div>
          </div>
        </div>
      )}
      {!isLoading && !hasError && (
        <div className={cx(styles.propertyWrapper, 'margin-bottom-3')}>
          {properties.map((contractProperty, i) => (
            <div className="flex flex-space-between" key={i}>
              <span>{t(contractProperty.labelI18nKey)}</span> <p>{contractProperty.value}</p>
            </div>
          ))}
        </div>
      )}

      {confirmationModal && <ConfirmationModal title={t('contractdetail.modal.title')} onClose={() => toggleConfirmationModal()} handleSubmit={() => deleteContract()} />}
    </>
  );
}

export default ContractDetail;
