// @flow

import React from 'react';
import ReactSelect from 'react-select/async';
import { useTranslation } from 'react-i18next';

import Option from './Option';
import DropdownIndicator from './DropdownIndicator';

type Props = {
  id?: string,
  loadOptions: Function,
  value?: any,
  onChange: Function,
  [key: string]: any,
};

const Typeahead = ({ id, loadOptions, value, onChange, ...props }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="Typeahead">
      <ReactSelect
        value={value}
        onChange={onChange}
        inputId={id}
        className="Select__Container"
        classNamePrefix="Select"
        loadOptions={loadOptions}
        placeholder={t('common.search')}
        cacheOptions
        defaultOptions={[]}
        onInputChange={() => {}}
        loadingMessage={() => t('common.loading')}
        noOptionsMessage={() => t('typeahead.nooptions')}
        components={{ Option, DropdownIndicator }}
      ></ReactSelect>
    </div>
  );
};

export default Typeahead;
