// @flow

import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import BackMenu from '../../components/BackMenu';
import Button from '../../components/Button';
import ButtonGroup from '../../components/ButtonGroup';
import CreateVehicleCategoryModal from '../../components/CreateVehicleCategoryModal';
import Icon from '../../components/Icon';
import Modal from '../../components/Modal';
import { useNotifications } from '../../components/Notifications/NotificationsProvider';
import RequestVehicleInformation from '../../container/RequestVehicleForm/RequestVehicleInformation';
import useRawVehicleCategory from '../../hooks/useRawVehicleCategory';
import i18n from '../../i18n';
import { softDeleteCategory, updateVehicleCategory } from '../../services/vehicleCategoryService';
import styles from './VehicleCategoryEdit.module.css';


function VehicleCategoryEdit() {
  const history = useHistory();
  const { id: categoryId } = useParams();

  const [confirmationModalState, setConfModalState] = useState<boolean>(false);
  const [showEditModal, setEditModalState] = useState<boolean>(false);
  const [newCategory, setCategory] = useState<Object>(null);

  const { t } = useTranslation();
  const { rawCategory, isLoading } = useRawVehicleCategory(categoryId);
  const { addAlert, addSuccess } = useNotifications();

  useEffect(() => {
    let cat;
    if (rawCategory) cat = transformCategory(rawCategory);
    setCategory(cat);
  }, [rawCategory]);

  const toggleConfirmationModal = () => {
    setConfModalState(!confirmationModalState);
  };

  const toggleEditModalState = () => {
    setEditModalState(!showEditModal);
  };

  const archiveCategory = async () => {
    await softDeleteCategory(categoryId);
    history.push('/vehiclecategoryedit');
  };

  const transformCategory = data => {
    const browserLanguage = i18n.language.substr(0, 2).toLowerCase();
    const transformedCategory = {
      ...data,
      name:
        data.translations.find(elm => elm.languageCode === browserLanguage)?.name ||
        data.translations.find(elm => elm.languageCode === 'de')?.name,
      description:
        data.translations.find(elm => elm.languageCode === browserLanguage)?.description ||
        data.translations.find(elm => elm.languageCode === 'de')?.description,
    };
    return transformedCategory;
  };

  const updateCategories = data => {
    const updatedCategory = transformCategory(data);
    setCategory(updatedCategory);
  };

  const restoreCategory = async () => {
    try {
      await updateVehicleCategory({ ...newCategory, deletedAt: null }, newCategory.id);
      updateCategories({ ...newCategory, deletedAt: null });
      addSuccess(t('vehiclecategoryedit.success'));
      window.scrollTo(0, 0);
    } catch {
      addAlert(t('errorstate.defaultmessage'));
      window.scrollTo(0, 0);
    }
  };

  return (
    <>
      <BackMenu to="/vehiclecategoryedit" text={t('common.vehiclecategory.back')} />
      <div className={styles.container}>
        <div className={styles.header}>
          <button onClick={toggleEditModalState}>
            <Icon name="edit" className="icon--s2" />
            <p>{t('common.edit')}</p>
          </button>
          {newCategory && !newCategory.deletedAt && (
            <button onClick={toggleConfirmationModal}>
              <Icon name="drafts" className="icon--s2" />
              <p>{t('common.archive')}</p>
            </button>
          )}
          {newCategory && newCategory.deletedAt && (
            <>
              <button onClick={restoreCategory}>
                <Icon name="retry" className="icon--s2" />
                <span className={styles.restoreText}>{t('common.restore')}</span>
              </button>
              <div className={styles.archived}>
                <Icon name="026-exclamation-mark-circle" className="icon--s2" />
                <span>{t('common.archived')}</span>
              </div>
            </>
          )}
        </div>
        {categoryId && rawCategory && (
          <RequestVehicleInformation category={newCategory} isLoading={isLoading} />
        )}
      </div>
      {confirmationModalState && newCategory && (
        <Modal
          title={t('vehiclecategoryedit.modal.title')}
          onClose={toggleConfirmationModal}
          size="medium"
        >
          <p>{t('vehiclecategoryedit.modal.content', { categoryname: newCategory.name })}</p>
          <ButtonGroup responsive>
            <Button color="secondary" onClick={archiveCategory}>
              <Trans i18nKey="common.archive" />
            </Button>
            <Button onClick={toggleConfirmationModal}>
              <Trans i18nKey="common.cancel" />
            </Button>
          </ButtonGroup>
        </Modal>
      )}

      {showEditModal && rawCategory && (
        <CreateVehicleCategoryModal
          toggleCategoryModal={toggleEditModalState}
          initialValues={newCategory}
          updateCategories={updateCategories}
        />
      )}
    </>
  );
}

export default VehicleCategoryEdit;
