// @flow

import React, { useState } from 'react';
import Flatpickr from 'react-flatpickr';
import cx from 'classnames';
import isEqual from 'lodash/fp/isEqual';


import styles from './DatePicker.module.css';

type Props = {
  name?: string,
  value?: string,
  onChange?: Function,
  onBlur?: Function,
  placeholder?: string,
  id?: string,
  invalid?: boolean,
  disableWeekends?: boolean,
  options?: any,
};

const DatePicker = ({
  name,
  value,
  onChange,
  onBlur,
  placeholder,
  id,
  invalid,
  disableWeekends,
  options,
}: Props) => {
  const disable = [
    date => {
      const day = date.getDay();
      return day === 0 || day === 6;
    },
  ];

  const [calendarInstanceRef, setCalendarInstanceRef] = useState<Flatpickr>(null);

  return (
    <div className={cx('input-field', 'flatpickr', styles.container)} styles={{ zIndex: 900002 }}>
      <Flatpickr
        options={{
          dateFormat: 'd.m.Y',
          allowInput: true,
          disable: disableWeekends ? disable : [],
          monthSelectorType: 'static',
          locale: {
            firstDayOfWeek: 1,
          },
          ...options,
        }}
        id={id}
        name={name}
        placeholder={placeholder}
        value={value}
        className={cx({ invalid })}
        onChange={date => {
          if (date && onChange && !isEqual(value, date[0])) onChange(date[0]);
          if (!date && onChange) onChange(null);
          if (date.length === 0 && onChange) onChange(null);
        }}
        onBlur={e => {
          if (onBlur) {
            setTimeout(() => {
              onBlur(e);
            }, 300);
          }
        }}
        ref={setCalendarInstanceRef}
      />
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a>
        <i
          className="icon icon-009-calendar"
          aria-hidden="true"
          onClick={() => calendarInstanceRef.flatpickr.open()}
        />
        <span className="sr-only">Calendar</span>
      </a>
    </div>
  );
};

DatePicker.defaultProps = {
  disableWeekends: true,
};

export default DatePicker;
