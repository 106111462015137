//@flow

import React from 'react';
import { CardText } from '../../../components/Card';
type Props = {
  label: String,
  children: any,
};

function InformationCardItem({ label, children }: Props) {
  return (
    <div className="row center">
      <CardText className="col-sm-12 col-md-6 margin-bottom-1">{label}</CardText>
      <div className="col-sm-12 col-md-6 margin-bottom-2 font font--semi-bold gray">{children}</div>
    </div>
  );
}

export default InformationCardItem;
