// @flow

import { type DebtorCostcenter } from '../types/debtor-costcenter-types';
import { type Debtor } from '../types/debtor-types';

import { type PaginatedResponse } from '../types/pagination-types';
import { fetch } from './fetch';
import { type AxiosResponse } from 'axios';

import { parseQueryFilters } from '../util/QueryFilterHelper';
import { type QueryFilters } from '../util/QueryFilterHelper';

export async function getDebtorCostcenterOverview(): Promise<PaginatedResponse<DebtorCostcenter>> {
  return fetch<Debtor[]>('/debtorcostcenter', 'GET', null);
}

export async function getDebtorCostcenterById(id: number): Promise<AxiosResponse<{ data: DebtorCostcenter }>> {
  return fetch<Debtor>(`/debtorcostcenter/${id}`, 'GET', null);
}

export async function createDebtorCostcenter(payload: DebtorCostcenter): Promise<AxiosResponse<DebtorCostcenter>> {
  return fetch<Debtor>(`/debtorcostcenter`, 'POST', payload);
}

export async function updateDebtorCostcenter(id: number, payload: DebtorCostcenter): Promise<AxiosResponse<DebtorCostcenter>> {
  return fetch<Debtor>(`/debtorcostcenter/${id}`, 'PUT', payload);
}

export async function deleteDebtorCostcenter(id: number): Promise<void> {
  return fetch<Debtor>(`debtorcostcenter/${id}`, 'DELETE', null);
}

export async function getDebtorCostcenters(
  filters: QueryFilters,
): Promise<AxiosResponse<DebtorCostcenter[]>> {
  return fetch<Debtor[]>('/debtorcostcenter', 'GET', null, {
    params: parseQueryFilters(filters),
  });
}
