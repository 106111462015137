//@flow

import { Formik } from 'formik';
import React, { useContext, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { AuthContext } from '../../../../components/AuthProvider/AuthProvider';
import Button from '../../../../components/Button';
import ButtonGroup from '../../../../components/ButtonGroup';
import CommentField from '../../../../components/CommentField';
import MessageCard from '../../../../components/MessageCard';
import { useNotifications } from '../../../../components/Notifications/NotificationsProvider';
import RejectMutationModal from '../../../../components/RejectMutationModal';
import { approveMutation } from '../../../../services/contractMutationService';
import { type ContractMutation } from '../../../../types/contract-mutation-types';
import { MutationDetailContext } from '../../MutationDetail';

type Props = {
  selectedCategory: { value: String, label: String },
  mutation: ContractMutation,
  selectedRequestDate?: string | Date
};

function ApprovalForm({ mutation, selectedCategory, selectedRequestDate }: Props) {
  const { t } = useTranslation();
  const history = useHistory();
  const { fetchProfile } = useContext(AuthContext);
  const { currentApprovalPoint } = useContext(MutationDetailContext);

  const [showRejectionModal, setShowRejectingModal] = useState<boolean>(false);
  const { addSuccess, addAlert } = useNotifications();

  const validationSchema = Yup.object().shape({
    approved: Yup.boolean(),
  });

  async function handleSubmit(values, actions) {
    try {
      await approveMutation(mutation.id, {
        vehicleCategoryId: selectedCategory?.value,
        dateOfExecution: selectedRequestDate,
        ...values,
      });
      fetchProfile();

      addSuccess(t('mutationdetail.alerts.success'));
      window.scrollTo(0, 0);
      history.push('/mutation-overview');
    } catch {
      addAlert(t('errorstate.defaultmessage'));
      window.scrollTo(0, 0);
      actions.setSubmitting(false);
    }
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{ approvalPoint: currentApprovalPoint.type, approved: true, comment: '' }}
      validationSchema={validationSchema}
    >
      {({
        values,
        touched,
        errors,
        setFieldValue,
        setFieldTouched,
        handleChange,
        handleBlur,
        submitCount,
        handleSubmit,
        isSubmitting,
        isValid,
        submitForm,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            {showRejectionModal && (
              <RejectMutationModal
                mutationId={mutation.id}
                approvalPoint={values.approvalPoint}
                title={t('requestvehicledetail.rejectionform.title')}
                onClose={() => setShowRejectingModal(false)}
              />
            )}

            <MessageCard
              type="important"
              title={t('requestvehicledetail.returnnotice.title')}
              className="margin-bottom-2"
            >
              <Trans i18nKey="requestvehicledetail.returnnotice.message" />
            </MessageCard>
            <CommentField value={values.comment} onChange={v => setFieldValue('comment', v)} />
            <ButtonGroup responsive>
              <Button
                color="primary"
                type="submit"
                disabled={isSubmitting}
                isLoading={isSubmitting}
              >
                <Trans i18nKey="mutationdetail.buttons.accept" />
              </Button>
              <Button
                color="secondary"
                type="button"
                disabled={isSubmitting}
                onClick={() => setShowRejectingModal(true)}
              >
                <Trans i18nKey="mutationdetail.buttons.reject" />
              </Button>
              <Button
                color="secondary"
                type="button"
                disabled={isSubmitting}
                onClick={history.goBack}
              >
                <Trans i18nKey="mutationdetail.buttons.cancel" />
              </Button>
            </ButtonGroup>
          </form>
        );
      }}
    </Formik>
  );
}

export default ApprovalForm;
