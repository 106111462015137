import * as Yup from 'yup';

export const validationSchemaDe = Yup.object().shape({
  name_german: Yup.string().required('formvalidation.required.message'),
  type: Yup.string().required('formvalidation.required.message'),
  isRepresentationVehicle: Yup.boolean().required('formvalidation.required.message'),
  interiorConstructionId: Yup.number().positive().when('type', {
    is: 'utilityvehicle',
    then: Yup.number().positive().required(),
    otherwise: Yup.number().positive().nullable(),
  }),
  description_german: Yup.string().required('formvalidation.required.message'),
  purpose_german: Yup.string().required('formvalidation.required.message'),
  abbreviation: Yup.string().required('formvalidation.required.message'),
  disIdentifier: Yup.string().required('formvalidation.required.message'),
  loadCapacity: Yup.number().positive().required('formvalidation.required.message'),
  additionalFeatures_german: Yup.string(),
  fieldOfApplication_german: Yup.string(),
  pricePerMonth: Yup.number().positive().required('formvalidation.required.message'),
  towableMass: Yup.number().positive().when('hasTowHitch', {
    is: true,
    then: Yup.number().positive().min(1).required('formvalidation.required.message'),
    otherwise: Yup.number().positive().min(0)
  }),
  minLoadVolume: Yup.number().positive(),
  energyEfficiencyClass: Yup.string().required('formvalidation.required.message'),
  maxEmission: Yup.number().positive().required('formvalidation.required.message'),
  typeOfDrive: Yup.string().required('formvalidation.required.message'),
  maxGrossWeight: Yup.number().positive().required('formvalidation.required.message'),
  hasTowHitch: Yup.boolean(),
  hasNavigationSystem: Yup.boolean(),
  hasParkingSensors: Yup.boolean(),
  hasAirConditioning: Yup.boolean(),
  hasCitySafetySystem: Yup.boolean(),
  hasLDWS: Yup.boolean(),
  hasBlindSpotMonitor: Yup.boolean(),
  hasCruiseControl: Yup.boolean(),
  cruiseControlType: Yup.object().when('hasCruiseControl', {
    is: true,
    then:  Yup.object().shape({
      value: Yup.string().required('formvalidation.required.message'),
      label: Yup.string().required('formvalidation.required.message')
    }).required('formvalidation.required.message'),
  }).nullable()
});

export const validationSchemaFr = Yup.object().shape({
  name_french: Yup.string().required('formvalidation.required.message'),
  description_french: Yup.string().required('formvalidation.required.message'),
  purpose_french: Yup.string().required('formvalidation.required.message'),
  additionalFeatures_french: Yup.string(),
  fieldOfApplication_french: Yup.string(),
});

export const validationSchemaIt = Yup.object().shape({
  name_italian: Yup.string().required('formvalidation.required.message'),
  description_italian: Yup.string().required('formvalidation.required.message'),
  purpose_italian: Yup.string().required('formvalidation.required.message'),
  additionalFeatures_italian: Yup.string(),
  fieldOfApplication_italian: Yup.string(),
});
