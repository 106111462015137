// @flow

import React, { useState, useContext, useEffect } from 'react';
import isEqual from 'lodash/fp/isEqual';
import { useTranslation, Trans } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import {addDays, startOfDay} from 'date-fns';

import Container from '../../components/Container';
import UserPicker from '../../components/UserPicker';
import BackMenu from '../../components/BackMenu';

import styles from './DriverChangeForm.module.css';
import DatePicker from '../../components/DatePicker/DatePicker';
import FormField from '../../components/FormField';
import MileageInput from '../../components/MileageInput';
import ButtonGroup from '../../components/ButtonGroup/ButtonGroup';
import Button from '../../components/Button/Button';
import AutoId from '../../components/AutoId';
import ValidationErrorMessage from '../../components/ValidationErrorMessage';
import { initialValues } from './initial-values';
import { validationSchema } from './validation-schema';
import RoutingPrompt from '../../components/RoutingPrompt';
import AcceptDriverConditions from '../../components/AcceptDriverConditions';
import { changeDriver, saveChangeDriverDraft, deleteChangeDriverDraft } from '../../services/contractService';
import { useNotifications } from '../../components/Notifications/NotificationsProvider';
import ContractInformation from '../../components/ContractInformation';
import { AuthContext } from '../../components/AuthProvider/AuthProvider';
import ConditionProtocol from '../../components/ConditionProtocol';
import CommentField from '../../components/CommentField';
import useContract from '../../hooks/useContract';
import Checkbox from '../../components/Checkbox';
import { getProfile } from '../../services/userService';
import { type User } from '../../types/user-types';
import useQueryParams from '../../hooks/useQueryParams';
import { getMutation, updateContractMutation, getOpenMutation } from '../../services/contractMutationService';
import MessageCard from '../../components/MessageCard/MessageCard';
import {type ContractMutation} from '../../types/contract-mutation-types';

function DriverChangeForm() {
  const { t } = useTranslation();
  const history = useHistory();
  const { id: contractId } = useParams();
  const { fetchProfile, profile } = useContext(AuthContext);
  const [hasSuccess, setHasSuccess] = useState<boolean>(false);
  const { addSuccess, addAlert } = useNotifications();
  const { contract, isLoading, hasError } = useContract(contractId);
  const [user, setUser] = useState<?User>();
  const draftId = +useQueryParams('draftId');
  const [formInitialValues, setFormInitialValues] = useState();
  const [showPrompt, setShowPrompt] = useState <boolean>(true);
  const [hasOpenMutation, setHasOpenMutation] = useState<ContractMutation | null>(null);

  useEffect(() => {
    async function fetchUser() {
      const { data } = await getProfile();
      setUser(data);
    }

    const fetchOpenMutation = async () => {
      const {data }= await getOpenMutation(+contractId, { contractMutationType: ['DRIVERCHANGE', 'RETURNVEHICLE'], status: ['PENDING']});
      setHasOpenMutation(data);
    };

    fetchUser();
    fetchOpenMutation();
  }, [contractId]);

  useEffect(() => {
    if (!draftId) {
      setFormInitialValues(initialValues);
      return;
    }



    const getDraftMutation = async () => {
      const { data: initialValues } = await getMutation(draftId);
      const mappedInitialValues = {
        ...initialValues,
        driver: initialValues.changeDriverMutation.driver ? {
          label: `${initialValues.changeDriverMutation.driver.lastname} ${initialValues.changeDriverMutation.driver.firstname}, ${initialValues.changeDriverMutation.driver.unit}`,
          value: initialValues.changeDriverMutation.driver.swisscomEmployeeNr,
          user: initialValues.changeDriverMutation.driver
        } : null,
        mileagePerYear: initialValues.changeDriverMutation.mileagePerYear ? { value: initialValues.changeDriverMutation.mileagePerYear, label: initialValues.changeDriverMutation.mileagePerYear } : null,
        dateOfChange: initialValues.requestedDateOfExecution,
        comment: initialValues.comment,
        sendToProvider: true,
        acceptDriverConditions: false,
        conditionProtocolRead: false,
      };
      setFormInitialValues(mappedInitialValues);
    };
    getDraftMutation();

  }, [draftId, t]);

  async function handleSubmit(values, actions) {
    try {
      const payload = {
        driverEmployeeNr: values.driver.value,
        dateOfChange: new Date(values.dateOfChange).toUTCString(),
        mileagePerYear: values.mileagePerYear.value,
        acceptDriverConditions: values.acceptDriverConditions,
        conditionProtocolRead: values.conditionProtocolRead,
        comment: values.comment,
        sendToProvider: values.sendToProvider,
        draftId,
      };
      await changeDriver(contractId, payload);
      fetchProfile();
      addSuccess(t('driverchangeform.submit.success'));
      setHasSuccess(true);
      history.push('/');
    } catch (err) {
      const errorCode = err?.response?.data?.error;

      const alertText = code => {
        if (code === 'CONFLICT_MUTATION_COLLISION') {
          return t('driverchangeform.submit.collision', {id: hasOpenMutation?.id});
        } else if (code === 'CONFLICT_IS_ALREADY_CURRENTDRIVER') {
          return t('driverchangeform.submit.samedriver');
        }
        return t('driverchangeform.submit.failure');
      };

      actions.setSubmitting(false);
      addAlert(alertText(errorCode));
      window.scrollTo(0, 0);
      throw err;
    }
  }

  const handleSaveDraft = async values => {
    try {
      const payload = {
        ...values,
        comment: values.comment,
        dateOfChange: values.dateOfChange,
        requestedDateOfExecution: values.dateOfChange,
        driver: values.driver,
        mileagePerYear: values.mileagePerYear ? values.mileagePerYear.value : null,
        driverEmployeeNr: values.driver ? values.driver.user.swisscomEmployeeNr : null,
        draftId,
      };

      if (hasValue(payload)) {
        if (draftId) {
          await updateContractMutation(draftId, payload);
          addSuccess(t('drafts.updatedraft'));
        } else {
          await saveChangeDriverDraft(contractId, payload);
          addSuccess(t('drafts.savedraft'));
        }
        setShowPrompt(false);
        history.push('/');
      } else {
        addAlert(t('drafts.emptydraft'));
      }
    } catch (err) {
      addAlert(t('drafts.failure'));
      throw err;
    }
    window.scrollTo(0, 0);
  };

  const removeDraft = async values => {
    try {
      if (formInitialValues && formInitialValues.changeDriverMutation) {
        await deleteChangeDriverDraft(draftId, formInitialValues.changeDriverMutation.id);
        addSuccess(t('drafts.deletedraft'));
        setShowPrompt(false);
        history.push('/');
      }
    } catch (err) {
      addAlert(t('drafts.faildeletedraft'));
      throw err;
    }
    window.scrollTo(0, 0);
  };

  const hasValue = payload => {
    return payload.comment !== '' || payload.dateOfChange || payload.driver || payload.mileagePerYear;
  };

  return (
    <>
      <BackMenu to="/" text={t('driverchangeform.back')} />
      <Container>
        {formInitialValues && <Formik
          onSubmit={handleSubmit}
          initialValues={formInitialValues}
          validationSchema={validationSchema}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            setFieldTouched,
            isValid,
            submitCount,
          }) => {
            return (
              <form onSubmit={handleSubmit}>
                <RoutingPrompt
                  when={!hasSuccess && !isEqual(values, initialValues) && showPrompt}
                  message={t('common.formprompt')}
                />
                <h1>{t('driverchangeform.title')}</h1>
                <ContractInformation
                  contract={contract}
                  isLoading={isLoading}
                  hasError={hasError}
                  className={styles.infoCard}
                />

                {hasOpenMutation && <MessageCard
                  type="important"
                  title={t('common.blockingmutations.title')}
                  className="margin-bottom-2">
                  <Trans i18nKey="common.blockingmutations.text" values={{ id: hasOpenMutation.id} } />
                </MessageCard>}

                <h3>{t('driverchangeform.subtitle')}</h3>
                <FormField label={t('driverchangeform.fields.driver.label')} required>
                  <UserPicker
                    name="driver"
                    value={values.driver}
                    onChange={driver => setFieldValue('driver', driver)}
                    onBlur={() => setFieldTouched('driver', true)}
                    invalid={touched.driver && errors.driver}
                  />
                  <ValidationErrorMessage
                    invalid={touched.driver && errors.driver}
                    i18nKey={errors.driver}
                  />
                </FormField>
                <FormField label={t('driverchangeform.fields.date.label')} required>
                  <DatePicker
                    name="dateOfChange"
                    invalid={touched.dateOfChange && errors.dateOfChange}
                    value={values.dateOfChange}
                    onChange={dateOfChange => setFieldValue('dateOfChange', dateOfChange)}
                    onBlur={() => setFieldTouched('dateOfChange', true)}
                    options={{ minDate: startOfDay(addDays(new Date(), 1)) }}
                  />
                  <ValidationErrorMessage
                    invalid={touched.dateOfChange && errors.dateOfChange}
                    i18nKey={errors.dateOfChange}
                  />
                </FormField>
                <FormField label={t('driverchangeform.fields.mileageperyear.label')} required>
                  <MileageInput
                    hasMarginTop={false}
                    name="mileagePerYear"
                    value={values.mileagePerYear}
                    onChange={value => setFieldValue('mileagePerYear', value)}
                    onBlur={() => setFieldTouched('mileagePerYear', true)}
                    invalid={touched.mileagePerYear && errors.mileagePerYear}
                  />
                  <ValidationErrorMessage
                    invalid={touched.mileagePerYear && errors.mileagePerYear}
                    i18nKey={errors.mileagePerYear}
                  />
                </FormField>
                <AutoId>
                  {id => (
                    <>
                      <AcceptDriverConditions
                        required
                        name="acceptDriverConditions"
                        value={values.acceptDriverConditions}
                        id={id}
                        onChange={event =>
                          setFieldValue('acceptDriverConditions', event.currentTarget.checked)
                        }
                        onBlur={() => setFieldTouched('acceptDriverConditions', true)}
                      />
                      <ValidationErrorMessage
                        className="margin-top-0 margin-bottom-4"
                        invalid={touched.acceptDriverConditions && errors.acceptDriverConditions}
                        i18nKey={errors.acceptDriverConditions}
                      ></ValidationErrorMessage>
                    </>
                  )}
                </AutoId>
                <ConditionProtocol
                  setFieldTouched={setFieldTouched}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  values={values}
                  touched={touched}
                  required
                  vehicleType={contract?.vehicle.vehicleCategory?.type}
                />

                {user && user.isFleetMember && <AutoId>{id => (<Checkbox className={styles.marginBottom} id={id} value={values.sendToProvider} onChange={v => setFieldValue('sendToProvider', v.target.checked)} >{t('driverchangeform.fields.sendtoprovider')}</Checkbox>)}</AutoId>}
                <CommentField
                  value={values.comment}
                  onChange={v => setFieldValue('comment', v)}
                  onBlur={handleBlur}
                />
                <ButtonGroup responsive>
                  <Button
                    type="submit"
                    disabled={(submitCount > 0 && !isValid) || isSubmitting}
                    isLoading={isSubmitting}
                  >
                    {t('common.send')}
                  </Button>
                  {profile?.isFleetMember &&
                    <>
                      <Button
                        type="button"
                        onClick={() => handleSaveDraft(values)}
                        isLoading={isSubmitting}
                        color="confirm"
                      >{t('drafts.buttons.save')}</Button>
                      {!isNaN(draftId) && <Button
                        className="deleteDraftButton"
                        type="button"
                        onClick={() => removeDraft(values)}
                        isLoading={isSubmitting}
                      >{t('drafts.buttons.delete')}</Button>}
                    </>
                  }
                  <Button color="secondary" onClick={history.goBack} type="button">
                    {t('common.cancel')}
                  </Button>
                </ButtonGroup>
                <p>{t('formvalidation.required.descriptions')}</p>

              </form>
            );
          }}
        </Formik>}
      </Container>
    </>
  );
}

export default DriverChangeForm;
