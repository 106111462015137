//@flow

import React from 'react';
import { useHistory } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { Formik } from 'formik';

import Button from '../Button';
import ButtonGroup from '../ButtonGroup';
import FormField from '../FormField';
import Modal from '../Modal';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import ValidationErrorMessage from '../ValidationErrorMessage';
import RadioButtonGroup from '../RadioButtonGroup';
import RadioButton from '../RadioButton';

import useVehicleInteriors from '../../hooks/useVehicleInteriors';

import { validationSchema } from './validation-schema';

type Props = {
  mutationId: number,
  approvalPoint: string,
  onClose: Function,
  children?: any,
};

function UtilityVehicleFilterModal({ mutationId, approvalPoint, onClose, children }: Props) {
  const history = useHistory();
  const { t } = useTranslation();

  const { isLoading, interiors } = useVehicleInteriors(false);

  async function handleSubmit(values, actions) {
    if (values.specificInterior) {
      history.push('/categories/utilityvehicle?interior=' + values.selectedInterior);
    } else {
      const standardInteriors = interiors.filter(i => i.isStandard).map(i => i.id);
      history.push('/categories/utilityvehicle?interior=' + standardInteriors.join(','));
    }
  }

  return (
    <Modal title={t('specificationmodal.utilityvehicle.title')} onClose={onClose} size="wide">
      <Formik
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        initialValues={{ specificInterior: false, selectedInterior: '' }}
      >
        {({
          values,
          touched,
          errors,
          setFieldValue,
          setFieldTouched,
          handleChange,
          handleBlur,
          submitCount,
          handleSubmit,
          isSubmitting,
          isValid,
          submitForm,
        }) => {
          return (
            <form
              onSubmit={e => {
                e.stopPropagation();
                return handleSubmit(e);
              }}
            >
              <FormField label={t('specificationmodal.utilityvehicle.specificinterior')}>
                <RadioButtonGroup
                  value={values.specificInterior}
                  onChange={v => setFieldValue('specificInterior', v === 'true')}
                >
                  <RadioButton value={false}>
                    <Trans i18nKey="common.no" />
                  </RadioButton>
                  <RadioButton value={true}>
                    <Trans i18nKey="common.yes" />
                  </RadioButton>
                </RadioButtonGroup>
                <ValidationErrorMessage
                  invalid={touched.rejectionComment && errors.rejectionComment}
                  i18nKey={'formvalidation.required.message'}
                />
              </FormField>
              {values.specificInterior && (
                <FormField label={t('specificationmodal.utilityvehicle.interiorselect')}>
                  {isLoading ? (
                    <div className="flex flex-center">
                      <LoadingSpinner size="large" />
                    </div>
                  ) : (
                    <RadioButtonGroup
                      value={values.selectedInterior}
                      onChange={v => setFieldValue('selectedInterior', v)}
                      multicolumn
                    >
                      {interiors
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .map(i => (
                          <RadioButton key={i.id} value={i.id}>
                            {i.name}
                          </RadioButton>
                        ))}
                    </RadioButtonGroup>
                  )}
                  <ValidationErrorMessage
                    invalid={touched.selectedInterior && errors.selectedInterior}
                    i18nKey={'formvalidation.required.message'}
                  />
                </FormField>
              )}
              <ButtonGroup responsive>
                <Button
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                >
                  <Trans i18nKey="specificationmodal.buttons.confirm" />
                </Button>
                <Button color="secondary" disabled={isSubmitting} onClick={() => onClose()}>
                  <Trans i18nKey="specificationmodal.buttons.cancel" />
                </Button>
              </ButtonGroup>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
}

export default UtilityVehicleFilterModal;
