// @flow

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import CategoryCard from '../../container/SelectVehicleCategory/CategoryCard';
import useVehicleInteriors from '../../hooks/useVehicleInteriors';
import { type VehicleCategory, type VehicleInteriorConstruction } from '../../types/vehicle-category-types';
import CreateVehicleCategoryModal from '../CreateVehicleCategoryModal';
import Icon from '../Icon';
import InteriorCard from '../InteriorCard';
import InteriorEditModal from '../InteriorEditModal';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import { useNotifications } from '../Notifications/NotificationsProvider';
import styles from './VehicleCategories.module.css';



type Props = {
  filterProps: Object,
  categories: VehicleCategory[],
  resetInteriorCategories: Function,
  isLoading: Boolean,
  updateCategories: Function,
};

function VehicleCategories({
  filterProps,
  categories,
  resetInteriorCategories,
  isLoading,
  updateCategories,
}: Props) {
  const { t } = useTranslation();
  const [modalState, setModalState] = useState<boolean>(false);
  const [showInteriorModal, setShowInteriorModal] = useState<boolean>(false);
  const [hasInitialValues, setHasInitialValues] = useState<boolean>(false);
  const [filteredCategories, setFilteredCategories] = useState<VehicleCategory[]>([]);
  const [pageTitle, setPageTitle] = useState<string>('');
  const [showInteriorButton, setButtonState] = useState<boolean>(false);
  const [selectedInterior, setSelectedInterior] = useState<Object>({});
  const [interiorModalValue, setInteriorModalValue] = useState<Object>({});
  const history = useHistory();

  const {
    interiors,
    submitInterior,
    filteredInteriors,
    setFilteredInteriors,
    removeInterior,
    restoreInterior
  } = useVehicleInteriors(true);
  const { addAlert, addSuccess } = useNotifications();

  useEffect(() => {
    setFilteredInteriors(interiors.filter(elm => !elm.isStandard));
    //eslint-disable-next-line
  }, [interiors]);

  useEffect(() => {
    let newPageTitle = '';
    if (filterProps && filterProps.find(elm => elm.active && !elm.interior)) {
      newPageTitle = filterProps.find(elm => elm.active).pageTitle;
    } else {
      if (
        filterProps &&
        filterProps.find(elm => elm.active && elm.interior) &&
        !selectedInterior.id
      ) {
        newPageTitle = filterProps.find(elm => elm.active).pageTitle;
      } else {
        newPageTitle = selectedInterior.name;
      }
    }

    setPageTitle(newPageTitle);
  }, [filterProps, selectedInterior]);

  useEffect(() => {
    setFilteredCategories(categories);
  }, [categories]);

  useEffect(() => {
    // resets selected interior
    setSelectedInterior({});
    const activeFilter = filterProps ? filterProps.find(elm => elm.active) : null;
    setButtonState(
      !!(filterProps && activeFilter?.type === 'utilityvehicle' && activeFilter?.interior),
    );
  }, [filterProps]);

  const toggleCategoryModal = () => {
    setModalState(!modalState);
  };
  const toggleInteriorModal = hasInitialValues => {
    setHasInitialValues(hasInitialValues);
    setShowInteriorModal(!showInteriorModal);
  };

  const handleInteriorClick = interior => {
    setButtonState(false);
    setSelectedInterior(interior);
    setFilteredCategories(categories.filter(elm => elm.interiorConstruction.id === interior.id));
  };

  const reactivateInterior = async (interior: VehicleInteriorConstruction) => {
    try {
      await restoreInterior(interior);
      addSuccess(t('vehiclecategoryadmin.reactivate.success'));
    } catch {
      addAlert(t('vehiclecategoryadmin.reactivate.failure'));
    }
    window.scrollTo(0, 0);
    toggleInteriorModal(false);
  };

  const deleteInterior = async interiorId => {
    try {
      await removeInterior(interiorId);
      addSuccess(t('vehiclecategoryadmin.remove.success'));
    } catch {
      addAlert(t('vehiclecategoryadmin.remove.archiveassociatedcategories'));
    }
    window.scrollTo(0, 0);
    toggleInteriorModal(false);
  };

  const handleEditClick = (e, interior, hasInitialValues) => {
    e.stopPropagation();
    setInteriorModalValue(filteredInteriors.find(elm => elm.id === interior.id));
    toggleInteriorModal(hasInitialValues);
  };

  const goToDetail = id => {
    history.push(`/vehiclecategoryedit/${id}`);
  };

  const handleBackButtonClick = () => {
    setSelectedInterior({});
    resetInteriorCategories();
    const activeFilter = filterProps ? filterProps.find(elm => elm.active) : null;
    setButtonState(
      !!(filterProps && activeFilter?.type === 'utilityvehicle' && activeFilter?.interior),
    );
  };

  const submit = async values => {
    try {
      await submitInterior(values);
      addSuccess(
        !values.id
          ? t('vehiclecategoryadmin.submit.create')
          : t('vehiclecategoryadmin.submit.update'),
      );
    } catch {
      addAlert(t('vehiclecategoryadmin.submit.failure'));
    }
    window.scrollTo(0, 0);
    toggleInteriorModal(false);
  };

  return (
    <>
      {filterProps && (
        <div className={styles.container}>
          {filterProps &&
            filterProps.find(elm => elm.active && elm.interior) &&
            selectedInterior.id && (
            <div
              className={styles.backbutton}
              onClick={() => handleBackButtonClick()}
              role="button"
              tabIndex="-1"
            >
              <Icon name="arrow-left"></Icon>
              <span>{t('vehiclecategoryadmin.specialinteriorbackbutton')}</span>
            </div>
          )}
          <div className={styles.headerContainer}>
            <h2>{pageTitle}</h2>
            <div className={styles.buttonContainer}>
              <sdx-button-group>
                {!showInteriorButton && (
                  <sdx-button
                    label={t('vehiclecategoryadmin.createnewcategory')}
                    onClick={() => {
                      toggleCategoryModal();
                    }}
                  ></sdx-button>
                )}
                {showInteriorButton && (
                  <sdx-button
                    label={t('vehiclecategoryadmin.createnewinterior')}
                    onClick={() => {
                      toggleInteriorModal(false);
                    }}
                  ></sdx-button>
                )}
              </sdx-button-group>
            </div>
          </div>
          <div className={styles.categoryCardsContainer}>
            {isLoading && <LoadingSpinner />}
            {!showInteriorButton &&
              filteredCategories.map(category => (
                <div className="col-md-6 margin-bottom-2" key={category.id}>
                  <CategoryCard
                    category={category}
                    onClick={() => {
                      goToDetail(category.id);
                    }}
                    className={styles.card}
                  />
                </div>
              ))}

            {showInteriorButton &&
              filteredInteriors.map(interior => (
                <div className="col-md-6 margin-bottom-2" key={interior.id}>
                  <InteriorCard
                    interior={interior}
                    onClick={handleInteriorClick}
                    handleEditClick={handleEditClick}
                  />
                </div>
              ))}
          </div>
          {showInteriorModal && (
            <InteriorEditModal
              toggleInteriorModal={toggleInteriorModal}
              interior={hasInitialValues ? interiorModalValue : null}
              deleteInterior={deleteInterior}
              submit={submit}
              restoreInterior={reactivateInterior}
            />
          )}

          {modalState && (
            <CreateVehicleCategoryModal
              toggleCategoryModal={toggleCategoryModal}
              initialValue={null}
              updateCategories={updateCategories}
            />
          )}
        </div>
      )}
    </>
  );
}

export default VehicleCategories;
