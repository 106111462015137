import cx from 'classnames';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import BackMenu from '../../components/BackMenu';
import Container from '../../components/Container';
import ErrorState from '../../components/ErrorState';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import useQueryParams from '../../hooks/useQueryParams';
import useVehicleCategories from '../../hooks/useVehicleCategories';
import CategoryCard from './CategoryCard';
import styles from './SelectVehicleCategory.module.css';



function SelectVehicleCategory() {
  const params = useParams();
  const history = useHistory();
  const { t } = useTranslation();

  const representationFilter = useQueryParams('representationVehicle');

  const { isLoading, categories, hasError } = useVehicleCategories(
    params.category,
    useQueryParams('interior'),
  );

  function handleClick(categoryId) {
    history.push('/request-vehicle?categoryId=' + categoryId);
  }

  return (
    <>
      <BackMenu to="/" text={t('selectvehiclecategory.back')} />
      <Container>
        <h1>
          <Trans i18nKey="selectvehiclecategory.title"></Trans>
        </h1>
        <div className="row">
          {!isLoading &&
            !hasError &&
            categories.map(
              category =>
                (!representationFilter ||
                  category.isRepresentationVehicle.toString() === representationFilter) && (
                  <div className="col-md-6 margin-bottom-2" key={category.id}>
                    <CategoryCard category={category} onClick={handleClick} />
                  </div>
                ),
            )}
          {isLoading && (
            <div className={cx('col-md-12 flex flex-center', styles.loadingState)}>
              <LoadingSpinner />
            </div>
          )}
          {!isLoading && hasError && (
            <div className={cx('col-md-12 flex flex-center', styles.loadingState)}>
              <ErrorState />
            </div>
          )}
        </div>
      </Container>
    </>
  );
}

export default SelectVehicleCategory;
