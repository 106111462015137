import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import cx from 'classnames';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import SortableTableHead from '../../../components/SortableTableHead';
import useContracts from '../../../hooks/useContracts';
import useContractQueryFilters from '../useContractQueryFilters';
import tableStyles from '../ContractTable.module.css';
import LoadingSpinner from '../../../components/LoadingSpinner';
import EmptyState from '../../../components/EmptyState';
import ErrorState from '../../../components/ErrorState';
import ContractListItem from '../ContractListItem';
import Pagination from '../../../components/Pagination';
import Icon from '../../../components/Icon';
import { type OrderDirection } from '../../../types/order-types';

function ContractTable() {
  const { t } = useTranslation();
  const history = useHistory();

  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const page = urlParams.get('p') ? +urlParams.get('p') - 1 : undefined;

  const { filter: contractFilter, setSearchQuery, setExclusiveSort } = useContractQueryFilters();
  const { contracts, isLoading, hasError, pagination, loadMore, setPage } = useContracts(
    contractFilter,
    page,
  );

  const setSort = (sortOrders: Array<[string, OrderDirection]>) => {
    setPage(0);
    setExclusiveSort(sortOrders);
  };

  return (
    <>
      <div className="row flex-items-md-right">
        <div className="col-md-3">
          <Input
            id="search-contract"
            placeholder={t('common.search')}
            onChange={evt => {
              setPage(0);
              setSearchQuery(evt.currentTarget.value);
            }}
            value={contractFilter?.query || ''}
            inputClassName="margin-0"
          />
        </div>
        <div className="col-md-3 align-right">
          <Link to="/billing/run-billing">
            <Button className="padding-v-0 padding-h-1">
              <Trans i18nKey="contractoverview.startBilling" />
            </Button>
          </Link>
        </div>
      </div>
      <div className="row">
        <div className={`table table--responsive ${tableStyles.orderlist}`}>
          <div className="table__wrapper">
            <table className={cx({ [tableStyles.empty]: contracts.length === 0 })}>
              <thead>
                <tr>
                  <td style={{ width: '5%' }}>
                    <SortableTableHead
                      i18nKey="common.labels.contractnumber"
                      sortProperties={['contractNumber']}
                      sortFilters={contractFilter?.sort}
                      setSort={setSort}
                    />
                  </td>
                  <td style={{ width: '5%' }}>
                    <SortableTableHead
                      i18nKey="common.labels.snumber"
                      sortProperties={['contract.vehicle.sNumber']}
                      sortFilters={contractFilter?.sort}
                      setSort={setSort}
                    />
                  </td>
                  <td style={{ minWidth: '100px' }}>
                    <Trans i18nKey="contractoverview.listheader.company" />
                  </td>
                  <td style={{ width: '10%' }}>
                    <Trans i18nKey="contractoverview.listheader.contractstart" />
                  </td>
                  <td style={{ width: '10%' }}>
                    <Trans i18nKey="contractoverview.listheader.contractend" />
                  </td>
                  <td style={{ minWidth: '300px' }}>
                    <SortableTableHead
                      i18nKey="contractoverview.listheader.driver"
                      sortProperties={[
                        'contract.contractor.lastname',
                        'contract.contractor.firstname',
                        'contract.contractor.unit',
                      ]}
                      sortFilters={contractFilter?.sort}
                      setSort={setSort}
                    />
                  </td>
                  <td style={{ width: '10%' }}>
                    <Trans i18nKey="common.labels.employeenumber" />
                  </td>
                  <td style={{ minWidth: '100px' }}>
                    <Trans i18nKey="common.labels.price" />
                  </td>
                  <td style={{ width: '5%' }}>
                    <Trans i18nKey="contractoverview.listheader.state" />
                  </td>
                  <td style={{ width: '10%' }}>
                    <Trans i18nKey="contractoverview.listheader.nextbilldate" />
                  </td>
                  <td style={{ width: '10%' }}>
                    <Trans i18nKey="contractoverview.listheader.lastbilldate" />
                  </td>
                  <td style={{ width: '10%' }}></td>
                </tr>
              </thead>
              <tbody>
                {isLoading && (
                  <tr>
                    <td colSpan="12">
                      <div className={tableStyles.empty__outer}>
                        <LoadingSpinner />
                      </div>
                    </td>
                  </tr>
                )}
                {!isLoading && !hasError && contracts.length === 0 && (
                  <tr>
                    <td colSpan="12">
                      <div className={tableStyles.empty__outer}>
                        <EmptyState />
                      </div>
                    </td>
                  </tr>
                )}
                {!isLoading && hasError && (
                  <tr>
                    <td colSpan="12">
                      <div className={tableStyles.empty__outer}>
                        <ErrorState />
                      </div>
                    </td>
                  </tr>
                )}

                {!isLoading &&
                  !hasError &&
                  contracts.length > 0 &&
                  contracts.map(c => <ContractListItem key={c.id} contract={c} />)}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 flex flex-items-center flex-space-between">
          {pagination.totalCount > pagination.take && pagination.totalCount > 0 && (
            <Pagination pagination={pagination} loadMore={loadMore} />
          )}
        </div>
      </div>
      <div
        role="button"
        tabIndex="-1"
        onClick={() => history.push('/contracts/create')}
        className={tableStyles.circle}
      >
        <Icon name="plus" className="white icon--s3"></Icon>
      </div>
    </>
  );
}

export default ContractTable;
