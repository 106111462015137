// @flow

import React, { type Node } from 'react';
import cx from 'classnames';

type Props = {
  children: Node,
  className?: string,
  style?: Object,
};

function CardText({ children, className, style }: Props) {
  const classes = cx('card__text', className);
  return (
    <p className={classes} style={style}>
      {children}
    </p>
  );
}

export default CardText;
