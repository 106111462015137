// @flow

export function getMonths(conflict: boolean) {
  const months = [];

  const activeMonth = getActiveMonth();
  !conflict && months.push(activeMonth - 1);
  months.push(activeMonth);

  return months;
}

export function getActiveMonth() {
  const now = new Date();
  return now.getMonth();
}

export function getLastMonth() {
  const activeMonth = getActiveMonth();

  return new Date(new Date().setMonth(activeMonth - 1)).toISOString();
}
